import {
	CContainer,
	CRow,
	CCol,
	CButton,
	CModal,
	CModalHeader,
	CModalBody,
	CModalTitle,
	CModalFooter,
} from "@coreui/react";

const TermsAndConditions = (props) => {
	return (
		<CModal
			show={props.show}
			size="xl"
			onClose={props.closeHandler}
			color="warning">
			<CModalHeader closeButton>
				<CModalTitle>Terms & Conditions</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CContainer>
					<CRow>
						<CCol lg="12">
							<h4
								style={{
									textDecoration: "underline",
									fontWeight: "bold",
									textAlign: "center",
								}}>
								CONSENT TO CREDIT CHECK
							</h4>
						</CCol>
						<CCol lg="12">
							<p
								style={{
									fontSize: "14px",
									color: "black",
									textAlign: "justify",
								}}>
								I hereby consent to, and accept this as written notice of,
								Quad-Fi or its affiliates (“
								<b>Quad-Fi</b>”) obtaining a copy of my credit report in
								connection with my application for a loan from Quad-Fi (“
								<b>Loan Application</b>”). I acknowledge that the credit
								information obtained from consumer reporting agencies will be
								used to assess this Loan Application. I further consent to the
								collection, use and disclosure of my personal and credit
								information by Quad-Fi to consumer reporting agencies in
								connection with this Loan Application, any resulting loan
								agreement (“<b>Loan Agreement</b>”) and my ongoing customer
								relationship with Quad-Fi. While Quad-Fi will cease collecting
								such information after my customer relationship with Quad-Fi
								ends, I consent to Quad-Fi continuing to disclose such
								information to credit reporting agencies after my customer
								relationship with Quad-Fi has ended to maintain the integrity of
								the credit reporting system and credit granting process. I
								acknowledge that credit reporting agencies may disclose my
								credit and personal information to third parties requesting such
								information. I further consent to Quad-Fi periodically
								monitoring my account and my credit profile throughout the
								duration of my customer relationship with Quad-Fi for purposes
								including, but not limited to, assessing my default risk,
								determining my eligibility for special promotional offers,
								ensuring my compliance with the terms of the Loan Agreement and
								collection of outstanding amounts. I further acknowledge that
								Quad-Fi has provided me with the name and address of the
								consumer reporting agencies supplying the credit report which
								will be referred to in connection with this Loan Application.
								These consumer reporting agencies are Trans Union of Canada,
								Inc. and Equifax Canada Inc., located at 3115 Harvester Road,
								Suite 201, Burlington ON and 5700 Yonge Street, North York, ON,
								respectively. I acknowledge and expressly represent to Quad-Fi
								that I have read and agreed to the terms of Quad-Fi's Privacy
								Policy.
							</p>
						</CCol>
					</CRow>
				</CContainer>
			</CModalBody>
			<CModalFooter>
				<CButton color="info" onClick={() => props.closeHandler()}>
					Close
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default TermsAndConditions;
