import {
	CButton,
	CCol,
	CContainer,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CProgress,
	CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import TermsAndConditions from "../../Components/OfferSuccess/TermsAndConditionsSoftCheck";
import {
	checkDecisionRules,
	generateUcOTP,
	getActiveApplication,
	getUserLatestApplication,
	hardCheckUpdate,
	updateApp,
	verifyUcOTP,
} from "../../services/user";
import { reloadApplication } from "../../utils";

export default function SoftCheckConsent(props) {
	const { register, handleSubmit, watch } = useForm({
		mode: "onChange",
	});
	const [displayTermsAndConditions, setDisplayTermsAndConditions] =
		useState(false);
	const [country, setCountry] = useState("");
	const history = useHistory();
	const [loading, setLoading] = React.useState("");
	const arrivalTime = new Date();
	const [authenticationCode, setAuthenticationCode] = useState("");
	const [submitButton, setSubmitButton] = useState(false);
	const [authorizationCheck, setAuthorizationCheck] = useState(false);
	const [softCheckCheckBox, setSoftCheckCheckBox] = useState(false);
	const [authCreditReportCheckBox, setAuthCreditReportCheckBox] =
		useState(false);

	const hideLoader = () => {
		setLoading(false);
	};
	useEffect(() => {
		if (country === "Mexico") {
			if (authenticationCode.length === 6 && authCreditReportCheckBox) {
				setSubmitButton(true);
			} else {
				setSubmitButton(false);
			}
		} else {
			setSubmitButton(true);
		}
	}, [
		displayTermsAndConditions,
		country,
		authenticationCode,
		authCreditReportCheckBox,
		softCheckCheckBox,
	]);
	const showLoader = () => {
		setLoading(true);
	};

	useEffect(() => {
		(async () => {
			const userCurrentApplication = await getUserLatestApplication();
			let userCountry;
			try {
				userCountry = JSON.parse(
					JSON.parse(userCurrentApplication.application.UcDetails)
						.countryDetails
				).country;
				setCountry(userCountry);
			} catch (e) {
				console.log(e);
			}
			if (
				userCurrentApplication &&
				typeof userCurrentApplication.application !== "undefined"
			) {
				const applicationData = await getActiveApplication(
					userCurrentApplication.application.appId
				);
				if (
					applicationData.isSoftCheckProcessed !== "" &&
					applicationData.isSoftCheckProcessed
				) {
					window.location.href = "/customerPortal";
				}
			}
		})();
	}, []);

	useEffect(() => {
		(async function () {
			if (softCheckCheckBox && country === "Mexico") {
				await generateUcOTP();
			}
		})();
	}, [softCheckCheckBox]);
	const callSoftcheck = async (appId) => {
		const result = await hardCheckUpdate("softCheck");
		let updatedInformation = {
			page: "softcheck",
			appId: appId,
		};
		if (appId) {
			hideLoader();
			if (result.data.success === false) {
				await updateApp(updatedInformation);
				history.push("application/error");
			} else {
				if (
					typeof result.data.message !== "undefined" &&
					result.data.message === "isSoftCheckProcessed"
				) {
					await reloadApplication(history);
				} else {
					await updateApp(updatedInformation);
					sessionStorage.setItem("softCheck", "true");
					props.nextStep();
				}
			}
		}
	};

	const handleSave = async () => {
		let appId = sessionStorage.getItem("appId");
		if (country === "Mexico") {
			const { success } = await verifyUcOTP(authenticationCode);
			if (!success) {
				alert("Invalid Authentication Code");
				return;
			}
		}
		showLoader();

		let decisionRulesCheck = await checkDecisionRules({
			appId: appId,
		});
		if (decisionRulesCheck) {
			if (decisionRulesCheck.status === "Reject") {
				if (decisionRulesCheck.redirect) {
					sessionStorage.setItem("loanLimitExceeded", "1");
				} else {
					sessionStorage.removeItem("loanLimitExceeded");
				}
				history.push("application/error");
			} else {
				await callSoftcheck(appId);
			}
		} else {
			await callSoftcheck(appId);
		}
		await sendAnalytics(arrivalTime, STEP_PAGES.SOFTCHECK_CONSENT);
		hideLoader();
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div className="no-scroll vertical-middle-page">
					<div className="content-container">
						<div className="text-container">
							<h1 className="center text-container-margin">
								<b>We’re Almost There; Your Rate is Just a Click Away.</b>
							</h1>
							<h4 className="readable">
								<b>
									Checking your rate by clicking the button below will not
									affect your credit score.
								</b>{" "}
								However, if you select a product and choose to apply for a loan,
								we will request your full credit report from one or more
								consumer agencies.
							</h4>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="checkbox"
								name="softCheck"
								onChange={(e) => {
									setSoftCheckCheckBox(e.target.checked);
								}}
								id="flexCheckDefault"
								ref={register({ required: true })}
							/>
							<label class="form-check-label" for="flexCheckDefault">
								<b>
									I agree to the &nbsp;
									{/* eslint-disable-next-line */}
									<a
										//eslint-disable-next-line
										href="javascript:void(0)"
										onClick={() => setDisplayTermsAndConditions(true)}>
										Terms & Conditions
									</a>
									{country === "Mexico" ? (
										<>&nbsp;and Clause of electronic media such as PIN</>
									) : null}
								</b>
							</label>
						</div>
						{country === "Mexico" && softCheckCheckBox ? (
							<>
								<div class="form-check">
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											name="authCreditReport"
											onChange={(e) => {
												setAuthCreditReportCheckBox(e.target.checked);
											}}
											value=""
											id="flexCheckDefault"
											ref={register({ required: true })}
										/>
										<label class="form-check-label" for="flexCheckDefault">
											<b>
												{/* eslint-disable-next-line */}
												<a
													//eslint-disable-next-line
													href="javascript:void(0)"
													onClick={() => setAuthorizationCheck(true)}>
													Authorization to request credit Report
												</a>
											</b>
										</label>
									</div>
									<label style={{ padding: "10px" }}>Authentication Code</label>
									<input
										type="text"
										style={{ width: "30%" }}
										placeholder="6-digit code"
										value={authenticationCode}
										maxLength="6"
										onChange={(e) => setAuthenticationCode(e.target.value)}
										required
									/>
									<br />
									<label>
										We just sent you a email with a 6-digit code. Please enter
										in textbox
									</label>
								</div>
							</>
						) : null}
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{softCheckCheckBox && submitButton ? (
								<CButton className="custom-button enabled" onClick={handleSave}>
									Check my Rate
								</CButton>
							) : (
								<CButton
									className="custom-button disabled"
									onClick={handleSubmit(props.nextStep)}
									disabled>
									Check my Rate
								</CButton>
							)}
						</div>
					</div>
				</div>
			</div>
			<TermsAndConditions
				country={country}
				show={displayTermsAndConditions}
				closeHandler={() => setDisplayTermsAndConditions(false)}
			/>
			<CModal
				show={authorizationCheck}
				size="xl"
				onClose={() => setAuthorizationCheck(false)}
				color="warning">
				<CModalHeader closeButton>
					<CModalTitle>Terms & Conditions</CModalTitle>
				</CModalHeader>
				<CModalBody>
					<CContainer>
						<CRow>
							<CCol lg="12">
								<h4
									style={{
										textDecoration: "underline",
										fontWeight: "bold",
										textAlign: "center",
									}}>
									Authorization to request Credit Reports
								</h4>
							</CCol>
							<CCol lg="12">
								<p
									style={{
										fontSize: "14px",
										color: "black",
										textAlign: "justify",
									}}>
									I expressly authorize Quad-Fi Inc to carry out Investigations
									on my Credit Behavior in Credit Information Companies (SIC)
									that it deems ROCAD appropriate. I know the nature and scope
									of the Information that will be requested, the use that will
									be given and that periodic inquiries of my credit history may
									be made.I agree that this authorization has a validity of 3
									years as of today, and where appropriate as long as we have a
									legal relationship. I accept that this document remains the
									property of (Quad-Fi Inc and/or SIC Consulted) for purposes of
									control and compliance with article 28 of the LRSIC.
								</p>
							</CCol>
						</CRow>
					</CContainer>
				</CModalBody>
				<CModalFooter>
					<CButton color="info" onClick={() => setAuthorizationCheck()}>
						Close
					</CButton>
				</CModalFooter>
			</CModal>
		</section>
	);
}
