import { CButton } from "@coreui/react";
import { useEffect } from "react";
import logoIcon from "src/assets/icons/app-logo.png";
import { sendAnalytics } from "src/services/analyticsService";
import usePageTracking from "src/usePageTracking";
import { STEP_PAGES } from "src/Utils/enums";
import { useHistory } from "react-router-dom";

const TryOfferLater = () => {
	const history = useHistory();
	useEffect(() => {
		(async function () {
			await sendAnalytics(new Date(), STEP_PAGES.TRY_LATER);
		})();
	});
	usePageTracking();

	const handleClick = () => {
		history.push("customerPortal");
	};

	return (
		<section className="purposeWrappers">
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div className="no-scroll vertical-middle-page">
					<div style={{ textAlign: "center" }}>
						<h2>
							<strong>
								Plesae make sure the information provided is correct.
								<br />
								We couldn't approve your credit report
							</strong>
						</h2>
						<div className="button-container">
							<CButton className="custom-button enabled" onClick={handleClick}>
								Go back to Portal
							</CButton>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default TryOfferLater;
