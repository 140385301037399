import React from "react";
import CIcon from "@coreui/icons-react";

const _nav = [
	// {
	//     _tag: "CSidebarNavDivider",
	//     className: "m-3",
	// },
	{
		_tag: "CSidebarNavItem",
		name: "Dashboard",
		to: "/customerPortal",
		icon: <CIcon name="cil-Home" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavDropdown",
		name: "Personal Information",
		route: "/basicInfo",
		icon: "cilUser",
		_children: [
			{
				_tag: "CSidebarNavItem",
				name: "General",
				to: "/basicInfo/userInfo",
			},
			{
				_tag: "CSidebarNavItem",
				name: "Address",
				to: "/basicInfo/AddressInfo",
			},
			{
				_tag: "CSidebarNavItem",
				name: "Education",
				to: "/basicInfo/EducationInfo",
			},
			{
				_tag: "CSidebarNavItem",
				name: "Employment",
				to: "/basicInfo/EmploymentInfo",
			},
		],
	},
	{
		_tag: "CSidebarNavDropdown",
		name: "Documents",
		route: "/documents",
		icon: "cil-Paperclip",
		_children: [
			{
				_tag: "CSidebarNavItem",
				name: "Customer Documents",
				to: "/documents/generalDocuments",
			},
			{
				_tag: "CSidebarNavItem",
				name: "Loan Documents",
				to: "/documents/legalDocuments",
			},
		],
	},
	
	{
		_tag: "CSidebarNavDropdown",
		name: "Loan Details",
		route: "/loanDetails",
		icon: "cilBookmark",
		_children: [
			{
				_tag: "CSidebarNavItem",
				name: "Loan Summary",
				to: "/loanDetails",
			},
			{
				_tag: "CSidebarNavItem",
				name: "Loan Schedule",
				to: "/schedule",
			},
			{
				_tag: "CSidebarNavItem",
				name: "Monthly Statement",
				to: "/monthlyStatement",
			},
		],
	},
	{
		_tag: "CSidebarNavDivider",
		className: "m-2",
	},
];

export default _nav;
