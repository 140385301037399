import { CProgress } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { getLoggedUser } from "src/actions/users";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import store from "src/store";
import { GetAllProducts } from "../../actions/products";
import { updateAppProduct, updateUserAppFlow } from "../../services/user";
import { Tooltip } from "src/Components/Tooltip/Tooltip";

const OtherOffer = (props) => {
	const history = useHistory();
	const offerNameRef = useRef();
	const [offers, setOffers] = useState("");
	const [allProducts, setAllProducts] = useState([]);
	const [loading, setLoading] = React.useState("");
	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};
	// Add State / Functionality

	const handleSave = async (id) => {
		showLoader();
		let updatedInformation = {
			page: "product",
		};
		let user = await getLoggedUser();
		sessionStorage.removeItem("appId");
		const result = await updateUserAppFlow(user.id, updatedInformation);
		if (result.success) {
			hideLoader();
			await updateAppProduct({ page: "product", productId: id });
			history.push("productPurpose");
		}
	};

	const handleSaveAndExit = () => {
		history.push("customerPortal");
	};

	const handleOtherOffers = (event) => {
		setOffers(event.target.value);
	};

	useEffect(() => {
		const localFn = async () => {
			console.log(offers);
			showLoader();
			const result = await props.GetAllProducts();
			hideLoader();
			console.log("props.data", result);
			setAllProducts(store.getState().products.products);
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	// Render
	return (
		<section className="otherOffer-nw">
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div className="no-scroll">
					<div class="content-container">
						<div className="text-container">
							<h1>
								Hmm, it looks like this loan is not the right fit for you. Here
								are some products that may better meet you needs:
							</h1>
						</div>
						<div className="cards-container">
							{allProducts.map((object, index) =>
								object.id !== sessionStorage.getItem("productId") ? (
									<div className="icon-card change-loan">
										<input
											ref={offerNameRef}
											offer-id={object.id}
											type="radio"
											id={"offer-" + object.id}
											name="offer-type"
											value={"offer-" + object.id}
											class="custom-radio"
											onChange={handleOtherOffers}
										/>
										<label
											htmlFor={"offer-" + object.id}
											className="card-label">
											<img src={object.path} alt="" />
											<h2>
												<b>{object.name}</b>
											</h2>
											<button onClick={() => handleSave(object.id)}>
												<b>Get Started</b>
											</button>
										</label>
									</div>
								) : (
									""
								)
							)}
						</div>
					</div>
					<div className="load-class">
						{loading ? (
							<div className="load-class-inner">
								<img src={loadingIcon} alt="" />
							</div>
						) : null}
					</div>
					<div className="text-center btm-conetentor">
						<span>or</span>
						<button onClick={handleSaveAndExit}>Go back to portal</button>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};
const mapStateToProps = ({ users }) => ({
	data: users,
});

export default withRouter(
	connect(mapStateToProps, { GetAllProducts })(OtherOffer)
);
