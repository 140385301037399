import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from "@coreui/react";
import axios from "axios";
import { createBrowserHistory } from "history";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { withOneTabEnforcer } from "react-one-tab-enforcer";
import { connect } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/fixed";
import { checkAuth } from "./actions/users";
import { Login } from "./Components/Login/Login";
import { Register } from "./Components/Login/Register";
import CustomerPortal from "./containers/CustomerPortal";
import ErrorPage from "./page/Application_flow/ErrorPage";
import ErrorPage2 from "./page/Application_flow/ErrorPage2";
import Intro from "./page/Application_flow/Intro";
import OtherOffer from "./page/Application_flow/OtherOffer";
import AddressInfo from "./page/BasicInfo/AddressInfo";
import ChangePassword from "./page/BasicInfo/changePassword";
import EducationInfo from "./page/BasicInfo/EducationInfo";
import EmploymentInfo from "./page/BasicInfo/EmploymentInfo";
import UserInfo from "./page/BasicInfo/userInfo";
import Blogs from "./page/Blogs/Blogs";
import DetailedBlog from "./page/Blogs/DetailedBlog";
import FirstToKnow from "./page/BoardingProcess/FirstToKnow";
import ForgetPass from "./page/BoardingProcess/ForgetPass";
import ForgetPassForm from "./page/BoardingProcess/ForgetPassForm";
import LoanAmount from "./page/BoardingProcess/LoanAmount";
import SignIn from "./page/BoardingProcess/Login";
import ProductPurpose from "./page/BoardingProcess/ProductPurpose";
import ProductSelection from "./page/BoardingProcess/ProductSelection";
import SignUp from "./page/BoardingProcess/Register";
import TryLater from "./page/BoardingProcess/TryLater";
import VerifyEmails from "./page/BoardingProcess/VerifyEmails";
import GeneralDocuments from "./page/Documents/generalDocument";
import LegalDocuments from "./page/Documents/legalDocument";
import LoanDetail from "./page/LoanDetail/loanDetail";
import MonthlyStatement from "./page/Schedule/monthlyStatement";
import Schedule from "./page/Schedule/schedule";
import TermsOfUse from "./page/static/termsOfUse";
import { headers } from "./reusable/headers";
import "./scss/style.scss";
import { getUserLatestApplication } from "./services/user";
import { getUserInbox } from "./store";
import usePageTracking from "./usePageTracking";
import { reloadApplication } from "./utils";
import { hotjar } from "react-hotjar";
import PrQuestions from "./page/BoardingProcess/PrQuestions";
import Country from "./page/BoardingProcess/ucFlow/country";
import CountryQuestion from "./page/BoardingProcess/ucFlow/countryQuestion";
import TryOfferLater from "./page/BoardingProcess/TryOfferLater";

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse" />
	</div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = (props) => {
	const history = createBrowserHistory();
	const [loanDisabled, setLoanDisabled] = useState(false);
	const [sessionRes, setSessionRes] = useState(
		sessionStorage.getItem("appId") ? true : false
	);
	const [showModal, setShowModal] = useState(false);
	const [locationKeys, setLocationKeys] = useState([]);
	const status_check_arr = ["1", "2", "6", "4", "3"];
	usePageTracking(window.location.pathname);

	const logoutUser = async () => {
		try {
			await axios.get(`${process.env.REACT_APP_API_HOST}/V1/user/logout`, {
				headers: headers(),
				withCredentials: true,
			});
			window.location.href = `/signin`;
			sessionStorage.clear();
		} catch (e) {
			console.log("Error while logging out", e.message);
		}
	};

	useEffect(() => {
		hotjar.initialize(3004017, 6);
	}, []);

	//eslint-disable-next-line
	useEffect(() => {
		if (sessionStorage.getItem("current_application_status")) {
			if (
				status_check_arr.includes(
					sessionStorage.getItem("current_application_status")
				)
			) {
				setLoanDisabled(true);
			}
		}
	});

	useEffect(() => {
		return history.listen(async (location) => {
			if (history.action === "PUSH") {
				setLocationKeys([location.key]);
			}

			if (history.action === "POP") {
				if (locationKeys[1] === location.key) {
					setLocationKeys(([_, ...keys]) => keys);
				} else {
					setLocationKeys((keys) => [location.key, ...keys]);
					let userData = await checkAuth();
					if (userData) {
						window.location.href = "/customerPortal";
						// await reloadApplication(history);
					}
				}
			}
		});
	}, [locationKeys, history]);

	useEffect(() => {
		const localFn = async () => {
			let address = window.location.href;

			if (address.includes("utm_source")) {
				let source = new URL(address)?.searchParams.get("utm_source");
				console.log("Source => " + source);
				localStorage.setItem("source", source);
			}
			if (address.includes("utm_medium")) {
				let medium = new URL(address)?.searchParams.get("utm_medium");
				console.log("Medium" + medium);
				localStorage.setItem("Medium", medium);
			}
			if (address.includes("utm_campaign")) {
				let campaign = new URL(address)?.searchParams.get("utm_campaign");
				console.log("Campaign", campaign);
				localStorage.setItem("Campaign", campaign);
			}

			if (address.includes("utm_term")) {
				let keyword = new URL(address)?.searchParams.get("utm_term");
				console.log("Keyword" + keyword);
				localStorage.setItem("Keyword", keyword);
			}

			if (address.includes("utm_content")) {
				let content = new URL(address)?.searchParams.get("utm_content");
				console.log("Content" + content);
				localStorage.setItem("Content", content);
			}
			if (address.includes("utm_BorrowellMemberID")) {
				let utm_BorrowerId = new URL(address)?.searchParams.get(
					"utm_BorrowellMemberID"
				);
				localStorage.setItem("Borrower", utm_BorrowerId);
			}
			if (address.includes("name")) {
				let utm_BorrowerId = new URL(address)?.searchParams.get("name");
				localStorage.setItem("name", utm_BorrowerId);
			}
			if (address.includes("tracking_id")) {
				let utm_BorrowerId = new URL(address)?.searchParams.get("tracking_id");
				localStorage.setItem("tracking_id", utm_BorrowerId);
			}
		};
		localFn();
	}, []);

	useEffect(() => {
		const localFn = async () => {
			let userData = await checkAuth();

			if (userData) {
				const userCurrentApplication = await getUserLatestApplication();
				if (
					userCurrentApplication &&
					typeof userCurrentApplication.application !== "undefined"
				) {
					const current_app_status =
						userCurrentApplication.application.status_id;
					sessionStorage.setItem(
						"current_application_status",
						current_app_status
					);
				}
			}
		};
		localFn();
		document.addEventListener("visibilitychange", () => {
			const visibilityState = document.visibilityState === "hidden" ? 0 : 1;
			axios.post(
				`${process.env.REACT_APP_API_HOST}/V1/admin/updateSessionStatus`,
				{ sessionStatus: visibilityState },
				{ headers: headers(), withCredentials: true }
			);
		});
	}, []);

	useEffect(() => {
		const localFn = async () => {
			let userDataCheck = await checkAuth();
			if (!sessionStorage.getItem("appId")) {
				setSessionRes(userDataCheck);
			} else {
				setSessionRes(true);
			}
		};
		localFn();
	});

	const onPrompt = () => {
		if (sessionRes) {
			setShowModal(true);
		}
	};

	const onIdle = async () => {
		if (sessionRes) {
			setShowModal(false);
			await logoutUser();
		}
	};

	const { reset } = useIdleTimer({
		onPrompt,
		onIdle,
		timeout:
			1000 *
			60 *
			parseInt(process.env.REACT_APP_USER_IDLE_PROMPT_SHOWTIME || "7"),
		promptTimeout:
			1000 *
			60 *
			parseInt(process.env.REACT_APP_USER_IDLE_PROMPT_TIMEOUT || "3"),
		events: [
			"mousemove",
			"keydown",
			"wheel",
			"DOMMouseScroll",
			"mousewheel",
			"mousedown",
			"touchstart",
			"touchmove",
			"MSPointerDown",
			"MSPointerMove",
			"visibilitychange",
		],
		immediateEvents: [],
		debounce: 0,
		throttle: 0,
		eventsThrottle: 200,
		element: document,
		startOnMount: true,
		startManually: false,
		stopOnIdle: false,
		crossTab: false,
		syncTimers: 0,
	});

	const resumeActivity = () => {
		reset();
		setShowModal(false);
	};
	let allowedPageLocations = [
		"/",
		"/productPurpose",
		"/loanAmount",
		"/signup",
		"/signin",
		"/forgetpassword",
		"/verify",
		"/forgetpass",
		"/be-first-to-know",
		"/try-again-later",
		"/try-offer-later",
		"/pr-details",
		"/Country",
		"/CountryQuestion",
	];
	// Exclude public pages for authentication
	useEffect(() => {
		const interval = setIntervalAsync(async () => {
			const authStatus = await checkAuth();
			if (document.visibilityState === "visible" && authStatus) {
				await axios.post(
					`${process.env.REACT_APP_API_HOST}/V1/admin/sendHeartbeat`,
					{},
					{ headers: headers(), withCredentials: true }
				);
			} else if (
				!authStatus &&
				!allowedPageLocations.includes(window.location.pathname)
			) {
				if (
					!window.location.pathname.split("/").includes("reset") &&
					!window.location.pathname.split("/").includes("password")
				) {
					await logoutUser();
				}
			}
		}, 10000);
		return () => clearIntervalAsync(interval);
		//eslint-disable-next-line
	}, []);

	const isUserLoggedIn = sessionStorage.getItem("appId");

	useEffect(() => {
		//If user refreshes the page then take user back to last screen
		const localFn = async () => {
			const userCurrentApplication = await getUserLatestApplication();
			if (
				userCurrentApplication &&
				typeof userCurrentApplication.application !== "undefined"
			) {
				if (userCurrentApplication.application.status_id <= 11) {
					window.addEventListener("popstate", (event) => {
						// window.location.reload();
					});
				}
			}
			if (performance.navigation.type === 1) {
				if (window.location.pathname === "/layout") {
					await reloadApplication(history);
				}
				if (!sessionStorage.getItem("refreshed")) {
					sessionStorage.setItem("refreshed", "1");
					window.location.reload();
				}
			}
		};
		localFn();
	});

	return (
		<>
			<BrowserRouter>
				{props.userInbox.userInbox.length > 0}
				<React.Suspense fallback={loading}>
					<Switch>
						<Route
							exact
							path="/be-first-to-know"
							name="Be the first to know"
							render={(props) => <FirstToKnow {...props} />}
						/>
						<Route
							exact
							path="/try-again-later"
							name="Try Again Later"
							render={(props) => <TryLater {...props} />}
						/>
						<Route
							exact
							path="/try-offer-later"
							name="Try Offer Later"
							render={(props) => <TryOfferLater {...props} />}
						/>
						<Route
							exact
							path="/login"
							name="Login Page"
							render={(props) => <Login {...props} />}
						/>
						<Route
							exact
							path="/register"
							name="Register Page"
							render={(props) => <Register {...props} />}
						/>
						{!isUserLoggedIn && (
							<Route
								exact
								path="/forgetpassword"
								name="Forget Page"
								render={(props) => <ForgetPass {...props} />}
							/>
						)}
						{!isUserLoggedIn && (
							<Route
								exact
								path="/reset/password/:token"
								name="Forget Password"
								render={(props) => <ForgetPassForm {...props} />}
							/>
						)}
						{!isUserLoggedIn && (
							<Route
								exact
								path="/verify/email/:token"
								name="Forget Password"
								render={(props) => <VerifyEmails {...props} />}
							/>
						)}
						{!isUserLoggedIn && (
							<Route
								exact
								path="/signup"
								name="Sign up"
								render={(props) => <SignUp {...props} />}
							/>
						)}
						{!sessionRes ? (
							<Route
								exact
								path="/signin"
								name="Sign In"
								render={(props) => <SignIn {...props} />}
							/>
						) : null}
						{!sessionRes ? (
							<Route
								exact
								path="/pr-details"
								name="PR Details"
								render={(props) => <PrQuestions {...props} />}
							/>
						) : null}
						{!sessionRes ? (
							<Route
								exact
								path="/Country"
								name="Country"
								render={(props) => <Country {...props} />}
							/>
						) : null}
						{!sessionRes ? (
							<Route
								exact
								path="/CountryQuestion"
								name="CountryQuestion"
								render={(props) => <CountryQuestion {...props} />}
							/>
						) : null}
						<Route
							exact
							path="/terms/of/use"
							name="Terms of User"
							render={(props) => <TermsOfUse {...props} />}
						/>
						<Route
							exact
							path="/customerPortal"
							name="Customer Portal"
							render={(props) =>
								sessionRes ? (
									<CustomerPortal {...props} />
								) : (
									<Redirect from="/" to="/productSelection" />
								)
							}
						/>
						{sessionRes && (
							<Route
								exact
								path="/basicInfo/userInfo"
								name="User Info"
								render={(props) => <UserInfo {...props} />}
							/>
						)}
						{sessionRes && (
							<Route
								exact
								path="/user/changePassword"
								name="User Info"
								render={(props) => <ChangePassword {...props} />}
							/>
						)}
						{sessionRes && (
							<Route
								exact
								path="/otherOffers"
								name="Other Offers"
								render={(props) => <OtherOffer {...props} />}
							/>
						)}
						{!loanDisabled && sessionRes && (
							<Route
								exact
								path="/schedule"
								name="Schedule"
								render={(props) => <Schedule {...props} />}
							/>
						)}
						{!loanDisabled && sessionRes && (
							<Route
								exact
								path="/monthlyStatement"
								name="Monthly Statement"
								render={(props) => <MonthlyStatement {...props} />}
							/>
						)}
						{!loanDisabled && sessionRes && (
							<Route
								exact
								path="/loanDetails"
								name="Loan Details"
								render={(props) => <LoanDetail {...props} />}
							/>
						)}
						{sessionRes && (
							<Route
								exact
								path="/documents/generalDocuments"
								name="User Info"
								render={(props) => <GeneralDocuments {...props} />}
							/>
						)}
						{sessionRes && (
							<Route
								exact
								path="/documents/legalDocuments"
								name="User Info"
								render={(props) => <LegalDocuments {...props} />}
							/>
						)}
						{sessionRes && (
							<Route
								exact
								path="/basicInfo/addressInfo"
								name="Address Info"
								render={(props) => <AddressInfo {...props} />}
							/>
						)}
						{sessionRes && (
							<Route
								exact
								path="/basicInfo/educationInfo"
								name="Education Info"
								render={(props) => <EducationInfo {...props} />}
							/>
						)}
						{sessionRes && (
							<Route
								exact
								path="/application/error"
								name="Error"
								render={(props) => <ErrorPage {...props} />}
							/>
						)}
						{sessionRes && (
							<Route
								exact
								path="/application/failed"
								name="Error"
								render={(props) => <ErrorPage2 {...props} />}
							/>
						)}
						{sessionRes && (
							<Route
								exact
								path="/basicInfo/EmploymentInfo"
								name="Employment Info"
								render={(props) => <EmploymentInfo {...props} />}
							/>
						)}
						{!sessionRes ? (
							<Route
								exact
								path="/productSelection"
								name="Product Selection"
								render={(props) => <ProductSelection {...props} />}
							/>
						) : null}
						{!sessionRes ? (
							<Route
								exact
								path="/productPurpose"
								name="Product Purpose"
								render={(props) => <ProductPurpose {...props} />}
							/>
						) : null}
						{!sessionRes ? (
							<Route
								exact
								path="/loanAmount"
								name="Loan Amount"
								render={(props) => <LoanAmount {...props} />}
							/>
						) : null}
						{sessionRes && (
							<Route
								exact
								path="/intro"
								name="Intro"
								render={(props) => <Intro {...props} />}
							/>
						)}
						{sessionRes && (
							<Route
								exact
								path="/layout"
								name="Layout"
								render={(props) => <TheLayout {...props} />}
							/>
						)}
						<Route
							exact
							path="/404"
							name="Page 404"
							render={(props) => <Page404 {...props} />}
						/>
						<Route
							exact
							path="/500"
							name="Page 500"
							render={(props) => <Page500 {...props} />}
						/>
						{sessionRes && (
							<Route
								exact
								path="/blogs"
								name="Blogs"
								render={(props) => <Blogs {...props} />}
							/>
						)}
						{sessionRes && (
							<Route
								exact
								path="/blogs/:id"
								name="Blogs"
								render={(props) => <DetailedBlog {...props} />}
							/>
						)}
						<Route
							path="/"
							name="Home"
							render={(props) =>
								sessionRes ? (
									<Redirect from="/" to="/customerPortal" />
								) : (
									<ProductSelection {...props} />
								)
							}
						/>{" "}
						:
					</Switch>
				</React.Suspense>
				<ToastContainer autoClose={2000} />
			</BrowserRouter>
			<div>
				<CModal show={showModal} onClose={() => setShowModal(!showModal)}>
					<CModalHeader closeButton>
						<CModalTitle>User Inactivity Alert</CModalTitle>
					</CModalHeader>
					<CModalBody>
						You seem to be inactive. We are logging you out in a minute. Press
						OK to resume.
					</CModalBody>
					<CModalFooter>
						<CButton color="secondary" onClick={resumeActivity}>
							OK
						</CButton>
					</CModalFooter>
				</CModal>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({ userInbox: state });

const DifferentWarningComponent = () => (
	<div>
		Sorry! You can only have this application opened in one tab. If trying to
		reset password, please close other quadfi's opened tabs and refresh this
		page.
	</div>
);

export default connect(mapStateToProps, { getUserInbox })(
	withOneTabEnforcer({
		appName: "customer-portal",
		OnlyOneTabComponent: DifferentWarningComponent,
	})(App)
);
