import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import logoIcon from "src/assets/icons/app-logo.png";
import infoIcon from "src/assets/icons/info.svg";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { sendAnalytics } from "src/services/analyticsService";
import store from "src/store";
import { STEP_PAGES } from "src/Utils/enums";
import { GetAllProducts } from "../../actions/products";
import { checkAuth, getLoggedUser, LogoutUser } from "../../actions/users";
import {
	checkGatewayLimit,
	updateAppProduct,
	updateUserAppFlow,
} from "../../services/user";

const ProductSelection = (props) => {
	// Add State / Functionality
	const history = useHistory();
	const [product, setProduct] = useState("");
	const [allProducts, setAllProducts] = useState([]);
	const [loading, setLoading] = React.useState("");
	// Validation
	const [mouseClick, setMouseClick] = useState(1);
	// const [finalTime,setFinalTime]=useState(0)
	const arrivalTime = new Date();
	const keyboardClick = 0;
	const wpm = 0;

	localStorage.setItem("PreviousPage", "NO");
	localStorage.setItem("Autofill", "NO");
	localStorage.setItem("DropOff", "NO");
	localStorage.setItem("KeyBoardClick", keyboardClick);
	localStorage.setItem("WPM", wpm);

	const { register, errors } = useForm({
		mode: "onChange",
	});

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};
	useEffect(() => {
		const localFn = async () => {
			showLoader();
			const result = await props.GetAllProducts();
			hideLoader();
			console.log("props.data", result);
			setAllProducts(store.getState().products.products);
			if (
				localStorage.getItem("ProductSelection") &&
				JSON.parse(localStorage.getItem("ProductSelection")).value &&
				JSON.parse(localStorage.getItem("ProductSelection")).id
			) {
				const data = JSON.parse(localStorage.getItem("ProductSelection"));
				console.log(data);
				await setProduct({ value: data.value, id: data.id });
				await handleSave();
			}
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (product !== "") {
			handleSave();
		}
		//eslint-disable-next-line
	}, [product]);

	const handleRadioCheck = async (e, prod) => {
		localStorage.setItem(
			"ProductSelection",
			JSON.stringify({ value: e.target.value, id: prod.id })
		);
		await setProduct({ value: e.target.value, id: prod.id });
		console.log(JSON.parse(localStorage.getItem("ProductSelection")));
	};

	const handleSave = async () => {
		showLoader();
		let updatedInformation = {
			page: "product",
		};
		const isLogged = await checkAuth();
		if (isLogged) {
			const loggedUser = await getLoggedUser();
			const result = await updateUserAppFlow(loggedUser.id, updatedInformation);
			if (result.success) {
				hideLoader();
				await sendAnalytics(arrivalTime, STEP_PAGES.PRODUCT_SELECTION);
				await updateAppProduct({
					page: "product",
					productId: product.id,
				});
				history.push("productPurpose");
			}
		} else {
			sessionStorage.setItem("productId", product.id);
			hideLoader();
			const data = JSON.parse(localStorage.getItem("ProductSelection"));
			if (data.id === 3) {
				history.push("Country");
			} else {
				history.push("productPurpose");
			}
		}
	};

	useEffect(() => {
		(async () => {
			const { success, redirect } = await checkGatewayLimit({
				loanAmount: 0,
			});
			if (success) {
				if (redirect) {
					window.location.href = "/be-first-to-know";
				}
			}
		})();
	}, []);

	const handleMouseClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	return (
		<section className="product-wrappers">
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div
					className="product-sectionOuter no-scroll vertical-middle-page"
					style={{ marginTop: "0px" }}>
					<div className="content-container">
						<div className="text-container">
							<h1 className="product-title">
								<b>How can we help you?</b>
							</h1>
						</div>
						<div className="purpose-row">
							{allProducts.map((p) => (
								<div
									className={
										p.disabled === 1
											? "icon-card icon-card-disabled"
											: "icon-card"
									}>
									{p.disabled === 0 ? (
										<input
											type="radio"
											id={`${p.name.toLowerCase()}-loan`}
											name="product-type"
											value={`${p.name.toLowerCase()}-loan`}
											className="custom-radio"
											ref={register({
												required: "Please select one of the options",
											})}
											// checked={product == 'personal-loan'}
											onChange={(e) => handleRadioCheck(e, p)}
										/>
									) : null}

									<label
										htmlFor={`${p.name.toLowerCase()}-loan`}
										className="card-label"
										onClick={handleMouseClick}>
										<img src={`${p.path}`} alt="" />
										<b className="text-center inboxtitle">
											{p.name} Loans {p.disabled === 1 ? "(Coming soon)" : null}
										</b>
									</label>
								</div>
							))}
						</div>
						{errors["product-type"] && (
							<div className="error-container">
								<p className="error-message">
									Please select one of the options
								</p>
							</div>
						)}
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<div className="info-container-product bottom-right-info">
				<p className="tool-tip-product">
					<b>
						Our Personal Loans are available to any Canadian citizen or
						permanent resident over the age of 18. Our Newcomer Loans are
						available for immigrants who have overseas credit history (and are
						over 18 and a Canadian citizen or a permanent resident). QuadFi's
						Newcomer Loans convert your overseas credit history to Canadian
						standards to help you get the lowest rates possible.
					</b>
				</p>
				<img width="18px" height="18px" src={infoIcon} alt="" />
			</div>
		</section>
	);
};

const mapStateToProps = ({ users }) => ({
	data: users,
});

export default withRouter(
	connect(mapStateToProps, { LogoutUser, GetAllProducts })(ProductSelection)
);
