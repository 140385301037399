import { CButton, CProgress } from "@coreui/react";
import logoIcon from "src/assets/icons/app-logo.png";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";

const Name = (props) => {
	const arrivalTime = new Date();

	const handleSave = async () => {
		props.nextStep();
		await sendAnalytics(arrivalTime, STEP_PAGES.EQUIFAX_INTRO);
	};

	const handleSaveAndExit = () => {
		localStorage.setItem("DropOff", "YES");
		props.handleSaveExit();
		props.lastStep();
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll no-scrolls vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container spaced equifax">
								<h3>
									<b className="highlight">Step 2 of 3</b>
								</h3>
								<h1>
									<b>Almost there!</b>
								</h1>
								<h2 style={{ fontWeight: "normal" }}>
									To verify your identity, please answer two questions provided
									by a major credit bureau.
								</h2>
								<h4>
									This is used for authentication only and will not impact your
									credit score.
								</h4>
								<h4>
									Please answer these questions only once during any 72 hours
									period; in particular, in case you decide to revise and
									resubmit your application.
								</h4>
							</div>
						</div>
						<div className="button-container">
							<button className="custom-button enabled" onClick={handleSave}>
								Next
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default Name;
