import { CInput, CProgress } from "@coreui/react";
import { useEffect } from "react";
import { useState } from "react";
import { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import logoIcon from "src/assets/icons/app-logo.png";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import {
	COUNTRY_NAMES,
	COUNTRY_CREDIT_REPORT_LIST,
	COUNTRY_SUPPLY_CREDIT_REPORT,
} from "src/Utils/consts";

const Country = (props) => {
	const history = useHistory();
	const [userCountry, setUserCountry] = useState("");
	const [isFormValid, setisFormValid] = useState(false);
	const [moveDate, setMoveDate] = useState(null);

	const { handleSubmit, errors, formState } = useForm({
		reValidateMode: "onChange",
		mode: "all",
	});

	const { isValid } = formState;

	const styless = {
		option: (provided, state) => ({
			...provided,
			fontWeight: state.isSelected ? "bold" : "normal",
			color: state.data.color,
			backgroundColor: "white",
			paddingLeft: "20px",
			marginLeft: "20px",
			marginRight: "200px",
			fontSize: 15,
		}),
		menu: (provided) => ({ ...provided, minWidth: "100%", left: 0 }),
	};

	const options = COUNTRY_CREDIT_REPORT_LIST.map((data) => ({
		id: data,
		name: data,
		value: data,
		label: data,
	}));

	const handlePrCategory = async (e) => {
		console.log(e);
		if (e && e.value) {
			setUserCountry({
				id: e.value,
				name: e.value,
				value: e.value,
				label: e.value,
			});
		}
	};

	useEffect(() => {
		const moveDate = JSON.parse(
			sessionStorage.getItem("userCountry")
		)?.moveDate;
		setMoveDate(moveDate);
		const prefilCountry = JSON.parse(
			sessionStorage.getItem("userCountry")
		)?.country;
		setUserCountry({
			id: prefilCountry,
			name: prefilCountry,
			value: prefilCountry,
			label: prefilCountry,
		});
	}, []);

	const handleSave = async () => {
		const countryInfo = {
			country: userCountry.value,
			moveDate: moveDate,
		};
		sessionStorage.setItem("userCountry", JSON.stringify(countryInfo));
		if (COUNTRY_SUPPLY_CREDIT_REPORT.includes(userCountry.value)) {
			history.push("/CountryQuestion");
		} else {
			let details = {
				country: userCountry.value,
				moveDate: moveDate,
			};
			sessionStorage.setItem("countryDetails", JSON.stringify(details));
			let ucForm = {
				orig_country: JSON.parse(sessionStorage.getItem("userCountry")).country,
				request_date: new Date().toISOString().split("T")[0],
				credit_report: null,
			};
			sessionStorage.setItem("uc_input", JSON.stringify(ucForm));
			history.push("/pr-details");
		}
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div
					className="no-scroll vertical-middle-page "
					style={{ width: "100%", padding: 0 }}>
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<div className="text-container">
									<h1 className="product-title">
										<b>What's your country of origin?</b>
									</h1>
								</div>

								<div className="form-control-plaintext custom-input custom-input-container">
									<Select
										placeholder="Please select an option"
										styles={styless}
										options={options}
										name="userCountry"
										// defaultValue={JSON.parse(sessionStorage.getItem("userCountry"))?.country}
										defaultValue={userCountry}
										value={userCountry}
										onChange={(event) => handlePrCategory(event)}
										required
										isSearchable={false}
									/>
								</div>
								{/* </div> */}
							</div>
							<div className="text-container text-container-margin">
								<div className="text-container">
									<h1 className="product-title">
										<b>What date did you move to Canada?</b>
									</h1>
								</div>

								<div className="form-control-plaintext custom-input custom-input-container">
									<CInput
										type="date"
										id="date"
										name="date"
										value={moveDate}
										defaultValue={
											JSON.parse(sessionStorage.getItem("userCountry"))
												?.moveDate
										}
										onChange={(e) => setMoveDate(e.target.value)}
										required
									/>
								</div>
								{/* </div> */}
							</div>

							{Object.keys(errors).length > 0 && (
								<div className="error-container">
									<p className="error-message">
										{errors[Object.keys(errors)[0]].message}
									</p>
								</div>
							)}
						</div>
						<div className="button-container button-container-margin">
							{moveDate && userCountry ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={false}>
									Next
								</button>
							) : (
								<button className="custom-button disabled" disabled={true}>
									Next
								</button>
							)}
							<button
								className="custom-back-button"
								onClick={async () => {
									localStorage.removeItem("ProductSelection");
									sessionStorage.removeItem("productId");
									history.push("/");
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default Country;
