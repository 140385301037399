import {
	CAlert,
	CButton,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CContainer,
	CForm,
	CFormGroup,
	CImg,
	CInput,
	CLabel,
	CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { getLoggedUser } from "src/actions/users";
import menuIcon from "../../assets/icons/menuIcon.png";
import { verifyCodeFirstTime } from "../../services/twoFaService";
import {
	getUserApplications,
	resendVerificationMail,
	updateUser,
	userInfo,
} from "../../services/user";
import "../../style/personalinformation.scss";
import { TheSidebar } from "./../../containers/index";
import TopNav from "./../../containers/TopNav";

const UserInfo = () => {
	const [hidden, setHidden] = React.useState(true);
	const [email, setEmail] = React.useState("");
	const [applications, setApplications] = useState([]);
	const [user, setUser] = useState({});
	const [loggedUser, setLoggedUser] = useState({});
	const [lastname, setLastName] = React.useState("");
	const [firstname, setFirstName] = React.useState("");
	const [applicationId, setApplicationId] = React.useState("");
	const [editShow, setEditShow] = React.useState(false);
	const unRead = [];
	const [loading, setLoading] = React.useState("");

	const [code, setCode] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [showError, setShowMessage] = React.useState(false);
	const [formUpdated, setFormUpdated] = React.useState(false);

	const twoFaField = false;
	const emailVerified = false;

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};
	const handleEmail = (event) => {
		setEmail(event.target.value);
	};

	const handleTwoFa = (event) => {
		setCode(event.target.value);
	};

	const handleLastName = (event) => {
		setLastName(event.target.value);
	};

	const handleFirstName = (event) => {
		setFirstName(event.target.value);
	};

	const verifyCode = async (e) => {
		e.preventDefault();
		showLoader();
		const { data } = await verifyCodeFirstTime(code);

		const msg = data.success
			? "Your Two Factor Authentication is Enabled"
			: data.message;
		hideLoader();
		setShowMessage(true);
		setMessage(msg);
		return;
	};

	const handleSave = () => {
		user.personal.name = firstname;
		user.personal.lastName = lastname;
		user.personal.email = email;

		let updatedInformation = {
			page: "general",
			firstName: user.personal.name,
			lastName: user.personal.lastName,
			email: user.personal.email,
		};
		updateUser(applicationId, updatedInformation);
		setHidden(true);
		setFormUpdated(true);
	};

	useEffect(() => {
		console.log(editShow);
		if (formUpdated) {
			window.location.reload();
		}
		//eslint-disable-next-line
	}, [formUpdated]);

	useEffect(() => {
		const localFn = async () => {
			showLoader();
			// Todo: change the userId to the actual user with logs in

			let user = await getLoggedUser();
			setLoggedUser(user);
			let userApps = await getUserApplications(user.id);
			if (
				userApps.success &&
				userApps.applications &&
				userApps.applications.length > 0
			) {
				setApplications(userApps.applications);
			}
			console.log("printing user applications", applications);

			if (
				sessionStorage.getItem("CurrentSelect") !== 0 &&
				sessionStorage.getItem("CurrentSelect") != null
			) {
				setEditShow(true);
				async function Call() {
					setApplicationId(sessionStorage.getItem("CurrentSelect"));
					showLoader();
					await userInfo(sessionStorage.getItem("CurrentSelect"), setUser);
					hideLoader();
				}
				Call();
			} else {
				const option = document.querySelectorAll(".options");
				sessionStorage.setItem("CurrentSelect", option[0].value);
				let userId = option[0].value;
				setApplicationId(option[0].value);
				showLoader();
				await userInfo(userId, setUser);
				hideLoader();
			}
			console.log("qqqqqqqq", user.email);
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	const [activeCls, setActivecls] = useState(false);
	const menuHandler = () => {
		setActivecls(!activeCls);
	};

	const resendEmailHandler = async () => {
		const result = await resendVerificationMail(loggedUser.id);
		if (result) {
			toast.success("Check Your Email for verification");
		} else {
			toast.error("Something went wrong");
		}
	};

	return (
		<div className="PI_page">
			<div
				className={`c-app c-default-layout ${
					activeCls ? "sidBrexpand" : null
				}`}>
				<TheSidebar />
				<div className="c-wrapper">
					<div className="c-body">
						<CContainer fluid className="PageLayout">
							<CRow class="w-87">
								<CCol class="col-auto">
									<div className="user-name-container">
										<h1 className="p-0 pb-2">
											<b>Basic Information</b>
										</h1>
									</div>
									<CCard className="m-4">
										<CCardHeader>
											<div className="d-flex"></div>
										</CCardHeader>
										<div className="contain_body">
											<CCardBody>
												{!user.personal ? null : (
													<CForm action="" method="post">
														<CFormGroup>
															<CLabel
																htmlFor="first_name"
																style={{
																	color: "#ff9e00",
																}}>
																<b>First Name</b>
															</CLabel>
															{hidden ? (
																<CInput
																	type="first_name"
																	id="first_name"
																	name="first_name"
																	disabled
																	value={user.personal.name}
																	class="form-control-plaintext w-67 h-58"
																/>
															) : (
																<CInput
																	type="first_name"
																	id="first_name"
																	name="first_name"
																	onChange={handleFirstName}
																	class="form-control w-67 h-58"
																/>
															)}
														</CFormGroup>
														<CFormGroup>
															<CLabel
																htmlFor="last_name"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Last Name</b>
															</CLabel>
															{hidden ? (
																<CInput
																	type="last_name"
																	id="last_name"
																	name="last_name"
																	disabled
																	value={user.personal.lastName}
																	class="form-control-plaintext w-67 h-58"
																/>
															) : (
																<CInput
																	type="last_name"
																	id="last_name"
																	name="last_name"
																	onChange={handleLastName}
																	class="form-control w-67 h-58"
																/>
															)}
														</CFormGroup>
														<CFormGroup>
															<CLabel
																htmlFor="email"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Email</b>
															</CLabel>
															{hidden ? (
																<CInput
																	type="email"
																	id="email"
																	name="email"
																	disabled
																	value={
																		user.personal.email
																			? user.personal.email
																			: loggedUser.email
																		// loggedUser.email
																	}
																	class="form-control-plaintext w-67 h-58"
																/>
															) : (
																<CInput
																	type="email"
																	id="email"
																	name="email"
																	onChange={handleEmail}
																	class="form-control w-67 h-58"
																/>
															)}
														</CFormGroup>
														<CFormGroup>
															<CLabel
																htmlFor="dob"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Date Of Birth</b>
															</CLabel>
															<CInput
																type="dob"
																id="dob"
																name="dob"
																disabled
																value={
																	user.personal.Date_of_birth === 0 ||
																	user.personal.Date_of_birth === "Invalid date"
																		? ""
																		: user.personal.Date_of_birth.split(", ")
																				.reverse()
																				.join()
																				.replaceAll(",", "-")
																}
																class="form-control-plaintext w-67 h-58"
															/>
														</CFormGroup>
														{twoFaField && (
															<>
																{" "}
																<CInput
																	type="twofacode"
																	id="twofacode"
																	name="twofacode"
																	placeholder="Please Enter your Code here..."
																	onChange={handleTwoFa}
																	class="form-control-plaintext w-67 h-58"
																/>
																<button
																	class="btn btn-primary"
																	onClick={verifyCode}>
																	Verify
																</button>
															</>
														)}
														{emailVerified && (
															<CAlert className="text-center" color="warning">
																{message}
																<span
																	className="resendEmailButton"
																	onClick={resendEmailHandler}>
																	Resend Email
																</span>
															</CAlert>
														)}
														{showError && (
															<CAlert className="text-center" color="warning">
																{message}
															</CAlert>
														)}
													</CForm>
												)}
												<div
													className={
														"load-class " +
														(!user.personal ? "load_change" : "")
													}>
													{loading ? (
														<Loader
															type="ThreeDots"
															color="#ff9700"
															height={100}
															width={100}
															timeout={10000}
														/>
													) : null}
												</div>
											</CCardBody>
										</div>

										<CCardFooter>
											<CRow>
												<CButton
													shape="pill"
													className="SaveButton"
													style={
														hidden
															? {
																	visibility: "hidden",
															  }
															: {
																	visibility: "visible",
															  }
													}
													onClick={() => handleSave()}>
													Save
												</CButton>
												<CButton
													shape="pill"
													className="CancelButton"
													style={
														hidden
															? {
																	visibility: "hidden",
															  }
															: {
																	visibility: "visible",
															  }
													}
													onClick={() => setHidden(true)}>
													Cancel
												</CButton>
											</CRow>
										</CCardFooter>
									</CCard>
								</CCol>
							</CRow>
						</CContainer>
						<div className="top_navOuter position-relative">
							<button className="bars" onClick={menuHandler}>
								<CImg src={menuIcon}></CImg>
							</button>
							<TopNav unRead={unRead} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(UserInfo);
