import { CButton, CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import logoIcon from "src/assets/icons/app-logo.png";
import infoIcon from "src/assets/icons/info.svg";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import { getUserInProgressApp, updateUserAppFlow } from "../../services/user";

const Investment = (props) => {
	const inProgressApp = getUserInProgressApp();
	let preFill = { has_investments: "", investment_amount: "" };
	if (inProgressApp) {
		if (inProgressApp.appData.investment["has_investment"] !== undefined) {
			preFill["has_investments"] =
				inProgressApp.appData.investment.has_investment;
		}
		if (inProgressApp.appData.investment["investment_amount"] !== undefined) {
			if (inProgressApp.appData.investment["investment_amount"] !== "") {
				preFill["investment_amount"] =
					inProgressApp.appData.investment.investment_amount;
			} else {
				preFill["investment_amount"] =
					inProgressApp.appData.investment.investment_amount;
			}
		}
	}
	const { register, handleSubmit, watch, errors } = useForm({
		mode: "onChange",
	});
	const [hasInvestment, setHasInvestment] = useState(preFill.has_investments);
	const [investmentAmount, setInvestmentAmount] = useState(
		preFill.investment_amount
	);
	const [loading, setLoading] = React.useState("");
	const [keyboardClick, setKeyboardClick] = useState(1);
	const [mouseClick, setMouseClick] = useState(1);
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

	const arrivalTime = new Date();

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	useEffect(() => {
		if (hasInvestment !== "" && mouseClick > 1) {
			if (hasInvestment === "no") {
				handleSave();
			}
		}
		//eslint-disable-next-line
	}, [hasInvestment, mouseClick]);

	const handleSave = async (obj1, back) => {
		setNextButtonDisabled(true);
		let details = {
			page: "investment",
			hasInvestment,
			investmentAmount,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			setNextButtonDisabled(false);
			hideLoader();
			if (typeof back !== "boolean" || !back) {
				props.nextStep();
				await sendAnalytics(arrivalTime, STEP_PAGES.INVESTMENT);
			} else {
				localStorage.setItem("PreviousPage", "YES");
				console.log("Current step is: " + sessionStorage.getItem("productId"));
				if (sessionStorage.getItem("productId") === "1") {
					props.goToStep(18);
				} else if (sessionStorage.getItem("productId") === "2") {
					props.goToStep(14);
				} else if (sessionStorage.getItem("productId") === "4") {
					props.goToStep(12);
				}
				await sendAnalytics(arrivalTime, STEP_PAGES.INVESTMENT);
			}
		}
		return response;
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		let details = {
			page: "investment",
			hasInvestment,
			investmentAmount,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			hideLoader();
			props.handleSaveExit();
			props.lastStep();
		}
	};

	const handleInvestmentPayment = (value) => {
		if (value) {
			const newValue = value
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			setInvestmentAmount(newValue.replace(/,/g, ""));
			return newValue;
		}
		return value;
	};

	const investmentWatch = watch("hasInvestments");

	let investmentSection;
	if (hasInvestment === "yes") {
		investmentSection = (
			<div className="input-container">
				<div className="no-left-padding">
					<label htmlFor="investmentAmount" className="custom-label">
						<b>Total Investment/Saving Amount</b>
					</label>
					<div className="amount-input">
						<input
							type="text"
							id="investmentAmount"
							name="investmentAmount"
							class="form-control-plaintext w-67 h-58 custom-input"
							placeholder="50,000"
							onChange={(event) => {
								const { value } = event.target;
								event.target.value = handleInvestmentPayment(value);
								setKeyboardClick(keyboardClick + 1);
								localStorage.setItem("KeyBoardClick", keyboardClick);
							}}
							defaultValue={investmentAmount}
							ref={register({
								required: true,
								pattern: /^[0-9.,]*$/i,
							})}
						/>
					</div>
				</div>
			</div>
		);
	} else {
		investmentSection = null;
	}

	const handleMouseClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>Do you have any savings or investments?</b>
								</h1>
							</div>
							<div className="cards-container">
								<div className="icon-card">
									<input
										type="radio"
										id="yesInvestment"
										name="hasInvestments"
										value="yes"
										class="custom-radio"
										onChange={(event) => setHasInvestment(event.target.value)}
										ref={register({ required: true })}
										onClick={handleMouseClick}
										checked={hasInvestment === "yes" ? true : false}
									/>
									<label htmlFor="yesInvestment" className="card-label">
										<h2>
											<b>Yes</b>
										</h2>
									</label>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="noInvestment"
										name="hasInvestments"
										value="no"
										class="custom-radio"
										onChange={(event) => setHasInvestment(event.target.value)}
										onClick={handleMouseClick}
										ref={register({ required: true })}
										checked={hasInvestment === "no" ? true : false}
									/>
									<label htmlFor="noInvestment" className="card-label">
										<h2>
											<b>No</b>
										</h2>
									</label>
								</div>
							</div>
							{investmentSection}
							{errors.hasInvestment && (
								<div className="error-container">
									<p className="error-message">Please select an option</p>
								</div>
							)}
							{errors.investmentAmount && (
								<div className="error-container">
									<p className="error-message">Please enter a valid amount</p>
								</div>
							)}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{typeof errors.hasInvestments === "undefined" &&
							typeof errors.investmentAmount === "undefined" &&
							investmentWatch ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							) : (
								<button
									className="custom-button disabled"
									onClick={handleSubmit(handleSave)}
									disabled>
									Next
								</button>
							)}
							<button
								className="custom-back-button"
								onClick={async () => {
									await handleSave(
										{
											hasInvestment: hasInvestment,
											investmentAmount: investmentAmount,
										},
										true
									);
									if (props?.previousStep) {
										props.previousStep();
									}
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="info-container">
				<p className="tool-tip">
					<b>Ex: Stock, cash, Crypto., etc.</b>
				</p>
				<img src={infoIcon} alt="" />
			</div>
		</section>
	);
};

export default Investment;
