import * as actionTypes from "./actionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import { headers } from "../reusable/headers";

export const getRequest = () => {
	return {
		type: actionTypes.GET_REQUEST_SUCCESS,
		payload: {},
	};
};

export const getRMRequest = () => {
	return {
		type: actionTypes.GET_RM_REQUEST,
		payload: {},
	};
};

export const SignupSuccess = (response) => {
	return {
		type: actionTypes.SIGNUP_SUCCESS,
		payload: {
			response,
		},
	};
};

export const LoginSuccess = (response) => {
	return {
		type: actionTypes.LOGIN_SUCCESS,
		payload: {
			response,
		},
	};
};

export const SignupUser = (data) => async (dispatch) => {
	try {
		dispatch(getRequest());
		await axios
			.post(`${process.env.REACT_APP_API_HOST}/V1/user/signup`, data, {
				headers: { "Access-Control-Allow-Origin": "*" },
				withCredentials: true,
			})
			.then((response) => {
				if (!response.data.authenticated) {
					if (response.data.message) toast.error(response.data.message);
					dispatch(createError(response.data.message));
				} else {
					toast.success("Check Your Email for verification");
					const { sessionToken } = response.data;
					sessionStorage.setItem("_st", sessionToken);
					return dispatch(SignupSuccess(response.data));
				}
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const LoginUser = (data) => async (dispatch) => {
	try {
		dispatch(getRequest());
		await axios
			.post(`${process.env.REACT_APP_API_HOST}/V1/user/login`, data, {
				headers: { "Access-Control-Allow-Origin": "*" },
				withCredentials: true,
			})
			.then((response) => {
				if (!response.data.authenticated) {
					if (response.data.message) toast.error(response.data.message);
					dispatch(createError(response.data.message));
				} else {
					toast.success("Successfully Logged In.");
					const { sessionToken } = response.data;
					sessionStorage.setItem("_st", sessionToken);
					return dispatch(LoginSuccess(response.data));
				}
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const LogoutUser = () => async (dispatch) => {
	try {
		dispatch(getRequest());
		axios
			.get(`${process.env.REACT_APP_API_HOST}/V1/user/logout`, {
				headers: headers(),
				withCredentials: true,
			})
			.then((response) => {
				console.log("logout action user");
				sessionStorage.clear();
				localStorage.clear();
				window.location.href = `/signin`;

				toast.success("Successfully Logged Out.");
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const createError = (error) => {
	console.log("error", error);
	if (error && error.response && error.response.status === 401) {
		clearSession();
	}

	return function (dispatch) {
		setTimeout(function () {
			dispatch(clearError());
		}, 2000);
		dispatch({
			type: actionTypes.GET_RESPONSE_ERROR,
			payload: error,
		});
	};
};

export const clearError = () => {
	console.log("clear error");
	return function (dispatch) {
		dispatch({
			type: actionTypes.CLEAR_ERROR,
		});
	};
};

export const setUnreadMessageCount = (dispatch, count = 0) => {
	console.log("set unread Message Count");
	return dispatch({
		type: actionTypes.SET_UNREAD_MESSAGE_COUNT,
		payload: { count },
	});
};

export const checkAuth = async () => {
	try {
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_HOST}/V1/admin/checkAuth`,
			{ headers: headers(), withCredentials: true }
		);
		return data.success;
	} catch (e) {
		return false;
	}
};

export const getLoggedUser = async () => {
	try {
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_HOST}/V1/admin/getUser`,
			{ headers: headers(), withCredentials: true }
		);
		return data;
	} catch (e) {
		return {};
	}
};

const clearSession = () => {
	sessionStorage.clear();
	// history.push('/login')
	window.location.href = "/signin";
};
