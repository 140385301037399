import { CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { EID_WAIT_TIME, ENV } from "src/Utils/consts";
import { PRODUCTID, STEP_PAGES } from "src/Utils/enums";
import * as actionTypes from "../../../actions/actionTypes";
import {
	getActiveApplication,
	getEquifaxQuestions,
} from "../../../services/user";

const EquifaxQ1 = (props) => {
	let dispatch = useDispatch();
	const history = useHistory();
	let [transactionKey, setTransactionKey] = useState("");
	const [question, setQuestion] = React.useState();
	const [mouseClick, setMouseClick] = useState(0);
	const [loading, setLoading] = React.useState("");

	const arrivalTime = new Date();

	const { register, errors, formState } = useForm({
		mode: "onChange",
	});
	const { isValid } = formState;
	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	useEffect(() => {
		console.log(isValid);
		async function processEquifaxQuestions() {
			showLoader();

			const applicationData = await getActiveApplication(
				sessionStorage.getItem("appId")
			);

			let callEidApi = true;

			if (applicationData.equifaxQuestionsCalledAt !== "") {
				const apiCallDate = new Date(applicationData.equifaxQuestionsCalledAt);
				const currentDate = new Date();
				const milliseconds = Math.abs(currentDate - apiCallDate);
				const hours_diff = milliseconds / 36e5;
				if (parseInt(hours_diff) < EID_WAIT_TIME) {
					callEidApi = false;
				}
			}

			if (callEidApi) {
				let equifaxQuestions = await getEquifaxQuestions();
				console.log(
					equifaxQuestions,
					"EQUIFAX QUESTION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
				);
				if (equifaxQuestions && equifaxQuestions.data) {
					if (equifaxQuestions.data.success) {
						setQuestion(equifaxQuestions.data.questions[0]);
						setTransactionKey(equifaxQuestions.data.transactionKey);
						localStorage.setItem("equifax", JSON.stringify(equifaxQuestions));
					} else if (equifaxQuestions.data.status === "Try Again") {
						window.location.href = "/try-again-later";
					} else if (equifaxQuestions.data.status === "No Match Found") {
						if (
							sessionStorage.getItem("productId") &&
							sessionStorage.getItem("productId") === PRODUCTID.NEWCOMER
						) {
							// If selected product is New comer
							props.goToStep(19);
						}
						else{
							window.location.href = "/try-again-later";
						}
					} else if (equifaxQuestions.data.status === "Rejected") {
						history.push("application/error", {
							isFraudCheckFailed: true,
						});
					} else {
						window.location.href = "/try-again-later";
					}
				} else {
					window.location.href = "/try-again-later";
				}
			} else {
				window.location.href = "/try-again-later";
			}

			hideLoader();
		}
		processEquifaxQuestions();
		//eslint-disable-next-line
	}, []);

	const handleSave = async (data) => {
		data.transactionKey = transactionKey;
		dispatch({
			type: actionTypes.SET_EQUIFAX_ANSWER_1,
			payload: data,
		});
		props.nextStep();
		await sendAnalytics(arrivalTime, STEP_PAGES.EQUIFAXQ1);
	};

	const handleClick = (eq_answerID = "") => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);

		if (eq_answerID !== "") {
			const selectedAnswer = {
				answer: eq_answerID,
			};
			handleSave(selectedAnswer);
		}
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div className="no-scroll tablet vertical-middle-page">
					<div>
						<div class="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>{question && question.QuestionText}</b>
								</h1>
							</div>
							<div className="cards-container">
								{question &&
									question.AnswerChoice.map((cardContent, index) => (
										<div className="icon-card equifax-question-box">
											<input
												type="radio"
												id={"EQ1-" + index}
												name="answer"
												value={cardContent["answerId"]}
												class="custom-radio"
												ref={register({
													required: "Please select one of the options",
												})}
												onClick={() => handleClick(cardContent["answerId"])}
											/>
											<label
												htmlFor={"EQ1-" + index}
												className="card-label inboxtitle">
												<h3>
													<b>
														{cardContent["$t"]}
														{process.env.REACT_APP_ENVIRONMENT_DEPLOY_NAME ===
															ENV.DEV && cardContent["correctAnswer"] === "true"
															? "(C)"
															: ""}
													</b>
												</h3>
											</label>
										</div>
									))}
							</div>
							<div className="load-class">
								{loading ? (
									<div className="load-class-inner">
										<img src={loadingIcon} alt="" />
									</div>
								) : null}
							</div>
							{Object.keys(errors).length > 0 && (
								<div className="error-container">
									<p className="error-message">
										{errors[Object.keys(errors)[0]].message}
									</p>
								</div>
							)}
						</div>
						<div className="load-class"></div>
						<div className="button-container"></div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default EquifaxQ1;
