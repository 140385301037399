import { Link } from "react-router-dom";
import React from "react";

const NextButton = ({ handleSave, route, className = "" }) => {
	return (
		<div>
			<Link
				className={"btn btn-secondary float-right m-2 " + className}
				onClick={handleSave}
				to={route}>
				Next
			</Link>
		</div>
	);
};

export default NextButton;
