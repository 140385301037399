import React, { useState } from "react";
import "../../style/personalinformation.scss";
import { getLoggedUser, LogoutUser } from "../../actions/users";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TheSidebar } from "./../../containers/index";
import TopNav from "./../../containers/TopNav";
import { toast } from "react-toastify";
import { generateToken, updatePassword } from "../../services/user";
import menuIcon from "../../assets/icons/menuIcon.png";
import {
	CContainer,
	CRow,
	CCol,
	CButton,
	CModal,
	CModalHeader,
	CModalBody,
	CModalTitle,
	CModalFooter,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CCard,
	CCardBody,
	CCardFooter,
	CImg,
} from "@coreui/react";
import { passwordStrength } from "check-password-strength";

const toastOptions = {
	autoClose: 5000,
	delay: 0,
};

const ChangePasswordNew = (props) => {
	const unRead = [];
	const [activeCls, setActivecls] = useState(false);
	const [isModalOneOpen, setIsModalOneOpen] = useState(true);
	const [isModalTwoOpen, setIsModalTwoOpen] = useState(false);
	const [password, setPassword] = useState({
		value: "",
		error: false,
		errMsg: "",
	});
	const [inputForm, setInputForm] = useState({
		nPValue: "",
		nPError: false,
		nPErrMsg: "",
		cPValue: "",
		cPError: false,
		cPErrMsg: "",
		tValue: "",
		tError: false,
		tErrMsg: "",
	});

	const [passwordAdded, setPasswordAdded] = useState(false);
	const [strength, setStrength] = useState(false);
	const [strongPasswordEntered, setStrongPasswordEntered] = useState(false);

	function closeModalOneHandler() {
		setIsModalOneOpen(false);
		window.location.href = "/customerPortal";
	}

	async function submitPasswordHandler() {
		if (password.value === "") {
			setPassword({ ...password, error: true });
		} else {
			setPassword({ ...password, error: false });
			const { email } = await getLoggedUser();
			if (email && email.length) {
				const { success, message } = await generateToken(email, password.value);
				if (success) {
					toast.success(message, toastOptions);
					setIsModalOneOpen(false);
					setIsModalTwoOpen(true);
				} else {
					toast.error(message, toastOptions);
				}
			}
		}
	}

	function validateInputFileds() {
		let nPMsg = "";
		let cPMsg = "";
		let tMsg = "";
		if (inputForm.nPValue === "") {
			nPMsg = "Please enter the password";
		}
		if (inputForm.nPValue !== "" && inputForm.nPValue.length < 8) {
			nPMsg = "Password should be of minimum of 8 characters";
		}
		if (inputForm.cPValue === "") {
			cPMsg = "Please enter the confirm password";
		}
		if (inputForm.cPValue !== "" && inputForm.cPValue.length < 8) {
			cPMsg = "Confirm password should be of minimum of 8 characters";
		}
		if (inputForm.tValue === "") {
			tMsg = "Please enter the token received in your registerd email";
		}
		if (
			inputForm.nPValue !== "" &&
			inputForm.cPValue !== "" &&
			inputForm.nPValue !== inputForm.cPValue
		) {
			nPMsg = "Both input password must be same";
			cPMsg = "Both input password must be same";
		}
		if (nPMsg.length || cPMsg.length || tMsg.length) {
			setInputForm({
				...inputForm,
				nPError: true,
				nPErrMsg: nPMsg,
				cPError: true,
				cPErrMsg: cPMsg,
				tError: true,
				tErrMsg: tMsg,
			});
			return false;
		}
		return true;
	}

	const handleChangePassword = async () => {
		let res = validateInputFileds();
		if (res && inputForm.nPValue === inputForm.cPValue) {
			const { email } = await getLoggedUser();
			const { success, message } = await updatePassword(
				email,
				inputForm.nPValue,
				inputForm.tValue
			);
			if (success) {
				toast.success(
					"Password successfully updated. Please login again into application",
					toastOptions
				);
				setTimeout(() => {
					props.LogoutUser();
				}, 3000);
			} else {
				toast.error(message, toastOptions);
			}
		}
	};

	const [hidden, setHidden] = useState(true);
	const menuHandler = () => {
		setActivecls(!activeCls);
	};

	const checkPasswordStrength = (passwordValue) => {
		const passwordStrengthScore = passwordStrength(passwordValue);
		console.log(passwordStrengthScore);
		setStrength(passwordStrengthScore);
		if (passwordStrengthScore.id >= 3 && passwordStrengthScore.length >= 10) {
			setStrongPasswordEntered(true);
		} else {
			setStrongPasswordEntered(false);
		}
	};

	return (
		<>
			<div className="PI_page">
				<div
					className={`c-app c-default-layout ${
						activeCls ? "sidBrexpand" : null
					}`}>
					<TheSidebar />
					{isModalOneOpen && (
						<div className="c-wrapper">
							<div className="c-body">
								<div className="top_navOuter position-relative">
									<button
										className="bars"
										onClick={() => setActivecls(!activeCls)}></button>
									<TopNav unRead={unRead} />
									<CModal
										show={isModalOneOpen}
										size="md"
										onClose={() => closeModalOneHandler()}
										color="warning">
										<CModalHeader closeButton>
											<CModalTitle>Verify Password</CModalTitle>
										</CModalHeader>
										<CModalBody>
											<CContainer>
												<CRow>
													<CCol lg="12">
														<CForm action="" method="post">
															<CFormGroup>
																<CLabel
																	htmlFor="first_name"
																	style={{
																		color: "#ff9e00",
																	}}>
																	<b>Password</b>
																</CLabel>

																<CInput
																	type="password"
																	id="password"
																	name="password"
																	onChange={(e) =>
																		setPassword({
																			...password,
																			value: e.target.value,
																		})
																	}
																	class="form-control w-67 h-58"
																/>
																{password.error && (
																	<p
																		style={{
																			color: "red",
																		}}>
																		Please enter the password
																	</p>
																)}
															</CFormGroup>
														</CForm>
													</CCol>
												</CRow>
											</CContainer>
										</CModalBody>
										<CModalFooter>
											<CButton
												color="secondary"
												onClick={() => closeModalOneHandler()}>
												Close
											</CButton>
											<CButton
												color="stack-overflow"
												onClick={() => submitPasswordHandler()}>
												Submit
											</CButton>
										</CModalFooter>
									</CModal>
								</div>
							</div>
						</div>
					)}
					{/* change passsword form */}
					{isModalTwoOpen && (
						<div className="c-wrapper">
							<div className="c-body">
								<CContainer fluid className="PageLayout">
									<CRow class="w-87">
										<CCol class="col-auto">
											<CCard className="m-4">
												<div className="contain_body">
													<CCardBody>
														<CForm>
															<CFormGroup>
																<CLabel
																	htmlFor="new_password"
																	style={{
																		color: "#ff9e00",
																	}}>
																	<b>Password</b>
																</CLabel>

																<CInput
																	type="password"
																	id="newPassword"
																	name="newPassword"
																	onChange={(e) => {
																		setInputForm({
																			...inputForm,
																			nPValue: e.target.value,
																		});
																		setPasswordAdded(true);
																		checkPasswordStrength(e.target.value);
																	}}
																	class="form-control w-67 h-58"
																/>

																{passwordAdded ? (
																	<div className="password-checker-container">
																		<p className="password-checker-text">
																			Password must contain the following:
																		</p>
																		<ul className="password-checker-list">
																			<li
																				class={
																					strength.contains.includes(
																						"lowercase"
																					)
																						? "valid"
																						: ""
																				}>
																				A lowercase letter{" "}
																				<span className="fa fa-check-circle"></span>
																			</li>
																			<li
																				class={
																					strength.contains.includes(
																						"uppercase"
																					)
																						? "valid"
																						: ""
																				}>
																				A capital (Uppercase) letter{" "}
																				<span className="fa fa-check-circle"></span>
																			</li>
																			<li
																				class={
																					strength.contains.includes("number")
																						? "valid"
																						: ""
																				}>
																				A number{" "}
																				<span className="fa fa-check-circle"></span>
																			</li>
																			<li
																				class={
																					strength.contains.includes("symbol")
																						? "valid"
																						: ""
																				}>
																				A Symbol{" "}
																				<span className="fa fa-check-circle"></span>
																			</li>
																			<li
																				class={
																					strength.length >= 10 ? "valid" : ""
																				}>
																				Minimun 10 characters{" "}
																				<span className="fa fa-check-circle"></span>
																			</li>
																		</ul>
																	</div>
																) : null}

																{inputForm.nPError && (
																	<p
																		style={{
																			color: "red",
																		}}>
																		{inputForm.nPErrMsg}
																	</p>
																)}
															</CFormGroup>
															<CFormGroup>
																<CLabel
																	htmlFor="confirm_password"
																	style={{
																		color: "#ff9e00",
																	}}>
																	<b>Confirm Password</b>
																</CLabel>
																{/* confirm password */}
																<CInput
																	type="password"
																	id="confirmPassword"
																	name="confirmPassword"
																	onChange={(e) => {
																		setInputForm({
																			...inputForm,
																			cPValue: e.target.value,
																		});
																	}}
																	class="form-control w-67 h-58"
																/>

																{inputForm.cPError && (
																	<p
																		style={{
																			color: "red",
																		}}>
																		{inputForm.cPErrMsg}
																	</p>
																)}
															</CFormGroup>
															{/* token */}
															<CFormGroup>
																<CLabel
																	htmlFor="confirm_password"
																	style={{
																		color: "#ff9e00",
																	}}>
																	<b>Token</b>
																</CLabel>
																{/* token */}
																<CInput
																	type="text"
																	id="token"
																	name="token"
																	onChange={(e) =>
																		setInputForm({
																			...inputForm,
																			tValue: e.target.value,
																		})
																	}
																	class="form-control w-67 h-58"
																/>
																{inputForm.tError && (
																	<p
																		style={{
																			color: "red",
																		}}>
																		{inputForm.tErrMsg}
																	</p>
																)}
																<p
																	style={{
																		color: "gray",
																	}}>
																	NOTE: Token is sent on your registered email.
																</p>
															</CFormGroup>
														</CForm>
													</CCardBody>
												</div>

												<CCardFooter>
													<CRow>
														{!strongPasswordEntered ? (
															<CButton
																shape="pill"
																className="SaveButton"
																disabled>
																Save
															</CButton>
														) : (
															<CButton
																shape="pill"
																className="SaveButton"
																onClick={() => handleChangePassword()}>
																Save
															</CButton>
														)}

														<CButton
															shape="pill"
															className="CancelButton"
															style={
																hidden
																	? {
																			visibility: "hidden",
																	  }
																	: {
																			visibility: "visible",
																	  }
															}
															onClick={() => setHidden(true)}>
															Cancel
														</CButton>
													</CRow>
												</CCardFooter>
											</CCard>
										</CCol>
									</CRow>
								</CContainer>
								<div className="top_navOuter position-relative">
									<button className="bars" onClick={menuHandler}>
										<CImg src={menuIcon}></CImg>
									</button>
									<TopNav unRead={unRead} />
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
const mapStateToProps = ({ users }) => ({
	data: users,
});

export default withRouter(
	connect(mapStateToProps, { LogoutUser })(ChangePasswordNew)
);
