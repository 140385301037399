import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import logoIcon from "src/assets/icons/app-logo.png";

import { COUNTRY_NAMES } from "src/Utils/consts";

import PlacesAutocomplete, {
	geocodeByAddress,
} from "react-places-autocomplete";

const MexicoCountryQuestion = (props) => {
	let countryDetails = JSON.parse(sessionStorage.getItem("countryDetails"));
	let preFill = {
		streetAddress: countryDetails?.streetAddress ?? "",
		state: countryDetails?.state ?? "",
		city: countryDetails?.city ?? "",
		country: countryDetails?.country ?? "",
		postalCode: countryDetails?.postalCode ?? "",
		neighborhood: countryDetails?.neighborhood ?? "",
	};
	const history = useHistory();
	const [address, setAddress] = React.useState("");
	const [fullAddress, setFullAddress] = React.useState(null);
	const [fullCheck, setFullCheck] = React.useState(true);
	const {
		register,
		handleSubmit,
		errors,
		formState,
		watch,
		setValue,
		trigger,
		setError,
		clearErrors,
	} = useForm({
		reValidateMode: "onChange",
		mode: "all",
	});

	const [submitIssue, setSubmitIssue] = useState(false);
	let fatherSurname = watch(
		"fatherSurname",
		countryDetails?.fatherSurname ?? ""
	);
	let motherSurname = watch(
		"motherSurname",
		countryDetails?.motherSurname ?? ""
	);
	let taxIdNumber = watch("taxIdNumber", countryDetails?.taxId ?? "");
	let streetAddress = watch(
		"streetAddress",
		countryDetails?.streetAddress ?? ""
	);
	let city = watch("city", countryDetails?.city ?? "");
	let postalCode = watch("postalCode", countryDetails?.postalCode ?? "");
	let country = watch("country", countryDetails?.country ?? "");
	let state = watch("state", countryDetails?.state ?? "");
	let neighborhood = watch("neighborhood", countryDetails?.neighborhood ?? "");

	useEffect(() => {
		if (
			!fatherSurname ||
			taxIdNumber?.length < 9 ||
			taxIdNumber?.length > 13 ||
			!streetAddress ||
			!city ||
			!state ||
			!postalCode ||
			!country ||
			!neighborhood
		) {
			setSubmitIssue(true);
		} else {
			setSubmitIssue(false);
		}
	}, [
		fatherSurname,
		motherSurname,
		taxIdNumber,
		streetAddress,
		city,
		state,
		postalCode,
		country,
		neighborhood,
	]);

	const { isValid } = formState;
	var searchOptions = {
		componentRestrictions: {
			country: "mx",
		},
	};
	const handleSave = async (data) => {
		try {
			let details = {
				fatherSurname: fatherSurname?.trim() ?? "",
				motherSurname: motherSurname?.trim() ?? "",
				taxId: taxIdNumber?.trim() ?? "",
				country: JSON.parse(sessionStorage.getItem("userCountry")).country,
				moveDate: JSON.parse(sessionStorage.getItem("userCountry")).moveDate,
				streetAddress: streetAddress?.trim() ?? "",
				city: city?.trim() ?? "",
				postalCode: postalCode?.trim() ?? "",
				state: state?.trim() ?? "",
				neighborhood: neighborhood?.trim() ?? "",
			};
			sessionStorage.setItem("countryDetails", JSON.stringify(details));
			let ucForm = {
				orig_country: JSON.parse(sessionStorage.getItem("userCountry")).country,
				request_date: new Date().toISOString().split("T")[0],
				credit_report: null,
			};
			sessionStorage.setItem("uc_input", JSON.stringify(ucForm));
			history.push("/pr-details");
		} catch (e) {
			console.log(e.message, "e.mmmm");
		}
	};
	useEffect(() => {
		(async () => {
			let countryDetails = JSON.parse(sessionStorage.getItem("countryDetails"));
			if (countryDetails) {
				setValue("fatherSurname", countryDetails?.fatherSurname ?? "");
				setValue("motherSurname", countryDetails?.motherSurname ?? "");
				setValue("taxIdNumber", countryDetails?.taxId ?? "");
				trigger("taxIdNumber");
				fillAddress(0, countryDetails?.streetAddress ?? "");
				fillAddress(1, countryDetails?.state ?? "");
				fillAddress(2, countryDetails?.city ?? "");
				fillAddress(3, countryDetails?.country ?? "");
				fillAddress(4, countryDetails?.postalCode ?? "");
				fillAddress(5, countryDetails?.neighborhood ?? "");

				if (!!countryDetails?.streetAddress) {
					setFullAddress("autofill");
				}
			}
		})();
	}, []);
	const handleFatherNameChange = (e) => {
		const { value } = e.target;
		if (!value) {
			setValue("fatherSurname", "");
			trigger("fatherSurname");
		}
		clearErrors("fatherSurname");
		const regex = /^[a-zA-Z ]*$/;
		if (value && regex.test(value)) {
			setValue("fatherSurname", value.trim());
			trigger("fatherSurname");
		}
	};
	const handleMotherNameChange = (e) => {
		const { value } = e.target;
		if (!value) {
			setValue("motherSurname", "");
			trigger("motherSurname");
		}
		clearErrors("motherSurname");
		const regex = /^[a-zA-Z ]*$/;
		if (value && regex.test(value)) {
			setValue("motherSurname", value.trim());
			trigger("motherSurname");
		}
	};
	const handleTaxId = (e) => {
		clearErrors("taxIdNumber");
		const validate = /^[a-zA-Z0-9 ]*$/;
		if (!validate.test(e.target.value)) {
			return;
		}

		const { value } = e.target;
		if (!(value.length > 9 && value.length <= 13)) {
			setError("taxIdNumber", {
				type: "manual",
				message: "Tax Id Number should be between 10-13 characters",
			});
		}
		const regex = /^[a-zA-Z ]*$/;
		if (value.length > 3 && !regex.test(value.slice(0, 4))) {
			return setError("taxIdNumber", {
				type: "manual",
				message: "first 4 position must be alphabetic",
			});
		} else if (
			value.length > 5 &&
			!(Number(value.slice(4, 6)) >= 0 && Number(value.slice(4, 6)) <= 99)
		) {
			return setError("taxIdNumber", {
				type: "manual",
				message: "5 and 6 must contain a total of 00 and 99",
			});
		} else if (
			value.length > 7 &&
			!(Number(value.slice(6, 8)) >= 1 && Number(value.slice(6, 8)) <= 12)
		) {
			return setError("taxIdNumber", {
				type: "manual",
				message: "7 and 8 must contain a total of 01 to 12",
			});
		} else if (
			value.length > 9 &&
			!(Number(value.slice(8, 10)) >= 1 && Number(value.slice(8, 10)) <= 31)
		) {
			return setError("taxIdNumber", {
				type: "manual",
				message: "9 and 10 must contain a total of 01 to 31",
			});
		} else {
			setValue("taxIdNumber", value.trim());
			trigger("taxIdNumber");
		}
	};
	const handleChange = (address, e) => {
		console.log(address, "Addfa");
		if (address === "") {
			setFullAddress(null);
			setFullCheck(false);
			setAddress("");
		} else {
			setFullCheck(true);
			console.log(address, "Addfa");
			setAddress(address);
		}
		console.log(fullCheck);
	};

	const fillAddress = (index, value) => {
		const regex = /^[a-zA-Z0-9_ ]*$/;
		if (regex.test(value)) {
			//eslint-disable-next-line
			switch (index) {
				case 0:
					setValue("streetAddress", value);
					trigger("streetAddress");
					break;
				case 1:
					setValue("state", value);
					trigger("state");
					break;
				case 2:
					setValue("city", value);
					trigger("city");
					break;
				case 3:
					setValue("country", value);
					trigger("country");
					break;

				case 4:
					setValue("postalCode", value);
					trigger("postalCode");
					break;
				case 5:
					setValue("neighborhood", value);
					trigger("neighborhood");
					break;
				case 6:
					setValue("state", value);
					trigger("state");
					break;
			}
		}
	};
	const emptyField = () => {
		for (let i = 0; i < 5; i++) {
			fillAddress(0, "");
		}
	};
	const handleSelect = async (address) => {
		emptyField();
		const result = await geocodeByAddress(address);
		setFullAddress(result);
		setAddress("");
	};
	useEffect(() => {
		try {
			if (fullAddress && fullAddress !== "autofill") {
				console.log(fullAddress, "fulll");
				const address = fullAddress[0].address_components;
				console.log(address, "Aaaaaaaaa");
				const { long_name: streetNumber } = address.find((item) =>
					item.types.includes("street_number")
				);
				const { long_name: route } = address.find((item) =>
					item.types.includes("route")
				);
				const { long_name: country } = address.find((item) =>
					item.types.includes("country")
				);
				const { long_name: postalCode } = address.find((item) =>
					item.types.includes("postal_code")
				);
				const { long_name: city } = address.find((item) =>
					item.types.includes("administrative_area_level_1")
				);
				const { long_name: state } = address.find((item) =>
					item.types.includes("sublocality")
				);

				fillAddress(0, `${streetNumber} ${route}`);
				fillAddress(1, state);
				fillAddress(2, city);
				fillAddress(3, country);
				fillAddress(4, postalCode);
			}
		} catch (e) {
			console.log(e);
		}
	}, [fullAddress]);

	return (
		<>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<section>
					<div id="page-section" className="wrapper">
						<div
							className="no-scroll vertical-middle-page"
							style={{ width: "100%" }}>
							<div>
								{JSON.parse(sessionStorage.getItem("userCountry")).country ===
									COUNTRY_NAMES.MEXICO && (
									<div className="content-container countryQuestion">
										<div className="text-container text-container-margin">
											<h1>
												<b>Please provide these details.</b>
											</h1>
										</div>
										<div
											className="input-container-pr "
											style={{ width: "100%" }}>
											<div className="edubox">
												<label className="custom-label">
													<b>Paternal Surname</b>
												</label>
												<input
													id="fatherSurname"
													name="fatherSurname"
													defaultValue={fatherSurname}
													value={fatherSurname}
													onChange={handleFatherNameChange}
													maxLength={60}
													className={
														"form-control-plaintext w-67 h-58 custom-input " +
														(errors.fatherSurname &&
														errors.fatherSurname.message
															? "custom-input-error"
															: "")
													}
													placeholder="Paternal Surname"
													{...register("fatherSurname", {
														// required: "Father Surname is required",
													})}
												/>
											</div>
											<div className="edubox" style={{ width: "20rem" }}>
												<label className="custom-label">
													<b>Maternal Surname</b>
												</label>
												<input
													id="motherSurname"
													name="motherSurname"
													defaultValue={motherSurname}
													value={motherSurname}
													onChange={handleMotherNameChange}
													maxLength={60}
													className={
														"form-control-plaintext w-67 h-58 custom-input " +
														(errors.motherSurname &&
														errors.motherSurname.message
															? "custom-input-error"
															: "")
													}
													placeholder="Maternal Surname"
													{...register("motherSurname", {
														// required: "Mother Surname is required",
													})}
												/>
											</div>
										</div>
										<div
											className="input-container-pr "
											style={{ width: "100%" }}>
											<div className="edubox">
												<label className="custom-label">
													<b>Tax ID Number (RFC)</b>
												</label>
												<input
													type="taxIdNumber"
													id="taxIdNumber"
													name="taxIdNumber"
													defaultValue={preFill.taxIdNumber}
													value={taxIdNumber}
													onChange={handleTaxId}
													maxLength={13}
													minLength={10}
													className={
														"form-control-plaintext w-67 h-58 custom-input " +
														(errors.taxIdNumber && errors.taxIdNumber.message
															? "custom-input-error"
															: "")
													}
													{...register("taxIdNumber", {
														// required: "Tax ID Number is required",
														minLength: 10,
													})}
													placeholder="Tax ID Number (RFC)"
												/>
												{taxIdNumber.length < 10 ? (
													<span>TaxId Should be between 10-13</span>
												) : null}
											</div>
										</div>
										<div className="content-container">
											<div className="edubox">
												<label className="custom-label">
													<b
														onClick={() => {
															setFullAddress(null);
															fillAddress(5, "");
															fillAddress(6, "");
														}}>
														Open Search
													</b>
												</label>
											</div>
											<div
												className="edubox"
												hidden={fullAddress === null ? false : true}>
												<label className="custom-label">
													<b>
														Search your address (use below text box to search
														your address)
													</b>
												</label>
												<PlacesAutocomplete
													value={address}
													onChange={handleChange}
													onSelect={handleSelect}
													searchOptions={searchOptions}>
													{({
														getInputProps,
														suggestions,
														getSuggestionItemProps,
														loading,
													}) => (
														<React.Fragment>
															{/* <div className="search-input"> */}
															<input
																{...getInputProps({
																	// placeholder: streetname
																	// 	? streetname
																	// 	: user.address.currentAddress,
																	className:
																		"form-control-plaintext w-67 h-58 custom-input ",
																})}
																// onClick={handleClick}
															/>
															{/* </div> */}
															<div
																className={
																	suggestions.length > 0
																		? "autocomplete-dropdown-container has-options"
																		: "autocomplete-dropdown-container"
																}>
																{loading && (
																	<div className="loading">Loading...</div>
																)}
																{suggestions.map((suggestion, i) => {
																	const className = suggestion.active
																		? "suggestion-item--active"
																		: "suggestion-item";
																	// inline style for demonstration purpose
																	const style = suggestion.active
																		? {
																				backgroundColor: "#ff9300",
																				cursor: "pointer",
																				padding: "0.5rem 1rem",
																		  }
																		: {
																				backgroundColor: "#ffffff",
																				cursor: "pointer",
																				padding: "0.5rem 1rem",
																		  };
																	return (
																		<div
																			key={i}
																			{...getSuggestionItemProps(suggestion, {
																				className,
																				style,
																			})}>
																			<span>{suggestion.description}</span>
																		</div>
																	);
																})}
															</div>
														</React.Fragment>
													)}
												</PlacesAutocomplete>
											</div>

											{fullAddress != null && (
												<>
													<div className="input-container multiple-mobile-inputs">
														<div>
															<label
																htmlFor="streetAddress"
																className="custom-label">
																<b>Street Address</b>
															</label>
															<input
																type="streetAddress"
																id="streetAddress"
																name="streetAddress"
																className={
																	errors.streetAddress
																		? "form-control-plaintext w-67 h-58 custom-input custom-input-error"
																		: "form-control-plaintext w-67 h-58 custom-input"
																}
																placeholder="Street Address"
																value={streetAddress}
																{...register("streetAddress", {
																	// required: "streetAddress is required",
																})}
																onChange={(e) => fillAddress(0, e.target.value)}
																defaultValue={preFill.streetAddress}
															/>
														</div>
														<div>
															<label
																htmlFor="Neighbourhood"
																className="custom-label">
																<b>Neighborhood</b>
															</label>
															<input
																type="neighborhood"
																id="neighborhood"
																name="neighborhood"
																className={
																	errors.neighborhood
																		? "form-control-plaintext w-67 h-58 custom-input custom-input-error"
																		: "form-control-plaintext w-67 h-58 custom-input"
																}
																placeholder="Neighborhood"
																onChange={(e) => fillAddress(5, e.target.value)}
																value={neighborhood}
																{...register(
																	"neighborhood",

																	{
																		// required: "Neighbourhood is required",
																	}
																)}
																defaultValue={preFill.neighborhood}
															/>
														</div>
														<div>
															<label htmlFor="country" className="custom-label">
																<b>County</b>
															</label>
															<input
																type="country"
																id="country"
																name="country"
																className={
																	errors.country
																		? "form-control-plaintext w-67 h-58 custom-input custom-input-error"
																		: "form-control-plaintext w-67 h-58 custom-input"
																}
																onChange={(e) => fillAddress(3, e.target.value)}
																placeholder="Country"
																{...register("country", {
																	// required: "Country is required",
																})}
																value={country}
																defaultValue={preFill.country}
															/>
														</div>
													</div>
													<div className="input-container multiple-mobile-inputs">
														<div>
															<label htmlFor="city" className="custom-label">
																<b>City</b>
															</label>
															<input
																type="city"
																id="city"
																name="city"
																className={
																	errors.city
																		? "form-control-plaintext w-67 h-58 custom-input custom-input-error"
																		: "form-control-plaintext w-67 h-58 custom-input"
																}
																placeholder="City"
																value={city}
																onChange={(e) => fillAddress(2, e.target.value)}
																{...register("city", {
																	// required: "city is required",
																})}
																defaultValue={preFill.city}
															/>
														</div>
														<div>
															<label htmlFor="state" className="custom-label">
																<b>State </b>
															</label>
															<input
																type="state"
																id="state"
																name="state"
																className={
																	errors.state
																		? "form-control-plaintext w-67 h-58 custom-input custom-input-error"
																		: "form-control-plaintext w-67 h-58 custom-input"
																}
																placeholder="State"
																{...register("state", {
																	// required: "state is required",
																})}
																value={state}
																onChange={(e) => fillAddress(1, e.target.value)}
																defaultValue={preFill.state}
															/>
														</div>

														<div>
															<label
																htmlFor="postalCode"
																className="custom-label">
																<b>Zip Code</b>
															</label>
															<input
																type="postalCode"
																id="postalCode"
																name="postalCode"
																className={
																	errors.postalCode
																		? "form-control-plaintext w-67 h-58 custom-input custom-input-error"
																		: "form-control-plaintext w-67 h-58 custom-input"
																}
																placeholder="Zip Code"
																onChange={(e) => fillAddress(4, e.target.value)}
																value={postalCode}
																{...register(
																	"postalCode",

																	{
																		// required: "postalCode is required",
																		value: postalCode
																			? postalCode
																			: preFill.postalCode,
																	}
																)}
																defaultValue={preFill.postalCode}
															/>
														</div>
													</div>
												</>
											)}
										</div>
										{console.log(errors, "errrrrrrrrrrrrr")}
										{Object.keys(errors).length > 0 &&
											Object.values(errors).map((data) => (
												<div className="error-container">
													<p className="error-message">{data.message}</p>
												</div>
											))}
									</div>
								)}
								<div className="button-container button-container-margin">
									{console.log(errors, submitIssue, "submitconsole")}
									{Object.values(errors).length > 0 || submitIssue ? (
										<button className="custom-button disabled">Next</button>
									) : (
										<button
											className="custom-button enabled"
											onClick={handleSubmit(handleSave)}>
											Next
										</button>
									)}

									<button
										className="custom-back-button"
										onClick={async () => {
											history.push("/Country");
										}}>
										Back
									</button>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default MexicoCountryQuestion;
