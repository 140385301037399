import React from "react";
import PropTypes from "prop-types";
import dlogo from "src/containers/logo_dark.png";
import headerdots from "src/assets/icons/header-menu-icon.png";
import { getLoggedUser, LogoutUser } from "../actions/users";
import { connect } from "react-redux";
import {
	getUsersNotifications,
	resendVerificationMail,
	updateUsersNotification,
} from "../services/user";

import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import {
	CNavbar,
	CNavbarBrand,
	CNavbarNav,
	CButton,
	CDropdown,
	CDropdownMenu,
	CDropdownToggle,
	CDropdownItem,
	CImg,
	CBadge,
	CAlert,
	CLink,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";

class TopNav extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			auth: true,
			anchorEl: null,
			searchExpand: false,
			notificationCount: 0,
			loanAmountNotification: "",
			usersNotification: [],
			user: {},
		};
	}

	handleChange = (event, checked) => {
		this.setState({ auth: checked });
	};

	handleMenu = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleLogout = async () => {
		console.log("logout user");
		await this.props.LogoutUser();
	};
	handleChangePassword = () => {
		window.location.href = "/user/changePassword";
	};

	handleNotificationClicked = async (appId, status_id) => {
		await updateUsersNotification(appId, status_id);

		const users_notification = await getUsersNotifications(appId);

		await this.setState({
			usersNotification: users_notification,
			notificationCount: users_notification.length,
		});
	};

	componentDidMount = async () => {
		const user = await getLoggedUser();
		this.setState({ user: user });
		console.log(user.email, "ssssssss");

		this.interval = setInterval(
			() => this.setState({ time: Date.now() }),
			1000
		);

		const users_notification = await getUsersNotifications(
			sessionStorage.getItem("CurrentSelect")
				? sessionStorage.getItem("CurrentSelect")
				: sessionStorage.getItem("appId")
		);

		let NotifyCount = 0;
		if (users_notification && users_notification.length) {
			//eslint-disable-next-line
			users_notification.map((item) => {
				if (item.isViewed === false) {
					NotifyCount++;
				}
			});
		}

		await this.setState({
			usersNotification: users_notification,
			notificationCount: NotifyCount,
		});
	};

	resendEmailHandler = () => {
		const result = resendVerificationMail(this.state.user.id);
		if (result) {
			toast.success("Check Your Email for verification");
		} else {
			toast.error("Something went wrong");
		}
	};

	componentWillUnmount() {
		clearInterval(this.interval);
	}
	render() {
		const { anchorEl } = this.state;
		console.log(anchorEl);

		return (
			<div>
				<CNavbar expandable color="white" fixed="top">
					<CNavbarBrand>
						<CImg src={dlogo} />
					</CNavbarBrand>
					<CNavbarNav className="ml-auto">
						{this.state.user.verified === 0 ? (
							<CAlert color="warning">
								Please verify your email for smooth complition of your loan
								application.
								<span onClick={this.resendEmailHandler}>Resend Email</span>
							</CAlert>
						) : null}

						<CDropdown className="c-header-nav-item mx-auto p-1 header-action-icon">
							<CDropdownToggle className="c-header-nav-link" caret={false}>
								<CButton className="header-notification-btn">
									<CIcon
										content={
											this.state.notificationCount > 0
												? freeSet.cilBellExclamation
												: freeSet.cilBell
										}
									/>
									{this.state.notificationCount > 0 ? (
										<CBadge
											className="header-notification-badge"
											color="secondary">
											{this.state.notificationCount}
										</CBadge>
									) : null}
								</CButton>
							</CDropdownToggle>
							{this.state.usersNotification.length > 0 ? (
								<CDropdownMenu placement="bottom-end" className="pt-0">
									{this.state.usersNotification.map((item) =>
										item.isViewed === false ? (
											<CDropdownItem header tag="div" color="light">
												<CLink
													as={Link}
													to={
														item.status_id === "12" ||
														item.status_id === "11" ||
														item.status_id === "0" ||
														item.status_id === "99"
															? "/documents/generalDocuments"
															: item.status_id === "26" ||
															  item.status_id === "27"
															? "/banking"
															: "/documents/legalDocuments"
													}>
													<strong
														onClick={() => {
															this.handleNotificationClicked(
																item.appId,
																item.status_id
															);
														}}>
														{item.message}
													</strong>
												</CLink>
											</CDropdownItem>
										) : (
											<>
												<CDropdownItem
													className="dropdown-header"
													color="light"
													href={
														item.status_id === "12" || item.status_id === "11"
															? "/documents/generalDocuments"
															: "/documents/legalDocuments"
													}>
													<strong
														style={{
															color: "grey",
														}}>
														{item.message}
													</strong>
												</CDropdownItem>
											</>
										)
									)}

									{this.props.unRead
										.slice(0, this.props.unRead.length)
										.map((newestMessage) => (
											<CDropdownItem
												onClick={() => this.handleMessage(newestMessage)}>
												<CIcon
													name="cil-user-follow"
													className="mr-2 text-success"
												/>
												{newestMessage.content.subject}
											</CDropdownItem>
										))}
								</CDropdownMenu>
							) : (
								""
							)}
						</CDropdown>
						<div className="header-action-icon">
							<CDropdown>
								<CDropdownToggle>
									<CImg src={headerdots} />
								</CDropdownToggle>
								<CDropdownMenu>
									<CDropdownItem onClick={this.handleChangePassword}>
										Change Password
									</CDropdownItem>
									<CDropdownItem onClick={this.handleLogout}>
										Logout
									</CDropdownItem>
								</CDropdownMenu>
							</CDropdown>
						</div>
					</CNavbarNav>
				</CNavbar>
			</div>
		);
	}
}

TopNav.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ users }) => ({
	data: users,
});

export default withRouter(connect(mapStateToProps, { LogoutUser })(TopNav));
