import React, { useEffect, useState } from "react";
import logoIcon from "src/assets/icons/app-logo.png";
import { saveFirstToKnow } from "src/services/user";
import { QUADFI_MAIN_URL } from "src/Utils/consts";

const FirstToKnow = (props) => {
	const [formComponent, setFormComponent] = useState({
		firstName: "",
		lastName: "",
		email: "",
	});
	const [checkForm, setCheckForm] = useState(false);
	const handleSubmit = async () => {
		const result = await saveFirstToKnow(formComponent);
		if (result) {
			window.location.href = QUADFI_MAIN_URL;
		}
	};
	useEffect(() => {
		const { firstName, lastName, email } = formComponent;
		if ((!firstName || !lastName, !email)) {
			setCheckForm(true);
		} else {
			setCheckForm(false);
		}
	}, [formComponent]);
	return (
		<section className="purposeWrappers">
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>Be the first to know!</b>
								</h1>
								<h3 style={{ marginTop: 10, marginBottom: 0 }}>
									Thank you for your interest in QuadFi. At this time, we are
									not offering new loans because of excess customers' demand. We
									are working to address this. Please add your email below to be
									notified as soon as we are accepting new applications. Thanks
									again!
								</h3>
							</div>
							<div className="input-container">
								<input
									className="form-control-plaintext w-67 h-58 custom-input"
									placeholder="First Name"
									onChange={(e) =>
										setFormComponent({
											...formComponent,
											firstName: e.target.value,
										})
									}
								/>
								<input
									className="form-control-plaintext w-67 h-58 custom-input"
									placeholder="Last Name"
									onChange={(e) =>
										setFormComponent({
											...formComponent,
											lastName: e.target.value,
										})
									}
								/>
								<input
									className="form-control-plaintext w-67 h-58 custom-input"
									placeholder="Email"
									onChange={(e) =>
										setFormComponent({
											...formComponent,
											email: e.target.value,
										})
									}
								/>
							</div>
						</div>
						<div className="button-container button-container-margin">
							<button
								disabled={checkForm}
								className="custom-back-button enabled"
								onClick={handleSubmit}>
								Submit
							</button>
							<p style={{ marginTop: 17 }}>
								For more information go to{" "}
								<strong>
									<a
										href="https://quadfi.com/"
										target="_blank"
										rel="noreferrer">
										Quadfi
									</a>
								</strong>
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default FirstToKnow;
