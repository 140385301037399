import {
	CCreateElement,
	CSidebar,
	CSidebarMinimizer,
	CSidebarNav,
	CSidebarNavDivider,
	CSidebarNavDropdown,
	CSidebarNavItem,
	CSidebarNavTitle,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import usePageTracking from "src/usePageTracking";
import { checkAuth } from "../actions/users";
import {
	getActiveApplication,
	getCustomerSupportTicketNotifications,
	redirectUser,
} from "../services/user";
import "../style/sidebar.scss";
import navigation from "./_nav";

const TheSidebar = () => {
	const dispatch = useDispatch();
	const show = useSelector((state) => state.sidebarShow);
	const [sideNavList, setSideNavList] = useState(navigation);

	usePageTracking(navigation);
	useEffect(() => {
		const getCurrentSelect = async () => {
			let user = await checkAuth();
			if (user) {
				if (!sessionStorage.getItem("CurrentSelect")) {
					if (sessionStorage.getItem("appId")) {
						sessionStorage.setItem(
							"CurrentSelect",
							sessionStorage.getItem("appId")
						);
					}
				}
			}
			await redirectUser();
		};

		getCurrentSelect();

		const interval = setInterval(async () => {
			if (window.location.pathname !== "/layout") {
				let messageNotifications =
					await getCustomerSupportTicketNotifications();
				if (messageNotifications.data.notifications.length > 0) {
					toast.success("There's a new message for you", {
						className: "custom-notification-toast",
					});
				}

				try {
					let listData = [];
					//eslint-disable-next-line
					navigation.map((nav) => {
						const t = { ...nav };
						if (nav.to === "/message") {
							if (messageNotifications.data.unreadMessages.count > 0) {
								t.className = "unread-messages-icon";
							}
						}
						if (nav.name === "Loan Details") {
							if (sessionStorage.getItem("current_application_status")) {
								const status_check_arr = ["1", "2", "6", "4", "3"];
								if (
									status_check_arr.includes(
										sessionStorage.getItem("current_application_status")
									)
								) {
									t.className = "disabled-menu-item";
								}
							}
						}
						listData.push(t);
					});

					const currentApplication = await getActiveApplication(
						sessionStorage.getItem("CurrentSelect")
					);

					if (currentApplication) {
						if (currentApplication.status_id > 10) {
							setSideNavList(listData);
						} else {
							listData.splice(3, 1);
							setSideNavList(listData);
						}
					} else {
						listData.splice(3, 1);
						setSideNavList(listData);
					}
				} catch (err) {}
			}
		}, 30000);
		return () => clearInterval(interval);
	}, []);

	return (
		<CSidebar
			show={show}
			className="Sidebar"
			onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}>
			<CSidebarNav>
				<CCreateElement
					items={sideNavList}
					components={{
						CSidebarNavDivider,
						CSidebarNavDropdown,
						CSidebarNavItem,
						CSidebarNavTitle,
					}}
				/>
			</CSidebarNav>
			<CSidebarMinimizer className="c-d-md-down-none" />
		</CSidebar>
	);
};

export default React.memo(TheSidebar);
