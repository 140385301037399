import { CButton, CImg, CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import trophyIcon from "src/assets/icons/App-Flow-Icons/offer-success-icon.png";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { DateToEST, reloadApplication } from "src/utils";
import { LOAN_TERM } from "src/Utils/consts";
import { STEP_PAGES } from "src/Utils/enums";
import TermsAndConditions from "../../Components/OfferSuccess/TermsAndConditions";
import {
	getActiveApplication,
	getAppLoanAmount,
	getQuadFiData,
	hardCheckUpdate,
	updateUserAppFlow,
	getUserLatestApplication,
} from "../../services/user";

const OfferSuccess = (props) => {
	const history = useHistory();
	const [offer, setOffer] = useState({});
	const [showdisplay, setshowdisplay] = useState(false);
	const [amount, setAmount] = useState("");
	const [amount36, setAmount36] = useState("");
	const [amount60, setAmount60] = useState("");
	const [offerSelect, setOfferSelect] = useState("");
	const [displayTermsAndConditions, setDisplayTermsAndConditions] =
		useState(false);
	const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
		useState(false);
	const [DiffOffermessage, setDiffOffermessage] = useState("");
	const [loading, setLoading] = React.useState("");
	const [mouseClick, setMouseClick] = useState(0);
	const arrivalTime = new Date();

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	const handleSaveAndExit = () => {
		props.handleSaveExit();
		props.lastStep();
	};

	const handleOfferSelect = (event) => {
		setOfferSelect(event.target.value);
	};

	useEffect(() => {
		console.log(DiffOffermessage);
		const localFn = async () => {
			const userCurrentApplication = await getUserLatestApplication();
			if (
				userCurrentApplication &&
				typeof userCurrentApplication.application !== "undefined"
			) {
				const applicationData = await getActiveApplication(
					userCurrentApplication.application.appId
				);
				if (
					applicationData.isHardCheckProcessed !== "" &&
					applicationData.isHardCheckProcessed
				) {
					window.location.href = "/customerPortal";
				}
			}
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	const handleSave = async (back) => {
		let details = "";
		let OfferDate = DateToEST(new Date());
		if (offerSelect === "offer-0") {
			details = {
				productAmount: amount,
				productInstallments: "36 Months",
				productMonthlyCharge: amount36,
				productRate: offer["36month-fixed"],
				page: "offer",
				date: OfferDate,
			};
		} else if (offerSelect === "offer-1") {
			details = {
				productAmount: amount,
				productInstallments: "60 Months",
				productMonthlyCharge: amount60,
				productRate: offer["60month-fixed"],
				page: "offer",
				date: OfferDate,
			};
		}
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		const result = await hardCheckUpdate("hardCheck");

		if (
			result.data.success &&
			typeof result.data.message !== "undefined" &&
			result.data.message === "isHardCheckProcessed"
		) {
			await reloadApplication(history);
			return true;
		}

		if (response.success) {
			sessionStorage.removeItem("softCheck");
			hideLoader();
			if (typeof back !== "boolean" || !back) {
				props.nextStep();
				await sendAnalytics(arrivalTime, STEP_PAGES.OFFER_SUCCESS);
			} else {
				localStorage.setItem("PreviousPage", "YES");
				await sendAnalytics(arrivalTime, STEP_PAGES.OFFER_SUCCESS);
			}
		}
		return response;
	};

	useEffect(() => {
		const localFn = async () => {
			const { soft_check_results } = await getActiveApplication(
				sessionStorage.getItem("appId")
			);
			if (soft_check_results.soft_check_result === undefined) {
				const result = await getQuadFiData();
				hideLoader();
				if (result.error == "ucError") {
					window.location.href = "/try-offer-later";
				} else {
					if (result.data && result.data.Decision === "accept") {
						setOffer(result.data);
						setAmount(result.amount["final_amount"]);
						setAmount36(result.amount["montly_payment_36"]);
						setAmount60(result.amount["montly_payment_60"]);
						setDiffOffermessage(`${result.amount["final_amount"]}`);
					} else if (result.api_failed === true) {
						sessionStorage.removeItem("softCheck");
						history.push("application/error");
					} else {
						sessionStorage.removeItem("softCheck");
						history.push("application/error");
						console.log("rejected", offer);
					}
				}
			} else {
				history.push("application/error");
			}
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (offer["36month-fixed"]) {
			hideLoader();
			setshowdisplay(true);
		} else {
			showLoader();
			setshowdisplay(false);
		}
		if (
			offer[LOAN_TERM.thirtySixMonthFixed] &&
			!offer[LOAN_TERM.sixtyMonthFixed]
		) {
			setOfferSelect("offer-0");
		}
		if (
			offer[LOAN_TERM.sixtyMonthFixed] &&
			!offer[LOAN_TERM.thirtySixMonthFixed]
		) {
			setOfferSelect("offer-1");
		}
	}, [offer]);

	useEffect(() => {
		const localFn = async () => {
			try {
				console.log(sessionStorage.getItem("appId"), "propsuserid");
				const offeramount = await getAppLoanAmount(
					sessionStorage.getItem("appId")
				);
				if (offeramount.success === true) {
					console.log(offeramount.loanAmount[0].loanAmount, amount, "here");
					//replace 2nd amount with entered amount
					if (
						offeramount.loanAmount[0].loanAmount.toString() !==
						amount.toString()
					) {
						setDiffOffermessage(`${amount}`);
					}
				}
			} catch (err) {
				console.log(err.message);
			}
		};
		localFn();
	}, [amount]);

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll">
					<div
						style={showdisplay ? {} : { display: "none" }}
						class="content-container position-relative loan-success">
						<ul className="list-inline offer-list-col">
							<li>
								<CImg src={trophyIcon}></CImg>
							</li>
							<li>
								<h1>
									<b>Congratulations</b>
								</h1>
								<h3>
									<b>Here are your offers:</b>
								</h3>
							</li>
						</ul>
						<p className="text-center text-note-offer">
							<strong>
								NOTE: Loan and loan size offered are conditional on successful
								verification.
							</strong>
						</p>
						<div className="cards-container mzlnew">
							{offer[LOAN_TERM.thirtySixMonthFixed] ? (
								<div className="icon-card">
									{!offer[LOAN_TERM.sixtyMonthFixed] ? (
										<input
											type="radio"
											id={"offer-0"}
											name="offer-type"
											value={"offer-0"}
											class="custom-radio"
											onChange={handleOfferSelect}
											onClick={handleClick}
											checked={true}
										/>
									) : (
										<input
											type="radio"
											id={"offer-0"}
											name="offer-type"
											value={"offer-0"}
											class="custom-radio"
											onChange={handleOfferSelect}
											onClick={handleClick}
										/>
									)}
									<label htmlFor={"offer-0"} className="card-label set-width">
										{" "}
										<h4>Lowest Interest Rate</h4>
										<div className="">
											<b>Amount</b>
											<h5>${amount}</h5>
										</div>
										<div className="">
											<b>Installments</b>
											<h5>36</h5>
										</div>
										<div className="">
											<b>Monthly</b>
											<h5>${amount36}</h5>
										</div>
										<div className="">
											<b>Interest Rate</b>
											<h5>{offer[LOAN_TERM.thirtySixMonthFixed] + " %"}</h5>
										</div>
									</label>
								</div>
							) : (
								""
							)}
							{offer[LOAN_TERM.sixtyMonthFixed] ? (
								<div className="icon-card">
									{!offer[LOAN_TERM.thirtySixMonthFixed] ? (
										<input
											type="radio"
											id={"offer-1"}
											name="offer-type"
											value={"offer-1"}
											class="custom-radio"
											onChange={handleOfferSelect}
											onClick={handleClick}
											checked={true}
										/>
									) : (
										<input
											type="radio"
											id={"offer-1"}
											name="offer-type"
											value={"offer-1"}
											class="custom-radio"
											onChange={handleOfferSelect}
											onClick={handleClick}
										/>
									)}
									<label htmlFor={"offer-1"} className="card-label set-width">
										<h4>Lowest Monthly Payment</h4>
										<div className="">
											<b>Amount</b>
											<h5>${amount}</h5>
										</div>
										<div className="">
											<b>Installments</b>
											<h5>60</h5>
										</div>
										<div className="">
											<b>Monthly</b>
											<h5>${amount60}</h5>
										</div>
										<div className="">
											<b>Interest Rate</b>
											<h5>{offer[LOAN_TERM.sixtyMonthFixed] + " %"}</h5>
										</div>
									</label>
								</div>
							) : (
								""
							)}
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="checkbox"
								name="softCheck"
								value=""
								id="flexCheckDefault2"
								onChange={(event) => {
									setTermsAndConditionsAccepted(event.target.checked);
								}}
								onClick={handleClick}
							/>
							<label class="form-check-label" for="flexCheckDefault2">
								<b>
									I agree to the&nbsp;
									{/* eslint-disable-next-line */}
									<a
										//eslint-disable-next-line
										href="javascript:void(0)"
										onClick={() => setDisplayTermsAndConditions(true)}>
										Terms & Conditions
									</a>
								</b>
								<br />
							</label>
						</div>
					</div>
					<div className="load-class">
						{loading ? (
							<div className="load-class-inner">
								<img src={loadingIcon} alt="" />
							</div>
						) : null}
					</div>
					<div
						style={showdisplay ? {} : { display: "none" }}
						className="button-container button-container-margin offers-button-cls">
						<button
							type="submit"
							className={[
								"custom-button",
								termsAndConditionsAccepted && offerSelect !== ""
									? "enabled"
									: "disabled",
							].join(" ")}
							pressed="true"
							onClick={handleSave}
							{...{
								[!termsAndConditionsAccepted || offerSelect === ""
									? "disabled"
									: "_disabled"]: true,
							}}>
							Apply for this loan
						</button>
					</div>
				</div>
			</div>
			<Tooltip />

			<TermsAndConditions
				show={displayTermsAndConditions}
				closeHandler={() => setDisplayTermsAndConditions(false)}
			/>
		</section>
	);
};

export default OfferSuccess;
