exports.ENV = {
	DEV: "development",
	UAT: "uat",
	PROD: "production",
};

const PASSWORD_STRONG_SCORE = 4;

const LOAN_AMOUNT = {
	STUDENTS_MIN: 1000,
	STUDENTS_MAX: 15000,
	STUDENTS_DEFAULT: 1000,
	REFINANCING_MIN: 5000,
	REFINANCING_MAX: 50000,
	REFINANCING_DEFAULT: 5000,
	NEWCOMER_MIN: 5000,
	NEWCOMER_MAX: 30000,
	NEWCOMER_DEFAULT: 5000,
	PERSONAL_MIN: 5000,
	PERSONAL_MAX: 50000,
	PERSONAL_DEFAULT: 5000,
	GRADUATE_MIN: 5000,
	GRADUATE_MAX: 50000,
	GRADUATE_DEFAULT: 5000,
};
const LOAN_TERM = {
	sixtyMonthFixed: "60month-fixed",
	thirtySixMonthFixed: "36month-fixed",
};

const CANADA = "Canada";
const COUNTRY_ADDRESS_ALLOWED = "ca";
const LANGUAGE_ENGLISH = "en";
exports.ALLOWED_APPLICATION_STATUS_FOR_REAPPLY = [2, 6, 7, 23, 24, 25];
exports.COUNTRY_CREDIT_REPORT_LIST = ["Philippines" ,"Mexico","United States" ,"Other"]
exports.COUNTRY_SUPPLY_CREDIT_REPORT = ["Philippines" ,"Mexico"]

exports.COUNTRY_NAMES = {
	PHILIPPINES: "Philippines",
	MEXICO: "Mexico",
	UNITED_STATES: "United States",
	OTHER: "Other",
}
const APP_STATUS_GROUPS = {
	ARCHIVED: "2, 6, 25",
};

const EID_STATUS = {
	REJECTED: "Rejected",
	TRY_AGAIN: "Try Again",
};

const EID_WAIT_TIME = 72;

const MULTIPLE_UPLOAD_DOCS = [
	"Last 3 months of bank statement where your salary is deposited to",
];

const MAXIMUM_MULTIPLE_FILES_ALLOWED = 3;

const FILE_EXTENSIONS = {
	JPG: "image/jpg",
	JPEG: "image/jpeg",
	PNG: "image/png",
	PDF: "application/pdf",
};

const VOUCHED_FILE_EXTENSIONS = [
	FILE_EXTENSIONS.JPEG,
	FILE_EXTENSIONS.JPG,
	FILE_EXTENSIONS.PNG,
];
const DOC_FILE_EXTENSIONS = [
	FILE_EXTENSIONS.JPEG,
	FILE_EXTENSIONS.JPG,
	FILE_EXTENSIONS.PNG,
	FILE_EXTENSIONS.PDF,
];
exports.MFA_NOT_SUPPORTED = "MFA_NOT_SUPPORTED";
exports.RATE_LIMIT = "RATE_LIMIT";
exports.PRODUCT_READY = "PRODUCT_READY";

exports.PLAID_RETRY_ATTEMPTS = 5;
exports.QUADFI_MAIN_URL = "https://quadfi.com";
exports.QUADFI_PRIVACY_POLICY_URL = " https://quadfi.com/privacy-policy/";

exports.COUNTRY_ADDRESS_ALLOWED = COUNTRY_ADDRESS_ALLOWED;
exports.LANGUAGE_ENGLISH = LANGUAGE_ENGLISH;

exports.LOAN_TERM = LOAN_TERM;
exports.PHONE_NUMBER_LENGTH = 12; // it includes dash 122-213-1231 => 12
exports.PASSWORD_STRONG_SCORE = PASSWORD_STRONG_SCORE;
exports.LOAN_AMOUNT = LOAN_AMOUNT;
exports.CANADA = CANADA;
exports.APP_STATUS_GROUPS = APP_STATUS_GROUPS;
exports.EID_STATUS = EID_STATUS;
exports.EID_WAIT_TIME = EID_WAIT_TIME;
exports.MULTIPLE_UPLOAD_DOCS = MULTIPLE_UPLOAD_DOCS;
exports.MAXIMUM_MULTIPLE_FILES_ALLOWED = MAXIMUM_MULTIPLE_FILES_ALLOWED;
exports.VOUCHED_FILE_EXTENSIONS = VOUCHED_FILE_EXTENSIONS;
exports.DOC_FILE_EXTENSIONS = DOC_FILE_EXTENSIONS;
exports.FILE_EXTENSIONS = FILE_EXTENSIONS;
