import { CButton, CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
// Pages
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import Select from "react-select";
import logoIcon from "src/assets/icons/app-logo.png";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import {
	getCompanyData,
	getIndustries,
	getUserInProgressApp,
	updateUserAppFlow,
} from "../../../services/user";
import AutoComplete from "../../Application_flow/components/autocomplete";

const Employed = (props) => {
	// console.log('Comp Employed ', { props })

	const inProgressApp = getUserInProgressApp();
	let preFill = {
		employer: "",
		starting_date: null,
		job_title: "",
		annual_income: "",
		companyFromList: "",
		globalIndustry: "",
		employedPastThreeMonths: "",
	};
	if (inProgressApp) {
		preFill = {
			employer: inProgressApp.appData.employment.type_of_work,
			starting_date: inProgressApp.appData.employment.starting_date,
			job_title: inProgressApp.appData.employment.current_title,
			annual_income: inProgressApp.appData.employment.annual_income,
			industry: inProgressApp.appData.employment.industry,
			companyFromList: inProgressApp.appData.employment.companyFromList,
			globalIndustry: {
				id: inProgressApp.appData.employment.industry,
				name: inProgressApp.appData.employment.industry,
				type: inProgressApp.appData.employment.industry,
				label: inProgressApp.appData.employment.industry
					? inProgressApp.appData.employment.industry.replace(/\b\w/g, (l) =>
							l.toUpperCase()
					  )
					: "",
			},
			employedPastThreeMonths:
				inProgressApp.appData.employment.employedPastThreeMonths,
		};
		if (
			inProgressApp.appData.employment.annual_income !== "" &&
			inProgressApp.appData.employment.annual_income
		) {
			preFill["annual_income"] = inProgressApp.appData.employment.annual_income
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	}

	const {
		register,
		errors,
		handleSubmit,
		setValue,
		trigger,
		formState,
		getValues,
	} = useForm({
		mode: "onChange",
	});

	const [companyName, setCompanyName] = React.useState(preFill.employer);
	const [jobStartDate, setJobStartDate] = React.useState(
		preFill.starting_date ? new Date(preFill.starting_date) : null
	);
	const [pickerDate, setPickerDate] = useState(
		preFill.starting_date ? preFill.starting_date : null
	);

	const [industry, setIndustry] = React.useState("");
	const [globalIndustry, setGlobalIndustry] = React.useState(preFill.industry);
	const [state, setState] = useState({
		reason: "",
	});
	const [typingTime, setTypingTime] = useState(new Date());
	const [stopTyping, setStopTyping] = useState(new Date());
	const [keyboardClick, setKeyboardClick] = useState(1);
	const [mouseClick, setMouseClick] = useState(1);
	const [wpm, setWpm] = useState(0);
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const [globalIndustries, setGlobalIndustries] = useState([]);
	const [globalIndustrySuggestions, setGlobalIndustrySuggestions] = useState(
		[]
	);
	const [showIndustryList, setShowIndustryList] = useState(
		preFill.companyFromList === "not selected" ? true : false
	);
	const [isCompanySelected, setIsCompanySelected] = useState(
		preFill.companyFromList === "selected" ? true : false
	);
	const [isIndustrySelected, setIsIndustrySelected] = useState(
		preFill.companyFromList === "not selected" && preFill.industry !== ""
			? true
			: false
	);
	const [isFormValid, setIsFormValid] = useState(true);
	const [selectedCompany, setSelectedCompany] = useState("");

	const companyData = [];
	const jobTitle = preFill.job_title;
	const jobAnnualIncome = preFill.annual_income ? preFill.annual_income : "";
	const arrivalTime = new Date();

	const setReason = (reason) => setState({ ...state, reason });
	const styless = {
		option: (provided, state) => ({
			...provided,
			fontWeight: state.isSelected ? "bold" : "normal",
			color: state.data.color,
			backgroundColor: "white",
			fontSize: "20px",
			paddingLeft: "20px",
			marginLeft: "20px",
			marginRight: "200px",
		}),
	};
	const { isValid } = formState;

	const handleBackbutton = async () => {
		localStorage.setItem("PreviousPage", "YES");
		props.setEmploymentStatus("");
		await sendAnalytics(arrivalTime, STEP_PAGES.EMPLOYMENT_STATUS);
	};

	const handleSave = async (formData) => {
		console.log(preFill);
		setNextButtonDisabled(true);
		console.log("formData => ", { formData });
		formData.annualIncome = formData.annualIncome.replace(/,/g, "");
		// formData.companyFromList = preFill.companyFromList === 'not selected' ? 'not selected' : 'selected';
		formData.companyFromList =
			selectedCompany !== ""
				? "selected"
				: preFill.companyFromList
				? preFill.companyFromList
				: "not selected";
		let details = {
			page: "employmentStatus",
			...formData,
			industry: industry
				? industry
				: sessionStorage.getItem("industry_selected"),
			employedPastThreeMonths: employedPastThreeMonths
				? employedPastThreeMonths
				: sessionStorage.getItem("employedPastThreeMonths")
				? sessionStorage.getItem("employedPastThreeMonths")
				: preFill.employedPastThreeMonths,
			employmentExperience: employmentExperience
				? employmentExperience
				: sessionStorage.getItem("employmentExperience"),
			startingDate: pickerDate ? pickerDate : preFill.starting_date,
		};
		console.log(details, "detailsdetails", industry);
		updateUserAppFlow(props.userId, details);
		props.steps.nextStep();
		await sendAnalytics(arrivalTime, STEP_PAGES.EMPLOYMENT_STATUS);
	};

	const handleState = (index, value) => {
		console.log("function called fo the set state");
		setValue("companyName", index);
		trigger("companyName");
	};

	const handleSaveAndExit = () => {
		localStorage.setItem("DropOff", "YES");
		let details = {
			page: "employmentStatus",
			...getValues(),
		};
		updateUserAppFlow(props.userId, details);
		props.steps.lastStep();
	};

	const handleAnnulIncome = (value) => {
		const regex = /^[0-9.,]*$/i;
		if (value && regex.test(value)) {
			const newValue = value
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			console.log(newValue);
			return newValue;
		} else {
			return value.slice(0, -1);
		}
	};
	/// Autocomplete
	const [suggestions, setSuggestions] = useState([]);
	const [suggestionsLoading, setSuggestionsLoading] = useState(false);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [employmentExperience, setEmploymentExperience] = useState(0);
	const [employedPastThreeMonths, setEmployedPastThreeMonths] = useState("");
	// const [companyName,setCompanyName] = useState("");

	const handleCompanyName = (e) => {
		setCompanyName(e.target.value);
		getSuggestions(e.target.value);
		setIsCompanySelected(false);

		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);

		var iTotal = 0;
		var iKeys = 0;
		var iWords = 0;

		setTypingTime(new Date());

		if (stopTyping !== 0) {
			iKeys++;

			// iTotal += typingTime - stopTyping;
			iTotal += Math.abs(Math.round(typingTime - stopTyping) / 1000 / 60);
			iWords = e.target.value.split(/\s/).length;
			let CPM = ((iKeys / iTotal) * 6000, 2);
			let WPM = ((iWords / iTotal) * 6000, 2);

			console.log("CPM" + CPM + "WPM" + WPM);
			setWpm(WPM);
			console.log("words " + iWords);
			localStorage.setItem("WPM", wpm);
		}

		setStopTyping(new Date());
	};

	const closeSuggestionBox = () => {
		setShowSuggestions(false);
		setSuggestions([]);
		setSuggestionsLoading(false);
	};

	let suggestionTimeout;

	const getSuggestions = (text = "") => {
		if (!text || text.length < 3) {
			return closeSuggestionBox();
		}
		clearTimeout(suggestionTimeout);
		//For handling concurrent on change
		setShowSuggestions(true);
		setSuggestionsLoading(true);
		suggestionTimeout = setTimeout(() => {
			fetchSuggestions(text);
		}, 300);
	};

	const fetchSuggestions = (text = "") => {
		fetchData(text);
	};

	const fetchData = async (text) => {
		try {
			const data = await getCompanyData(text);
			console.log(data, "Dataa");
			const newData = data.data.map((item) => {
				item.name = item.name.replace(/\b\w/g, (l) => l.toUpperCase());
				return item;
			});
			setSuggestionsLoading(false);
			setSuggestions(newData);
		} catch (err) {
			setSuggestionsLoading(false);
			setSuggestions([]);
		}
	};

	const companySelectionHandler = (item) => {
		closeSuggestionBox();

		setShowIndustryList(false);
		setGlobalIndustry("");

		setValue("typeOfWork", item.name);
		// alert(item.name)
		console.log(item, "itemitemitemitem");
		setIndustry(item.meta.industry);
		sessionStorage.setItem("industry_selected", item.meta.industry);
		sessionStorage.setItem("company_selected", item.name);
		setReason(item.name);
		localStorage.setItem("Autofill", "YES");
		setIsCompanySelected(true);
		setSelectedCompany(item.name);
	};

	useEffect(() => {
		console.log(isCompanySelected);
		console.log(isIndustrySelected);
		console.log(isFormValid);
		console.log("companyData ->", { companyData });
		if (companyData) {
			handleState(companyData.name, companyData.industry);
		}
		//eslint-disable-next-line
	}, [companyData]);

	useEffect(() => {
		console.log(globalIndustries);
		// Check after every update if form is valid or not
		if (isValid) {
			setNextButtonDisabled(false);
		}
		if (
			(employmentExperience > 0 &&
				employmentExperience <= 3 &&
				employedPastThreeMonths === "") ||
			!jobStartDate
		) {
			setNextButtonDisabled(true);
		}
		//eslint-disable-next-line
	}, [
		isValid,
		employmentExperience,
		employedPastThreeMonths,
		jobStartDate,
		pickerDate,
	]);

	//eslint-disable-next-line
	useEffect(() => {
		if (showIndustryList && globalIndustry === "") {
			setNextButtonDisabled(true);
		} else {
			setNextButtonDisabled(false);
		}
	});

	useEffect(() => {
		const localFn = async () => {
			const allIndustries = await getIndustries();
			setGlobalIndustries(allIndustries.data);
			if (allIndustries) {
				let newDataArr = [];
				//eslint-disable-next-line
				allIndustries.data.map((item) => {
					let tmpRow = {};
					tmpRow["id"] = item.name;
					tmpRow["name"] = item.name.replace(/\b\w/g, (l) => l.toUpperCase());
					tmpRow["value"] = item.name;
					tmpRow["label"] = item.name.replace(/\b\w/g, (l) => l.toUpperCase());
					newDataArr.push(tmpRow);
				});
				setGlobalIndustrySuggestions(newDataArr);
			} else {
				setGlobalIndustrySuggestions([]);
			}
		};
		localFn();
	}, []);

	const handleGlobalIndustry = ({ type, value, id }) => {
		setGlobalIndustry(value);
		sessionStorage.setItem("industry_selected", value);
		setIsIndustrySelected(true);
		setIsFormValid(true);
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	const handleKeyboard = (e) => {
		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);
		var iTotal = 0;
		var iKeys = 0;
		var iWords = 0;
		setTypingTime(new Date());
		if (stopTyping !== 0) {
			iKeys++;
			iTotal += Math.abs(Math.round(typingTime - stopTyping) / 1000 / 60);
			iWords = e.target.value.split(/\s/).length;
			let CPM = ((iKeys / iTotal) * 6000, 2);
			let WPM = ((iWords / iTotal) * 6000, 2);
			setWpm(WPM);
			localStorage.setItem("WPM", wpm);
			console.log(CPM);
		}
		setStopTyping(new Date());
	};

	const handleDateChange = (event) => {
		const currentDate = new Date();
		const checkDate = new Date(event);
		const employmentExp =
			currentDate.getMonth() -
			checkDate.getMonth() +
			12 * (currentDate.getFullYear() - checkDate.getFullYear());
		if (employmentExp >= 0) {
			setEmploymentExperience(parseInt(employmentExp) + 1);
			sessionStorage.setItem(
				"employmentExperience",
				parseInt(employmentExp) + 1
			);
		} else {
			setEmploymentExperience(0);
			sessionStorage.setItem("employmentExperience", 0);
		}
		if (parseInt(employmentExp) + 1 > 3) {
			setEmployedPastThreeMonths("yes");
			sessionStorage.setItem("employedPastThreeMonths", "yes");
		} else {
			setEmployedPastThreeMonths("");
			sessionStorage.setItem("employedPastThreeMonths", "");
		}

		setJobStartDate(event);
		var today = new Date(event);
		var mm = String(today.getMonth() + 1).padStart(2, "0");
		var yyyy = today.getFullYear();
		setPickerDate(yyyy + "-" + mm);
	};

	const empArrangmentOptions = [
		{
			id: "yes",
			name: "Yes",
			value: "yes",
			label: "Yes",
		},
		{
			id: "no",
			name: "no",
			value: "no",
			label: "No",
		},
	];

	const handleExperience = ({ type, value, id }) => {
		setEmployedPastThreeMonths(value);
	};

	const handleIndustryDislay = (e) => {
		if (e.target.value.length > 0) {
			setShowIndustryList(true);
			setGlobalIndustry("");
		}
	};

	return (
		<section className="eduction-new">
			<CProgress
				color="stack-overflow"
				value={(props.steps.currentStep / props.steps.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>Tell us about your employment</b>
								</h1>
								<p
									className="title-page-note"
									style={{ margin: 0, fontWeight: 600 }}>
									If you cannot find your company in the search bar, please
									simply click on "No Result found!" and type manually.
								</p>
							</div>
							<div className="address-input-container">
								{/* Here we are recording the type of employment 
                                    from the EmploymentStatus page
                                    in a hidden input
                                    */}
								<input
									type="hidden"
									name="employmentStatus"
									ref={register}
									value="employed"
								/>

								<div className="input-container">
									<div className="edubox ">
										<label htmlFor="companyName" className="custom-label">
											<b>Name of Company</b>
										</label>

										<AutoComplete
											loading={suggestionsLoading}
											data={suggestions}
											show={showSuggestions}
											selectionHandler={companySelectionHandler}>
											<input
												type="text"
												// id="companyName"
												name="typeOfWork"
												onBlur={(e) => {
													setTimeout(closeSuggestionBox, 300);
													handleIndustryDislay(e);
													setIsFormValid(false);
												}}
												autoComplete={"new-field-1"}
												className={
													"custom-input search-input" +
													(errors.companyName ? "custom-input-error col-8" : "")
												}
												onChange={(e) => handleCompanyName(e)}
												// onChange={(e)=>handleKeyboard(e)}
												onClick={handleClick}
												// value={companyName}
												// onSelect={handleSelect}
												placeholder="XYZ Company"
												defaultValue={companyName}
												ref={register({
													required: true,
													pattern:
														/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/,
												})}
											/>
										</AutoComplete>
									</div>

									{showIndustryList ? (
										<>
											<div className="edubox lg-edubox">
												<label
													htmlFor="globalIndustry"
													className="custom-label">
													<b>Industry</b>
												</label>
												<div className="form-control-plaintext custom-input custom-input-container">
													<Select
														placeholder="Please select an option"
														styles={styless}
														options={globalIndustrySuggestions}
														name="globalIndustry"
														onChange={(event) => handleGlobalIndustry(event)}
														defaultValue={preFill.globalIndustry}
														required
														isSearchable={false}
													/>
												</div>
											</div>
										</>
									) : null}

									{/* Starting Date */}
									<div className="edubox">
										<label htmlFor="companyName" className="custom-label">
											<b>Start Date</b>
										</label>
										<br />
										<div class="form-control-plaintext w-67 h-58 custom-input third-datepicker">
											<DatePicker
												name="startingDate"
												minDate={new Date("1 January, 1970")}
												maxDate={new Date()}
												selected={jobStartDate}
												onClick={handleClick}
												onChange={(date) => handleDateChange(date)}
												dateFormat="yyyy-MM"
												showMonthYearPicker
												showFullMonthYearPicker
												onChangeRaw={(e) => e.preventDefault()}
											/>
										</div>
										<span class="validity"></span>
									</div>
								</div>

								<div className="input-container">
									<div className="edubox">
										<label htmlFor="currentTitle" className="custom-label">
											<b>Job Title</b>
										</label>

										<input
											type="text"
											id="currentTitle"
											name="currentTitle"
											className={
												"form-control-plaintext w-67 h-58 custom-input search-input" +
												(errors.currentTitle ? "custom-input-error" : "")
											}
											placeholder="Job title"
											maxLength={30}
											ref={register({
												required: true,
												pattern:
													//eslint-disable-next-line
													/^[-a-z0-9,\/()&:. ]*[a-z][-a-z0-9,\/()&:. ]*$/i,
											})}
											onChange={(e) => handleKeyboard(e)}
											onClick={handleClick}
											defaultValue={jobTitle}
										/>
									</div>
									<div className="edubox">
										<label htmlFor="annualIncome" className="custom-label">
											<b>Annual Income</b>
										</label>
										<div className="amount-input">
											<input
												type="text"
												id="annualIncome"
												name="annualIncome"
												onChange={(event) => {
													const { value } = event.target;
													event.target.value = handleAnnulIncome(value);
													setKeyboardClick(keyboardClick + 1);
													localStorage.setItem("KeyBoardClick", keyboardClick);
												}}
												onClick={handleClick}
												className={
													"form-control-plaintext w-67 h-58 custom-input " +
													(errors.annualIncome ? "custom-input-error" : "")
												}
												placeholder="50,000"
												defaultValue={jobAnnualIncome}
												maxLength="15"
												ref={register({
													required: "Please enter proper value",
												})}
											/>
										</div>
									</div>
								</div>
								{employmentExperience > 0 && employmentExperience <= 3 ? (
									<div className="input-container w-100">
										<div className="edubox w-100">
											<label className="custom-label">
												<b>
													It seems your current employment arrangement is short.
													Have you been employed during the past three months
													including current and other employers or as
													self-employed?
												</b>
											</label>
											<div
												id="employment_experience"
												name="employment_experience"
												class="form-control-plaintext custom-input custom-input-container">
												<Select
													placeholder="Please Select an option"
													styles={styless}
													options={empArrangmentOptions}
													name="employment_experience"
													onChange={(event) => handleExperience(event)}
												/>
											</div>
										</div>
									</div>
								) : null}
							</div>

							{Object.keys(errors).length > 0 && (
								<div className="error-container">
									<p className="error-message">Please enter a valid input</p>
								</div>
							)}
							{/* </form> */}
						</div>
						<div className="load-class"></div>
						<div className="button-container button-container-margin">
							{isValid &&
							pickerDate &&
							(employmentExperience > 0 && employmentExperience <= 3
								? !!employedPastThreeMonths
								: true) ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							) : (
								<button
									className="custom-button disabled"
									disabled={nextButtonDisabled}>
									Next
								</button>
							)}
							<button className="custom-button" onClick={handleBackbutton}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default Employed;
