import { checkAuth } from "./actions/users";
import { getUserLatestApplication } from "./services/user";
import { ALLOWED_APPLICATION_STATUS_FOR_REAPPLY } from "./Utils/consts";

export const reloadApplication = async (history) => {
	let user = await checkAuth();
	if (user) {
		const userCurrentApplication = await getUserLatestApplication();
		if (
			userCurrentApplication &&
			typeof userCurrentApplication.application !== "undefined"
		) {
			let app = userCurrentApplication.application;
			let pageNo = 1;

			if (
				ALLOWED_APPLICATION_STATUS_FOR_REAPPLY.includes(parseInt(app.status_id))
			) {
				history.push("customerPortal");
			} else {
				app.productId = parseInt(app.productId)
				if (app.page === "product") {
					history.push("productPurpose");
				} else if (app.page === "purpose") {
					history.push("loanAmount");
				} else if (app.page === "loanAmount" && app.status_id === 1) {
					history.push({ pathname: "layout", state: { page: 1 } });
				} else {
					if (app.page === "name") {
						pageNo = 2;
					} else if (app.page === "BirthDate") {
						pageNo = 3;
					} else if (app.page === "citizenship") {
						pageNo =
							app.productId === 1
								? 8
								: app.productId === 2
								? 4
								: app.productId === 4
								? 4
								: app.productId === 3
								? 4
								: app.productId === 5
								? 4
								: 4;
					} else if (app.page === "maritalStatus") {
						pageNo =
							app.productId === 1
								? 9
								: app.productId === 2
								? 5
								: app.productId === 4
								? 5
								: app.productId === 3
								? 5
								: app.productId === 5
								? 5
								: 5;
					} else if (app.page === "contact") {
						pageNo =
							app.productId === 1
								? 10
								: app.productId === 2
								? 6
								: app.productId === 4
								? 6
								: app.productId === 3
								? 6
								: app.productId === 5
								? 6
								: 6;
					} else if (app.page === "address") {
						pageNo =
							app.productId === 1
								? 11
								: app.productId === 2
								? 7
								: app.productId === 4
								? 7
								: app.productId === 3
								? 7
								: app.productId === 5
								? 7
								: 7;
					} else if (app.page === "livingArrangment") {
						pageNo =
							app.productId === 1
								? 12
								: app.productId === 2
								? 8
								: app.productId === 4
								? 8
								: app.productId === 3
								? 8
								: app.productId === 5
								? 8
								: 8;
					} else if (app.page === "highestDegree") {
						pageNo =
							app.productId === 1
								? 4
								: app.productId === 2
								? 10
								: app.productId === 3
								? 13
								: app.productId === 5
								? 13
								: app.productId === 4
								? 13
								: 1;
					} else if (app.page === "education") {
						pageNo =
							app.productId === 1
								? 5
								: app.productId === 2
								? 11
								: app.productId === 3
								? 13
								: app.productId === 5
								? 13
								: app.productId === 4
								? 13
								: 1;
					} else if (app.page === "employmentStatus") {
						pageNo =
							app.productId === 1
								? 15
								: app.productId === 2
								? 12
								: app.productId === 4
								? 10
								: app.productId === 3
								? 10
								: app.productId === 5
								? 10
								: 12;
					} else if (app.page === "monthlyPayment") {
						pageNo =
							app.productId === 1
								? 13
								: app.productId === 2
								? 9
								: app.productId === 4
								? 9
								: app.productId === 3
								? 9
								: app.productId === 5
								? 9
								: 15;
					} else if (app.page === "otherIncome" || app.page === "OtherIncome") {
						pageNo =
							app.productId === 1
								? 17
								: app.productId === 2
								? 13
								: app.productId === 4
								? 11
								: app.productId === 3
								? 11
								: app.productId === 5
								? 11
								: 13;
					} else if (app.page === "investment") {
						pageNo =
							app.productId === 1
								? 18
								: app.productId === 2
								? 14
								: app.productId === 4
								? 12
								: app.productId === 3
								? 12
								: app.productId === 5
								? 12
								: 14;
					} else if (app.page === "cosigner") {
						pageNo = app.productId === 1 ? 19 : 1;
					} else if (app.page === "equifax" || app.page === "equifix") {
						pageNo =
							app.productId === 1
								? 27
								: app.productId === 2
								? 16
								: app.productId === 4
								? 16
								: app.productId === 3
								? 16
								: app.productId === 5
								? 16
								: 15;
					} else if (app.page === "softcheck") {
						pageNo =
							app.productId === 1
								? 30
								: app.productId === 2
								? 19
								: app.productId === 4
								? 19
								: app.productId === 3
								? 19
								: app.productId === 5
								? 19
								: 15;
					} else {
						pageNo = 1;
					}

					if (app.status_id === 3) {
						pageNo =
							app.productId === 1
								? 30
								: app.productId === 2
								? 19
								: app.productId === 4
								? 19
								: app.productId === 3
								? 19
								: app.productId === 5
								? 19
								: 18;
					} else if (app.status_id === 5) {
						pageNo =
							app.productId === 1
								? 31
								: app.productId === 2
								? 20
								: app.productId === 4
								? 20
								: app.productId === 3
								? 20
								: app.productId === 5
								? 20
								: 19;
					} else if (app.status_id === 8) {
						pageNo =
							app.productId === 1
								? 32
								: app.productId === 2
								? 21
								: app.productId === 4
								? 21
								: app.productId === 3
								? 21
								: app.productId === 5
								? 21
								: 20;
					} else if (app.status_id === 10) {
						pageNo =
							app.productId === 1
								? 32
								: app.productId === 2
								? 21
								: app.productId === 4
								? 21
								: app.productId === 3
								? 21
								: app.productId === 5
								? 21
								: 20;
					}

					if (
						app.appData &&
						typeof app.appData.hard_check_results !== "undefined" &&
						app.appData.hard_check_results !== ""
					) {
						pageNo =
							app.productId === 1
								? 32
								: app.productId === 2
								? 21
								: app.productId === 4
								? 21
								: app.productId === 3
								? 21
								: app.productId === 5
								? 21
								: 20;
					}

					if (
						app.status_id === 11 ||
						app.status_id === 12 ||
						app.status_id === 13
					) {
						window.location.href = "documents/generalDocuments";
					} else if (app.status_id === 14) {
						window.location.href = "documents/legalDocuments";
					} else {
						history.push({
							pathname: "layout",
							state: { page: pageNo },
							hash: `#${app.page}`,
						});
						return true;
					}
				}
			}
		}
	}
};

const DATE_OPTIONS = {
	FORMAT: "en-US",
	TIMEZONE: "America/Toronto",
};

export const DateToEST = (date) => {
	if (!date) return "Data Not Available";
	return date.toLocaleString(DATE_OPTIONS.FORMAT, {
		timeZone: DATE_OPTIONS.TIMEZONE,
	});
};

export const formatDate = (utcDate, onlyDate = false) => {
	try {
		if (!utcDate) return "Data Not Available";
		let localDate = new Date(utcDate);
		if (onlyDate) {
			return localDate.toLocaleDateString("en-CA");
		} else {
			return (
				localDate.toLocaleDateString("en-CA") +
				" " +
				localDate.toLocaleTimeString()
			);
		}
	} catch (e) {
		console.log("Formatting Error");
		return "";
	}
};
