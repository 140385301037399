import { debug } from "src/services/debugService";
import { updateAnalytics } from "./analysis";

async function sendAnalytics(arrivalTime, page) {
	let currentTime = new Date();
	let timeSpent = Math.abs(Math.round(currentTime - arrivalTime) / 1000);
	debug("page timeSpent: ", page, timeSpent);
	let body = {
		time: timeSpent,
		page,
		appId: sessionStorage.getItem("appId"),
		keyBoard: localStorage.getItem("KeyBoardClick"),
		mouse: localStorage.getItem("mouseCount"),
		wpm: localStorage.getItem("WPM"),
		previousPage: localStorage.getItem("PreviousPage"),
		autoFill: localStorage.getItem("Autofill"),
		dropOff: localStorage.getItem("DropOff"),
	};
	await updateAnalytics(body);
	localStorage.setItem("KeyBoardClick", 0);
	localStorage.setItem("mouseCount", 0);
	localStorage.setItem("WPM", 0);
	localStorage.setItem("PreviousPage", "NO");
	localStorage.setItem("Autofill", "NO");
	localStorage.setItem("DropOff", "NO");
}

export { sendAnalytics };
