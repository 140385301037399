function headers() {
	let headers = {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
		"Cache-Control": "no-cache",
		Pragma: "no-cache",
		Expires: "0",
		st: sessionStorage.getItem("_st"),
	};
	return headers;
}

export { headers };
