import { CButton, CProgress } from "@coreui/react";
import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import { getUserInProgressApp, updateUserAppFlow } from "../../services/user";

const BirthDate = (props) => {
	const inProgressApp = getUserInProgressApp();
	let preFill = { p_day: "", p_month: "", p_Year: "" };
	if (
		inProgressApp &&
		inProgressApp.appData &&
		inProgressApp.appData.personal &&
		inProgressApp.appData.personal.Date_of_birth
	) {
		let date_array = inProgressApp.appData.personal.Date_of_birth.split(", ");
		preFill = {
			p_day:
				date_array[0] === 0 || date_array[0] === "Invalid date"
					? ""
					: date_array[0],
			p_month: date_array[1],
			p_Year: date_array[2],
		};
	}
	const { register, errors, handleSubmit, watch } = useForm({
		mode: "onChange",
	});
	const watchAllFields = watch();
	const [date, setDate] = React.useState(preFill.p_day);
	const [month, setMonth] = React.useState(preFill.p_month);
	const [year, setYear] = React.useState(preFill.p_Year);
	const [typingTime, setTypingTime] = useState(new Date());
	const [stopTyping, setStopTyping] = useState(new Date());
	const [keyboardClick, setKeyboardClick] = useState(0);
	const [mouseClick, setMouseClick] = useState(0);
	const [wpm, setWpm] = useState(0);
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const [errUpdated, setErrUpdated] = useState("");

	const arrivalTime = new Date();

	let inputDate = moment([year, month - 1, date]);
	let validDate = inputDate.isValid();
	let errorMsg;
	if (
		typeof errors.date === "undefined" &&
		typeof errors.month === "undefined" &&
		typeof errors.year === "undefined"
	) {
	} else if (!validDate) {
		errorMsg = (
			<div className="error-container">
				<p className="error-message">
					Please enter a valid date <br />
					<b>(Example: 31-12-1990)</b>
				</p>
			</div>
		);
	} else {
		errorMsg = (
			<div className="error-container">
				<p className="error-message">
					Please enter a valid date <br />
					<b>(Example: 31-12-1990)</b>
				</p>
			</div>
		);
	}

	const [loading, setLoading] = React.useState("");
	const hideLoader = () => {
		setLoading(false);
	};
	const showLoader = () => {
		setLoading(true);
	};
	const handleSave = async (back) => {
		setNextButtonDisabled(true);
		const birth = inputDate.format("DD, MM, YYYY");
		let details = {
			page: "BirthDate",
			birth: birth,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			setNextButtonDisabled(false);
			hideLoader();
			if (typeof back !== "boolean" || !back) {
				props.nextStep();
				await sendAnalytics(arrivalTime, STEP_PAGES.BIRTH_DATE);
			} else {
				localStorage.setItem("PreviousPage", "YES");
				await sendAnalytics(arrivalTime, STEP_PAGES.BIRTH_DATE);
			}
		}
		if (response.err) {
			console.log("Error Message in birthdate");
		}
		return response;
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		const birth = inputDate.format("DD, MM, YYYY");
		let details = {
			page: "BirthDate",
			birth: birth,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			hideLoader();
			props.handleSaveExit();
			props.lastStep();
		}
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	const handleDate = (e) => {
		setDate(e.target.value);
		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);

		var iTotal = 0;
		var iKeys = 0;
		var iWords = 0;

		setTypingTime(new Date());

		if (stopTyping !== 0) {
			iKeys++;
			iTotal += Math.abs(Math.round(typingTime - stopTyping) / 1000 / 60);
			iWords = e.target.value.split(/\s/).length;
			let CPM = ((iKeys / iTotal) * 6000, 2);
			console.log("Current CPM", CPM);
			let WPM = ((iWords / iTotal) * 6000, 2);
			setWpm(WPM);
			localStorage.setItem("WPM", wpm);
		}
		setStopTyping(new Date());
	};

	const handleMonth = (e) => {
		setMonth(e.target.value);
		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);

		var iTotal = 0;
		var iKeys = 0;
		var iWords = 0;

		setTypingTime(new Date());

		if (stopTyping !== 0) {
			iKeys++;
			iTotal += Math.abs(Math.round(typingTime - stopTyping) / 1000 / 60);
			iWords = e.target.value.split(/\s/).length;
			let CPM = ((iKeys / iTotal) * 6000, 2);
			console.log("Current CPM", CPM);
			let WPM = ((iWords / iTotal) * 6000, 2);
			setWpm(WPM);
			localStorage.setItem("WPM", wpm);
		}
		setStopTyping(new Date());
	};

	const handleYear = (e) => {
		if (new Date().getFullYear() - e.target.value > 18) {
			setYear(e.target.value);
			setKeyboardClick(keyboardClick + 1);
			localStorage.setItem("KeyBoardClick", keyboardClick);

			var iTotal = 0;
			var iKeys = 0;
			var iWords = 0;

			setTypingTime(new Date());

			if (stopTyping !== 0) {
				iKeys++;
				iTotal += Math.abs(Math.round(typingTime - stopTyping) / 1000 / 60);
				iWords = e.target.value.split(/\s/).length;
				let CPM = ((iKeys / iTotal) * 6000, 2);
				console.log("Current CPM", CPM);
				let WPM = ((iWords / iTotal) * 6000, 2);
				setWpm(WPM);
				localStorage.setItem("WPM", wpm);
			}
			setStopTyping(new Date());
			setErrUpdated("");
		} else {
			errors.year = "Underage";
			setErrUpdated("The entered age is below the minimum legal age");
		}
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div class="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>When were you born?</b>
								</h1>
							</div>
							<div className="input-container align-baseline">
								{console.log(
									"tomato",
									typeof errors.date === "undefined",
									errors.date
								)}
								<input
									type="string"
									id="date"
									name="date"
									defaultValue={date}
									onChange={(e) => handleDate(e)}
									onClick={handleClick}
									className={
										"form-control-plaintext w-67 h-58 custom-input" +
										(typeof errors.date === "undefined"
											? ""
											: `${console.log(
													"not a valid date"
											  )} + input-border-error`)
									}
									placeholder="Day"
									ref={register({
										required: true,
										max: 31,
										min: 1,
										pattern: /^[0-9]*$/i,
									})}
								/>
								<input
									type="string"
									id="month"
									name="month"
									defaultValue={month}
									onChange={(e) => handleMonth(e)}
									onClick={handleClick}
									className={
										"form-control-plaintext w-67 h-58 custom-input" +
										(typeof errors.month === "undefined"
											? ""
											: `${console.log(
													"not a valid month"
											  )} + input-border-error`)
									}
									placeholder="Month"
									ref={register({
										required: true,
										max: 12,
										min: 1,
										pattern: /^[0-9]*$/i,
									})}
								/>
								<input
									type="string"
									id="year"
									name="year"
									defaultValue={year}
									onChange={(e) => handleYear(e)}
									onClick={handleClick}
									className={
										`form-control-plaintext w-67 h-58 custom-input` +
										(typeof errors.year === "undefined"
											? ""
											: `${console.log(
													"not a valid year"
											  )} + input-border-error`)
									}
									placeholder="Year"
									ref={register({
										required: true,
										max: moment().subtract(18, "year"),
										min: 1930,
										pattern: /^[0-9]*$/i,
									})}
								/>
							</div>
							{errorMsg}
							{errUpdated !== "" && (
								<div className="error-container">
									<p className="error-message">
										The entered age is below the minimum legal age
									</p>
								</div>
							)}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{watchAllFields.date &&
							watchAllFields.month &&
							watchAllFields.year &&
							typeof errors.date === "undefined" &&
							typeof errors.month === "undefined" &&
							typeof errors.year === "undefined" &&
							validDate &&
							errUpdated === "" ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							) : (
								<button
									className="custom-button disabled"
									disabled={nextButtonDisabled}>
									Next
								</button>
							)}
							<button
								className="custom-back-button"
								onClick={async () => {
									await handleSave(true);
									if (props?.previousStep) {
										props.previousStep();
									}
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default BirthDate;
