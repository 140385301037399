import {
	CButton,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CContainer,
	CForm,
	CFormGroup,
	CImg,
	CInput,
	CLabel,
	CRow,
} from "@coreui/react";
import React, { useEffect, useMemo, useState } from "react";
import Loader from "react-loader-spinner";
import PlacesAutocomplete, {
	geocodeByAddress,
} from "react-places-autocomplete";
import { getLoggedUser } from "src/actions/users";
import { COUNTRY_ADDRESS_ALLOWED, LANGUAGE_ENGLISH } from "src/Utils/consts";
import menuIcon from "../../assets/icons/menuIcon.png";
import { getUserApplications, updateUser, userInfo } from "../../services/user";
import "../../style/personalinformation.scss";
import { TheSidebar } from "./../../containers/index";
import TopNav from "./../../containers/TopNav";

const AddressInfo = () => {
	const [hidden, setHidden] = React.useState(true);
	const [user, setUser] = useState({ address: {} });
	const [streetname, setStreetName] = React.useState("");
	const [city, setCity] = React.useState("");
	const [province, setProvince] = React.useState("");
	const [postalcode, setPostalCode] = React.useState("");
	const [applications, setApplications] = useState([]);
	const [applicationId, setApplicationId] = React.useState("");
	const [editShow, setEditShow] = React.useState(false);
	const unRead = [];
	const [loading, setLoading] = React.useState("");
	const [fullAddress, setFullAddress] = React.useState(null);
	const [fullCheck, setFullCheck] = React.useState(true);
	const [saveDisabled, setSaveDisabled] = useState(false);
	const [address, setAddress] = React.useState("");
	const streetnumber = "";

	const componentForm = useMemo(
		() => [
			"street_number",
			"route",
			"sublocality",
			"neighborhood",
			"locality",
			"administrative_area_level_2",
			"administrative_area_level_1",
			"country",
			"postal_code",
		],
		[]
	);

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	var searchOptions = {
		componentRestrictions: {
			country: COUNTRY_ADDRESS_ALLOWED,
			language: LANGUAGE_ENGLISH,
		},
	};

	const handleChange = (address, e) => {
		console.log(address, "Addfa");
		if (address === "") {
			setFullAddress(null);
			setFullCheck(false);
			setAddress("");
		} else {
			setFullCheck(true);
			setAddress(address);
		}
		console.log(fullCheck);
	};
	const handleCity = (event) => {
		setCity(event.target.value);
	};

	const handleSelect = async (G_address) => {
		const geocode = await geocodeByAddress(G_address);
		setFullAddress(geocode);

		let streetAddressVal = "";
		if (geocode.length) {
			if (
				typeof geocode[0]["address_components"] !== "undefined" &&
				geocode[0]["address_components"].length
			) {
				//eslint-disable-next-line
				geocode[0]["address_components"].map((itm) => {
					if (itm.types.includes("street_number")) {
						streetAddressVal += itm.long_name + " ";
					}
					if (itm.types.includes("route")) {
						streetAddressVal += itm.long_name;
					}
				});

				if (streetAddressVal === "") {
					//eslint-disable-next-line
					geocode[0]["address_components"].map((itm) => {
						if (itm.types.includes("sublocality_level_1")) {
							streetAddressVal = itm.long_name;
						}
					});
				}
			}
		}
		setAddress(streetAddressVal);

		console.log("user details handleselect--", user);

		if (sessionStorage.getItem("CurrentSelect") !== 0) {
			hideLoader();
		} else {
			setEditShow(true);
		}
	};

	const handleProvince = (event) => {
		setProvince(event.target.value);
	};

	const handlePostal = (event) => {
		setPostalCode(event.target.value);
	};
	console.log("user details--", user);
	const handleSave = () => {
		user.address.currentAddress = streetname;
		user.address.civicNumber = streetnumber;
		user.address.City = city;
		user.address.Province = province;
		user.address.postalCode = postalcode;

		let updatedInformation = {
			page: "address",
			currentAddress: streetname !== "" ? streetname : user.address.currentAddress,
			streetNumber:
				streetnumber !== "" ? streetnumber : user.address.civicNumber,
			city: city !== "" ? city : user.address.City,
			province: province !== "" ? province : user.address.Province,
			postalcode: postalcode !== "" ? postalcode : user.address.postalCode,
			Country: user.address.Country,
		};

		updateUser(sessionStorage.getItem("appId"), updatedInformation);
		setHidden(true);
	};
	const handleCancel = () => {
		(async () =>
			await userInfo(sessionStorage.getItem("CurrentSelect"), setUser))();
		setHidden(true);
	};

	useEffect(() => {
		showLoader();
		// eslint-disable-next-line no-unused-vars
		let userValue = (async () => await getLoggedUser())();
		let userApps = (async () => await getUserApplications(user.id))();
		if (
			userApps.success &&
			userApps.applications &&
			userApps.applications.length > 0
		) {
			setApplications(userApps.applications);
		}

		console.log(applications);

		if (sessionStorage.getItem("CurrentSelect") !== 0) {
			setEditShow(true);
			async function Call() {
				setApplicationId(sessionStorage.getItem("CurrentSelect"));
				console.log(applicationId);
				showLoader();
				(async () =>
					await userInfo(sessionStorage.getItem("CurrentSelect"), setUser))();
				hideLoader();
			}
			Call();
		} else {
			console.log("here");
			const option = document.querySelectorAll(".options");
			console.log(option[0], "options");
			sessionStorage.setItem("CurrentSelect", option[0].value);
			let userId = option[0].value;
			setApplicationId(option[0].value);
			showLoader();
			(async () => await userInfo(userId, setUser))();
			hideLoader();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (fullAddress && fullAddress.length > 0) {
			setSaveDisabled(false);
		} else {
			setSaveDisabled(true);
		}
	}, [fullAddress]);
	useEffect(() => {
		if (fullAddress) {
			// console.log("FULLaddress: " + JSON.stringify(fullAddress));
			let flag = 0,
				indexZeroFlag = 0;
			const address_vals = [];

			for (var i = 0; i < fullAddress[0].address_components.length; i++) {
				var addressType = fullAddress[0].address_components[i].types;
				for (let a of addressType) {
					for (let [index, value] of componentForm.entries()) {
						if (a === value) {
							if (index === 1) {
								// eslint-disable-next-line no-unused-vars
								flag = 1;
							}
							console.log(
								"index and value of COMponentForm-- ",
								index,
								value,
								fullAddress[0].address_components[i].long_name
							);
							if (index === 0) {
								indexZeroFlag = 1;
								index++;
								let streetAddressVal = "";
								if (i === 1) {
									streetAddressVal =
										fullAddress[0].address_components[i - 1].long_name +
										" " +
										fullAddress[0].address_components[i].long_name;
								} else {
									streetAddressVal =
										fullAddress[0].address_components[i].long_name;
								}
								address_vals[index] = streetAddressVal;
								continue;
							}
							if (index === 1 && indexZeroFlag === 1) {
								continue;
							}

							address_vals[index] =
								fullAddress[0].address_components[i].long_name;
						}
					}
				}
			}
			let provinceCode = {};
			let city = {};
			let route = {};
			let street_number = {};
			fullAddress[0].address_components.forEach((itemAddr) => {
				if (itemAddr.types.includes("administrative_area_level_1")) {
					provinceCode = itemAddr;
				}
				if (itemAddr.types.includes("locality")) {
					console.log("itemAddr.types.includes locality/city", itemAddr);
					city = itemAddr;
				}
				if (itemAddr.types.includes("street_number")) {
					console.log("itemAddr.types.includes street_number", itemAddr);
					street_number = itemAddr;
				}
				if (itemAddr.types.includes("route")) {
					console.log("itemAddr.types.includes route", itemAddr);
					route = itemAddr;
				}
			});
			console.log(city);
			if (
				typeof provinceCode.long_name !== "undefined" &&
				provinceCode.long_name !== ""
			) {
				setProvince(provinceCode.long_name);
			}
			console.log("truncate<<< Fulladdress", address_vals);
			if (typeof address_vals[8] !== "undefined") {
				setPostalCode(address_vals[8]);
			}
			if (typeof address_vals[4] !== "undefined") {
				setCity(address_vals[4]);
			}
			if (
				typeof street_number.long_name !== "undefined" &&
				typeof route.long_name !== "undefined"
			) {
				console.log(
					"fianlll streat address: ",
					(street_number.long_name ? street_number.long_name : "").concat(
						" ",
						route.long_name ? route.long_name : ""
					)
				);
				setStreetName(
					(street_number.long_name ? street_number.long_name : "").concat(
						" ",
						route.long_name ? route.long_name : ""
					)
				);
			}
		}
		user.address.currentAddress = streetname ? streetname : user.address.currentAddress;
		user.address.civicNumber = streetnumber
			? streetnumber
			: user.address.civicNumber;
		user.address.City = city ? city : user.address.City;
		user.address.Province = province ? province : user.address.Province;
		user.address.postalCode = postalcode ? postalcode : user.address.postalCode;
		console.log(
			"postal_code_value",
			postalcode,
			province,
			city,
			streetname + "and FUlladdreSS " + JSON.stringify(fullAddress)
		);
		console.log("user details in useEffect--", user);
	}, [
		fullAddress,
		componentForm,
		postalcode,
		city,
		province,
		streetname,
		streetnumber,
		user,
	]);

	const [activeCls, setActivecls] = useState(false);
	const menuHandler = () => {
		setActivecls(!activeCls);
	};

	const formEdit = () => {
		setHidden(false);
	};

	return (
		<div className="PI_page">
			<div
				className={`c-app c-default-layout ${
					activeCls ? "sidBrexpand" : null
				}`}>
				<TheSidebar />
				<div className="c-wrapper">
					<div className="c-body">
						<CContainer fluid className="PageLayout">
							<CRow class="w-87">
								<CCol class="col-auto">
									<div className="user-name-container">
										<h1 className="p-0 pb-2">
											<b>Address</b>
										</h1>
									</div>
									<CCard className="m-4">
										<CCardHeader>
											<div className="d-flex">
												<CButton
													shape="pill"
													className="edit_bttn_option p-0"
													onClick={() => formEdit()}>
													{editShow ? <b>Edit</b> : ""}
												</CButton>
											</div>
										</CCardHeader>
										<div className="contain_body">
											<CCardBody>
												{!user.address ? null : (
													<CForm action="" method="post">
														<CFormGroup>
															<CLabel
																htmlFor="streetname"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Street</b>
															</CLabel>
															{hidden ? (
																<CInput
																	type="streetname"
																	id="streetname"
																	name="streetname"
																	// onChange={handleStreetName}
																	disabled
																	class="form-control-plaintext w-67 h-58"
																	value={user.address.currentAddress}
																/>
															) : (
																<PlacesAutocomplete
																	value={address}
																	onChange={handleChange}
																	onSelect={handleSelect}
																	searchOptions={searchOptions}>
																	{({
																		getInputProps,
																		suggestions,
																		getSuggestionItemProps,
																		loading,
																	}) => (
																		<React.Fragment>
																			<div className="search-input">
																				<input
																					{...getInputProps({
																						placeholder: streetname
																							? streetname
																							: user.address.currentAddress,
																						// className: errors.streetAddress
																						// 	? "custom-input custom-input-error"
																						// 	: "custom-input ",
																					})}
																					// onClick={handleClick}
																				/>
																			</div>
																			<div
																				className={
																					suggestions.length > 0
																						? "autocomplete-dropdown-container has-options"
																						: "autocomplete-dropdown-container"
																				}>
																				{loading && (
																					<div className="loading">
																						Loading...
																					</div>
																				)}
																				{suggestions.map((suggestion, i) => {
																					const className = suggestion.active
																						? "suggestion-item--active"
																						: "suggestion-item";
																					// inline style for demonstration purpose
																					const style = suggestion.active
																						? {
																								backgroundColor: "#ff9300",
																								cursor: "pointer",
																								padding: "0.5rem 1rem",
																						  }
																						: {
																								backgroundColor: "#ffffff",
																								cursor: "pointer",
																								padding: "0.5rem 1rem",
																						  };
																					return (
																						<div
																							key={i}
																							{...getSuggestionItemProps(
																								suggestion,
																								{
																									className,
																									style,
																								}
																							)}>
																							<span>
																								{suggestion.description}
																							</span>
																						</div>
																					);
																				})}
																			</div>
																		</React.Fragment>
																	)}
																</PlacesAutocomplete>
															)}
														</CFormGroup>
														<CFormGroup>
															<CLabel
																htmlFor="city"
																style={{
																	color: "#ff9e00",
																}}>
																<b>City</b>
															</CLabel>
															{hidden ? (
																<CInput
																	type="city"
																	id="city"
																	name="city"
																	disabled
																	value={user.address.City}
																	class="form-control-plaintext w-67 h-58"
																/>
															) : (
																<CInput
																	type="city"
																	id="city"
																	name="city"
																	onChange={handleCity}
																	class="form-control w-67 h-58"
																	// defaultValue={user.address.City}
																	value={city ? city : user.address.City}
																/>
															)}
														</CFormGroup>
														<CFormGroup>
															<CLabel
																htmlFor="province"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Province</b>
															</CLabel>
															{hidden ? (
																<CInput
																	type="province"
																	id="province"
																	name="province"
																	disabled
																	value={user.address.Province}
																	class="form-control-plaintext w-67 h-58"
																/>
															) : (
																<CInput
																	type="province"
																	id="province"
																	name="province"
																	onChange={handleProvince}
																	class="form-control w-67 h-58"
																	value={
																		province ? province : user.address.Province
																	}
																/>
															)}
														</CFormGroup>
														<CFormGroup>
															<CLabel
																htmlFor="postalcode"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Postal Code</b>
															</CLabel>
															{hidden ? (
																<CInput
																	type="postalcode"
																	id="postalcode"
																	name="postalcode"
																	disabled
																	value={user.address.postalCode}
																	class="form-control-plaintext w-67 h-58"
																/>
															) : (
																<CInput
																	type="postalcode"
																	id="postalcode"
																	name="postalcode"
																	onChange={handlePostal}
																	class="form-control w-67 h-58"
																	// defaultValue={user.address.postalCode}
																	value={
																		postalcode
																			? postalcode
																			: user.address.postalCode
																	}
																/>
															)}
														</CFormGroup>
													</CForm>
												)}
												<div
													className={
														"load-class " + (!user.address ? "load_change" : "")
													}>
													{loading ? (
														<Loader
															type="ThreeDots"
															color="#ff9700"
															height={100}
															width={100}
															timeout={10000} //3 secs
														/>
													) : null}
												</div>
											</CCardBody>{" "}
										</div>

										<CCardFooter>
											<CRow>
												<CButton
													shape="pill"
													className="SaveButton"
													disabled={saveDisabled}
													style={
														hidden
															? {
																	visibility: "hidden",
															  }
															: {
																	visibility: "visible",
															  }
													}
													onClick={() => handleSave()}>
													Save
												</CButton>
												<CButton
													shape="pill"
													className="CancelButton"
													style={
														hidden
															? {
																	visibility: "hidden",
															  }
															: {
																	visibility: "visible",
															  }
													}
													onClick={() => handleCancel()}>
													Cancel
												</CButton>
											</CRow>
										</CCardFooter>
									</CCard>
								</CCol>
							</CRow>
						</CContainer>
						<div className="top_navOuter position-relative">
							<button className="bars" onClick={menuHandler}>
								<CImg src={menuIcon}></CImg>
							</button>
							<TopNav unRead={unRead} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(AddressInfo);
