import { CButton, CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { EID_STATUS, ENV } from "src/Utils/consts";
import { STEP_PAGES } from "src/Utils/enums";
import {
	equifaxUpdate,
	getActiveApplication,
	updateApp,
} from "../../../services/user";
import store from "../../../store";
const EquifaxQ2 = (props) => {
	let state = store.getState();
	const history = useHistory();
	let [answers, setAnswers] = useState({});
	const [question, setOuestion] = React.useState();
	const [loading, setLoading] = React.useState("");
	const [mouseClick, setMouseClick] = useState(0);
	const arrivalTime = new Date();

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	useEffect(() => {
		const localFn = async () => {
			showLoader();

			let equifaxQuestions = JSON.parse(localStorage.getItem("equifax"));
			if (equifaxQuestions.data.success === true) {
				setOuestion(equifaxQuestions.data.questions[1]);
				hideLoader();
				localStorage.removeItem("equifax");
			}
		};
		localFn();
	}, []);

	const { register, errors, formState } = useForm({
		mode: "onChange",
	});
	const { isValid } = formState;
	console.log(isValid);

	const handleChange = (data) => {
		let answer1;
		if (state.users && state.users.answer) answer1 = state.users.answer;
		setAnswers({ answer1: answer1, answer2: data.answerId });
	};

	const updatePageInfo = async () => {
		let appId = sessionStorage.getItem("appId");
		let updatedInformation = {
			page: "equifix",
			appId: appId,
		};
		if (appId) await updateApp(updatedInformation);
	};

	const handleSave = async (answerObject = "") => {
		showLoader();
		await updatePageInfo();
		await sendAnalytics(arrivalTime, STEP_PAGES.EQUIFAXQ2);

		const applicationData = await getActiveApplication(
			sessionStorage.getItem("appId")
		);

		let callEidApi = true;

		if (applicationData.equifaxQuestionsCalledAt !== "") {
			const apiCallDate = new Date(applicationData.equifaxQuestionsCalledAt);
			const currentDate = new Date();
			const milliseconds = Math.abs(currentDate - apiCallDate);
			const hours_diff = milliseconds / 36e5;
			if (parseInt(hours_diff) < 72) {
				callEidApi = false;
			}
		}

		if (callEidApi) {
			const result = await equifaxUpdate(
				answerObject,
				state.users.transactionKey
			);
			console.log(result, "EQUIFAX UPDATE RESULT");

			if (result.success === false && result.status === EID_STATUS.REJECTED) {
				history.push("application/error");
			}
			if (result.success === false && result.status === EID_STATUS.TRY_AGAIN) {
				window.location.href = "/try-again-later";
			}
			if (result.success === true) {
				hideLoader();
				props.nextStep();
			}
		} else {
			window.location.href = "/try-again-later";
		}
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		showLoader();
		await updatePageInfo();
		await equifaxUpdate(answers, state.users.transactionKey);
		hideLoader();
		props.lastStep();
	};

	const handleClick = (eq_answerID = "") => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);

		if (eq_answerID !== "") {
			const selectedAnswer = {
				answer: eq_answerID,
			};
			console.log(selectedAnswer);
			let eq_answer1;
			if (state.users && state.users.answer) eq_answer1 = state.users.answer;
			const answerObj = { answer1: eq_answer1, answer2: eq_answerID };
			handleSave(answerObj);
		}
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll tablet vertical-middle-page">
					<div>
						<div class="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>{question && question.QuestionText}</b>
								</h1>
							</div>
							<div className="cards-container">
								{question &&
									question.AnswerChoice.map((cardContent, index) => (
										<div className="icon-card equifax-question-box">
											<input
												type="radio"
												id={"EQ2-" + index}
												name="answer"
												value={cardContent["answerId"]}
												class="custom-radio"
												ref={register({
													required: "Please select one of the options",
												})}
												onChange={() => handleChange(cardContent)}
												onClick={() => handleClick(cardContent["answerId"])}
											/>
											<label
												htmlFor={"EQ2-" + index}
												className="card-label inboxtitle">
												<h3>
													<b>
														{cardContent["$t"]}
														{process.env.REACT_APP_ENVIRONMENT_DEPLOY_NAME ===
															ENV.DEV && cardContent["correctAnswer"] === "true"
															? "(C)"
															: ""}
													</b>
												</h3>
											</label>
										</div>
									))}
							</div>
							{Object.keys(errors).length > 0 && (
								<div className="error-container">
									<p className="error-message">
										{errors[Object.keys(errors)[0]].message}
									</p>
								</div>
							)}
							<div className="load-class">
								{loading ? (
									<div className="load-class-inner">
										<img src={loadingIcon} alt="" />
									</div>
								) : null}
							</div>
						</div>
						<div className="button-container">
							{/* {isValid ? (
                        <button className="custom-button enabled" onClick={handleSubmit(handleSave)}>Next</button>
                    ) : (
                        <button className="custom-button disabled" onClick={handleSubmit(handleSave)}>Next</button>
                    )} */}
							{/* <button className="custom-button" onClick={handleSaveAndBack}>Back</button> */}
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default EquifaxQ2;
