import { CButton, CProgress } from "@coreui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import { getUserInProgressApp, updateUserAppFlow } from "../../services/user";
import { Tooltip } from "src/Components/Tooltip/Tooltip";

const MonthlyPayment = (props) => {
	const inProgressApp = getUserInProgressApp();
	let preFill = "";
	if (inProgressApp) {
		preFill = inProgressApp.appData.address.monthly_payment;
	}
	const [monthlyPayment, setMonthlyPayment] = React.useState(
		preFill ? preFill : ""
	);
	const [keyboardClick, setKeyboardClick] = useState(1);
	const [mouseClick, setMouseClick] = useState(0);
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const { register, errors, handleSubmit, formState, getValues } = useForm({
		mode: "onChange",
	});

	const arrivalTime = new Date();
	const { isValid } = formState;

	const rentSelected = localStorage.getItem("living_arrangement_rent");

	const handleMonthlyPayment = (value) => {
		if (value) {
			const newValue = value
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			setMonthlyPayment(newValue.replace(/,/g, ""));
			return newValue;
		} else {
			setMonthlyPayment("");
		}
		return value;
	};

	const [loading, setLoading] = React.useState("");
	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	const handleSave = async (formData) => {
		console.log(errors);
		setNextButtonDisabled(true);
		let details = {
			page: "monthlyPayment",
			monthlyPayment,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			setNextButtonDisabled(false);
			hideLoader();
			props.nextStep();
			await sendAnalytics(arrivalTime, STEP_PAGES.MONTHLY_PAYMENT);
		}
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		let details = {
			page: "monthlyPayment",
			...getValues(),
			monthlyPayment,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		console.log("See this response", response);
		if (response.success) {
			hideLoader();
			props.handleSaveExit();
			props.lastStep();
		}
	};

	const handleSaveAndBack = async () => {
		localStorage.setItem("PreviousPage", "YES");
		let details = {
			page: "monthlyPayment",
			monthlyPayment,
		};
		updateUserAppFlow(props.user.id, details);
		props.previousStep();
		await sendAnalytics(arrivalTime, STEP_PAGES.MONTHLY_PAYMENT);
	};

	const handleMouseClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div className="content-container">
						<div className="text-container text-container-margin">
							<h1>
								<b>What is your monthly rental or mortgage payment?</b>
							</h1>
						</div>
						<div className="input-container medium-input">
							<div className="no-left-padding">
								<label htmlFor="monthlyPaymentAmount" className="custom-label">
									<b>Amount</b>
								</label>
								<div className="amount-input full-width">
									<input
										type="text"
										id="monthlyPaymentAmount"
										name="monthlyPaymentAmount"
										className={
											"form-control-plaintext w-67 h-58 custom-input " +
											(errors.otherIncomeAmount ? "custom-input-error" : "")
										}
										maxLength={10}
										placeholder="1,000"
										onChange={(event) => {
											const { value } = event.target;
											event.target.value = handleMonthlyPayment(value);
											setKeyboardClick(keyboardClick + 1);
											localStorage.setItem("KeyBoardClick", keyboardClick);
										}}
										defaultValue={monthlyPayment}
										onClick={handleMouseClick}
										ref={register({
											required: true,
											min: rentSelected === "RENT" ? 1 : 0,
											pattern: /^[0-9.,]*$/i,
										})}
									/>
									{errors.monthlyPaymentAmount && rentSelected === "RENT" ? (
										<p className="text-center">Rent Amount cannot be 0</p>
									) : null}
								</div>
							</div>
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{isValid && monthlyPayment !== "" ? (
								<CButton
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</CButton>
							) : (
								<CButton
									className="custom-button disabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</CButton>
							)}
							<CButton
								className="custom-back-button"
								onClick={handleSaveAndBack}>
								Back
							</CButton>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default MonthlyPayment;
