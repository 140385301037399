import { CButton, CLabel, CProgress } from "@coreui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import Select, { components, createFilter } from "react-select";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { headers } from "src/reusable/headers";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import {
	getUniversityCountries,
	getUserInProgressApp,
	updateUserAppFlow,
} from "../../services/user";
import { CANADA } from "../../Utils/consts";

const endpoint = process.env.REACT_APP_API_HOST + "/V1";
const CustomOption = ({ children, ...props }) => {
	const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
	const newProps = { ...props, innerProps: rest };
	return <components.Option {...newProps}>{children}</components.Option>;
};

export default function Education(props) {
	const gpaData = [
		{
			id: 1,
			type: "gpa",
			value: "A+",
			label: "A+",
			style: { color: "red" },
		},
		{ id: 2, type: "gpa", value: "A", label: "A" },
		{ id: 3, type: "gpa", value: "A-", label: "A-" },
		{ id: 4, type: "gpa", value: "B+", label: "B+" },
		{ id: 5, type: "gpa", value: "B", label: "B" },
		{ id: 6, type: "gpa", value: "B-", label: "B-" },
		{ id: 7, type: "gpa", value: "C+", label: "C+" },
		{ id: 8, type: "gpa", value: "C", label: "C" },
		{ id: 9, type: "gpa", value: "C-", label: "C-" },
		{ id: 10, type: "gpa", value: "D+", label: "D+" },
		{ id: 11, type: "gpa", value: "D", label: "D" },
		{ id: 12, type: "gpa", value: "D-", label: "D-" },
	];

	const inProgressApp = getUserInProgressApp();
	let preFill = {
		gpa: "",
		school_name: "",
		graduation_date: null,
		major: "",
		valid: false,
	};
	let preFillSchool = "";
	if (inProgressApp) {
		let gpaSelected = {};
		//eslint-disable-next-line
		gpaData.map((gpaItem) => {
			if (gpaItem.value === inProgressApp.appData.education.gpa) {
				gpaSelected = gpaItem;
			}
		});
		preFill = {
			gpa: gpaSelected,
			school_name: {
				value: inProgressApp.appData.education.university,
				label: inProgressApp.appData.education.university,
				type: "school",
				id: inProgressApp.appData.education.university_id,
			},
			graduation_date: inProgressApp.appData.education.completion_date,
			major: {
				value: inProgressApp.appData.education.major,
				label: inProgressApp.appData.education.major,
				type: "major",
				id: inProgressApp.appData.education.program_id,
				university_id: inProgressApp.appData.education.university_id,
			},
		};
		preFillSchool = inProgressApp.appData.education.university;
		if (
			preFill.gpa !== "" &&
			preFill.school_name !== "" &&
			preFill.graduation_date !== "" &&
			preFill.major !== ""
		) {
			preFill.valid = true;
		}
	}

	const { errors, handleSubmit } = useForm({
		mode: "onChange",
	});
	const [majordata, setmajordata] = useState([]);
	const [degreedata, setdegreedata] = useState([]);
	const [countriesData, setCountriesData] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState([]);
	const [institutionSelectedValue, setInstitutionSelectedValue] = useState({
		selected: "",
	});
	const [majorSelectedValue, setMajorSelectedValue] = useState({
		selected: "",
	});

	const [state, setState] = useState({
		gpa: "",
		major: "",
		university: "",
		degree: "",
		completionDate: null,
	});
	const [university_id, setUniversityId] = useState("");
	const [program_id, setProgramId] = useState("");
	const [gpa_id, setGPAId] = useState("");
	const [mouseClick, setMouseClick] = useState(0);
	const setGPA = (gpa) => setState({ ...state, gpa });
	const setMajor = (major) => setState({ ...state, major });
	const setUniversity = (university) => setState({ ...state, university });
	const setDegree = (degree) => setState({ ...state, degree });
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const setCompletionDate = (completionDate) =>
		setState({ ...state, completionDate });
	let errorMsg;

	const [pickerDate, setPickerDate] = useState(
		preFill.graduation_date ? new Date(preFill.graduation_date) : null
	);
	const [pickerCompletionDate, setpPickerCompletionDate] = useState(
		preFill.graduation_date
	);

	const university_name = preFillSchool;
	const arrivalTime = new Date();

	if (
		typeof errors.major === "undefined" &&
		typeof errors.completionDate === "undefined" &&
		typeof errors.degree === "undefined" &&
		typeof errors.school === "undefined"
	) {
	} else {
		errorMsg = (
			<div className="error-container">
				<p class="error-message"> Please enter a valid input!</p>
			</div>
		);
	}

	const [loading, setLoading] = React.useState("");
	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	useEffect(() => {
		const localFn = async () => {
			console.log(gpa_id);
			const result = await getUniversityCountries();
			result.sort(function (x, y) {
				return x.country === CANADA ? -1 : y.country === CANADA ? 1 : 0;
			});
			console.log("Global Uni Countries", result);
			let countriesResult = result.map((item, index) => ({
				value: item.country,
				label: item.country,
				id: index,
			}));
			countriesResult.push({ value: "Other", label: "Other" });
			setCountriesData(countriesResult);
			if (preFill.gpa !== "") {
				setGPA(preFill.gpa.value);
				setGPAId(preFill.gpa.id);
			}
			if (preFill.school_name !== "") {
				setUniversity(preFill.school_name.value);
				setUniversityId(preFill.school_name.id);
			}
			if (preFill.major !== "") {
				setMajor(preFill.major.value);
				setProgramId(preFill.major.id);
			}
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	const handleSave = async (back) => {
		setNextButtonDisabled(true);
		const details = {
			page: "education",
			university: state.university ? state.university : university_name,
			university_id: university_id,
			program_id: program_id,
			degree: state.degree,
			compeletion_date: pickerCompletionDate
				? pickerCompletionDate
				: preFill.graduation_date,
			major: state.major,
			gpa: state.gpa,
			country: selectedCountry,
		};

		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);

		if (response.success) {
			setNextButtonDisabled(false);
			hideLoader();
			if (typeof back !== "boolean" || !back) {
				props.goToStep(16);
				await sendAnalytics(arrivalTime, STEP_PAGES.EDUCATION);
			} else {
				localStorage.setItem("PreviousPage", "YES");
				await sendAnalytics(arrivalTime, STEP_PAGES.EDUCATION);
			}
		}
		return response;
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		const details = {
			page: "education",
			university: state.university,
			degree: state.degree,
			compeletion_date: state.completionDate,
			major: state.major,
			gpa: state.gpa,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			hideLoader();
			props.handleSaveExit();
			props.lastStep();
		}
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};
	const handleCountryClick = (event) => {
		console.log("handleCountryClick event: ", event.value);
		preFill.school_name = "";
		preFill.major = "";
		setdegreedata([]);
		setInstitutionSelectedValue({ selected: "" });
		setMajorSelectedValue({ selected: "" });
		setPickerDate(null);
		setpPickerCompletionDate(null);
		setSelectedCountry(event.value);
		setNextButtonDisabled(true);
		if (event.value === "Other") {
			setdegreedata([{ value: "Other", label: "Other", type: "school" }]);
		}
	};

	const getGlobalMajors = async () => {
		await axios
			.get(`${endpoint}/user/getGlobalMajor`, {
				headers: headers(),
				withCredentials: true,
			})
			.then((major) => {
				let options = [];
				//eslint-disable-next-line
				major.data.programs_schema.map(function (city) {
					options.push({
						value: city.name,
						label: city.name,
						type: "major",
						id: city.id,
						university_id: city.id,
					});
				});
				options.push({
					value: "Other",
					label: "Other",
					type: "major",
					university_id: "Other",
				});
				setmajordata(options);
				console.log("outter set");
			});
	};

	const handleSelect = async ({ type, value, id }) => {
		console.log("event", id, type, type === "school");
		if (type === "school") {
			setUniversity(value);
			setUniversityId(id);
			setInstitutionSelectedValue({
				selected: {
					value: value,
					label: value,
					type: "school",
					id: id,
				},
			});
			setMajorSelectedValue({ selected: "" });
		} else if (type === "major") {
			setMajor(value);
			setProgramId(id);
			setMajorSelectedValue({
				selected: { value: value, label: value, type: "major", id: id },
			});
		} else if (type === "Degree") {
			if (value === "I'm not a student") {
				props.goToStep(33);
			}
			setDegree(value);
		} else if (type === "gpa") {
			setGPA(value);
			setGPAId(id);
		}
	};

	useEffect(() => {
		setNextButtonDisabled(true);
		if (
			majorSelectedValue.selected.value &&
			institutionSelectedValue.selected.value &&
			pickerDate &&
			selectedCountry
		) {
			setNextButtonDisabled(false);
		}
	}, [
		majorSelectedValue,
		institutionSelectedValue,
		pickerDate,
		selectedCountry,
	]);

	useEffect(() => {
		const localFn = async () => {
			console.log("selectedCountry: ", selectedCountry);
			if (
				sessionStorage.getItem("highestDegree") != null &&
				selectedCountry !== ""
			) {
				await axios
					.get(`${endpoint}/user/getUniversity/${selectedCountry}`, {
						headers: headers(),
						withCredentials: true,
					})
					.then(async (res) => {
						let options = await res.data.university.map(function (city) {
							return {
								value: city.name,
								label: city.name,
								type: "school",
								id: city.id,
							};
						});
						if (selectedCountry === "Canada" || selectedCountry === "Other") {
							options.push({ value: "Other", label: "Other", type: "school" });
						}
						await setdegreedata(options);
						await axios
							.get(`${endpoint}/user/getDegree`, {
								headers: headers(),
								withCredentials: true,
							})
							.then(async (degree) => {
								let options = degree.data.degree.map(function (city) {
									return {
										value: city.name,
										label: city.name,
										type: "Degree",
									};
								});
								options.push({
									value: "Other",
									label: "Other",
									type: "Degree",
								});

								await axios
									.get(
										`${endpoint}/user/getMajor/${selectedCountry}/${sessionStorage.getItem(
											"appId"
										)}`,
										{
											headers: headers(),
											withCredentials: true,
										}
									)
									.then((major) => {
										console.log(major);
										let options = [];
										//eslint-disable-next-line
										major.data.programs_schema.map(function (city) {
											if (
												city.level_id ===
													sessionStorage.getItem("highestDegree") ||
												selectedCountry !== "Canada"
											) {
												options.push({
													value: city.name,
													label: city.name,
													type: "major",
													id: city.area_of_study,
													university_id: city.university_id,
												});
											}
										});
										// if (selectedCountry == "Canada") {
										options.push({
											value: "Other",
											label: "Other",
											type: "major",
											university_id: "Other",
										});
										// }
										setmajordata(options);
										console.log("Inner set");
									});
							});
					});
			}
		};
		localFn();
		//eslint-disable-next-line
	}, [sessionStorage.getItem("highestDegree"), selectedCountry]);

	useEffect(() => {
		if (university_id !== "" && selectedCountry === "Canada") {
			axios
				.get(
					`${endpoint}/user/getMajorByUniversity/${selectedCountry}/${university_id}/${sessionStorage.getItem(
						"highestDegree"
					)}`,
					{
						headers: headers(),
						withCredentials: true,
					}
				)
				.then(async (major) => {
					console.log(major);
					let options = [];
					//eslint-disable-next-line
					major.data.programs_schema.map(function (city) {
						let pushItem = false;
						if (parseInt(sessionStorage.getItem("highestDegree")) === 13) {
							// If professional degree
							if (
								city.level_id === 9 ||
								city.level_id === 2 ||
								city.level_id === 1
							) {
								pushItem = true;
							}
						} else if (parseInt(sessionStorage.getItem("highestDegree")) === 14) {
							// If other
							if (
								city.level_id !== 9 &&
								city.level_id !== 2 &&
								city.level_id !== 1
							) {
								pushItem = true;
							}
						} else {
							if (city.level_id === parseInt(sessionStorage.getItem("highestDegree"))) {
								pushItem = true;
							}
						}
						if (pushItem) {
							options.push({
								value: city.name,
								label: city.name,
								type: "major",
								id: city.area_of_study,
								university_id: city.university_id,
							});
						}
					});
					options.push({
						value: "Other",
						label: "Other",
						type: "major",
						university_id: "Other",
					});
					setmajordata(options);
					if (state.university === "Other") {
						console.log("Inner 2 other");
						await getGlobalMajors();
					}
					console.log(
						"*****************************************************************"
					);
					console.log(options);
					console.log(
						"*****************************************************************"
					);
				});
		}
		//eslint-disable-next-line
	}, [state.university]);

	const handleDateChange = (date) => {
		setCompletionDate(date);
		setPickerDate(date);

		var today = new Date(date);
		var mm = String(today.getMonth() + 1).padStart(2, "0");
		var yyyy = today.getFullYear();
		setpPickerCompletionDate(yyyy + "-" + mm);
	};

	return (
		<section className="eduction-new">
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>Tell us about your education.</b>
								</h1>
								<p
									className="title-page-note"
									style={{ margin: 0, fontWeight: 600 }}>
									If you cannot find your Country, Institution, or Major, please
									select "Other".
								</p>
							</div>
							<div className="address-input-container">
								<div className="input-container">
									<div className="edubox w-100">
										<CLabel htmlFor="nf-school" className="custom-label">
											<b>Country</b>
										</CLabel>
										<div
											id="nf-country"
											class="form-control-plaintext  custom-input custom-input-container search-input-container">
											<Select
												placeholder="Search Country"
												options={countriesData}
												onChange={(event) => handleCountryClick(event)}
												onClick={handleCountryClick}
												// defaultValue={
												//     // preFill.school_name
												// }
											/>
										</div>
									</div>
									<div className="edubox w-100">
										<CLabel htmlFor="nf-school" className="custom-label">
											<b>Institution</b>
										</CLabel>
										<div
											id="nf-degree"
											class="form-control-plaintext  custom-input custom-input-container search-input-container">
											<Select
												placeholder="Search School"
												options={degreedata}
												onChange={(event) => handleSelect(event)}
												onClick={handleClick}
												defaultValue={preFill.school_name}
												value={institutionSelectedValue.selected}
											/>
										</div>
									</div>
								</div>

								<div className="input-container">
									<div className="edubox">
										<CLabel htmlFor="nf-graduation" className="custom-label">
											<b>Graduation Date</b>
										</CLabel>
										<br />
										<div class="form-control-plaintext w-67 h-58 custom-input third-datepicker">
											<DatePicker
												id="nf-graduation"
												name="completionDate"
												placeholder="Month, Year"
												minDate={new Date("1 January, 1970")}
												maxDate={new Date()}
												onChange={(date) => handleDateChange(date)}
												onClick={handleClick}
												selected={pickerDate}
												dateFormat="yyyy-MM"
												showMonthYearPicker
												showFullMonthYearPicker
											/>
										</div>
									</div>
									<div className="edubox">
										<CLabel htmlFor="nf-major" className="custom-label">
											<b>Major</b>
										</CLabel>
										<div
											id="nf-degree"
											class="form-control-plaintext custom-input custom-input-container search-input-container">
											<Select
												placeholder="Search Majors"
												options={majordata}
												onChange={(event) => handleSelect(event)}
												onClick={handleClick}
												filterOption={createFilter({
													ignoreAccents: false,
												})}
												components={{
													Option: CustomOption,
												}}
												defaultValue={preFill.major}
												value={majorSelectedValue.selected}
											/>
										</div>
									</div>
								</div>
							</div>
							{errorMsg}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{
								// watchAllFields.major &&
								// typeof errors.major === "undefined" &&
								preFill.valid ||
								(state.university !== "" &&
									state.major !== "" &&
									pickerCompletionDate) ? (
									// watchAllFields.degree &&
									// typeof errors.degree === "undefined"
									// &&
									// watchAllFields.school &&
									// typeof errors.school === "undefined"
									<button
										className="custom-button enabled"
										onClick={handleSubmit(handleSave)}
										disabled={nextButtonDisabled}>
										Next
									</button>
								) : (
									<button
										className="custom-button disabled"
										disabled={nextButtonDisabled}>
										Next
									</button>
								)
							}
							<button
								className="custom-back-button"
								onClick={async () => {
									await handleSave(true);
									if (props?.previousStep) {
										props.previousStep();
									}
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
}
