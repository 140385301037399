import { CButton, CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import canadianIcon from "src/assets/icons/App-Flow-Icons/canadian-visa.png";
import otherIcon from "src/assets/icons/App-Flow-Icons/legal-other.png";
import cardHolderIcon from "src/assets/icons/App-Flow-Icons/permanent-resident.png";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import { getUserInProgressApp, updateUserAppFlow } from "../../services/user";

export default function Citizenship(props) {
	const inProgressApp = getUserInProgressApp();
	let preFill = "";
	if (inProgressApp) {
		preFill = inProgressApp.appData.personal.citizenship;
	}
	const { register, handleSubmit, errors } = useForm({
		mode: "onChange",
	});
	const [citizenship, setCitizenship] = useState(preFill);
	const [loading, setLoading] = React.useState("");
	const [mouseClick, setMouseClick] = useState(0);
	const arrivalTime = new Date();

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	useEffect(() => {
		if (citizenship !== "" && mouseClick > 0) {
			handleSave();
		}
		//eslint-disable-next-line
	}, [citizenship, mouseClick]);

	const handleSave = async () => {
		let details = {
			page: "citizenship",
			citizenship,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			hideLoader();
			props.nextStep();
			await sendAnalytics(arrivalTime, STEP_PAGES.CITIZENSHIP);
		}
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		let details = {
			page: "citizenship",
			citizenship,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			hideLoader();
			props.handleSaveExit();
			props.lastStep();
		}
	};

	const handleSaveAndBack = async () => {
		localStorage.setItem("PreviousPage", "YES");
		if (sessionStorage.getItem("productId") === "1") {
			props.previousStep();
		} else {
			props.previousStep();
			await sendAnalytics(arrivalTime, STEP_PAGES.CITIZENSHIP);
		}
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll tablet vertical-middle-page">
					<div>
						<div class="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>What is your legal status in Canada?</b>
								</h1>
							</div>
							<div className="cards-container">
								<div className="icon-card">
									<input
										type="radio"
										id="Canadian"
										name="citizenship"
										value="Canadian"
										class="custom-radio"
										onChange={(event) => setCitizenship(event.target.value)}
										onClick={handleClick}
										ref={register({ required: true })}
										checked={
											(preFill !== "" && preFill === "Canadian") ||
											citizenship === "Canadian"
												? true
												: false
										}
									/>
									<label htmlFor="Canadian" className="card-label">
										<img src={canadianIcon} alt="" />
										<b>Canadian Citizen</b>
									</label>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="PR"
										name="citizenship"
										value="PR"
										class="custom-radio"
										onChange={(event) => setCitizenship(event.target.value)}
										onClick={handleClick}
										ref={register({ required: true })}
										checked={
											(preFill !== "" && preFill === "PR") ||
											citizenship === "PR"
												? true
												: false
										}
									/>
									<label htmlFor="PR" className="card-label">
										<img src={cardHolderIcon} alt="" />
										<b>Permanent Resident</b>
									</label>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="Other"
										name="citizenship"
										value="Other"
										class="custom-radio"
										onChange={(event) => setCitizenship(event.target.value)}
										onClick={handleClick}
										ref={register({ required: true })}
										checked={
											(preFill !== "" && preFill === "Other") ||
											citizenship === "Other"
												? true
												: false
										}
									/>
									<label htmlFor="Other" className="card-label">
										<img src={otherIcon} alt="" />
										<b>Other</b>
									</label>
								</div>
							</div>
							{errors.citizenship && (
								<div class="error-container">
									<p className="error-message">Please select an option</p>
								</div>
							)}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{preFill && preFill !== "" ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}>
									Next
								</button>
							) : null}
							<button
								className="custom-back-button"
								onClick={handleSaveAndBack}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
}
