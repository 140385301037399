import React, { Component } from "react";
import { checkResetToken, ChangePass } from "../../services/user";
import logoIcon from "src/assets/icons/app-logo.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { passwordStrength } from "check-password-strength";
export default class ForgetPassForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isValid: false,
			password: "",
			MatchPass: false,
			error: "",
			type: "password",
			cnfType: "password",
			strength: false,
			strongPasswordEntered: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.ConfirmPassword = this.ConfirmPassword.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async componentDidMount() {
		const result = await checkResetToken(this.props.match.params.token);
		console.log(result);
		result === "success"
			? this.setState({ isValid: true })
			: this.setState({ isValid: false });
	}
	handleChange = () => {
		if (!this.state.password || this.state.length.password === "") {
			this.setState({ error: "Password is required" });
		} else if (this.state.password.length < 8) {
			this.setState("Password should be of minimum 8 characters.");
		} else {
			this.setState({ error: "" });
		}
	};

	handleClick = () =>
		this.setState(({ type }) => ({
			type: type === "text" ? "password" : "text",
		}));

	handleClickCnf = () =>
		this.setState(({ cnfType }) => ({
			cnfType: cnfType === "text" ? "password" : "text",
		}));

	handlePasswordChange = (e) => {
		this.setState({ password: e.target.value });
		this.checkPasswordStrength(e.target.value);
	};
	ConfirmPassword = (e) => {
		this.state.password === e.target.value
			? this.setState({ MatchPass: true })
			: this.setState({ MatchPass: false });
	};
	handleSubmit = async () => {
		if (
			this.state.MatchPass === true &&
			this.state.password !== "" &&
			this.state.isValid === true
		) {
			const result = await ChangePass(
				this.state.password,
				this.props.match.params.token
			);
			result === "success"
				? (window.location.href = "/signin")
				: (window.location.href = "/");
		} else {
			this.setState({
				error:
					"Make Sure your Password is of 10 length and the password matches with both textbox",
			});
		}
	};

	checkPasswordStrength = (passwordValue) => {
		const passwordStrengthScore = passwordStrength(passwordValue);
		this.setState({ strength: passwordStrengthScore });
		if (passwordStrengthScore.id >= 3 && passwordStrengthScore.length >= 10) {
			this.setState({ strongPasswordEntered: true });
		} else {
			this.setState({ strongPasswordEntered: false });
		}
	};

	render() {
		return (
			<div>
				{this.state.isValid ? (
					<section>
						<div className="base-container">
							<div className="header">
								<img src={logoIcon} alt="QuadFi Logo" />
							</div>
							<div className="content">
								<div className="form">
									<div className="form-group">
										<input
											type={this.state.type}
											name="password"
											maxLength={32}
											placeholder="New Password"
											className="user-input"
											value={this.state.password}
											onChange={this.handlePasswordChange}
										/>
										<span
											style={{
												position: "absolute",
												marginTop: "7px",
											}}
											onClick={this.handleClick}>
											{this.state.type === "text" ? (
												<VisibilityOffIcon />
											) : (
												<VisibilityIcon />
											)}
										</span>
										{this.state.password !== "" ? (
											<div className="password-checker-container">
												<p className="password-checker-text">
													Password must contain the following:
												</p>
												<ul className="password-checker-list">
													<li
														class={
															this.state.strength.contains.includes("lowercase")
																? "valid"
																: ""
														}>
														A lowercase letter{" "}
														<span className="fa fa-check-circle"></span>
													</li>
													<li
														class={
															this.state.strength.contains.includes("uppercase")
																? "valid"
																: ""
														}>
														A capital (Uppercase) letter{" "}
														<span className="fa fa-check-circle"></span>
													</li>
													<li
														class={
															this.state.strength.contains.includes("number")
																? "valid"
																: ""
														}>
														A number{" "}
														<span className="fa fa-check-circle"></span>
													</li>
													<li
														class={
															this.state.strength.contains.includes("symbol")
																? "valid"
																: ""
														}>
														A Symbol{" "}
														<span className="fa fa-check-circle"></span>
													</li>
													<li
														class={
															this.state.strength.length >= 10 ? "valid" : ""
														}>
														Minimun 10 characters{" "}
														<span className="fa fa-check-circle"></span>
													</li>
												</ul>
											</div>
										) : null}
									</div>
									<div className="form-group">
										<input
											type={this.state.cnfType}
											name="password"
											maxLength={32}
											placeholder="Confirm Password"
											className="user-input"
											onChange={this.ConfirmPassword}
										/>
										<span
											style={{
												position: "absolute",
												marginTop: "7px",
											}}
											onClick={this.handleClickCnf}>
											{this.state.cnfType === "text" ? (
												<VisibilityOffIcon />
											) : (
												<VisibilityIcon />
											)}
										</span>
										<p>{this.state.error}</p>
									</div>
								</div>
							</div>
							<div className="cta-button">
								<button
									type="button"
									className="btn custom-button"
									onClick={this.handleSubmit}
									disabled={this.state.strongPasswordEntered ? false : true}>
									Reset Now
								</button>
							</div>
							<small>
								If you did not request this reset, please notify us <br />
								at info@quadfi.com or call us at 1-866-782-3340
							</small>
						</div>
					</section>
				) : (
					<h1>404</h1>
				)}
			</div>
		);
	}
}
