import { CContainer, CImg } from "@coreui/react";
import React, { useState } from "react";
import { TheSidebar, TopNav } from "src/containers";
import { useSelector } from "react-redux";
import menuIcon from "../..//assets/icons/menuIcon.png";

function Blogs() {
	const userInbox = useSelector((state) => {
		return state.userInbox;
	});
	const unRead = userInbox.filter((email) => !email.isRead);
	const [activeCls, setActivecls] = useState(false);
	const menuHandler = () => {
		setActivecls(!activeCls);
	};

	return (
		<div className="gnrl-documents">
			<TopNav unRead={unRead} />
			<CContainer fluid className="px-0">
				<div className="d-flex main-outer-csp">
					<div className={`${activeCls ? "manulWidth" : null}`}>
						<button className="bars" onClick={menuHandler}>
							<CImg src={menuIcon}></CImg>
						</button>
						<div
							className={`sideBar-outer ${activeCls ? "sidBrexpand" : null}`}>
							<TheSidebar />
						</div>
					</div>
				</div>
			</CContainer>
		</div>
	);
}

export default React.memo(Blogs);
