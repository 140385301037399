import { CButton, CProgress } from "@coreui/react";
import React from "react";
import logoIcon from "src/assets/icons/app-logo.png";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";

const Success = (props) => {
	const arrivalTime = new Date();
	const handleSave = async () => {
		window.location.href = "/documents/generalDocuments";
		await sendAnalytics(arrivalTime, STEP_PAGES.SUCCESS);
	};

	const handleSaveAndExit = () => {
		localStorage.setItem("DropOff", "YES");
		window.location.href = "/documents/generalDocuments";
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scoll vertical-middle-page">
					<div className="content-container">
						<div className="text-container spaced">
							<h3>
								<b className="highlight">Congratulations!</b>
							</h3>
							<h1>
								<b>Application successfuly submitted.</b>
							</h1>
							<h4>
								Please continue to dashboard and submit the supporting
								documents.
							</h4>
						</div>
						<div className="load-class"></div>
						<div className="button-container">
							<button className="custom-button enabled" onClick={handleSave}>
								Next
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Success;
