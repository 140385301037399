import PhillCountryQuestion from "./country/phillippinesCountryQuestion";

const {
	default: MexicoCountryQuestion,
} = require("./country/mexicoCountryQuestion");
const CountryQuestion = () => {
	if (JSON.parse(sessionStorage.getItem("userCountry")).country === "Mexico") {
		return <MexicoCountryQuestion />;
	} else if (
		JSON.parse(sessionStorage.getItem("userCountry")).country === "Philippines"
	) {
		return <PhillCountryQuestion />;
	} else {
		return <div>Country not found</div>;
	}
};
export default CountryQuestion;
