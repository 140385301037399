import axios from "axios";
import { checkAuth, getLoggedUser } from "src/actions/users";
import { headers } from "../reusable/headers";
const endpoint = process.env.REACT_APP_API_HOST + "/V1";

export const exchangeToken = async (public_token, metadata) => {
	try {
		const response = {
			method: "POST",
			url: `${endpoint}/api/exchangeLinkToken`,
			data: public_token,
		};
		console.log("Response below");
		console.log(response);
	} catch (e) {
		throw new Error(e.message);
	}
};

export const createPlaidLink = async () => {
	try {
		const response = await axios({
			method: "POST",
			url: `${endpoint}/api/createLinkToken`,
			data: { appId: sessionStorage.getItem("appId") },
			headers: headers(),
			withCredentials: true,
		});
		const data = await response;
		return data.data.link_token;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getUserApplications = async (userId) => {
	try {
		const result = await axios({
			method: "GET",
			url: `${endpoint}/application/getUserApplications`,
			headers: headers(),
			withCredentials: true,
		});
		console.log("applications result", result);
		return result.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getQuadFiData = async (userId) => {
	try {
		const result = await axios({
			method: "GET",
			url: `${endpoint}/user/getQuadfiOutput/${sessionStorage.getItem(
				"appId"
			)}`,
			headers: headers(),
			withCredentials: true,
		});
		return result.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const createApp = async (appObj) => {
	try {
		const updatedInformation = await axios({
			method: "POST",
			url: `${endpoint}/application/createApp`,
			data: appObj,
			headers: headers(),
			withCredentials: true,
		});
		if (updatedInformation.data) {
			sessionStorage.setItem("appId", updatedInformation.data.user.appId);
			return updatedInformation.data;
		}
	} catch (e) {
		throw new Error(e.message);
	}
};

export const createAppRegister = async (appObj) => {
	try {
		const updatedInformation = await axios({
			method: "POST",
			url: `${endpoint}/application/createAppRegister`,
			data: appObj,
			headers: headers(),
			withCredentials: true,
		});
		if (updatedInformation.data) {
			sessionStorage.setItem("appId", updatedInformation.data.user.appId);
			return updatedInformation.data;
		}
	} catch (e) {
		throw new Error(e.message);
	}
};

export const updateApp = async (appObj) => {
	try {
		const updatedInformation = await axios({
			method: "PUT",
			url: `${endpoint}/application/updateApp`,
			data: appObj,
			headers: headers(),
			withCredentials: true,
		});
		if (updatedInformation.data) {
			return updatedInformation.data;
		}
	} catch (e) {
		console.log("err", e);
		throw new Error(e.message);
	}
};

export const updateAppRegister = async (appObj) => {
	try {
		const updatedInformation = await axios({
			method: "PUT",
			url: `${endpoint}/application/updateAppRegister`,
			data: appObj,
			headers: headers(),
			withCredentials: true,
		});
		if (updatedInformation.data) {
			return updatedInformation.data;
		}
	} catch (e) {
		console.log("err", e);
		throw new Error(e.message);
	}
};

export const getEsignDoc = async (currentSelect, type) => {
	const Esign = await axios.post(
		`${process.env.REACT_APP_API_HOST}/V1/application/Esign`,
		{ currentSelect: currentSelect, docName: type },
		{
			headers: headers(),
			withCredentials: true,
		}
	);

	if (Esign) {
		console.log(Esign);
		return Esign;
	}
};

export const updateUserAppFlow = async (userId, details) => {
	try {
		let dataObj = {
			userId: userId,
			details: details,
		};
		if (sessionStorage.getItem("appId")) {
			dataObj.appId = sessionStorage.getItem("appId");
		}
		const updatedInformation = await axios({
			method: "POST",
			url: `${endpoint}/user/updateAppFlow`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});
		console.log("check", updatedInformation);

		await setUserAppSession();
		if (updatedInformation) {
			let appObj = {
				userId: userId,
				appId: updatedInformation.data.appId,
				page: details.page,
			};
			if (updatedInformation.data.newApp === true) {
				appObj.consent = sessionStorage.getItem("Consent");
				appObj.tracking_id = localStorage.getItem("tracking_id");
				const result = await createApp(appObj);
				return result;
			} else {
				const result = await updateApp(appObj);
				return result;
			}
		}
	} catch (e) {
		return {};
	}
};

const setUserAppSession = async () => {
	let user = await checkAuth();
	if (user) {
		const userCurrentApplication = await getUserLatestApplication();
		if (
			userCurrentApplication &&
			typeof userCurrentApplication.application !== "undefined"
		) {
			const userAppId = userCurrentApplication.application.appId;
			if (userAppId) {
				const userAppData = await getUserAppDataByAppId(userAppId);
				if (userAppData.success) {
					sessionStorage.setItem(
						"inprogress_app",
						JSON.stringify(userAppData.data)
					);
				}
			}
		}
	}
};

export const updateUserAppFlowRegister = async (userId, details) => {
	try {
		let dataObj = {
			userId: userId,
			details: details,
		};
		const updatedInformation = await axios({
			method: "POST",
			url: `${endpoint}/user/updateAppFlowRegister`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});
		console.log("check", updatedInformation);
		if (updatedInformation) {
			let appObj = {
				userId: userId,
				appId: updatedInformation.data.appId,
				page: details.page,
				tracking_id: localStorage.getItem("tracking_id"),
			};
			const result = await createAppRegister(appObj);
			return result;
		}
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getEquifaxQuestions = async () => {
	try {
		let appId = sessionStorage.getItem("appId");
		const questionsResult = await axios({
			method: "GET",
			url: `${endpoint}/application/questions/${appId}`,
			headers: headers(),
			withCredentials: true,
		});

		if (!questionsResult) {
			return {};
		} else {
			return questionsResult;
		}
	} catch (e) {
		throw new Error(e);
	}
};

export const getUniversityCountries = async () => {
	try {
		const universityResult = await axios({
			method: "GET",
			url: `${endpoint}/user/getCountries`,
			headers: headers(),
			withCredentials: true,
		});

		return universityResult.data.countries;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const equifaxUpdate = async (answers, transactionKey) => {
	try {
		let appId = sessionStorage.getItem("appId");

		let dataObj = {
			userId: appId,
			answer1: answers.answer1,
			answer2: answers.answer2,
			transactionKey: transactionKey,
		};
		console.log("ddataObj", dataObj);
		const updatedInformation = await axios({
			method: "POST",
			url: `${endpoint}/application/equifaxUpdate`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});

		return updatedInformation.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const savePlaidInfo = async (userId, data) => {
	try {
		let dataObj = {
			userId: sessionStorage.getItem("appId"),
			plaidAllAccountsData: data,
		};
		const updatedInformation = await axios({
			method: "POST",
			url: `${endpoint}/user/saveplaidAllAccountsData`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});

		return updatedInformation;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const logPlaidError = async (data) => {
	try {
		let dataObj = {
			plaidErrorData: JSON.stringify(data),
			appId: sessionStorage.getItem("appId"),
		};
		await axios({
			method: "POST",
			url: `${endpoint}/user/logplaidErrorData`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});
		return;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const createReport = async (token) => {
	try {
		let dataObj = {
			userId: sessionStorage.getItem("appId"),
			public_token: token,
		};
		const updatedInformation = await axios({
			method: "POST",
			url: `${endpoint}/api/createReport`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});

		return updatedInformation;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const saveCashFlow = async () => {
	try {
		let dataObj = {
			userId: sessionStorage.getItem("appId"),
		};
		const updatedInformation = await axios({
			method: "POST",
			url: `${endpoint}/user/cashFlowData`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});

		return updatedInformation;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const hardCheckUpdate = async (type) => {
	try {
		let dataObj = {
			userId: sessionStorage.getItem("appId"),
			type: type,
		};
		console.log("ddataObj", dataObj);
		const updatedInformation = await axios({
			method: "POST",
			url: `${endpoint}/application/hardCheckUpdate`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});
		console.log("update", updatedInformation);

		return updatedInformation;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const updateAppProduct = async (details) => {
	try {
		let dataObj = {
			details: details,
		};
		console.log("sessionStorage.getItem()", sessionStorage.getItem("appId"));
		if (sessionStorage.getItem("appId")) {
			dataObj.appId = sessionStorage.getItem("appId");
		}
		console.log("data", dataObj);
		if (dataObj.details.productId) {
			sessionStorage.setItem("productId", dataObj.details.productId);
		}
		await axios({
			method: "PUT",
			url: `${endpoint}/application/updateAppProduct`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});
	} catch (e) {
		throw new Error(e.message);
	}
};

export const updateAppStatus = async (details) => {
	try {
		let dataObj = {};
		console.log("sessionStorage.getItem()", sessionStorage.getItem("appId"));
		if (sessionStorage.getItem("appId")) {
			dataObj.appId = sessionStorage.getItem("appId");
		}

		await axios({
			method: "POST",
			url: `${endpoint}/user/update/app/status`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});
	} catch (e) {
		throw new Error(e.message);
	}
};

export const updateAppProductRegister = async (details, appId) => {
	try {
		let dataObj = {
			details: details,
			uc_input: sessionStorage.getItem("uc_input"),
			appId: appId,
		};
		await axios({
			method: "PUT",
			url: `${endpoint}/application/updateAppProductRegister`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});
	} catch (e) {
		throw new Error(e.message);
	}
};

export const createNewUser = async () => {
	try {
		await axios({
			method: "POST",
			url: `${endpoint}/user/createUser`,
		});
	} catch (e) {
		throw new Error(e.message);
	}
};

export const registerNewUser = async (data) => {
	try {
		await axios({
			method: "POST",
			data: data,
			url: `${endpoint}/user/signup`,
			withCredentials: true,
		});
	} catch (e) {
		throw new Error(e.message);
	}
};

export const uploadFile = async (userId, documentId, filePath) => {
	try {
		await axios({
			method: "POST",
			url: `${endpoint}/user/uploadDoc`,
			data: {
				userId: userId,
				documentId: documentId,
				filePath: filePath,
				withCredentials: true,
			},
			headers: headers(),
		});
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const updateUser = async (userId, details) => {
	try {
		await axios({
			method: "POST",
			url: `${endpoint}/user/updateUser`,
			data: {
				userId: userId,
				details: details,
			},
			headers: headers(),
			withCredentials: true,
		});
	} catch (e) {
		throw new Error(e.message);
	}
};

export const checkCreateNewApp = async () => {
	try {
		const checkApp = await axios({
			method: "GET",
			url: `${endpoint}/user/checkCreateNewApp`,
			headers: headers(),
			withCredentials: true,
		});
		return checkApp.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const userInfo = async (userId, setUser) => {
	try {
		const getUser = await axios({
			method: "POST",
			url: `${endpoint}/user/getUser`,
			data: {
				userId: userId,
			},
			headers: headers(),
			withCredentials: true,
		});
		console.log("printing user data", getUser.data);
		setUser(getUser.data);
	} catch (e) {
		throw new Error(e.message);
	}
};

export const userInfoDoc = async (userId, setUser) => {
	try {
		const getUser = await axios({
			method: "POST",
			url: `${endpoint}/user/getUser`,
			data: {
				userId: userId,
			},
			headers: headers(),
			withCredentials: true,
		});
		console.log("printing user data", getUser.data.documents);
		return getUser.data.documents;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getuserInfo = async (userId) => {
	try {
		const getUser = await axios({
			method: "POST",
			url: `${endpoint}/user/getUser`,
			data: {
				userId: userId,
			},
			headers: headers(),
			withCredentials: true,
		});
		return getUser.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getLatestScheduledAmountData = async (appId) => {
	try {
		const result = await axios({
			method: "GET",
			url: `${endpoint}/user/getLatestScheduledAmount/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
		return result.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const CheckHelloSignDoc = async (appId, type) => {
	try {
		const Hellosignres = await axios({
			method: "POST",
			url: `${endpoint}/user/Checkstatus/Hellosign`,
			data: {
				appId: appId,
				type: type,
			},
			headers: headers(),
			withCredentials: true,
		});
		return Hellosignres.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getUsersNotifications = async (appId) => {
	try {
		if (appId === null) return {};
		const getUser = await axios({
			method: "GET",
			url: `${endpoint}/user/getUsersNotification/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
		return getUser.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const resendVerificationMail = async (userId) => {
	console.log("userIdssssssssssss", userId);
	try {
		const getUser = await axios({
			method: "GET",
			url: `${endpoint}/user/resendVerificationMail`,
			headers: headers(),
			withCredentials: true,
		});
		return getUser.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getLoanAmountDetails = async () => {
	const userDocs = await axios.get(
		`${process.env.REACT_APP_API_HOST}/V1/admin/getProduct`,

		{
			headers: headers(),
			withCredentials: true,
		}
	);
	console.log(userDocs, "Userdocs");
	return userDocs.data;
};

export const getAppLoanAmount = async (appId) => {
	console.log("appppIIIDDD", appId);
	try {
		const getUser = await axios({
			method: "GET",
			url: `${endpoint}/application/getAppLoanAmount/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
		return getUser.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const updateUsersNotification = async (appId, status_id) => {
	try {
		const getUser = await axios({
			method: "PUT",
			url: `${endpoint}/user/updateUsersNotification/${appId}/${status_id}`,
			headers: headers(),
			withCredentials: true,
		});
		return getUser.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getUserInbox = async (userId, setUserInbox) => {
	try {
		const getUserInboxMessage = await axios({
			method: "POST",
			url: `${endpoint}/user/getUserInbox`,
			data: {
				userId: userId,
			},
			headers: headers(),
			withCredentials: true,
		});
		setUserInbox(getUserInboxMessage.data);
	} catch (e) {
		throw new Error(e.message);
	}
};

export const downloadFile = async (appId) => {
	try {
		await axios({
			method: "POST",
			url: `${endpoint}/application/downloadFile/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
	} catch (e) {
		throw new Error(e.message);
	}
};
export function calcPayoff(currentLoanData) {
	var payoffResult = [];
	var immediatePayoff = 0;
	var sum = 0;

	console.log(immediatePayoff);
	var extendedPayoff = 0;

	let flag = false;
	for (var i = 0; i < currentLoanData.length; i++) {
		if (currentLoanData[i][3] === "missed") {
			extendedPayoff +=
				currentLoanData[i][1] +
				currentLoanData[i][0] +
				currentLoanData[i][4] +
				currentLoanData[i][5] +
				currentLoanData[i][6];
		} else if (currentLoanData[i][3] === "Scheduled") {
			extendedPayoff += currentLoanData[i][1];
			flag = true;
		}
		console.log("First", extendedPayoff, "+", "Last", currentLoanData[i][2]);
		if (flag) {
			extendedPayoff += currentLoanData[i][7];
			sum = extendedPayoff;
			console.log(sum);
			break;
		}
	}
	let check = false,
		d = new Date(),
		currentMonth = d.getMonth();
	for (let i = 0; i < currentLoanData.length; i++) {
		if (
			currentLoanData[i][3] === "Scheduled" ||
			currentLoanData[i][3] === "missed"
		) {
			console.log(
				"((new Date(currentLoanData[i][8])).getMonth()) + (new Date(currentLoanData[i][8])).getFullYear()",
				new Date(currentLoanData[i][8]).getMonth() === new Date().getMonth() &&
					new Date(currentLoanData[i][8]).getFullYear(),
				new Date().getFullYear()
			);
			if (
				new Date(currentLoanData[i][8]).getMonth() === new Date().getMonth() &&
				new Date(currentLoanData[i][8]).getFullYear() ===
					new Date().getFullYear()
			) {
				var monthlyRate = currentLoanData[i][1] / 12 / 100;
				const monthInfo = {
					1: { dailyR: monthlyRate / 31, days: 31 },
					2: { dailyR: monthlyRate / 28, days: 28 },
					3: { dailyR: monthlyRate / 31, days: 31 },
					4: { dailyR: monthlyRate / 30, days: 30 },
					5: { dailyR: monthlyRate / 31, days: 31 },
					6: { dailyR: monthlyRate / 30, days: 30 },
					7: { dailyR: monthlyRate / 31, days: 31 },
					8: { dailyR: monthlyRate / 31, days: 31 },
					9: { dailyR: monthlyRate / 30, days: 30 },
					10: { dailyR: monthlyRate / 31, days: 31 },
					11: { dailyR: monthlyRate / 30, days: 30 },
					12: { dailyR: monthlyRate / 31, days: 31 },
				};
				const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

				var leftdayscharge = 0,
					leftdaysLpic = 0;
				const leftdays = monthInfo[months[d.getMonth()]].days - d.getDate();
				leftdayscharge =
					(leftdays * currentLoanData[i][1]) /
					monthInfo[months[d.getMonth()]].days;
				if (currentLoanData[i][6])
					leftdaysLpic =
						(leftdays * currentLoanData[i][6]) /
						monthInfo[months[d.getMonth()]].days;
				console.log("leftdayscharge", leftdaysLpic, leftdayscharge);
				immediatePayoff = extendedPayoff - leftdayscharge - leftdaysLpic;
				console.log("new date", immediatePayoff);
				check = true;
			} else if (new Date(currentLoanData[i][8]) > new Date()) {
				check = true;
				immediatePayoff = currentLoanData[i][7];
			}
			if (check) break;
		}
		if (check) break;
	}
	console.log(currentMonth);

	console.log("immediatePayoff", immediatePayoff);
	payoffResult.push(immediatePayoff, extendedPayoff);
	return payoffResult;
}

export const getComapnyData = async (name) => {
	console.log(
		`AI is: ${process.env.REACT_APP_PEOPLE_DATA_LABS_API_URL}/v5/autocomplete?field=company&text=${name}`
	);
	try {
		name = name.toLowerCase();
		var qs = require("qs");
		var data = qs.stringify({
			field: "company",
			text: name,
		});
		var config = {
			method: "get",
			url: `${process.env.REACT_APP_PEOPLE_DATA_LABS_API_URL}/v5/autocomplete?field=company&text=${name}`,
			headers: {
				"X-Api-Key": process.env.REACT_APP_PEOPLE_DATA_LABS_API_KEY,
				"Content-Type": "application/x-www-form-urlencoded",
			},
			data: data,
		};

		var data1 = await axios(config);

		console.log(data, "data538");

		return data1.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const sendEmail = async (
	recipientId,
	senderId,
	from,
	to,
	subject,
	message
) => {
	try {
		await axios({
			method: "POST",
			url: `${endpoint}/api/sendMail`,
			data: {
				recipientId: recipientId,
				senderId: senderId,
				from: from,
				to: to,
				subject: subject,
				message: message,
			},
			headers: headers(),
			withCredentials: true,
		});
		return { success: true };
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getApplicationPayments = async (appId) => {
	try {
		const paymentsResult = await axios({
			method: "GET",
			url: `${endpoint}/application/payments/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
		return paymentsResult.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getApplicationTransactions = async (appId) => {
	try {
		const transactionResult = await axios({
			method: "GET",
			url: `${endpoint}/application/transaction/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
		return transactionResult.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getApplicationSchedule = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/schedule/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
		return response.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getMonthlyPayments = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/getMonthlyPayments/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
		return response.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const archiveApplication = async (appId) => {
	try {
		const response = await updateApplicationStatus(appId, "archived");
		return response;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const cancelApplication = async (appId) => {
	try {
		const response = await updateApplicationStatus(appId, "Cancelled");
		return response;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const forgetPassword = async (emailuser) => {
	try {
		const body = { email: emailuser, type: "customer" };
		const res = await axios({
			method: "POST",
			url: `${endpoint}/user/forgetpass`,
			data: body,
		});
		return res.data;
	} catch (e) {
		console.log("forgetPassword:", e);
	}
};

export const checkResetToken = async (emailuser) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${endpoint}/user/forgetpass/${emailuser}`,
			data: { email: emailuser },
		});
		const { data } = response;
		return data.reset === "success" ? "success" : "Failed";
	} catch (e) {
		console.log(e);
	}
};

export const VerifyEmail = async (emailuser) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${endpoint}/user/verify/email/${emailuser}`,
			data: { email: emailuser },
		});
		const { data } = response;
		return data.reset === "success" ? "success" : "Failed";
	} catch (e) {
		console.log(e);
	}
};

export const ChangePass = async (password, token) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${endpoint}/user/changepass`,
			headers: headers(),
			data: { password, token },
		});
		const { data, statusCode } = response;
		return statusCode === 200 && data.reset === "success"
			? "success"
			: "Failed";
	} catch (e) {
		console.log(e);
	}
};

export const updatePassword = async (email, newPassword, token) => {
	try {
		let url = `${endpoint}/user/changePassword`;
		let body = {
			email,
			newPassword,
			token,
		};
		const response = await axios({
			method: "POST",
			url: url,
			data: body,
			headers: headers(),
			withCredentials: true,
		});
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const switchAutoPay = async (data) => {
	try {
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/admin/banking/autopay`,
			data,
			{ headers: headers(), withCredentials: true }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const checkGatewayLimit = async (data) => {
	try {
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/application/gateway/limit`,
			data,
			{ headers: headers(), withCredentials: true }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const saveFirstToKnow = async (data) => {
	try {
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/application/saveFirstToKnow`,
			data,
			{ headers: headers(), withCredentials: true }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const checkDecisionRules = async (data) => {
	try {
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/application/decisionRules/check`,
			data,
			{ headers: headers(), withCredentials: true }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const getUnReadMessageCount = async () => {
	let user = await getLoggedUser();
	const email = user.email;
	try {
		const data = await axios({
			method: "GET",
			url: `${endpoint}/admin/messages/unread?email=${email}`,
			headers: headers(),
			withCredentials: true,
		});
		return data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const manualPayment = async (amount, appId, additionalPayDate) => {
	try {
		let user = await getLoggedUser();
		let userId = user.id;
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/admin/banking/additionalPay`,
			{ amount, appId, userId, additionalPayDate },
			{ headers: headers(), withCredentials: true }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const getVouchedData = async (appId, status) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/voucedDocs/${appId}/${status}`,
			headers: headers(),
			withCredentials: true,
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const updateTransaction = async (data) => {
	try {
		const transactionResult = await axios({
			method: "POST",
			url: `${endpoint}/admin/transaction/update`,
			headers: headers(),
			data: data,
			withCredentials: true,
		});
		return transactionResult.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getBlogs = async () => {
	try {
		const blogResult = await axios({
			method: "GET",
			url: `${endpoint}/admin/blogs`,
			headers: headers(),
			withCredentials: true,
		});
		return blogResult.data.blogs;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getABlog = async (id) => {
	try {
		const blogResult = await axios({
			method: "GET",
			url: `${endpoint}/admin/blogs/${id}`,
			headers: headers(),
			withCredentials: true,
		});
		return blogResult.data.blogs;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getLatestBlog = async () => {
	try {
		const blogResult = await axios({
			method: "GET",
			url: `${endpoint}/admin/blogs/latest`,
			headers: headers(),
			withCredentials: true,
		});
		return blogResult.data.blogs;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getDocumentsStatus = async (appId) => {
	try {
		const Result = await axios({
			method: "GET",
			url: `${endpoint}/admin/documents-status/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
		console.log("Result", Result);
		return Result.data.status;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getActiveApplication = async (appId) => {
	try {
		const Result = await axios({
			method: "GET",
			url: `${endpoint}/admin/applications/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
		return Result.data.application;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getCompanyData = async (text) => {
	text = text.toLowerCase();
	const companyData = await axios.get(
		`${process.env.REACT_APP_API_HOST}/V1/application/getAutoCompleteRecord/${text}`,
		{ headers: headers(), withCredentials: true }
	);
	return companyData;
};
export const getReRequestData = async (currentSelect) => {
	const ReRequested = await axios.post(
		`${process.env.REACT_APP_API_HOST}/V1/admin/getrerequest`,
		{ currentSelect: currentSelect },
		{ headers: headers(), withCredentials: true }
	);
	return ReRequested.data.result;
};

export const getUserUploadedDocuments = async (appId) => {
	const userDocs = await axios.get(
		`${process.env.REACT_APP_API_HOST}/V1/admin/getUserUploadedDocuments/${appId}`,
		{ headers: headers(), withCredentials: true }
	);
	return userDocs.data.result;
};

export const getUserInProgressApp = () => {
	const inProgressApp = sessionStorage.getItem("inprogress_app");
	if (inProgressApp !== "") {
		return JSON.parse(inProgressApp);
	}
	return false;
};

export const getUserAppDataByAppId = async (appId) => {
	try {
		const result = await axios({
			method: "GET",
			url: `${endpoint}/application/getUserAppDataByAppId/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
		return result.data;
	} catch (e) {
		console.log(e);
	}
};

export const getCustomerSupportTickets = async () => {
	try {
		const response = await axios({
			method: "GET",
			url: `${endpoint}/admin/support/get_tickets`,
			headers: headers(),
			withCredentials: true,
		});
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const createSupportTicket = async (data) => {
	try {
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/admin/support/create_ticket`,
			data,
			{ headers: headers(), withCredentials: true }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const createSupportTicketMessage = async (data) => {
	try {
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/admin/support/create_ticket_message`,
			data,
			{ headers: headers(), withCredentials: true }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const getUserUnreadMessageCount = async () => {
	let user = await getLoggedUser();
	try {
		const data = await axios({
			method: "GET",
			url: `${endpoint}/admin/messages/unread_count?user=${user.id}`,
			headers: headers(),
			withCredentials: true,
		});
		return data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const setMessageAsRead = async (user_id) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${endpoint}/admin/support/read_message/${user_id}`,
			headers: headers(),
			withCredentials: true,
		});
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const getCustomerSupportTicketNotifications = async () => {
	try {
		const response = await axios({
			method: "GET",
			url: `${endpoint}/admin/support/get_ticket_notifications`,
			headers: headers(),
			withCredentials: true,
		});
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

export const setUserTicketAsRead = async (ticket_id) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${endpoint}/admin/support/read_ticket/${ticket_id}`,
			headers: headers(),
			withCredentials: true,
		});
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const getUserLatestApplication = async () => {
	try {
		const result = await axios({
			method: "GET",
			url: `${endpoint}/application/getUserLatestApplication`,
			headers: headers(),
			withCredentials: true,
		});
		return result.data;
	} catch (e) {
		throw new Error(e.message);
	}
};
export const generateUcOTP = async () => {
	try {
		const result = await axios({
			method: "POST",
			url: `${endpoint}/application/generateUcOTP`,
			data: { appId: sessionStorage.getItem("appId") },
			headers: headers(),
			withCredentials: true,
		});
		return result.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const verifyUcOTP = async (otp) => {
	try {
		const result = await axios({
			method: "POST",
			url: `${endpoint}/application/verifyUcOTP`,
			data: { appId: sessionStorage.getItem("appId"), otp: otp },
			headers: headers(),
			withCredentials: true,
		});
		return result.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const redirectUser = async (user_id = "") => {
	if (user_id !== "") {
		// When user logs in
		const userApp = await getUserLatestApplication();
		if (userApp.success) {
			if (userApp.application) {
				const userApplicationStatus = userApp.application.status_id;
				let redirecrUrl = "";
				if (userApplicationStatus === "16") {
					redirecrUrl = "/customerPortal";
				} else if (
					userApplicationStatus === "11" ||
					userApplicationStatus === "12" ||
					userApplicationStatus === "13"
				) {
					redirecrUrl = "/documents/generalDocuments";
				} else if (userApplicationStatus === "14") {
					redirecrUrl = "/documents/legalDocuments";
				} else if (userApplicationStatus === "15") {
					redirecrUrl = "/customerPortal";
				}
				if (redirecrUrl !== "") {
					window.location = redirecrUrl;
				}
			}
		}
	} else {
		// If user manually hits application flow url '/layout'
		const userApp = await getUserLatestApplication();
		if (userApp.success) {
			if (userApp.application) {
				const userApplicationStatus = userApp.application.status_id;
				if (window.location.pathname === "/layout") {
					// if user status is pending_document_upload or anything after pending_document_upload
					if (userApplicationStatus >= "11") {
						window.location = "/customerPortal";
					}
				}
			}
		}
	}
};

export const generateToken = async (email, password) => {
	const CUSTOMER = "customer";
	try {
		let url = `${endpoint}/admin/generateToken`;
		let body = {
			email,
			password,
			type: CUSTOMER,
		};
		const response = await axios({
			method: "POST",
			url: url,
			data: body,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const uploadTicketAttachment = async (data) => {
	try {
		let headersObj = headers();
		headersObj["Content-Type"] = "multipart/form-data";
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/admin/support/uploads`,
			data,
			{ headers: headersObj, withCredentials: true }
		);
		return response.data;
	} catch (e) {
		return false;
	}
};

export const getSupportAdminUser = async (data) => {
	try {
		let response = await axios.get(
			`${process.env.REACT_APP_API_HOST}/V1/admin/support_user`,
			{ headers: headers(), withCredentials: true }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const clearUserSession = () => async () => {
	try {
		axios
			.get(`${process.env.REACT_APP_API_HOST}/V1/user/logout`, {
				headers: headers(),
				withCredentials: true,
			})
			.then((response) => {
				sessionStorage.clear();
				localStorage.clear();
				window.location.href = `/signin`;
			})
			.catch((error) => {
				console.log(error);
			});
	} catch (e) {
		console.log(e);
	}
};

export const getOverallStatement = async (appId) => {
	try {
		const paymentsResult = await axios({
			method: "GET",
			url: `${endpoint}/application/overallstatement/${appId}`,
			headers: headers(),
			withCredentials: true,
		});
		return paymentsResult.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getIndustries = async () => {
	try {
		const result = await axios({
			method: "GET",
			url: `${endpoint}/user/getIndustries/`,
			headers: headers(),
			withCredentials: true,
		});
		return result.data;
	} catch (e) {
		console.log(e);
	}
};

export const updateApplicationStatus = async (appId, status) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${endpoint}/application/status`,
			data: {
				appId: appId,
				action: status,
			},
			headers: headers(),
			withCredentials: true,
		});
		return response.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const sendEmailToUser = async (emailType) => {
	try {
		let dataObj = {
			emailType: emailType,
			appId: sessionStorage.getItem("appId"),
		};
		const updatedInformation = await axios({
			method: "POST",
			url: `${endpoint}/application/sendEmail`,
			data: dataObj,
			headers: headers(),
			withCredentials: true,
		});

		return updatedInformation;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getUserBankDetails = async (userId) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${endpoint}/application/bankinfo`,
			data: { userId },
			headers: headers(),
			withCredentials: true,
		});
		return response;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const checkPlaid = async () => {
	try {
		const result = await axios({
			method: "POST",
			url: `${endpoint}/application/checkPlaid`,
			data: { appId: sessionStorage.getItem("appId") },
			headers: headers(),
			withCredentials: true,
		});
		return result.data;
	} catch (e) {
		throw new Error(e.message);
	}
};
export const getPrCategories = async (type) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${endpoint}/application/getPrCategories/${type}`,
			headers: headers(),
			withCredentials: true,
		});
		return response;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const uploadVouchedManual = async (data) => {
	try {
		const response = await axios.post(
			`${endpoint}/user/uploadVouchedManual`,
			data,
			{
				headers: headers(),
				withCredentials: true,
			}
		);
		return response;
	} catch (e) {
		throw new Error(e.message);
	}
};
