import React from "react";
import errorIcon from "src/assets/icons/App-Flow-Icons/error.svg";
import logoIcon from "src/assets/icons/app-logo.png";
import { CButton, CProgress } from "@coreui/react";
import { useHistory } from "react-router-dom";

const ErrorPage = (props) => {
	const history = useHistory();

	const handleClick = () => {
		history.push("customerPortal");
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div class="content-container">
					<div className="text-container center">
						<div>
							<img
								src={errorIcon}
								alt=""
								style={{
									width: "12%",
									height: "auto",
									marginBottom: "2rem",
								}}
							/>

							<h1 style={{ float: "right" }}>
								<br />
								<b className="ml-2">Sorry, something went wrong.</b>
							</h1>
							<b>
								<h3 style={{ marginTop: "120px" }}>
									{" "}
									Please try refreshing the page or go back to portal
								</h3>
							</b>
						</div>
					</div>
					<div className="button-container">
						<CButton className="custom-button enabled" onClick={handleClick}>
							Go back to Portal
						</CButton>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ErrorPage;
