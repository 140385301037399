import React, { useEffect, useState } from "react";

import fileUpload from "src/assets/icons/App-Flow-Icons/fileUpload2.svg";
import fileID from "src/assets/icons/fileID.png";
import FileUpload from "./FileUpload";

import { TheSidebar } from "../../containers/index";
import TopNav from "../../containers/TopNav";
import { FILE_EXTENSIONS } from "src/Utils/consts";

import {
	CAlert,
	CButton,
	CCardHeader,
	CContainer,
	CLabel,
	CSelect,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from "@coreui/react";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { getLoggedUser } from "src/actions/users";
import {
	getActiveApplication,
	getUserApplications,
	getuserInfo,
	getUserUploadedDocuments,
	getVouchedData,
	uploadVouchedManual,
} from "../../services/user";
import "../../style/documents.scss";
import { checkVouchedFileBeforeUpload } from "src/Utils/fileCheck";

const GeneralDocument = () => {
	const useStyles = makeStyles((theme) => ({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}));

	const [vouchedData, setVouchedData] = useState([]);

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [reload, setReload] = React.useState(false);
	const [warningMessageModal, setWarningMessageModal] = React.useState(false);
	const [warningMessage, setWarningMessage] = React.useState("");
	const hiddenFileInput = React.useRef(null);

	const handleClose = async () => {
		setReload(!reload);
		setOpen(false);
	};

	const userInbox = useSelector((state) => {
		return state.userInbox;
	});
	const unRead = userInbox.filter((email) => !email.isRead);

	const [userDocFile, setUserDocFile] = useState("Upload your document");

	const [upload_msg, setUpload_msg] = useState("");
	const [uploadFlag, setuploadFlag] = useState(false);
	const [userDocFileItem, setUserDocFileItem] = useState(false);
	const [applications, setApplications] = useState([]);
	const [applicationId, setApplicationId] = React.useState("");
	const [loading, setLoading] = React.useState("");
	const [userUploadedDocs, setUserUploadedDocs] = useState([]);
	const [showDocument, setShowDocument] = useState(false);

	const user = {};

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};
	const handleUserDoc = async (handleUploadedValue) => {
		await setUserDocFile(handleUploadedValue);
		await setUpload_msg(handleUploadedValue);
		await setUserDocFileItem(true);
		await setuploadFlag(true);

		setTimeout(() => {
			setuploadFlag(false);
		}, 5000);

		getUserUploadedDocuments(sessionStorage.getItem("CurrentSelect")).then(
			(d) => {
				setUserUploadedDocs(d);
			}
		);
	};

	const handleSelect = async (event) => {
		if (event.target.value !== 0) {
			await sessionStorage.setItem("CurrentSelect", event.target.value);

			await getUserUploadedDocuments(
				sessionStorage.getItem("CurrentSelect")
			).then((d) => {
				setUserUploadedDocs(d);
			});

			await setReload(!reload);
		}
	};

	useEffect(() => {
		const localFn = async () => {
			showLoader();

			let user = await getLoggedUser();
			let userApps = await getUserApplications(user.id);

			let currApps = userApps.applications.filter((item) => {
				if (
					item.status_id === 6 ||
					item.status_id === 4 ||
					item.status_id === 9
				) {
					return false;
				} else {
					return item;
				}
			});

			if (currApps && currApps.length) {
				await setApplications(currApps);
			}

			if (
				sessionStorage.getItem("CurrentSelect") !== 0 &&
				sessionStorage.getItem("CurrentSelect") != null &&
				sessionStorage.getItem("CurrentSelect") !== undefined
			) {
				const currentAppID = await sessionStorage.getItem("CurrentSelect");
				await getActiveApplication(currentAppID);

				userApps.applications.map(async (item) => {
					if (item.appId === sessionStorage.getItem("CurrentSelect")) {
						SetVouchValue(item.appId);
						if (item.status_id >= 11 && item.status_id !== 25) {
							await setShowDocument(true);
						} else {
							await setShowDocument(false);
						}
					}
				});

				let vouched = await getVouchedData(
					sessionStorage.getItem("CurrentSelect"),
					"Vouched"
				);
				if (vouched.success === true) {
					if (vouched.data[0].approvedByAdmin === 1) {
						await setVouchedData({
							approvedByAdmin: 1,
							filename: vouched.data[0].filename,
						});
					} else if (vouched.data[0].approvedByAdmin === 0) {
						await setVouchedData({
							approvedByAdmin: 0,
							filename: vouched.data[0].filename,
						});
					} else if (vouched.data[0].approvedByAdmin === 2) {
						await setVouchedData({
							approvedByAdmin: 2,
							filename: vouched.data[0].filename,
						});
					}
				} else {
					await setVouchedData([]);
				}

				await getUserUploadedDocuments(
					sessionStorage.getItem("CurrentSelect")
				).then((d) => {
					setUserUploadedDocs(d);
				});
			} else {
				const option = await document.querySelectorAll(".options");
				await sessionStorage.setItem("CurrentSelect", option[0].value);
				await setApplicationId(option[0].value);
				console.log("applicationId", applicationId);

				userApps.applications.map(async (item) => {
					if (item.appId === option[0].value) {
						SetVouchValue(item.appId);
						if (item.status_id >= 11 && item.status_id !== 25) {
							await setShowDocument(true);
						} else {
							await setShowDocument(false);
						}
					}
				});

				let vouched = await getVouchedData(option[0].value, "Vouched");
				if (vouched.success === true) {
					if (vouched.data[0].approvedByAdmin === 1) {
						await setVouchedData({
							approvedByAdmin: 1,
							filename: vouched.data[0].filename,
						});
					} else if (vouched.data[0].approvedByAdmin === 0) {
						await setVouchedData({
							approvedByAdmin: 0,
							filename: vouched.data[0].filename,
						});
					} else if (vouched.data[0].approvedByAdmin === 2) {
						await setVouchedData({
							approvedByAdmin: 2,
							filename: vouched.data[0].filename,
						});
					}
				} else {
					await setVouchedData([]);
				}

				await getUserUploadedDocuments(option[0].value).then((d) => {
					setUserUploadedDocs(d);
				});

				const currentAppID = await sessionStorage.getItem("CurrentSelect");
				await getActiveApplication(currentAppID);
			}

			hideLoader();
		};
		localFn();
	}, [reload, applicationId]);

	useEffect(() => {
		localStorage.setItem("Vouched_Callback", process.env.REACT_APP_API_HOST);
		localStorage.setItem(
			"Vouched_API_Key",
			process.env.REACT_APP_VOUCHED_API_KEY
		);
	}, []);

	const SetVouchValue = async (appId) => {
		let FirstName = "";
		let LastName = "";
		let Email = "";
		let Phone = "";
		if (
			process.env.REACT_APP_ENVIRONMENT_DEPLOY_NAME === "development" ||
			process.env.REACT_APP_ENVIRONMENT_DEPLOY_NAME === "uat"
		) {
			FirstName = "ODINSON";
			LastName = "THOR";
			Email = "test@test.id";
			Phone = "000-111-2222";
		} else {
			const { personal, basic } = await getuserInfo(appId);
			FirstName = personal.name;
			LastName = personal.lastName;
			Email = basic.email;
			Phone = personal.contact;
		}
		localStorage.setItem("Vouched_FirstName", FirstName);
		localStorage.setItem("Vouched_LastName", LastName);
		localStorage.setItem("Vouched_Email", Email);
		localStorage.setItem("Vouched_Phone", Phone);
	};

	const handleAcceptedVouchedFiles = async (acceptedFiles) => {
		setReload(true);
		const appId = sessionStorage.getItem("CurrentSelect");
		const data = new FormData();

		data.append("file", acceptedFiles);
		data.append("userId", appId);

		const response = await uploadVouchedManual(data);

		if (response.data.success) {
			handleUserDoc(acceptedFiles.name);
		} else {
			setWarningMessage("Some error occured! please try again later");
			setWarningMessageModal(true);
		}
		setReload(false);
	};

	const handleChangeVouched = async (event) => {
		const fileUploaded = event.target.files[0];
		if (!checkVouchedFileBeforeUpload(fileUploaded).success) {
			setWarningMessage(
				"File Upload Failed! Only image (jpeg, jpg, png) files are allowed."
			);
			setWarningMessageModal(true);
			return;
		}
		await handleAcceptedVouchedFiles(fileUploaded);
	};

	return (
		<div className="gnrl-documents">
			<TopNav unRead={unRead} />
			<CContainer fluid className="px-0">
				<div className="d-flex main-outer-csp">
					<TheSidebar />
					<div className="csp-content-outer nw-costumer c-wrapper">
						<div>
							<CCardHeader style={{ display: "none" }}>
								<div className="d-flex mb-3">
									<CLabel
										style={{
											alignSelf: "center",
											marginRight: "10px",
										}}
										htmlFor="selectLg"
										className="mb-0">
										Select Application
									</CLabel>

									<CSelect
										custom
										name="ccmonth"
										id="ccmonth"
										onChange={handleSelect}>
										<option value="0">Please select</option>
										{applications.map((color, index) => (
											<option
												value={color.appId}
												className="options"
												selected={
													sessionStorage.getItem("CurrentSelect") ===
													color.appId
														? true
														: false
												}>
												{color.productName} -{" $"}
												{color.offerAmount == null
													? "0"
													: color.offerAmount
															.toString()
															.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
											</option>
										))}
									</CSelect>
								</div>
							</CCardHeader>
							{loading ? (
								<div
									className={
										"load-class " + (!user.address ? "load_change" : "")
									}>
									<Loader
										type="ThreeDots"
										color="#ff9700"
										height={100}
										width={100}
										timeout={10000} //3 secs
									/>
								</div>
							) : applications && applications.length ? (
								<div className="user-info-app">
									{uploadFlag ? (
										<div className="alert_section">
											<CAlert color="success">
												{upload_msg} has been Uploaded.
											</CAlert>
										</div>
									) : (
										""
									)}

									<div className="user-info-container">
										<div className="ui-1 manualflex-gnrl">
											{showDocument ? (
												userUploadedDocs &&
												userUploadedDocs.length && (
													<div className="file-card">
														<div className="header">
															<div className="file-name">
																<h3 className="dcmnts-title">
																	<img src={fileUpload} alt="" />
																	<b> Proof Of Identity</b>
																</h3>
															</div>

															{vouchedData.approvedByAdmin === 1 ? (
																<div className="file-status">
																	<b>Verified</b>
																</div>
															) : vouchedData.approvedByAdmin === 0 ? (
																<div className="file-status file-status-pending">
																	<b>Pending Upload</b>
																</div>
															) : vouchedData.approvedByAdmin === 2 ? (
																<div className="file-status file-status-rejected">
																	<b>Pending Verification</b>
																</div>
															) : (
																<div className="file-status file-status-pending">
																	<b>Pending Upload</b>
																</div>
															)}
														</div>
														<div className="info-document doc-info-text">
															<p>
																Only Accepted document are Passport, Permanent
																residency Card, Citizenship Card
															</p>
															<p>
																Health card and Driving license are NOT accepted
															</p>
														</div>
														<div className="main">
															<img src={fileID} alt="" className="large_img" />

															<h5>
																<CButton
																	style={{
																		padding: "10px 45px",
																		border: "0 none",
																		backgroundColor: "#ff9e00",
																		color: "white",
																		fontSize: "16px",
																		borderRadius: "30px",
																		marginTop: "30px",
																	}}
																	onClick={() =>
																		hiddenFileInput.current.click()
																	}
																	disabled={
																		vouchedData.approvedByAdmin === 1
																			? true
																			: vouchedData.approvedByAdmin === 0
																			? false
																			: vouchedData.approvedByAdmin === 2
																			? true
																			: false
																	}>
																	Upload
																</CButton>
																<input
																	type="file"
																	onChange={handleChangeVouched}
																	ref={hiddenFileInput}
																	style={{ display: "none" }}
																	accept={`${FILE_EXTENSIONS.JPG}, ${FILE_EXTENSIONS.JPEG}, ${FILE_EXTENSIONS.PNG}`}
																/>
															</h5>
														</div>
													</div>
												)
											) : (
												<p className="error-text-message">
													Please complete the application before uploading your
													documents
												</p>
											)}

											{showDocument &&
												userUploadedDocs &&
												userUploadedDocs.map(
													(data) =>
														data.status !== "Vouched" && (
															<FileUpload
																fileType={data.status}
																handleUploadedValue={handleUserDoc}
																fileItem={userDocFile}
																completeItem={userDocFileItem}
																doc_data={{
																	approvedByAdmin: data.approvedByAdmin,
																	filename: data.filename ? data.filename : "",
																	doc_name: data.status,
																}}
																showLoader={showLoader}
																hideLoader={hideLoader}></FileUpload>
														)
												)}
										</div>
									</div>
								</div>
							) : (
								<div className="load-class load_change">
									<Loader
										type="ThreeDots"
										color="#ff9700"
										height={100}
										width={100}
										timeout={10000} //3 secs
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</CContainer>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={open}>
					<div className={classes.paper} style={{ position: "relative" }}>
						<span
							className="modal-closer-custom"
							onClick={() => {
								setOpen(false);
								setReload(!reload);
							}}>
							X
						</span>
						<iframe
							title="vouched"
							src="../vouched.html"
							width="1000"
							height="850"
							frameBorder="0"></iframe>
						;
					</div>
				</Fade>
			</Modal>
			<CModal
				className="custom-notification-sweetalert vertical-alert"
				show={warningMessageModal}
				onClose={() => setWarningMessageModal(false)}>
				<CModalHeader closeButton>
					<CModalTitle>Warning!</CModalTitle>
				</CModalHeader>
				<CModalBody>{warningMessage}</CModalBody>
				<CModalFooter>
					<CButton
						color="secondary"
						onClick={() => setWarningMessageModal(false)}>
						OK
					</CButton>
				</CModalFooter>
			</CModal>
		</div>
	);
};

export default React.memo(GeneralDocument);
