import { CImg, CLink } from "@coreui/react";
import React, { Component } from "react";
import { VerifyEmail } from "../../services/user";
import logo from "../../assets/icons/app-logo.png";
export default class VerifyEmails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			message: "",
		};
	}

	async componentDidMount() {
		const verifytoken = await VerifyEmail(this.props.match.params.token);
		verifytoken === "success"
			? this.setState({ message: "Your Email is Verified" })
			: this.setState({ message: "404" });
	}
	render() {
		return (
			<div className="veryEmail">
				<div class="save-exit-button-container">
					<CImg src={logo}></CImg>
				</div>
				<div className="verifyMessage">
					<h2>
						{this.state.message} Please click here to{" "}
						<CLink href="/signin">login</CLink>
					</h2>
				</div>
			</div>
		);
	}
}
