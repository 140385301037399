import { CButton, CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ownerIcon from "src/assets/icons/App-Flow-Icons/is-owner.png";
import relativeIcon from "src/assets/icons/App-Flow-Icons/with-relatives.png";
import roomateIcon from "src/assets/icons/App-Flow-Icons/with-rent.png";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import { getUserInProgressApp, updateUserAppFlow } from "../../services/user";
import { Tooltip } from "src/Components/Tooltip/Tooltip";

export default function LivingArrangment(props) {
	const inProgressApp = getUserInProgressApp();
	let preFill = "";
	if (inProgressApp) {
		preFill = inProgressApp.appData.address.type;
	}
	const [livingArrangment, setLivingArrangment] = React.useState(preFill);
	const { register, errors, handleSubmit, formState, watch } = useForm({
		mode: "onChange",
	});
	const { isValid } = formState;

	const [loading, setLoading] = React.useState("");
	const [mouseClick, setMouseClick] = useState(0);
	const arrivalTime = new Date();

	useEffect(() => {
		console.log(isValid);
		if (livingArrangment !== "" && mouseClick > 0) {
			handleSave();
		}
		//eslint-disable-next-line
	}, [livingArrangment, mouseClick]);

	const handleLivingArrangment = (event) => {
		setLivingArrangment(event.target.value);
		localStorage.setItem("living_arrangement_rent", event.target.value);
	};

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	const handleSave = async () => {
		let details = {
			page: "livingArrangment",
			livingArrangment,
		};
		localStorage.setItem("living_arrangement_rent", livingArrangment);
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			hideLoader();
			props.nextStep();
			await sendAnalytics(arrivalTime, STEP_PAGES.LIVING_ARRANGMENT);
		}
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		let details = {
			page: "livingArrangment",
			livingArrangment,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			hideLoader();
			props.handleSaveExit();
			props.lastStep();
		}
	};

	const handleSaveAndBack = async () => {
		localStorage.setItem("PreviousPage", "YES");
		let details = {
			page: "livingArrangment",
			livingArrangment: watch("Living-Status"),
		};
		updateUserAppFlow(props.user.id, details);
		props.previousStep();
		await sendAnalytics(arrivalTime, STEP_PAGES.LIVING_ARRANGMENT);
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div class="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>What are your current living arrangement?</b>
								</h1>
							</div>
							<div className="cards-container">
								<div className="icon-card">
									<input
										type="radio"
										id="Relative"
										name="Living-Status"
										value="OTHER"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onChange={handleLivingArrangment}
										onClick={handleClick}
										checked={
											(preFill !== "" && preFill === "relative") ||
											livingArrangment === "OTHER"
												? true
												: false
										}
									/>
									<label htmlFor="Relative" className="card-label">
										<img src={relativeIcon} alt="" />
										<b>Live with relatives</b>
									</label>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="Roomate"
										name="Living-Status"
										value="RENT"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onChange={handleLivingArrangment}
										onClick={handleClick}
										checked={
											(preFill !== "" && preFill === "roomate") ||
											livingArrangment === "RENT"
												? true
												: false
										}
									/>
									<label htmlFor="Roomate" className="card-label">
										<img src={roomateIcon} alt="" />
										<b>Rent</b>
									</label>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="Owner"
										name="Living-Status"
										value="OWNER"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onChange={handleLivingArrangment}
										onClick={handleClick}
										checked={
											(preFill !== "" && preFill === "owner") ||
											livingArrangment === "OWNER"
												? true
												: false
										}
									/>
									<label htmlFor="Owner" className="card-label">
										<img src={ownerIcon} alt="" />
										<b>Owner</b>
									</label>
								</div>
							</div>
							{Object.keys(errors).length > 0 && (
								<div className="error-container">
									<p className="error-message">
										{errors[Object.keys(errors)[0]].message}
									</p>
								</div>
							)}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{preFill && preFill !== "" ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}>
									Next
								</button>
							) : null}
							<button
								className="custom-back-button"
								onClick={handleSaveAndBack}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
}
