import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CProgress,
} from "@coreui/react";
import {
	checkPlaid,
	saveCashFlow,
	sendEmailToUser,
	updateApplicationStatus,
	updateAppStatus,
} from "src/services/user";
import {
	MFA_NOT_SUPPORTED,
	PLAID_RETRY_ATTEMPTS,
	PRODUCT_READY,
	RATE_LIMIT,
} from "src/Utils/consts";
import loadingIcon from "src/assets/icons/Spinner.svg";

import { useState } from "react";
const PlaidWait = (props) => {
	const [loading, setLoading] = useState("");
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [OtherErrorPopup, setOtherErrorPopup] = useState(false);
	let retriesAttempt = 0;
	const VerifyPlaid = async () => {
		const checkPlaidWebhook = await checkPlaid();
		console.log(checkPlaidWebhook, "checkPlaidWebhook");
		if (checkPlaidWebhook.status === PRODUCT_READY) {
			clearInterval(startChecker);
			props.goToNamedStep("success");
		}
		if (checkPlaidWebhook.status === RATE_LIMIT) {
			clearInterval(startChecker);
			sessionStorage.setItem("try_after", "20 Seconds");
			window.location.href = "/try-again-later";
		}
		if (retriesAttempt === PLAID_RETRY_ATTEMPTS) {
			console.log(checkPlaidWebhook);

			if (checkPlaidWebhook.status === MFA_NOT_SUPPORTED) {
				clearInterval(startChecker);
				askConfirmation();
			} else {
				updateAppStatus();
				setOtherErrorPopup(true)
			}
		} else {
			retriesAttempt++;
		}
	};
	const askConfirmation = () => {
		setConfirmPopup(true);
	};

	const handleRedirection = async () => {
		window.location.href = "/documents/generalDocuments";
	};
	const handleNoPlaid = async () => {
		setLoading(true);
		if (sessionStorage.getItem("appId")) {
			await updateApplicationStatus(
				sessionStorage.getItem("appId"),
				"pending_document_upload"
			);
			await saveCashFlow();
			await sendEmailToUser("Skip Plaid");
			await sendEmailToUser("Documents Request");
			sessionStorage.setItem("plaid_skipped", true);
		}
		setConfirmPopup(false);
		setLoading(false);
		handleRedirection();
	};
	const handleOtherIssue = async () => {
		setOtherErrorPopup(false);
		props.goToNamedStep("plaid");
	};
	const startChecker = setInterval(VerifyPlaid, 5000);

	return (
		<>
			<section>
				<CProgress
					color="stack-overflow"
					value={(props.currentStep / props.totalSteps) * 100}
					className="bg-white"
				/>
				<div id="page-section" className="wrapper">
					<div className="no-scoll vertical-middle-page">
						<div className="content-container">
							<div className="text-container spaced">
								<h1>
									<b>Plaid</b>
								</h1>
								<h4>Please wait while we are processing your Plaid request and do not refresh the page.</h4>

							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Confirmation popup */}
			<CModal
				className="custom-notification-sweetalert vertical-alert plaidWaitModal"
				show={confirmPopup}
				onClose={() => {
					setConfirmPopup(false);
				}}
				color="warning">
				<CModalHeader className="custom-notification-no-header">
					<CModalTitle></CModalTitle>
				</CModalHeader>
				<CModalBody className="text-center">
					{loading ? (
						<div className="load-class load-class-absoulte-outter">
							<div className="load-class-inner load-class-absoulte">
								<img src={loadingIcon} alt="" />
							</div>
						</div>
					) : null}
					<p className="custom-notification-title">Important Information!</p>
					<p className="custom-notification-text">
						You have multi-factor authentication enabled which is not currently
						supported, you will have to provide the void cheque and bank information
						manually to QuadFi.
					</p>
				</CModalBody>
				<CModalFooter>
					<CButton
						className="custom-notification-btn-cancel"
						color="default"
						onClick={handleNoPlaid}>
						Ok
					</CButton>
				</CModalFooter>
			</CModal>



			{/* Try Again popup */}
			<CModal
				className="custom-notification-sweetalert vertical-alert plaidWaitModal"
				show={OtherErrorPopup}
				onClose={() => {
					setOtherErrorPopup(false);
				}}
				color="warning">
				<CModalHeader className="custom-notification-no-header">
					<CModalTitle></CModalTitle>
				</CModalHeader>
				<CModalBody className="text-center">
					{loading ? (
						<div className="load-class load-class-absoulte-outter">
							<div className="load-class-inner load-class-absoulte">
								<img src={loadingIcon} alt="" />
							</div>
						</div>
					) : null}
					<p className="custom-notification-title">Error occured</p>
					<p className="custom-notification-text">We are facing some issues while connecting to your bank accounts.</p>
				</CModalBody>
				<CModalFooter>
					<CButton
						className="custom-notification-btn-cancel"
						color="default"
						onClick={handleOtherIssue}>
						Ok
					</CButton>
				</CModalFooter>
			</CModal>

		</>
	);
};

export default PlaidWait;
