import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import axios from "axios";
import { headers } from "./reusable/headers";

const middleware = [thunk];

// action
export const getUserInbox = (userId) => async (dispatch) => {
	try {
		await axios({
			method: "POST",
			url: `${process.env.REACT_APP_MAILBOX_API_URL}/user/getUserInbox`,
			data: {
				userId: userId,
			},
			headers: headers(),
		});
	} catch (e) {
		throw new Error(e);
	}
};

export const updateUserInbox = (mailId, payload) => async (dispatch) => {
	try {
		// /updateMailStatus
		await axios({
			method: "POST",
			url: `${process.env.REACT_APP_MAILBOX_API_URL}/user/updateMailStatus`,
			data: {
				mailId: mailId,
			},
			headers: headers(),
		});
	} catch (e) {
		throw new Error(e);
	}
};
const store = createStore(
	reducer,
	composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
