import React, { useEffect, useState } from "react";
import "../../style/personalinformation.scss";
import {
	CCardHeader,
	CLabel,
	CSelect,
	CContainer,
	CDataTable,
	CButton,
	CCollapse,
	CCardBody,
	CImg,
} from "@coreui/react";
import TopNav from "../../containers/TopNav";
import TheSidebar from "../../containers/TheSidebar";
import { useSelector } from "react-redux";
import {
	userInfo,
	getUserApplications,
	getMonthlyPayments,
} from "../../services/user";
import menuIcon from "../../assets/icons/menuIcon.png";
import Loader from "react-loader-spinner";
import { getLoggedUser } from "src/actions/users";

const MonthlyStatement = () => {
	const userInbox = useSelector((state) => {
		return state.userInbox;
	});
	const unRead = userInbox.filter((email) => !email.isRead);

	const details = [];
	const scheduleData = [];

	const [hidden, setHidden] = React.useState(true);
	const [editShow, setEditShow] = React.useState(false);
	const [applications, setApplications] = useState([]);
	const [applicationId, setApplicationId] = React.useState("");
	const [loading, setLoading] = React.useState("");
	const [user, setUser] = useState({});
	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	const handleSelect = async (event) => {
		if (event.target.value !== 0) {
			sessionStorage.setItem("CurrentSelect", event.target.value);
			setEditShow(true);
			let userId = event.target.value;
			console.log("Curremt ", event.target.value);
			setApplicationId(event.target.value);
			showLoader();
			await userInfo(userId, setUser);
			console.log(user);
			hideLoader();
		} else {
			setEditShow(false);
		}
	};

	useEffect(() => {
		const localFn = async () => {
			console.log(hidden);
			console.log(editShow);
			showLoader();

			let userdata = await getLoggedUser();
			let userApps = await getUserApplications(userdata.id);
			console.log(userApps, "aklfj");
			if (
				userApps.success &&
				userApps.applications &&
				userApps.applications.length > 0
			) {
				let _arr = [];
				for (let app of userApps.applications) {
					if (Number(app.status_id) >= 16 && Number(app.status_id) <= 21)
						_arr.push(app);
				}
				await setApplications(_arr);
			}
			console.log(applications);
			if (
				sessionStorage.getItem("CurrentSelect") !== 0 &&
				sessionStorage.getItem("CurrentSelect") != null
			) {
				setEditShow(true);
				async function Call() {
					let paymentSchedule = await getMonthlyPayments(
						sessionStorage.getItem("CurrentSelect")
					);
					if (paymentSchedule.success) {
					}
					setApplicationId(sessionStorage.getItem("CurrentSelect"));
					showLoader();
					await userInfo(sessionStorage.getItem("CurrentSelect"), setUser);
					console.log(user, "userdatas");
					hideLoader();
				}
				Call();
			} else {
				const option = document.querySelectorAll(".options");

				if (option.length > 0) {
					console.log("current sel", option);
					sessionStorage.setItem("CurrentSelect", option[0].value);
					let userId = option[0].value;
					console.log("here", option[0].value);
					let paymentSchedule = await getMonthlyPayments(userId);
					if (paymentSchedule.success) {
					}
					setApplicationId(option[0].value);
					showLoader();
					await userInfo(userId, setUser);
					hideLoader();
				}
			}
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		const localFn = async () => {
			console.log("app di = ", applicationId);
			if (applicationId) {
				let paymentSchedule = await getMonthlyPayments(applicationId);
				if (paymentSchedule.success) {
				}
			}
		};
		localFn();
	}, [applicationId]);

	const fields = ["No.", { key: "Statement Month" }, { key: "Download" }];

	const [activeCls, setActivecls] = useState(false);
	const menuHandler = () => {
		setActivecls(!activeCls);
	};

	return (
		<div className="sche-bg">
			<div className="top_navOuter position-relative">
				<button className="bars" onClick={menuHandler}>
					<CImg src={menuIcon}></CImg>
				</button>
				<TopNav unRead={unRead} />
			</div>
			<CContainer fluid>
				<div className="d-flex main-outer-csp">
					<div className={`sideBar-outer ${activeCls ? "sidBrexpand" : null}`}>
						<TheSidebar />
					</div>
					<div className="csp-content-outer">
						<div className="tbl-space tblsNewcls">
							<div class="title">Monthly Statement</div>
							<div className="card">
								<div>
									<CCardHeader style={{ display: "none" }}>
										<div className="d-flex mb-3">
											<CLabel htmlFor="selectLg" className="mb-0">
												Select Application
											</CLabel>
											<CButton
												shape="pill"
												className="edit_bttn_option pl-2 pr-2 ml-4 p-0"
												onClick={() => setHidden(false)}></CButton>
										</div>
										<div style={{ width: "40%" }}>
											<CSelect
												custom
												name="ccmonth"
												id="ccmonth"
												onChange={handleSelect}>
												<option value="0">Please select</option>
												{applications.map((color, index) => (
													<option
														value={color.appId}
														className="options"
														selected={
															sessionStorage.getItem("CurrentSelect") ===
															color.appId
																? true
																: false
														}>
														{color.productName} - {color.loanAmount}
													</option>
												))}
											</CSelect>
										</div>
									</CCardHeader>
								</div>
								{loading ? (
									<div
										className={
											"load-class " + (!user.address ? "load_change" : "")
										}>
										<Loader
											type="ThreeDots"
											color="#ff9700"
											height={100}
											width={100}
											timeout={10000}
										/>
									</div>
								) : (
									<div className="radius-table px-3 pt-3">
										<CDataTable
											items={scheduleData}
											fields={fields}
											itemsPerPage={10}
											hover
											pagination
											scopedSlots={{
												Download: (item) => (
													<td>
														<a
															href={`${
																process.env.REACT_APP_API_HOST
															}/V1/admin/documents/download/${sessionStorage.getItem(
																"CurrentSelect"
															)}/Monthly_statement/${item.month}?id=${item.id}`}>
															Download Statement
														</a>
													</td>
												),
												"No.": (item, index) => <td>{index + 1}</td>,
												"Statement Month": (item) => <td>{item.month}</td>,

												details: (item, index) => {
													return (
														<CCollapse show={details.includes(index)}>
															<CCardBody>
																<h4>{item.username}</h4>
																<p className="text-muted">
																	User since: {item.registered}
																</p>
																<CButton size="sm" color="info">
																	User Settings
																</CButton>
																<CButton
																	size="sm"
																	color="danger"
																	className="ml-1">
																	Delete
																</CButton>
															</CCardBody>
														</CCollapse>
													);
												},
											}}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</CContainer>
		</div>
	);
};

export default React.memo(MonthlyStatement);
