import React, { useState } from "react";
import { CButton, CLabel, CProgress } from "@coreui/react";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { useForm } from "react-hook-form";
import { updateUserAppFlow, getUserInProgressApp } from "../../services/user";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";

export default function EducationOther(props) {
	const inProgressApp = getUserInProgressApp();
	let preFill = { graduation_date: null };
	if (inProgressApp) {
		preFill = {
			graduation_date: inProgressApp.appData.education.completion_date,
		};
	}

	const [mouseClick, setMouseClick] = useState(0);
	const [pickerDate, setPickerDate] = useState(
		preFill.graduation_date ? new Date(preFill.graduation_date) : null
	);
	const [pickerCompletionDate, setpPickerCompletionDate] = useState(
		preFill.graduation_date
	);
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const [loading, setLoading] = React.useState("");
	const { handleSubmit } = useForm({
		mode: "onChange",
	});

	const arrivalTime = new Date();

	const handleDateChange = (date) => {
		setPickerDate(date);
		var today = new Date(date);
		var mm = String(today.getMonth() + 1).padStart(2, "0");
		var yyyy = today.getFullYear();
		setpPickerCompletionDate(yyyy + "-" + mm);
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	const handleSave = async (back) => {
		setLoading(true);
		const details = {
			page: "education",
			compeletion_date: pickerCompletionDate
				? pickerCompletionDate
				: preFill.graduation_date,
			university: "",
			major: "",
			gpa: "",
			country: "",
			program_id: "",
			university_id: "",
		};

		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			setNextButtonDisabled(false);
			setLoading(false);
			if (typeof back !== "boolean" || !back) {
				props.nextStep();
				await sendAnalytics(arrivalTime, STEP_PAGES.EDUCATION);
			} else {
				localStorage.setItem("PreviousPage", "YES");
				await sendAnalytics(arrivalTime, STEP_PAGES.EDUCATION);
			}
		}
		return response;
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		const details = {
			page: "education",
			compeletion_date: pickerCompletionDate,
		};
		setLoading(true);
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			setLoading(false);
			props.handleSaveExit();
			props.lastStep();
		}
	};

	return (
		<section className="eduction-new">
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div
								className="text-container text-container-margin"
								style={{ textAlign: "center" }}>
								<h1>
									<b>Tell us about your education.</b>
								</h1>
							</div>
							<div className="address-input-container">
								<div
									className="input-container"
									style={{ justifyContent: "center" }}>
									<div className="edubox">
										<CLabel htmlFor="nf-graduation" className="custom-label">
											<b>Graduation Date</b>
										</CLabel>
										<br />
										<div class="form-control-plaintext w-67 h-58 custom-input third-datepicker">
											<DatePicker
												name="completionDate"
												placeholder="Month, Year"
												minDate={new Date("1 January, 1970")}
												maxDate={new Date()}
												onChange={(date) => handleDateChange(date)}
												onClick={handleClick}
												selected={pickerDate}
												dateFormat="yyyy-MM"
												showMonthYearPicker
												showFullMonthYearPicker
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{pickerCompletionDate ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							) : (
								<button
									className="custom-button disabled"
									disabled={nextButtonDisabled}>
									Next
								</button>
							)}
							<button
								className="custom-back-button"
								onClick={async () => {
									await handleSave(true);
									if (props?.goToStep) {
										props.goToStep(13);
									}
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
}
