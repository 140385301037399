import axios from "axios";
import { getLoggedUser } from "src/actions/users";
import { headers } from "../reusable/headers";

export const sendEmailForTwoFA = async () => {
	try {
		const user = await getLoggedUser();
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/user/twofa/send`,
			{ data: user.id },
			{ headers: headers(), withCredentials: true }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const verifyCodeFirstTime = async (code) => {
	try {
		const user = await getLoggedUser();
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/user/twoFa/enable`,
			{ data: user.id, code },
			{ headers: headers(), withCredentials: true }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const disableTwoFA = async () => {
	try {
		const user = await getLoggedUser();
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/user/twofa/disable`,
			{ data: user.id },
			{ headers: headers(), withCredentials: true }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const checkTwoFa = async (formData) => {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/user/twofa/check`,
			formData,
			{ headers: { "Access-Control-Allow-Origin": "*" } }
		);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};
