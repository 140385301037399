import { CButton, CProgress } from "@coreui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
	browserName,
	isAndroid,
	isDesktop,
	isIOS,
	isMobileOnly,
	isTablet,
	isWinPhone,
} from "react-device-detect";
import { useForm } from "react-hook-form";
import { getLoggedUser } from "src/actions/users";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { debug } from "src/services/debugService";
import { STEP_PAGES } from "src/Utils/enums";
import { getUserInProgressApp, updateUserAppFlow } from "../../services/user";

const Name = (props) => {
	const inProgressApp = getUserInProgressApp();
	let preFill = { first_name: "", middle_name: "", last_name: "" };
	if (inProgressApp) {
		preFill = {
			first_name: inProgressApp.appData.personal.name,
			middle_name: inProgressApp.appData.personal.middleName,
			last_name: inProgressApp.appData.personal.lastName,
		};
	}
	const [firstName, setFirstName] = React.useState(preFill.first_name);
	const [lastName, setLastName] = React.useState(preFill.last_name);
	const [middleName, setMiddleName] = React.useState(preFill.middle_name);
	const { register, handleSubmit, errors, formState } = useForm({
		reValidateMode: "onChange",
		mode: "all",
	});
	const { isValid } = formState;
	const [loading, setLoading] = React.useState("");
	const [typingTime, setTypingTime] = useState(new Date());
	const [stopTyping, setStopTyping] = useState(new Date());
	const [keyboardClick, setKeyboardClick] = useState(1);
	const [mouseCount, setMouseCount] = useState(1);
	const [wpm, setWpm] = useState(0);
	const [haveName, setHaveName] = useState("Not Included");
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const [nameCheckUser, setNameCheckUser] = useState({ a: 1 });
	const arrivalTime = new Date();
	debug("Name props: ", props, arrivalTime);

	localStorage.setItem("PreviousPage", "NO");
	localStorage.setItem("Autofill", "NO");
	localStorage.setItem("DropOff", "NO");

	const hideLoader = () => {
		setLoading(false);
	};
	const showLoader = () => {
		setLoading(true);
	};
	const handleSave = async (data, back) => {
		setNextButtonDisabled(true);
		let details = {
			page: "name",
			firstName: data.first_name.trim(),
			middleName: data.middle_name.trim(),
			lastName: data.last_name.trim(),
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			setNextButtonDisabled(false);
			hideLoader();
			if (typeof back !== "boolean" || !back) {
				props.nextStep();
				await sendAnalytics(arrivalTime, STEP_PAGES.NAME);
			} else {
				localStorage.setItem("PreviousPage", "YES");
				await sendAnalytics(arrivalTime, STEP_PAGES.NAME);
			}
		}
		return response;
	};

	const handleDoNotTrack = () => {
		let message = "";
		if (navigator.doNotTrack) {
			message = "ON";
		} else {
			message = "OFF";
		}
		localStorage.setItem("DoNotTrack", message);
	};

	const handleDeviceCheck = () => {
		let message = "";
		if (isDesktop) {
			message = "Dekstop";
		} else if (isMobileOnly) {
			if (isWinPhone) {
				message = "Windows Mobile";
			}
			if (isAndroid) {
				message = "Android Mobile";
			}
			if (isIOS) {
				message = "iPhone";
			} else {
				message = "Unknown Mobile";
			}
		} else if (isTablet) {
			message = "Tablet";
		} else {
			message = "Unknown Device";
		}
		localStorage.setItem("deviceType", message);
	};

	const handleDeviceIP = async () => {
		const res = await axios.get("https://geolocation-db.com/json/");
		localStorage.setItem("ClientIP", res.data.IPv4);
	};

	const handleBrowser = () => {
		let message = "";
		if (browserName) {
			message = browserName;
		}
		localStorage.setItem("Browser", message);
	};

	const handleOperatingSystem = () => {
		let platform = window.navigator.platform;
		localStorage.setItem("OS", platform);
	};

	useEffect(() => {
		console.log(haveName);
		handleDoNotTrack();
		handleDeviceCheck();
		handleDeviceIP();
		handleBrowser();
		handleOperatingSystem();
		//eslint-disable-next-line
	}, []);

	const mouseClickCount = () => {
		setMouseCount(mouseCount + 1);
		localStorage.setItem("mouseCount", mouseCount);
	};

	const handleSaveAndExit = async (data) => {
		localStorage.setItem("DropOff", "YES");
		let details = {
			page: "name",
			firstName: data.first_name.trim(),
			middleName: data.middle_name.trim(),
			lastName: data.last_name.trim(),
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		console.log("See this response", response);
		if (response.success) {
			hideLoader();
			props.handleSaveExit();
			props.lastStep();
		}
	};

	const handleNameChange = (e) => {
		setFirstName(e.target.value.trim());
		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);

		var iTotal = 0;
		var iKeys = 0;
		var iWords = 0;

		setTypingTime(new Date());

		if (stopTyping !== 0) {
			iKeys++;
			// iTotal += typingTime - stopTyping;
			iTotal += Math.abs(Math.round(typingTime - stopTyping) / 1000 / 60);
			iWords = e.target.value.split(/\s/).length;
			let CPM = ((iKeys / iTotal) * 6000, 2);
			let WPM = ((iWords / iTotal) * 6000, 2);
			setWpm(WPM);
			localStorage.setItem("WPM", wpm);
			console.log(CPM);
		}
		setStopTyping(new Date());
	};
	const handleLastNameChange = (e) => {
		setLastName(e.target.value.trim());
		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);

		var iTotal = 0;
		var iKeys = 0;
		var iWords = 0;

		setTypingTime(new Date());

		if (stopTyping !== 0) {
			iKeys++;
			// iTotal += typingTime - stopTyping;
			iTotal += Math.abs(Math.round(typingTime - stopTyping) / 1000 / 60);
			iWords = e.target.value.split(/\s/).length;
			let CPM = ((iKeys / iTotal) * 6000, 2);
			let WPM = ((iWords / iTotal) * 6000, 2);
			setWpm(WPM);
			localStorage.setItem("WPM", wpm);
			console.log(CPM);
		}
		setStopTyping(new Date());
	};

	const handleMiddleNameChange = (e) => {
		setMiddleName(e.target.value.trim());
		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);

		var iTotal = 0;
		var iKeys = 0;
		var iWords = 0;

		setTypingTime(new Date());

		if (stopTyping !== 0) {
			iKeys++;
			// iTotal += typingTime - stopTyping;
			iTotal += Math.abs(Math.round(typingTime - stopTyping) / 1000 / 60);
			iWords = e.target.value.split(/\s/).length;
			let CPM = ((iKeys / iTotal) * 6000, 2);
			let WPM = ((iWords / iTotal) * 6000, 2);
			setWpm(WPM);
			localStorage.setItem("WPM", wpm);
			console.log(CPM);
		}
		setStopTyping(new Date());
	};

	useEffect(() => {
		const checkNameInEmail = async () => {
			let user = {};
			if (typeof nameCheckUser.email != "undefined") {
				user = nameCheckUser;
			} else {
				user = await getLoggedUser();
				setNameCheckUser(user);
			}
			let email = user.email;
			console.log("Email from state", email);
			if (email) {
				if (email.includes(firstName) && email.includes(lastName)) {
					setHaveName("First Name AND Last Name");
				} else if (email.includes(firstName)) {
					setHaveName("First Name");
				} else if (email.includes(lastName)) {
					setHaveName("Last Name");
				} else {
					setHaveName("Not Included");
				}
			}
		};
		checkNameInEmail();
		//eslint-disable-next-line
	}, [firstName, lastName]);

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton
						className="save-exit-button"
						onClick={handleSubmit(handleSaveAndExit)}>
						Save and Exit
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>What is your name?</b>
								</h1>
								<h3>Please provide your full legal name.</h3>
							</div>
							<div className="input-container">
								<input
									id="first_name"
									name="first_name"
									defaultValue={firstName}
									onChange={handleNameChange}
									onClick={mouseClickCount}
									maxLength={60}
									className={
										"form-control-plaintext w-67 h-58 custom-input " +
										(errors.first_name && errors.first_name.message
											? "custom-input-error"
											: "")
									}
									placeholder="First Name"
									ref={register({
										required: "Please make sure to fill all the inputs",
										pattern: {
											value: /^[a-zA-Z\s]*$/,
											message: "First Name can only contain letters, spaces",
										},
									})}
								/>

								<input
									id="middle_name"
									name="middle_name"
									defaultValue={middleName}
									onChange={handleMiddleNameChange}
									onClick={mouseClickCount}
									maxLength={60}
									className={
										"form-control-plaintext w-67 h-58 custom-input " +
										(errors.middle_name && errors.middle_name.message
											? "custom-input-error"
											: "")
									}
									placeholder="Middle Name(s)"
									ref={register({
										pattern: {
											value: /^[a-zA-Z\s]*$/,
											message: "Middle Name can only contain letters, spaces",
										},
									})}
								/>

								<input
									type="last_name"
									id="last_name"
									name="last_name"
									defaultValue={lastName}
									onChange={handleLastNameChange}
									onClick={mouseClickCount}
									maxLength={60}
									className={
										"form-control-plaintext w-67 h-58 custom-input " +
										(errors.last_name && errors.last_name.message
											? "custom-input-error"
											: "")
									}
									placeholder="Last Name"
									ref={register({
										required: "Please make sure to fill all the inputs",
										pattern: {
											value: /^[a-zA-Z\s]*$/,
											message: "Last Name can only contain letters, spaces",
										},
									})}
								/>
							</div>
							{Object.keys(errors).length > 0 && (
								<div className="error-container">
									<p className="error-message">
										{errors[Object.keys(errors)[0]].message}
									</p>
								</div>
							)}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{isValid ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							) : (
								<button
									className="custom-button disabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							)}
							<button
								className="custom-back-button"
								onClick={async () => {
									if (props?.previousStep) {
										props.previousStep();
									}
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default Name;
