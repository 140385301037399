import infoIcon from "src/assets/icons/info.svg";
import { QUADFI_PRIVACY_POLICY_URL } from "src/Utils/consts";

export function Tooltip() {
	return (
		<>
			<div className="info-container hoverable-container">
				<img src={infoIcon} alt="" />
				<p className="tool-tip">
					<b>
						We're required by law to verify your identification. See our{" "}
						<a
							href={QUADFI_PRIVACY_POLICY_URL}
							target="_blank"
							rel="noreferrer">
							privacy policy
						</a>{" "}
						for how we protect your information.
					</b>
				</p>
			</div>
		</>
	);
}
