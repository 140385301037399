import React from "react";
import dlogo from "src/containers/logo_dark.png";
import TermsAndConditions from "../OfferSuccess/TermsAndConditionsRegistration";

export class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			displayTermsAndConditions: false,
		};
	}

	render() {
		return (
			<section>
				<div className="base-container sign-in-box">
					<div className="header">
						<img src={dlogo} alt="QuadFi Logo" />
					</div>
					<div className="content">
						<div className="form">
							<div className="form-group">
								<input
									type="email"
									name="email"
									placeholder="Email"
									className="user-input"
								/>
							</div>
							<div className="form-group">
								<input
									type="password"
									name="password"
									placeholder="Password"
									className="user-input"
								/>
							</div>
							<div class="form-check">
								<input
									class="form-check-input"
									type="checkbox"
									value=""
									id="termsCheck"
								/>

								<label class="form-check-label" for="termsCheck">
									<b>
										I agree to the{" "}
										<a
											href="#terms"
											onClick={() =>
												this.setState({
													displayTermsAndConditions: true,
												})
											}>
											Terms and Conditions.
										</a>
									</b>
								</label>
							</div>
							<TermsAndConditions
								show={this.state.displayTermsAndConditions}
								closeHandler={() =>
									this.setState({
										displayTermsAndConditions: false,
									})
								}
							/>
						</div>
					</div>
					<div className="cta-button">
						<button type="button" className="btn">
							Sign Up
						</button>
					</div>
					<div className="footer">
						<a href="/forgetpassword">Forgot password?</a>
						<span>
							Already have an account? <a href="#/Login">Log In Here.</a>
						</span>
					</div>
				</div>
			</section>
		);
	}
}
