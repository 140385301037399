import { CContainer, CImg } from "@coreui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getLoggedUser } from "src/actions/users";
import { ALLOWED_APPLICATION_STATUS_FOR_REAPPLY } from "src/Utils/consts";
import menuIcon from "../assets/icons/menuIcon.png";
import { getUserLatestApplication, userInfo } from "../services/user";
import { getUserApplications } from "./../services/user";
import { TheContent, TheSidebar } from "./index";
import TopNav from "./TopNav";
import { reloadApplication } from "./../utils";

const CustomerPortal = (userId) => {
	const [user, setUser] = useState({});
	const [applications, setApplications] = useState([]);
	const [applicationsFetched, setApplicationsFetched] = useState(false);
	const history = useHistory();

	const userInbox = useSelector((state) => {
		return state.userInbox;
	});

	const unRead = userInbox.filter((email) => !email.isRead);
	const handleCreateApplication = async () => {
		return await checkNewApplicationCriteria();
	};

	const checkNewApplicationCriteria = async () => {
		let returnResponse = {
			success: false,
			message: "",
			warningMessage: "",
		};
		let user = await getLoggedUser();
		if (user) {
			const userCurrentApplication = await getUserLatestApplication(user.id);
			if (
				userCurrentApplication &&
				typeof userCurrentApplication.application !== "undefined"
			) {
				const userAppData = userCurrentApplication.application;
				const userAppStatusID = userAppData.status_id;
				if (
					userAppStatusID < 16 &&
					!ALLOWED_APPLICATION_STATUS_FOR_REAPPLY.includes(userAppStatusID)
				) {
					// Customer has a pending and not funded application.
					returnResponse = {
						success: true,
						message: "ask_confirmation",
						warningMessage: "",
					};
				} else if (
					userAppStatusID === 17 ||
					userAppStatusID === 18 ||
					userAppStatusID === 19 ||
					userAppStatusID === 20
				) {
					// Customer has a funded loan but not paid off yet
					returnResponse = {
						success: false,
						message: "not_allowed",
						warningMessage: "You have not paid your previous loan amount yet.",
					};
				} else if (userAppStatusID === 23 || userAppStatusID === 24) {
					// Customer has a funded loan that paid off in the past
					returnResponse = {
						success: true,
						message: "allowed",
						warningMessage: "",
					};
				} else if (userAppStatusID === 21) {
					// Customer has a funded loan that defaulted in the past
					returnResponse = {
						success: false,
						message: "not_allowed",
						warningMessage: "Your previous loan was defaulted in the past.",
					};
				} else if (userAppStatusID === 25) {
					// Customer has a cancelled application
					returnResponse = {
						success: true,
						message: "allowed",
						warningMessage: "",
					};
				} else if (
					ALLOWED_APPLICATION_STATUS_FOR_REAPPLY.includes(userAppStatusID)
				) {
					returnResponse = {
						success: true,
						message: "allowed",
						warningMessage: "",
					};
				} else {
					console.log("Exception Occured", userAppStatusID);
				}
			}
		}
		console.log(returnResponse);
		return returnResponse;
	};

	const handleCompleteApplication = async (app) => {
		sessionStorage.setItem("appId", app.appId);
		sessionStorage.setItem("productId", app.productId);
		sessionStorage.setItem("inprogress_app", JSON.stringify(app));
		await reloadApplication(history);
	};

	const getApplicationsUser = async () => {
		let user = await getLoggedUser();
		let userApps = await getUserApplications(user.id);
		if (
			userApps.success &&
			userApps.applications &&
			userApps.applications.length > 0
		) {
			setApplications(userApps.applications);
			setApplicationsFetched(true);
			userInfo(userApps.applications[0].appId, setUser);
			let currentApp = userApps.applications.filter((item) => {
				return item.status_id === 16;
			});
			if (currentApp.length > 0) {
				currentApp = currentApp[0];
				console.log("currentApp", currentApp);
				sessionStorage.setItem("currentApp", JSON.stringify(currentApp));
			}
			if (!sessionStorage.getItem("appId")) {
				sessionStorage.setItem("appId", userApps.applications[0].appId);
			}
		} else {
			setApplicationsFetched(true);
			setApplications([]); // If their is no application pass blank array
		}
	};

	useEffect(() => {
		const localFn = async () => {
			// Todo: change the userId to the actual user with logs in
			await getApplicationsUser();
		};
		localFn();
		//eslint-disable-next-line
	}, [sessionStorage.getItem("appId")]);

	const [activeCls, setActivecls] = useState(false);
	const menuHandler = () => {
		setActivecls(!activeCls);
	};

	return (
		<div className="portalOuter">
			<div className="top_navOuter position-relative">
				<button className="bars" onClick={menuHandler}>
					<CImg src={menuIcon}></CImg>
				</button>
				<TopNav unRead={unRead} />
			</div>
			<CContainer fluid className="px-0">
				<div className="d-flex main-outer-csp">
					<TheSidebar />
					<div className="csp-content-outer c-wrapper pl-0">
						<TheContent
							handleCreateApplication={handleCreateApplication}
							handleCompleteApplication={handleCompleteApplication}
							applications={applications}
							getApplicationsUser={getApplicationsUser}
							user={user}
							applicationsFetched={applicationsFetched}
						/>
					</div>
				</div>
			</CContainer>
		</div>
	);
};

export default CustomerPortal;
