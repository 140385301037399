import React from "react";
import dlogo from "src/containers/logo_dark.png";

export class Login extends React.Component {
	render() {
		return (
			<section>
				<div className="base-container">
					<div className="header">
						<img src={dlogo} alt="QuadFi Logo" />
					</div>
					<div className="content">
						<div className="form">
							<div className="form-group">
								<input
									type="email"
									name="email"
									placeholder="Email"
									className="user-input"
								/>
							</div>
							<div className="form-group">
								<input
									type="password"
									name="password"
									placeholder="Password"
									className="user-input"
								/>
							</div>
						</div>
					</div>
					<div className="cta-button">
						<button type="button" className="btn">
							Log In
						</button>
					</div>
					<div className="footer">
						<a href="/">Forgot password?</a>
						<span>
							Need an account? <a href="#/Register">Sign Up Here.</a>
						</span>
					</div>
				</div>
			</section>
		);
	}
}
