import React, { useState, useEffect } from "react";
import { updateUser, userInfo, getUserApplications } from "../../services/user";
import { TheSidebar } from "./../../containers/index";
import TopNav from "./../../containers/TopNav";
import "../../style/personalinformation.scss";
import menuIcon from "../../assets/icons/menuIcon.png";

import {
	CContainer,
	CRow,
	CCol,
	CCard,
	CCardBody,
	CCardFooter,
	CFormGroup,
	CLabel,
	CInput,
	CForm,
	CButton,
	CImg,
} from "@coreui/react";
import Loader from "react-loader-spinner";
import { getLoggedUser } from "src/actions/users";
const EducationInfo = () => {
	const [hidden, setHidden] = React.useState(true);
	const [degree, setDegree] = React.useState("");
	const [applications, setApplications] = useState([]);
	const [user, setUser] = useState({});
	const [major, setMajor] = React.useState("");
	const [university, setUniversity] = React.useState("");
	const [applicationId, setApplicationId] = React.useState("");
	const [editShow, setEditShow] = React.useState(false);
	const unRead = [];
	const [loading, setLoading] = React.useState("");
	const completion = "";
	const expectedgrad = "";
	const gpa = "";
	const hideLoader = () => {
		setLoading(false);
	};

	console.log(applications);
	console.log(applicationId);
	console.log(editShow);

	const showLoader = () => {
		setLoading(true);
	};

	const handleDegree = (event) => {
		setDegree(event.target.value);
	};

	const handleMajor = (event) => {
		setMajor(event.target.value);
	};

	const handleUniversity = (event) => {
		setUniversity(event.target.value);
	};

	const handleSave = () => {
		user.education.completion = completion;
		user.education.degree = degree;
		user.education.expect_graduation = expectedgrad;
		user.education.gpa = gpa;
		user.education.major = major;
		user.education.university = university;

		let updatedInformation = {
			page: "education",
			completionDate: user.education.completion_date,
			degree: user.education.degree,
			expectedGrad: user.education.expect_graduation,
			gpa: user.education.gpa,
			major: user.education.major,
			school: user.education.university,
		};

		updateUser(sessionStorage.getItem("appId"), updatedInformation);
		setHidden(true);
	};

	useEffect(() => {
		const localFn = async () => {
			showLoader();

			// Todo: change the userId to the actual user with logs in

			let user = await getLoggedUser();
			let userApps = await getUserApplications(user.id);
			if (
				userApps.success &&
				userApps.applications &&
				userApps.applications.length > 0
			) {
				setApplications(userApps.applications);
			}

			if (
				sessionStorage.getItem("CurrentSelect") !== 0 &&
				sessionStorage.getItem("CurrentSelect") != null
			) {
				setEditShow(true);
				async function Call() {
					setApplicationId(sessionStorage.getItem("CurrentSelect"));
					showLoader();
					await userInfo(sessionStorage.getItem("CurrentSelect"), setUser);
					hideLoader();
				}
				Call();
			} else {
				const option = document.querySelectorAll(".options");

				sessionStorage.setItem("CurrentSelect", option[0].value);
				let userId = option[0].value;
				setApplicationId(option[0].value);
				showLoader();
				await userInfo(userId, setUser);
				hideLoader();
			}
		};
		localFn();
	}, []);

	const [activeCls, setActivecls] = useState(false);
	const menuHandler = () => {
		setActivecls(!activeCls);
	};

	return (
		<div className="PI_page">
			<div
				className={`c-app c-default-layout ${
					activeCls ? "sidBrexpand" : null
				}`}>
				<TheSidebar />
				<div className="c-wrapper">
					<div className="c-body">
						<CContainer fluid className="PageLayout">
							<CRow class="w-87">
								<CCol class="col-auto">
									<div className="user-name-container">
										<h1 className="p-0 pb-2">
											<b>Education</b>
										</h1>
									</div>
									<CCard>
										<div className="contain_body">
											<CCardBody>
												{!user.education ? null : (
													<CForm action="" method="post">
														<CFormGroup>
															<CLabel
																htmlFor="school"
																style={{
																	color: "#ff9e00",
																}}>
																<b>School</b>
															</CLabel>
															{hidden ? (
																<CInput
																	type="school"
																	id="school"
																	name="school"
																	disabled
																	value={user.education.university}
																	class="form-control-plaintext w-67 h-58"
																/>
															) : (
																<CInput
																	type="school"
																	id="school"
																	name="school"
																	onChange={handleUniversity}
																	class="form-control w-67 h-58"
																/>
															)}
														</CFormGroup>
														<CFormGroup>
															<CLabel
																htmlFor="degree"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Degree</b>
															</CLabel>
															{hidden ? (
																<CInput
																	type="degree"
																	id="degree"
																	name="degree"
																	disabled
																	value={user.degreeValue}
																	class="form-control-plaintext w-67 h-58"
																/>
															) : (
																<CInput
																	type="degree"
																	id="degree"
																	name="degree"
																	onChange={handleDegree}
																	class="form-control w-67 h-58"
																/>
															)}
														</CFormGroup>
														<CFormGroup>
															<CLabel
																htmlFor="major"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Major</b>
															</CLabel>
															{hidden ? (
																<CInput
																	type="major"
																	id="major"
																	name="major"
																	disabled
																	value={user.education.major}
																	class="form-control-plaintext w-67 h-58"
																/>
															) : (
																<CInput
																	type="major"
																	id="major"
																	name="major"
																	onChange={handleMajor}
																	class="form-control w-67 h-58"
																/>
															)}
														</CFormGroup>
														<CFormGroup>
															<CLabel
																htmlFor="completiondate"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Graduation Date</b>
															</CLabel>
															<CInput
																type="completiondate"
																id="completiondate"
																name="completiondate"
																disabled
																value={user.education.completion_date}
																class="form-control-plaintext w-67 h-58"
															/>
														</CFormGroup>
													</CForm>
												)}
												<div
													className={
														"load-class " +
														(!user.education ? "load_change" : "")
													}>
													{loading ? (
														<Loader
															type="ThreeDots"
															color="#ff9700"
															height={100}
															width={100}
															timeout={10000}
														/>
													) : null}
												</div>
											</CCardBody>
										</div>
										<CCardFooter>
											<CRow>
												<CButton
													shape="pill"
													className="SaveButton"
													style={
														hidden
															? {
																	visibility: "hidden",
															  }
															: {
																	visibility: "visible",
															  }
													}
													onClick={() => handleSave()}>
													Save
												</CButton>
												<CButton
													shape="pill"
													className="CancelButton"
													style={
														hidden
															? {
																	visibility: "hidden",
															  }
															: {
																	visibility: "visible",
															  }
													}
													onClick={() => setHidden(true)}>
													Cancel
												</CButton>
											</CRow>
										</CCardFooter>
									</CCard>
								</CCol>
							</CRow>
						</CContainer>
						<div className="top_navOuter position-relative">
							<button className="bars" onClick={menuHandler}>
								<CImg src={menuIcon}></CImg>
							</button>
							<TopNav unRead={unRead} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(EducationInfo);
