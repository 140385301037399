import { VOUCHED_FILE_EXTENSIONS, DOC_FILE_EXTENSIONS } from "../Utils/consts";

const allowedExts =
	process.env.ALLOWED_FILE_EXTS?.split(",") || DOC_FILE_EXTENSIONS;

const vouchedAllowedExts = VOUCHED_FILE_EXTENSIONS;

const maxAllowedFileSize = (fileType) => {
	if (fileType.includes("image")) {
		return parseInt(process.env.MAX_IMAGE_SIZE || "5", 10) * 1024 * 1024; //Default is 5 MB
	} else if (fileType.includes("pdf")) {
		return parseInt(process.env.MAX_PDF_SIZE || "5", 10) * 1024 * 1024;
	}
	return 0;
};

export const checkFileBeforeUpload = (file) => {
	if (
		!allowedExts.includes(file.type) ||
		file.size > maxAllowedFileSize(file.type)
	) {
		return { success: false, message: "File Upload Failed" };
	}
	return { success: true, message: "File can be uploaded" };
};

export const checkVouchedFileBeforeUpload = (file) => {
	if (!vouchedAllowedExts.includes(file.type)) {
		return { success: false, message: "File Upload Failed" };
	}
	return { success: true, message: "File can be uploaded" };
};
