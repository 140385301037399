import {
	CBadge,
	CButton,
	CCol,
	CContainer,
	CImg,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CProgress,
	CProgressBar,
	CRow,
} from "@coreui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getLoggedUser } from "src/actions/users";
import {
	archiveApplication,
	cancelApplication,
	checkCreateNewApp,
	getApplicationPayments,
	getApplicationTransactions,
	getOverallStatement,
	updateApp,
	updateAppProduct,
	updateUserAppFlow,
} from "src/services/user";
import { APP_STATUS_GROUPS } from "src/Utils/consts";
import book from "../assets/icons/App-Flow-Icons/bookclosed.png";
import globe from "../assets/icons/App-Flow-Icons/globe.png";
import graph from "../assets/icons/App-Flow-Icons/graph.png";
import piggyBank from "../assets/icons/piggybank.png";
import { headers } from "../reusable/headers";
import "../style/personalinformation.css";

const TheContent = ({
	handleCompleteApplication,
	user,
	applications,
	getApplicationsUser,
	handleCreateApplication,
	applicationsFetched,
}) => {
	console.log("content", applications);

	const history = useHistory();
	const [Monthlypayments, SetMonthlypayments] = useState(0);
	const [transactions, setTransactions] = useState([]);
	const [totalAmountPaid, setTotalAmountPaid] = useState(0);
	const [appProgress, setAppProgress] = useState(0);
	const [progressApps, setProgressApps] = useState([]);
	const [completeApps, setCompleteApps] = useState([]);
	const [danger, setDanger] = useState(false);
	const [itemVal, setItemVal] = useState(false);
	const [currentApplication, setCurrentApplication] = useState({});
	const [reload, setreload] = useState(false);
	const [createNewApp, setCreateNewApp] = useState(false);
	const [cancelPopup, setCancelPopup] = useState(false);
	const [warningPopup, setWarningPopup] = useState(false);
	const [warningMessage, setWarningMessage] = useState("");
	const [totalPrinciplePaid, setTotalPrinciplePaid] = useState(0);
	const [overAllStatement, setOverAllStatement] = useState([]);
	const [paymentRatio, setPaymentRatio] = useState(0);

	const archivedStatus = APP_STATUS_GROUPS.ARCHIVED.split(",");
	const archivedStatusArr = archivedStatus.map((el) => el.trim());

	useEffect(() => {
		const localFn = async () => {
			console.log(Monthlypayments);
			console.log(transactions);
			console.log(appProgress);
			try {
				if (applications.length > 0) {
					let _arr = [];
					let complete_arr = [];
					for (let app of applications) {
						if (
							(app.status_id === 1 ||
								app.status_id === 3 ||
								app.status_id === 5 ||
								app.status_id === 8 ||
								app.status_id === 10 ||
								app.status_id === 11 ||
								app.status_id === 12 ||
								app.status_id === 13 ||
								app.status_id === 14 ||
								app.status_id === 15) &&
							!archivedStatusArr.includes(app.status_id)
						) {
							if (
								app.page === "product" ||
								app.page === "purpose" ||
								app.page === "loanAmount"
							) {
								app.progressBar = 10;
							} else if (
								app.page === "BirthDate" ||
								app.page === "citizenship" ||
								app.page === "maritalStatus"
							) {
								app.progressBar = 33;
							} else if (
								app.page === "contact" ||
								app.page === "address" ||
								app.page === "livingArrangment"
							) {
								app.progressBar = 50;
							} else if (
								app.page === "residedYear" ||
								app.page === "highestDegree" ||
								app.page === "education"
							) {
								app.progressBar = 70;
							} else if (
								app.page === "employmentStatus" ||
								app.page === "otherIncome" ||
								app.page === "investment"
							) {
								app.progressBar = 80;
							} else if (
								app.page === "cosigner" ||
								app.page === "equifax" ||
								app.page === "softcheck"
							) {
								app.progressBar = 90;
							} else {
								app.progressBar = 100;
							}
							_arr.push(app);
							// setCreateNewApp(false);
						}
						if (
							(app.status_id === "16" ||
								app.status_id === "6" ||
								app.status_id === "4" ||
								app.status_id === "9") &&
							!archivedStatusArr.includes(app.status_id)
						) {
							app.progressBar = 100;
							complete_arr.push(app);
						}
					}
					if (_arr && _arr.length) {
						sessionStorage.setItem("CurrentSelect", _arr[0].appId);
					}
					setProgressApps(_arr);
					setCompleteApps(complete_arr);

					let currentApp = applications.filter(
						async (item) =>
							Number(item.status_id) > 15 && Number(item.status_id) !== 23
					);
					if (currentApp.length > 0) {
						currentApp = currentApp[0];
						currentApp.appData.plaidAllAccountsData = currentApp.appData
							.plaidPrimaryAccountData
							? JSON.parse(currentApp.appData.plaidPrimaryAccountData)
							: {};
						setCurrentApplication(currentApp);
						console.log("currentApp", currentApp);
						let paymentsResult = await getApplicationPayments(currentApp.appId);
						let overallStatementResult = await getOverallStatement(
							currentApp.appId
						);
						if (overallStatementResult.success) {
							setOverAllStatement(overallStatementResult.payments);
						}
						if (paymentsResult.success) {
							if (paymentsResult.payments.upcoming.length > 0) {
								SetMonthlypayments(paymentsResult.payments.upcoming[0].total);
							}
							const { payments } = paymentsResult;
							const { paid } = payments;
							let totalPrinciple = 0;
							if (paid.length) {
								for (let paidPayment of paid) {
									const { principal } = paidPayment;
									totalPrinciple = totalPrinciple + principal;
								}
								setTotalPrinciplePaid(totalPrinciple);
								console.log(
									">>>>>>> totalPrinciplePaid >>>>>>",
									totalPrinciple
								);
							}
						}

						let transactionResult = await getApplicationTransactions(
							currentApp.appId
						);
						if (transactionResult.success) {
							setTransactions(transactionResult.transactions);
							setTotalAmountPaid(
								(transactionResult && transactionResult.totalAmount) ||
									//eslint-disable-next-line
									transactionResult.totalAmount === NaN ||
									transactionResult.totalAmount === undefined ||
									transactionResult.totalAmount.length === 0
									? 0
									: transactionResult.totalAmount
							);
							let progress = (
								(transactionResult.totalAmount * 100) /
								parseInt(currentApp.loanAmount)
							).toFixed(1);
							console.log(
								"totalAmountPaid",
								totalAmountPaid,
								progress,
								currentApp,
								currentApp.loanAmount
							);
							setAppProgress(progress);
						}
					}
				}
			} catch (e) {
				console.log(e.message);
			} finally {
				const checkCreateNewAppAllowed = await checkCreateNewApp();
				if (checkCreateNewAppAllowed.success === true) {
					setCreateNewApp(true);
				} else {
					setCreateNewApp(false);
				}
			}
		};
		localFn();
		//eslint-disable-next-line
	}, [applications, reload]);

	//eslint-disable-next-line
	useEffect(() => {
		const ratioAmount =
			(totalPrinciplePaid / currentApplication.offerAmount) * 100;
		// const ratioAmount = (totalPrinciplePaid / currentApplication.offerAmount) * 100;
		if (ratioAmount > 0) {
			setPaymentRatio(ratioAmount.toFixed(2));
		} else {
			setPaymentRatio(0);
		}
	});

	const createNewApplication = async () => {
		try {
			var user = await getLoggedUser();
			if (localStorage.getItem("ProductSelection").length) {
				const ProductSelection = JSON.parse(
					localStorage.getItem("ProductSelection")
				);
				let updatedInformation = {
					page: "product",
				};
				await updateUserAppFlow(user.id, updatedInformation);
				await updateAppProduct({
					page: "product",
					productId: ProductSelection.id,
				}).then(async () => {
					if (sessionStorage.getItem("appId") != null) {
						var appId = sessionStorage.getItem("appId");

						if (localStorage.getItem("ProductPurpose").length) {
							const ProductPurpose = JSON.parse(
								localStorage.getItem("ProductPurpose")
							);
							let updatedInformation = {
								page: "purpose",
								appId: appId,
							};
							if (appId) {
								await updateApp(updatedInformation);
								await updateAppProduct({
									page: "purpose",
									purposeId: ProductPurpose.id,
								}).then(async () => {
									if (localStorage.getItem("LoanAmount").length) {
										let updatedInformation = {
											page: "loanAmount",
											appId: appId,
											loanAmount: Number(localStorage.getItem("LoanAmount")),
										};
										await updateApp(updatedInformation);

										let source = localStorage.getItem("source");
										let medium = localStorage.getItem("Medium");
										let campaign = localStorage.getItem("Campaign");
										let keyword = localStorage.getItem("Keyword");
										let content = localStorage.getItem("Content");

										const res = await axios.get(
											"https://geolocation-db.com/json/"
										);

										localStorage.setItem("ClientIP", res.data.IPv4);

										localStorage.setItem("source", source);
										localStorage.setItem("Medium", medium);
										localStorage.setItem("Campaign", campaign);
										localStorage.setItem("Keyword", keyword);
										localStorage.setItem("Content", content);
										localStorage.removeItem("LoanAmount");
										localStorage.removeItem("ProductPurpose");
										localStorage.removeItem("ProductSelection");
									}
								});
							}
						}
					}
				});
			}
		} catch (e) {
			return "None";
		}
	};

	useEffect(() => {
		const localFn = async () => {
			const isCallRequired = await checkCreateNewApp();
			if (isCallRequired.success === true) {
				// Todo: change the userId to the actual user with logs in
				createNewApplication().then((d) => {
					localStorage.removeItem("LoanAmount");
					localStorage.removeItem("ProductPurpose");
					localStorage.removeItem("ProductSelection");
					if (d !== "None") {
						getApplicationsUser();
					}
				});
			}

			let source = localStorage.getItem("source");
			let medium = localStorage.getItem("Medium");
			let campaign = localStorage.getItem("Campaign");
			let keyword = localStorage.getItem("Keyword");
			let content = localStorage.getItem("Content");

			const res = await axios.get("https://geolocation-db.com/json/");

			localStorage.setItem("ClientIP", res.data.IPv4);
			localStorage.setItem("source", source);
			localStorage.setItem("Medium", medium);
			localStorage.setItem("Campaign", campaign);
			localStorage.setItem("Keyword", keyword);
			localStorage.setItem("Content", content);
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	const setIcon = (product) => {
		switch (product) {
			case "Students":
				return book;
			case "Refinancing":
				return graph;
			case "International":
				return globe;
			default:
				return piggyBank;
		}
	};

	async function handleSubmitData() {
		console.log(itemVal);
		handleCancelApplication(itemVal);
		setreload(!reload);
		setItemVal(false);
		setDanger(false);
	}

	const handleCancelApplication = async (app) => {
		console.log("app.appId", app);
		let result = await cancelApplication(app.appId);
		if (result.success) {
			toast.success("Application cancelled successfully");
		}
		await getApplicationsUser();
	};

	const handleArchiveApplication = async (app) => {
		console.log("app.appId", app);
		let result = await archiveApplication(app.appId);
		if (result.success) {
			toast.success("Application archived successfully");
		}
		await getApplicationsUser();
	};

	const checkNewApplication = () => {
		handleCreateApplication().then((res) => {
			console.log(res);
			if (res.success && res.message === "ask_confirmation") {
				setCancelPopup(true);
			} else if (res.success && res.message === "allowed") {
				axios
					.get(`${process.env.REACT_APP_API_HOST}/V1/user/logout`, {
						headers: headers(),
						withCredentials: true,
					})
					.then((response) => {
						sessionStorage.clear();
						localStorage.clear();
						window.location.href = `/`;
					});
			} else if (!res.success && res.message === "not_allowed") {
				setWarningMessage(res.warningMessage);
				setWarningPopup(true);
			}
		});
	};

	const handleConfirmCancelApp = async () => {
		const cancelAppId = sessionStorage.getItem("CurrentSelect")
			? sessionStorage.getItem("CurrentSelect")
			: sessionStorage.getItem("appId");
		let result = await cancelApplication(cancelAppId);
		if (result.success) {
			toast.success("Application cancelled successfully");
			sessionStorage.removeItem("appId");
			sessionStorage.removeItem("productId");
			sessionStorage.removeItem("CurrentSelect");
			history.push("productSelection");
		}
	};

	return (
		<div className="the-content-container nw-costumer">
			{/* User Name */}

			<CModal
				className="custom-notification-sweetalert vertical-alert"
				show={danger}
				onClose={() => setDanger(!danger)}
				color="warning">
				<CModalHeader className="custom-notification-no-header" closeButton>
					<CModalTitle></CModalTitle>
				</CModalHeader>
				<CModalBody className="text-center">
					<p className="custom-notification-title">Cancel Application</p>
					<p className="custom-notification-text">
						Are you sure you want to cancel your application?
					</p>
				</CModalBody>
				<CModalFooter>
					<CButton
						className="custom-notification-btn-ok"
						color="info"
						onClick={() => handleSubmitData(!danger)}>
						Yes
					</CButton>{" "}
					<CButton
						className="custom-notification-btn-cancel"
						color="default"
						onClick={() => setDanger(!danger)}>
						No
					</CButton>
				</CModalFooter>
			</CModal>
			<CContainer>
				<CRow>
					<CCol md={12}>
						<div className="user-name-container">
							<h1>
								<b>
									Welcome back {user && user.personal && user.personal.name}!
								</b>
							</h1>
							<h4>It's good to see you again.</h4>
							{/* <CButton onClick={handleCreateApplication} block={true} className="cmplt-btn">Create Your Application</CButton> */}
						</div>
						{createNewApp ? (
							<div className="create_new">
								<button onClick={checkNewApplication}>
									Create new application
								</button>
							</div>
						) : (
							""
						)}

						{/* Info  */}
					</CCol>
					<CCol md={12}>
						<div className="user-info-app">
							<h3>
								<b>Your Info</b>
							</h3>
						</div>
					</CCol>
					<CCol md={8} className="application-leftcontent">
						{/* Summary Card */}
						<CRow>
							<CCol md={12}>
								<div className="progress-section">
									<div className="cCard sumry-title">
										{progressApps.length > 0 && (
											<div className="cHeader">
												<h6>
													<b>Current applications</b>
												</h6>
											</div>
										)}
										{progressApps.length > 0 &&
											progressApps.map((item) =>
												item.status_id === 6 || item.status_id === 25 ? (
													""
												) : (
													<div className="cMain" key={item.appId}>
														{item.status_id === 1 ? (
															<CButton
																className="float-right"
																onClick={() => {
																	setDanger(true);
																	setItemVal(item);
																}}>
																X
															</CButton>
														) : null}
														<p className="pro-title">
															<span>Application in progress:</span>
															{item.productName} Loan
														</p>
														<div className="progress-wrp">
															<CProgress className="mb-3">
																<CProgressBar value={`${item.progressBar}`} />
															</CProgress>
															{console.log(
																item.appData.status,
																"appDatastatus"
															)}
															<div className="appstatus">
																<div>
																	<span>Application status:</span>
																	<span>
																		{item.status_id === 1
																			? "In Progress"
																			: item.status_id === 3
																			? "Soft Check Processing"
																			: item.status_id === 5
																			? "Loan Offer"
																			: item.status_id === 8
																			? "Hard Check Processing"
																			: item.status_id === 10
																			? "Plaid Verification"
																			: item.status_id === 11
																			? "Pending Document Upload"
																			: item.status_id === 12
																			? "Pending Document Verification"
																			: item.status_id === 13
																			? "Document Verified"
																			: item.status_id === 14
																			? "Waiting for Loan Document Sign"
																			: item.status_id === 15
																			? "Waiting for Funding"
																			: item.status_id === 16
																			? "Funded"
																			: ""}
																	</span>
																</div>
																{(item.status_id === 1 ||
																	item.status_id === 3 ||
																	item.status_id === 5 ||
																	item.status_id === 8 ||
																	item.status_id === 10 ||
																	item.status_id === 11 ||
																	item.status_id === 12 ||
																	item.status_id === 13 ||
																	item.status_id === 14) && (
																	<CButton
																		onClick={() =>
																			handleCompleteApplication(item)
																		}
																		className="completeapp">
																		Complete your application
																	</CButton>
																)}
															</div>
														</div>
													</div>
												)
											)}

										{completeApps.length < 0 &&
											completeApps.map((item) =>
												item.status_id === 6 ? (
													""
												) : (
													<div className="cMain">
														<CButton
															className="float-right"
															onClick={() => handleArchiveApplication(item)}>
															X
														</CButton>
														<p className="pro-title">
															<span>Application in progress:</span>
															{item.productName} Loan
														</p>
														<div className="progress-wrp">
															<CProgress className="mb-3">
																<CProgressBar value={`${item.progressBar}`} />
															</CProgress>
															{console.log(
																item.appData.status,
																"appDatastatus"
															)}
															<div className="appstatus">
																<div>
																	<span>Application status:</span>
																	<span>
																		{item.status_id === 16
																			? "Funded"
																			: item.status_id === 6
																			? "Rejected"
																			: item.status_id === 4
																			? "Soft Check Process Error"
																			: item.status_id === 9
																			? "Hard Check Process Error"
																			: ""}
																	</span>
																</div>
															</div>
														</div>
													</div>
												)
											)}
									</div>
								</div>
							</CCol>
							{currentApplication &&
							currentApplication.id &&
							currentApplication.status_id > 15 &&
							currentApplication.status_id !== 25 ? (
								<>
									{console.log(currentApplication, "currentApplication")}
									<CCol md={6} className="pb-sm-3">
										<div className="cCard sumry-title">
											<div className="cHeader">
												<h6>
													<b>Summary</b>
												</h6>
											</div>
											<div className="cMain">
												<b>
													{" "}
													<p>Total borrowing</p>
												</b>
												<b>
													{" "}
													<p>
														${" "}
														{currentApplication &&
														currentApplication.offerAmount == null
															? "0"
															: currentApplication.offerAmount
																	.toString()
																	.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
													</p>
												</b>
												<div className="app-prog-container">
													<CircularProgressbar
														value={paymentRatio}
														text={`${paymentRatio}%`}
													/>
												</div>
												<b>
													{" "}
													<p>
														Current <span class="d-block">outstanding</span>
													</p>
												</b>
												<b>
													{" "}
													<p>
														${" "}
														{currentApplication &&
															(
																currentApplication.offerAmount -
																totalPrinciplePaid
															)
																.toString()
																.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
													</p>
												</b>
											</div>
										</div>
									</CCol>
								</>
							) : (
								<></>
							)}
							<CCol md={12} className="cmn-padding pb-sm-5">
								{/* Overall Card */}
								<div className="cCard">
									{currentApplication &&
										currentApplication.id &&
										currentApplication.status_id > 15 &&
										currentApplication.status_id !== 25 && (
											<div className="cHeader">
												<h6>
													<b>Upcoming</b>
												</h6>
											</div>
										)}
									{applications.map(
										(app, index) =>
											parseInt(app.status_id) > 15 && (
												<div className="cMain">
													<div>
														<CRow>
															<CCol xs={2} md={2} sm={2}>
																<CImg
																	height={50}
																	src={setIcon(app.productName)}
																/>
															</CCol>
															<CCol
																xs={10}
																md={10}
																sm={10}
																className="px-md-0 textAlig">
																<div className="persnlLoan">
																	{app.productName} Loan
																</div>
																<span>
																	XXXXXXXXXXXXXXX
																	{currentApplication &&
																		currentApplication.appData &&
																		currentApplication.appData
																			.plaidAllAccountsData &&
																		currentApplication.appData
																			.plaidAllAccountsData.accounts &&
																		currentApplication.appData
																			.plaidAllAccountsData.accounts[0] &&
																		currentApplication.appData
																			.plaidAllAccountsData.accounts[0].mask}
																</span>
																<small>
																	Currency :{" "}
																	{app &&
																		app.appData &&
																		app.appData.loan_detail.currency}{" "}
																</small>

																<small>
																	{currentApplication &&
																		currentApplication.appData &&
																		currentApplication.appData &&
																		currentApplication.appData.loan_detail &&
																		currentApplication.appData.loan_detail
																			.interest_rate}{" "}
																	%{" "}
																</small>
															</CCol>
														</CRow>
													</div>
													<div>
														<table className="mini-statement-table">
															<tr>
																<th>Payment</th>
																<th>Status</th>
																<th>Due Date</th>
															</tr>
															{typeof overAllStatement.missed !== "undefined" &&
															overAllStatement.missed.length > 0
																? overAllStatement.missed.map((missed_item) => {
																		return (
																			<tr>
																				<td>
																					$
																					{missed_item.total
																						.toString()
																						.replace(
																							/\B(?=(\d{3})+(?!\d))/g,
																							","
																						) + " "}
																				</td>
																				<td>
																					<CBadge color="danger">Missed</CBadge>
																				</td>
																				<td>
																					{missed_item.due_date.split("T")[0]}
																				</td>
																			</tr>
																		);
																  })
																: null}
															{overAllStatement?.upcoming &&
															overAllStatement.upcoming.length > 0 ? (
																<tr>
																	<td>
																		$
																		{overAllStatement.upcoming[0]["total"]
																			.toString()
																			.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
																			" "}
																	</td>
																	<td>
																		<CBadge
																			color="warning"
																			style={{ color: "#000" }}>
																			Upcoming
																		</CBadge>
																	</td>
																	<td>
																		{
																			overAllStatement.upcoming[0][
																				"due_date"
																			].split("T")[0]
																		}
																	</td>
																</tr>
															) : null}
														</table>
													</div>
												</div>
											)
									)}
								</div>
							</CCol>
						</CRow>
					</CCol>
					<CCol md={4} className="pb-sm-3"></CCol>
					<CCol></CCol>
				</CRow>
			</CContainer>

			{/* Confirmation popup to ask user for canceling application */}
			<CModal
				className="custom-notification-sweetalert vertical-alert"
				show={cancelPopup}
				onClose={() => setCancelPopup(false)}
				color="warning">
				<CModalHeader className="custom-notification-no-header" closeButton>
					<CModalTitle></CModalTitle>
				</CModalHeader>
				<CModalBody className="text-center">
					<p className="custom-notification-title">Create New Application</p>
					<p className="custom-notification-text">
						By creating new application, your current application will be
						cancelled. Are you sure! you want to proceed?
					</p>
				</CModalBody>
				<CModalFooter>
					<CButton
						className="custom-notification-btn-ok"
						color="info"
						onClick={handleConfirmCancelApp}>
						Yes
					</CButton>{" "}
					<CButton
						className="custom-notification-btn-cancel"
						color="default"
						onClick={() => setCancelPopup(false)}>
						No
					</CButton>
				</CModalFooter>
			</CModal>
			{/* Warning popup to inform user that you are not allowed to create new application */}
			<CModal
				className="custom-notification-sweetalert vertical-alert"
				show={warningPopup}
				onClose={() => setWarningPopup(false)}
				color="warning">
				<CModalHeader className="custom-notification-no-header" closeButton>
					<CModalTitle></CModalTitle>
				</CModalHeader>
				<CModalBody className="text-center">
					<p className="custom-notification-title">Warning!</p>
					<p className="custom-notification-text">
						You are allowed to create new application.
					</p>
					<p className="custom-notification-text">{warningMessage}</p>
				</CModalBody>
				<CModalFooter>
					<CButton
						className="custom-notification-btn-cancel"
						color="default"
						onClick={() => setWarningPopup(false)}>
						OK
					</CButton>
				</CModalFooter>
			</CModal>
		</div>
	);
};

export default React.memo(TheContent);
