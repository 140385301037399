import React from "react";
import LoanFlow from "../page/Application_flow";
import CustomerPortal from "./CustomerPortal";

const TheLayout = (props) => {
	const auth = false;

	return (
		<div className="overflow">
			{auth ? (
				<div className="c-app c-default-layout">
					<CustomerPortal />
				</div>
			) : (
				<div className="mzn-support">
					<LoanFlow
						page={
							props.location &&
							props.location.state &&
							props.location.state.page
						}
					/>
				</div>
			)}
		</div>
	);
};

export default TheLayout;
