import {
	CButton,
	CCol,
	CContainer,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CRow,
} from "@coreui/react";
const TermsAndConditionsSoftCheck = (props) => {
	return (
		<CModal
			show={props.show}
			size="xl"
			onClose={props.closeHandler}
			color="warning">
			<CModalHeader closeButton>
				<CModalTitle>Terms & Conditions</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CContainer>
					<CRow>
						<CCol lg="12">
							<h4
								style={{
									textDecoration: "underline",
									fontWeight: "bold",
									textAlign: "center",
								}}>
								CONSENT TO SOFT CREDIT CHECK
							</h4>
						</CCol>
						<CCol lg="12">
							<p
								style={{
									fontSize: "14px",
									color: "black",
									textAlign: "justify",
								}}>
								I hereby consent to, and accept this as written notice of,
								Quad-Fi Inc. or its affiliates (“
								<b>Quad-Fi</b>”) performing a “soft” credit inquiry for the
								purpose of determining my eligibility for a loan with Quad-Fi
								and the terms of a potential loan extended to me by Quad-Fi. I
								acknowledge that such credit information will be requested from
								Trans Union of Canada, Inc. and Equifax Canada Inc., located at
								3115 Harvester Road, Suite 201, Burlington ON and 5700 Yonge
								Street, North York, ON, respectively. I understand that this
								“soft” credit inquiry will not be reported to any third parties
								requesting my credit report and will only appear on a credit
								report that I order for my own purposes. I understand that this
								inquiry{" "}
								<b style={{ textDecoration: "underline" }}>
									will not affect my credit score
								</b>{" "}
								and will only be used for the purpose of determining my
								eligibility for a loan with Quad-Fi.
							</p>
						</CCol>
					</CRow>

					{/* MEXICO */}

					{props.country === "Mexico" ? (
						<>
							<CRow>
								<CCol lg="12">
									<h4
										style={{
											textDecoration: "underline",
											fontWeight: "bold",
											textAlign: "center",
										}}>
										Clause to use electronic means of authentication,
									</h4>
								</CCol>
								<CCol lg="12">
									<p
										style={{
											fontSize: "14px",
											color: "black",
											textAlign: "justify",
										}}>
										The parties agree that the Client may express their consent
										regarding this (contract/terms and conditions, agreement,
										etc.), as well as have access to the services offered by
										Quad-Fi Inc through electronic means such as PIN.
									</p>
								</CCol>
							</CRow>
						</>
					) : null}
				</CContainer>
			</CModalBody>
			<CModalFooter>
				<CButton color="info" onClick={() => props.closeHandler()}>
					Close
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default TermsAndConditionsSoftCheck;
