const userInbox = (state = [], action) => {
	switch (action.type) {
		case "GET_USER_INBOX":
			return [...action.payload];
		case "UPDATE_USER_INBOX":
			return state.map((item) => {
				if (item.mailId === action.payload.mailId) {
					return { ...item, isRead: true };
				}
				return item;
			});
		default:
			return state;
	}
};

export default userInbox;
