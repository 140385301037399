import { CButton, CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import logoIcon from "src/assets/icons/app-logo.png";
import infoIcon from "src/assets/icons/info.svg";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import { getUserInProgressApp, updateUserAppFlow } from "../../services/user";

const OtherIncome = (props) => {
	const inProgressApp = getUserInProgressApp();
	let preFill = { has_other_income: "", income_amount: "" };
	if (inProgressApp) {
		if (inProgressApp.appData["has_other_income"] !== undefined) {
			preFill["has_other_income"] = inProgressApp.appData.has_other_income;
		}
		if (inProgressApp.appData["other_income_amount"] !== undefined) {
			preFill["income_amount"] = inProgressApp.appData.other_income_amount
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	}
	const [hasOtherIncome, setHasOtherIncome] = React.useState(
		preFill.has_other_income
	);
	const [otherIncomeAmount, setOtherIncomeAmount] = React.useState(
		preFill.income_amount
	);

	const [keyboardClick, setKeyboardClick] = useState(1);
	const [mouseClick, setMouseClick] = useState(1);
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const { register, errors, handleSubmit, formState, getValues } = useForm({
		mode: "onChange",
	});
	const { isValid } = formState;

	const arrivalTime = new Date();

	const handleHasOtherIncome = (event) => {
		setHasOtherIncome(event.target.value);
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	const handleOtherIncomeAmount = (value) => {
		if (value) {
			const newValue = value
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			setOtherIncomeAmount(newValue.replace(/,/g, ""));
			return newValue;
		}
		return value;
	};

	const [loading, setLoading] = React.useState("");
	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	useEffect(() => {
		if (hasOtherIncome !== "" && mouseClick > 1) {
			if (hasOtherIncome === "no") {
				handleSave();
			}
		}
		//eslint-disable-next-line
	}, [hasOtherIncome, mouseClick]);

	const handleSave = async (formData) => {
		setNextButtonDisabled(true);
		let details = {
			page: "otherIncome",
			hasOtherIncome,
			otherIncomeAmount,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			setNextButtonDisabled(false);
			hideLoader();
			props.nextStep();
			await sendAnalytics(arrivalTime, STEP_PAGES.OTHER_INCOME);
		}
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		let details = {
			page: "OtherIncome",
			...getValues(),
			hasOtherIncome,
			otherIncomeAmount,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		console.log("See this response", response);
		if (response.success) {
			hideLoader();
			props.handleSaveExit();
			props.lastStep();
		}
	};

	const handleSaveAndBack = async () => {
		localStorage.setItem("PreviousPage", "YES");
		let details = {
			page: "otherIncome",
			hasOtherIncome,
			otherIncomeAmount,
		};
		updateUserAppFlow(props.user.id, details);
		if (sessionStorage.getItem("productId") === "1") {
			props.goToStep(16);
		} else {
			props.previousStep();
			await sendAnalytics(arrivalTime, STEP_PAGES.OTHER_INCOME);
		}
	};

	const handleMouseClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>Do you have another source of income?</b>
								</h1>
							</div>
							<div className="cards-container">
								<div className="icon-card">
									<input
										type="radio"
										id="yesOtherIncome"
										name="hasOtherIncome"
										value="yes"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onClick={handleHasOtherIncome}
										checked={hasOtherIncome === "yes" ? true : false}
									/>
									<label htmlFor="yesOtherIncome" className="card-label">
										{/* <img src={yesIcon} alt="" /> */}
										<h2>
											<b>Yes</b>
										</h2>
									</label>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="noOtherIncome"
										name="hasOtherIncome"
										value="no"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onClick={handleHasOtherIncome}
										checked={hasOtherIncome === "no" ? true : false}
									/>
									<label htmlFor="noOtherIncome" className="card-label">
										{/* <img src={noIcon} alt="" /> */}
										<h2>
											<b>No</b>
										</h2>
									</label>
								</div>
							</div>
							{errors.hasOtherIncome && (
								<div className="error-container">
									<p className="error-message">
										{errors[Object.keys(errors)[0]].message}
									</p>
								</div>
							)}
							{hasOtherIncome === "yes" && (
								<div className="input-container">
									<div className="no-left-padding">
										<label htmlFor="otherIncomeAmount" className="custom-label">
											<b>Annual Other Income</b>
										</label>
										<div className="amount-input">
											<input
												type="text"
												id="otherIncomeAmount"
												name="otherIncomeAmount"
												className={
													"form-control-plaintext w-67 h-58 custom-input " +
													(errors.otherIncomeAmount ? "custom-input-error" : "")
												}
												placeholder="50,000"
												ref={register({
													required: "Please enter a valid amount",
													// min: 1,
													pattern: /^[0-9.,]*$/i,
												})}
												onChange={(event) => {
													const { value } = event.target;
													event.target.value = handleOtherIncomeAmount(value);
													setKeyboardClick(keyboardClick + 1);
													localStorage.setItem("KeyBoardClick", keyboardClick);
												}}
												defaultValue={otherIncomeAmount}
												onClick={handleMouseClick}
											/>
										</div>
										{Object.keys(errors).length > 0 && (
											<div className="error-container">
												<p className="error-message">
													Please enter a valid amount
												</p>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{isValid || hasOtherIncome !== "" ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							) : (
								<button
									className="custom-button disabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							)}
							<button
								className="custom-back-button"
								onClick={handleSaveAndBack}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="info-container">
				<p className="tool-tip">
					<b>Ex: Foreign income, monthly parental contribution., etc. </b>
				</p>
				<img src={infoIcon} alt="" />
			</div>
		</section>
	);
};

export default OtherIncome;
