import styled from "styled-components";
export const Autocomplete = styled.div`
	position: relative;
	display: inline-block;
	width: 100% !important;
	.autocomplete-items {
		position: absolute;
		border: 1px solid #d4d4d4;
		border-bottom: none;
		border-top: none;
		z-index: 99;
		top: 100%;
		left: 0;
		right: 0;
	}

	.autocomplete-items div {
		padding: 10px;
		cursor: pointer;
		background-color: #fff;
		border-bottom: 1px solid #d4d4d4;
	}

	.autocomplete-active {
		background-color: DodgerBlue !important;
		color: #ffffff;
	}
	.autocomplete-items div:not(.loading):hover {
		background-color: #e9e9e9;
	}
`;
