import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StepWizard from "react-step-wizard";
import { getLoggedUser } from "src/actions/users";
import { PRODUCTID } from "src/Utils/enums";
import CustomerPortal from "../../containers/CustomerPortal";
import Address from "./Address";
import BirthDate from "./BirthDate";
import Citizenship from "./Citizenship";
import Education from "./Education";
import EducationOther from "./EducationOther";
import EmploymentStatus from "./EmploymentStatus";
import EquifaxIntro from "./Equifax/EquifaxIntro";
import EquifaxQ1 from "./Equifax/EquifaxQ1";
import EquifaxQ2 from "./Equifax/EquifaxQ2";
import HighestDegree from "./HighestDegree";
import Intro from "./Intro";
import Investment from "./Investment";
import LivingArrangment from "./LivingArrangment";
import MaritalStatus from "./MaritalStatus";
import MonthlyPayment from "./MonthlyPayment";
import Name from "./Name";
import OfferSuccess from "./OfferSuccess";
import OtherIncome from "./OtherIncome";
import PhoneNumber from "./PhoneNumber";
import PlaidConnect from "./PlaidConnect";
import PlaidWait from "./plaidWait";
import SoftCheckConsent from "./SoftCheckConsent";
import Success from "./Success";

export default function LoanFlow(props) {
	const [user, setUser] = useState({});
	const history = useHistory();

	const handleSaveExit = () => {
		history.push("/customerPortal");
	};

	useEffect(() => {
		const getUser = async () => {
			const user = await getLoggedUser();
			setUser(user);
		};
		getUser();
	}, []);

	if (parseInt(sessionStorage.getItem("productId")) === PRODUCTID.PERSONAL) {
		return (
			<StepWizard
				initialStep={props.page && props.page}
				isLazyMount={true}
				isHashEnabled={true}
				onStepChange={async (stepResponse) => {
					console.log(stepResponse, "stepResponse");
					if (stepResponse.previousStep > stepResponse.activeStep) {
						if (
							stepResponse.previousStep >= 19 &&
							stepResponse.activeStep !== 21
						) {
							window.location.href = "/customerPortal";
						}
					}
				}}>
				<Intro hashKey={"Intro"} handleSaveExit={handleSaveExit} user={user} />
				<Name hashKey={"Name"} handleSaveExit={handleSaveExit} user={user} />
				<BirthDate
					hashKey={"BirthDate"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<Citizenship
					hashKey={"Citizenship"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<MaritalStatus
					hashKey={"MaritalStatus"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<PhoneNumber
					hashKey={"PhoneNumber"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<Address
					hashKey={"Address"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<LivingArrangment
					hashKey={"LivingArrangment"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<MonthlyPayment
					hashKey={"MonthlyPayment"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<EmploymentStatus
					hashKey={"EmploymentStatus"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<OtherIncome
					hashKey={"OtherIncome"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<Investment
					hashKey={"Investment"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<HighestDegree
					hashKey={"HighestDegree"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<Education
					hashKey={"Education"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<EducationOther
					hashKey={"EducationOther"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<EquifaxIntro
					hashKey={"EquifaxIntro"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<EquifaxQ1
					hashKey={"EquifaxQ1"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<EquifaxQ2
					hashKey={"EquifaxQ2"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<SoftCheckConsent
					hashKey={"SoftCheckConsent"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<OfferSuccess
					hashKey={"OfferSuccess"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<PlaidConnect hashKey={"PlaidConnect"} user={user} stepName={"plaid"} />
				<PlaidWait
					hashKey={"plaidWait"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<Success
					hashKey={"Success"}
					handleSaveExit={handleSaveExit}
					user={user}
					stepName={"success"}
				/>
				<CustomerPortal user={user} />
			</StepWizard>
		);
	} else if (
		parseInt(sessionStorage.getItem("productId")) === PRODUCTID.NEWCOMER
	) {
		return (
			<StepWizard
				initialStep={props.page && props.page}
				isLazyMount={true}
				isHashEnabled={true}
				onStepChange={async (stepResponse) => {
					if (stepResponse.previousStep > stepResponse.activeStep) {
						if (
							stepResponse.previousStep >= 19 &&
							stepResponse.activeStep !== 21
						) {
							window.location.href = "/customerPortal";
						}
					}
				}}>
				<Intro hashKey={"Intro"} handleSaveExit={handleSaveExit} user={user} />
				<Name hashKey={"Name"} handleSaveExit={handleSaveExit} user={user} />
				<BirthDate
					hashKey={"BirthDate"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<Citizenship
					hashKey={"Citizenship"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<MaritalStatus
					hashKey={"MaritalStatus"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<PhoneNumber
					hashKey={"PhoneNumber"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<Address
					hashKey={"Address"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<LivingArrangment
					hashKey={"LivingArrangment"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<MonthlyPayment
					hashKey={"MonthlyPayment"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<EmploymentStatus
					hashKey={"EmploymentStatus"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<OtherIncome
					hashKey={"OtherIncome"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<Investment
					hashKey={"Investment"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<HighestDegree
					hashKey={"HighestDegree"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<Education
					hashKey={"Education"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<EducationOther
					hashKey={"EducationOther"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<EquifaxIntro
					hashKey={"EquifaxIntro"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<EquifaxQ1
					hashKey={"EquifaxQ1"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<EquifaxQ2
					hashKey={"EquifaxQ2"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<SoftCheckConsent
					hashKey={"SoftCheckConsent"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<OfferSuccess
					hashKey={"OfferSuccess"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<PlaidConnect hashKey={"PlaidConnect"} user={user} stepName={"plaid"} />
				<PlaidWait
					hashKey={"plaidWait"}
					handleSaveExit={handleSaveExit}
					user={user}
				/>
				<Success
					hashKey={"Success"}
					handleSaveExit={handleSaveExit}
					user={user}
					stepName={"success"}
				/>
				<CustomerPortal user={user} />
			</StepWizard>
		);
	} else {
		console.log("Invalid product id");
	}
}
