import { CButton, CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import Select from "react-select";
import logoIcon from "src/assets/icons/app-logo.png";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import {
	getCompanyData,
	getIndustries,
	getUserInProgressApp,
	updateUserAppFlow,
} from "../../../services/user";
import AutoComplete from "../../Application_flow/components/autocomplete";

const SelfEmployed = (props) => {
	const inProgressApp = getUserInProgressApp();
	let preFill = {
		employer: "",
		starting_date: null,
		job_title: "",
		annual_income: "",
		industry: "",
		globalIndustry: "",
	};
	if (inProgressApp) {
		preFill = {
			annual_income: inProgressApp.appData.employment.annual_income,
			company_name: inProgressApp.appData.employment.type_of_work,
			industry: inProgressApp.appData.employment.industry,
			starting_date: inProgressApp.appData.employment.starting_date,
			globalIndustry: {
				id: inProgressApp.appData.employment.industry,
				name: inProgressApp.appData.employment.industry,
				type: inProgressApp.appData.employment.industry,
				label: inProgressApp.appData.employment.industry
					? inProgressApp.appData.employment.industry.replace(/\b\w/g, (l) =>
							l.toUpperCase()
					  )
					: "",
			},
		};
		if (
			inProgressApp.appData.employment.annual_income !== "" &&
			inProgressApp.appData.employment.annual_income
		) {
			preFill["annual_income"] = inProgressApp.appData.employment.annual_income
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	}
	const month = new Date().getMonth() + 1;
	const maxStartMonth = `${new Date().getFullYear()}-${
		month < 10 ? "0" + month : month
	}`;
	const { register, errors, handleSubmit, formState, getValues, setValue } =
		useForm({
			mode: "onChange",
		});
	const [reasonEmployed, setReasonEmployed] = useState("");
	const [industry, setIndustry] = React.useState(preFill.industry);
	const [jobStartDate, setJobStartDate] = React.useState(
		preFill.starting_date ? new Date(preFill.starting_date) : null
	);
	const [pickerDate, setPickerDate] = useState(
		preFill.starting_date ? preFill.starting_date : null
	);

	const { isValid } = formState;
	const [typingTime, setTypingTime] = useState(new Date());
	const [stopTyping, setStopTyping] = useState(new Date());
	const [keyboardClick, setKeyboardClick] = useState(1);
	const [mouseClick, setMouseClick] = useState(1);
	const [wpm, setWpm] = useState(0);
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const [employmentExperience, setEmploymentExperience] = useState("");
	const [employedPastThreeMonths, setEmployedPastThreeMonths] = useState("");
	const [globalIndustrySuggestions, setGlobalIndustrySuggestions] = useState(
		[]
	);
	const [globalIndustry, setGlobalIndustry] = React.useState(preFill.industry);

	const jobAnnualIncome = preFill.annual_income ? preFill.annual_income : "";
	const jobCompanyName = preFill.company_name;
	const arrivalTime = new Date();
	const CompanyError = "";

	const handleBackbutton = () => {
		localStorage.setItem("PreviousPage", "YES");
		props.setEmploymentStatus("");
	};

	const handleGlobalIndustry = ({ type, value, id }) => {
		setGlobalIndustry(value);
		sessionStorage.setItem("se_industry_selected", value);
	};

	useEffect(() => {
		const localFn = async () => {
			console.log(maxStartMonth);
			console.log(industry);
			const allIndustries = await getIndustries();
			if (allIndustries) {
				let newDataArr = [];
				//eslint-disable-next-line
				allIndustries.data.map((item) => {
					let tmpRow = {};
					tmpRow["id"] = item.name;
					tmpRow["name"] = item.name.replace(/\b\w/g, (l) => l.toUpperCase());
					tmpRow["value"] = item.name;
					tmpRow["label"] = item.name.replace(/\b\w/g, (l) => l.toUpperCase());
					newDataArr.push(tmpRow);
				});
				setGlobalIndustrySuggestions(newDataArr);
			} else {
				setGlobalIndustrySuggestions([]);
			}
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	const styless = {
		option: (provided, state) => ({
			...provided,
			fontWeight: state.isSelected ? "bold" : "normal",
			color: state.data.color,
			backgroundColor: "white",
			fontSize: "20px",
			paddingLeft: "20px",
			marginLeft: "20px",
			marginRight: "200px",
		}),
	};
	const [state, setState] = useState({
		reason: "",
	});

	const setReason = (reason) => setState({ ...state, reason });

	const typeOfWork = [
		{
			id: "freelancing",
			name: "Freelancing",
			value: "freelancing",
			label: "Freelancing",
		},
		{
			id: "contract",
			name: "contract",
			value: "contract",
			label: "Contract",
		},
		{
			id: "company_owner",
			name: "Owner",
			value: "company_owner",
			label: "Owner",
		},
	];

	const handleSave = async (formData) => {
		if (formData.annualIncome) {
			formData.annualIncome = formData.annualIncome.replace(/,/g, "");
		}
		if (formData.lastReportIncome) {
			formData.lastReportIncome = formData.lastReportIncome.replace(/,/g, "");
		}
		setNextButtonDisabled(true);

		let details = {
			page: "employmentStatus",
			...formData,
			industry: globalIndustry
				? globalIndustry
				: sessionStorage.getItem("se_industry_selected"),
			employmentStatus: "self-employed",
			companyName: formData.typeOfWork,
			employmentType: reasonEmployed,
			currentTitle: reasonEmployed,
			employedPastThreeMonths: employedPastThreeMonths
				? employedPastThreeMonths
				: sessionStorage.getItem("employedPastThreeMonths"),
			employmentExperience: employmentExperience
				? employmentExperience
				: sessionStorage.getItem("employmentExperience"),
			startingDate: pickerDate ? pickerDate : preFill.starting_date,
			lastReportIncome: formData?.lastReportIncome
				? formData.lastReportIncome
				: null,
		};
		updateUserAppFlow(props.userId, details);
		props.steps.nextStep();
		await sendAnalytics(arrivalTime, STEP_PAGES.SELF_EMPLOYMENT_STATUS);
	};

	const handleAnnulIncome = (value) => {
		const regex = /^[0-9.,]*$/i;
		if (value && regex.test(value)) {
			const newValue = value
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			console.log(newValue);
			return newValue;
		} else {
			return value.slice(0, -1);
		}
	};

	const handleSaveAndExit = () => {
		localStorage.setItem("DropOff", "YES");

		let details = {
			page: "employmentStatus",
			...getValues(),
		};
		updateUserAppFlow(props.userId, details);
		props.steps.lastStep();
	};
	const handleSelfEmployed = ({ type, value, id }) => {
		console.log("value", value);
		setReason(value);
		setReasonEmployed(value);
	};

	const [suggestions, setSuggestions] = useState([]);
	const [suggestionsLoading, setSuggestionsLoading] = useState(false);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const handleCompanyName = (e) => {
		getSuggestions(e.target.value);

		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);

		var iTotal = 0;
		var iKeys = 0;
		var iWords = 0;

		setTypingTime(new Date());

		if (stopTyping !== 0) {
			iKeys++;

			iTotal += Math.abs(Math.round(typingTime - stopTyping) / 1000 / 60);
			iWords = e.target.value.split(/\s/).length;
			let CPM = ((iKeys / iTotal) * 6000, 2);
			let WPM = ((iWords / iTotal) * 6000, 2);

			console.log("CPM" + CPM + "WPM" + WPM);
			setWpm(WPM);
			console.log("words " + iWords);
			localStorage.setItem("WPM", wpm);
		}

		setStopTyping(new Date());
	};

	const closeSuggestionBox = () => {
		setShowSuggestions(false);
		setSuggestions([]);
		setSuggestionsLoading(false);
	};

	let suggestionTimeout;

	const getSuggestions = (text = "") => {
		if (!text || text.length < 3) {
			return closeSuggestionBox();
		}
		clearTimeout(suggestionTimeout);
		//For handling concurrent on change
		setShowSuggestions(true);
		setSuggestionsLoading(true);
		suggestionTimeout = setTimeout(() => {
			fetchSuggestions(text);
		}, 300);
	};

	const fetchSuggestions = (text = "") => {
		fetchData(text);
	};

	const fetchData = async (text) => {
		try {
			const data = await getCompanyData(text);
			setSuggestionsLoading(false);
			setSuggestions(data.data);
		} catch (err) {
			setSuggestionsLoading(false);
			setSuggestions([]);
		}
	};

	const companySelectionHandler = (item) => {
		closeSuggestionBox();
		setValue("typeOfWork", item.name);
		setIndustry(item.meta.industry);
	};

	const handleDateChange = (event) => {
		const currentDate = new Date();
		const checkDate = new Date(event);
		const employmentExp =
			currentDate.getMonth() -
			checkDate.getMonth() +
			12 * (currentDate.getFullYear() - checkDate.getFullYear());
		if (employmentExp >= 0) {
			setEmploymentExperience(parseInt(employmentExp) + 1);
			sessionStorage.setItem(
				"employmentExperience",
				parseInt(employmentExp) + 1
			);
		} else {
			setEmploymentExperience(0);
			sessionStorage.setItem("employmentExperience", 0);
		}
		if (parseInt(employmentExp) + 1 > 3) {
			setEmployedPastThreeMonths("yes");
			sessionStorage.setItem("employedPastThreeMonths", "yes");
		} else {
			setEmployedPastThreeMonths("no");
			sessionStorage.setItem("employedPastThreeMonths", "no");
		}

		setJobStartDate(event);
		var today = new Date(event);
		var mm = String(today.getMonth() + 1).padStart(2, "0");
		var yyyy = today.getFullYear();
		setPickerDate(yyyy + "-" + mm);
	};

	const empArrangmentOptions = [
		{
			id: "yes",
			name: "Yes",
			value: "yes",
			label: "Yes",
		},
		{
			id: "no",
			name: "no",
			value: "no",
			label: "No",
		},
	];

	const handleExperience = ({ type, value, id }) => {
		setEmployedPastThreeMonths(value);
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};
	console.log("Mouse Click => " + mouseClick);

	return (
		<section className="eduction-new">
			<CProgress
				color="stack-overflow"
				value={(props.steps.currentStep / props.steps.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>Tell us about your employment</b>
								</h1>
								<p
									className="title-page-note"
									style={{ margin: 0, fontWeight: 600 }}>
									If you cannot find your company in the search bar, please
									simply click on "No Result found!" and type manually.
								</p>
							</div>
							{/* <form onSubmit={handleSubmit(handleSave)}> */}
							<div className="address-input-container">
								{/* Here we are recording the type of employment 
                                    from the EmploymentStatus page
                                    in a hidden input
                                    */}
								<input
									type="hidden"
									name="Emp-Status"
									ref={register}
									value="self-employed"
								/>
								<div className="input-container">
									<div className="edubox">
										<label htmlFor="companyName" className="custom-label">
											<b>How are you self employed</b>
										</label>

										<div
											id="nf-school"
											name="reason"
											class="form-control-plaintext  custom-input custom-input-container">
											<Select
												placeholder="Please select an option"
												options={typeOfWork}
												styles={styless}
												onChange={(event) => handleSelfEmployed(event)}
												name="reason"
											/>
										</div>
									</div>

									<div className="edubox">
										<label htmlFor="annualIncome" className="custom-label">
											<b>Annual Income</b>
										</label>
										<div className="amount-input">
											<input
												type="text"
												id="annualIncome"
												name="annualIncome"
												onChange={(event) => {
													const { value } = event.target;
													event.target.value = handleAnnulIncome(value);

													setKeyboardClick(keyboardClick + 1);
													localStorage.setItem("KeyBoardClick", keyboardClick);
												}}
												onClick={handleClick}
												className={
													"form-control-plaintext w-67 h-58 custom-input " +
													(errors.annualIncome ? "custom-input-error" : "")
												}
												defaultValue={jobAnnualIncome}
												placeholder="50,000"
												maxLength="15"
												ref={register({
													required: "Please enter proper value",
												})}
											/>
										</div>
									</div>
								</div>
								<div className="input-container">
									<div className="edubox">
										<label htmlFor="companyName" className="custom-label">
											<b>Company name</b>
										</label>

										<AutoComplete
											loading={suggestionsLoading}
											data={suggestions}
											show={showSuggestions}
											selectionHandler={companySelectionHandler}>
											<input
												type="text"
												name="typeOfWork"
												onBlur={() => setTimeout(closeSuggestionBox, 300)}
												autoComplete={"new-field-1"}
												className={
													"custom-input search-input" +
													(errors.companyName ? "custom-input-error col-8" : "")
												}
												onChange={(e) => handleCompanyName(e)}
												onClick={handleClick}
												placeholder="XYZ Company"
												defaultValue={jobCompanyName}
												ref={register({
													required: true,
													pattern:
														/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/,
												})}
											/>
										</AutoComplete>

										<span>{CompanyError}</span>
									</div>
									<div className="edubox lg-edubox">
										<label htmlFor="industry" className="custom-label">
											<b>Industry</b>
										</label>
										<div className="form-control-plaintext custom-input custom-input-container">
											<Select
												placeholder="Please select an option"
												styles={styless}
												options={globalIndustrySuggestions}
												name="globalIndustry"
												onChange={(event) => handleGlobalIndustry(event)}
												defaultValue={preFill.globalIndustry}
												required
												isSearchable={false}
											/>
										</div>
									</div>
								</div>
								<div className="input-container">
									<div className="edubox w-100">
										<label className="custom-label">
											<b>Start Date</b>
										</label>
										<br />
										<div class="form-control-plaintext w-67 h-58 custom-input third-datepicker">
											<DatePicker
												name="startingDate"
												minDate={new Date("1 January, 1970")}
												maxDate={new Date()}
												selected={jobStartDate}
												onClick={handleClick}
												onChange={(date) => handleDateChange(date)}
												dateFormat="yyyy-MM"
												showMonthYearPicker
												showFullMonthYearPicker
												onChangeRaw={(e) => e.preventDefault()}
											/>
										</div>
										<span class="validity"></span>
									</div>
								</div>
								<div className="input-container">
									<div className="edubox w-100">
										<label htmlFor="lastReportIncome" className="custom-label">
											<b>
												What was your last reported Total Income on your latest
												Notice of Assessment from the CRA?
											</b>
										</label>

										<br />
										{/* <div class="form-control-plaintext w-67 h-58 custom-input"> */}
										<div className="amount-input">
											<input
												type="text"
												id="lastReportIncome"
												name="lastReportIncome"
												onChange={(event) => {
													const { value } = event.target;
													event.target.value = handleAnnulIncome(value);

													setKeyboardClick(keyboardClick + 1);
													localStorage.setItem("KeyBoardClick", keyboardClick);
												}}
												onClick={handleClick}
												className={
													"form-control-plaintext w-67 h-58 custom-input " +
													(errors.lastReportIncome ? "custom-input-error" : "")
												}
												placeholder="50,000"
												ref={register({
													required: true,
													pattern: /^[0-9.,]*$/i,
												})}
											/>
											<p>
												<b>Note:-</b> if we offer you a loan, you will be asked
												to upload your latest Notice of Assessment from the CRA.
											</p>
										</div>
										<span class="validity"></span>
									</div>
								</div>
								{employmentExperience > 0 && employmentExperience <= 3 ? (
									<div className="input-container w-100">
										<div className="edubox w-100">
											<label className="custom-label">
												<b>
													It seems your current employment arrangement is short.
													Have you been employed during the past three months
													including current and other employers or as
													self-employed?
												</b>
											</label>
											<div
												id="employment_experience"
												name="employment_experience"
												class="form-control-plaintext custom-input custom-input-container">
												<Select
													placeholder="Please Select an option"
													styles={styless}
													options={empArrangmentOptions}
													name="employment_experience"
													onChange={(event) => handleExperience(event)}
													defaultValue={empArrangmentOptions[1]}
												/>
											</div>
										</div>
									</div>
								) : null}
							</div>
							{Object.keys(errors).length > 0 && (
								<div className="error-container">
									<p className="error-message">Please enter a valid input</p>
								</div>
							)}

							{/* </form> */}
						</div>
						<div className="load-class"></div>
						<div className="button-container button-container-margin">
							{isValid &&
							pickerDate &&
							state.reason !== "" &&
							globalIndustry ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							) : (
								<button
									className="custom-button disabled"
									disabled={nextButtonDisabled}
									//disabled
								>
									Next
								</button>
							)}
							<button className="custom-button" onClick={handleBackbutton}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default SelfEmployed;
