import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getAllProductsSuccess = (response) => {
	return {
		type: actionTypes.ALL_PRODUCTS,
		payload: {
			response,
		},
	};
};

export const GetAllProducts = () => async (dispatch) => {
	try {
		await axios
			.get(`${process.env.REACT_APP_API_HOST}/V1/user/getProduct`)
			.then((response) => {
				console.log("Log ", response);
				dispatch(getAllProductsSuccess(response.data));
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const createError = (error) => {
	console.log("error", error);
	if (error && error.response && error.response.status === 401) {
		clearSession();
	}

	return function (dispatch) {
		setTimeout(function () {
			dispatch(clearError());
		}, 2000);
		dispatch({
			type: actionTypes.GET_RESPONSE_ERROR,
			payload: error,
		});
	};
};

export const clearError = () => {
	console.log("clear error");
	return function (dispatch) {
		dispatch({
			type: actionTypes.CLEAR_ERROR,
		});
	};
};

const clearSession = () => {
	sessionStorage.clear();
	window.location.href = "/signin";
};
