import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from "@coreui/react";
import { passwordStrength } from "check-password-strength";
import { useState } from "react";
import { hotjar } from "react-hotjar";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import logoIcon from "src/assets/icons/app-logo.png";
import TermsAndConditions from "src/Components/OfferSuccess/TermsAndConditionsRegistration";
import store from "src/store";
import { STEP_PAGES } from "src/Utils/enums";
import { getLoggedUser, SignupUser } from "../../actions/users";
import { getAnalysisData } from "../../services/analysis";
import {
	getUserLatestApplication,
	updateAppProductRegister,
	updateAppRegister,
	updateUserAppFlowRegister,
} from "../../services/user";

const Register = (props) => {
	// Add State / Functionality
	const history = useHistory();
	const [formData, setFormData] = useState({});
	const [passwordAdded, setPasswordAdded] = useState(false);
	const [errors, setErrors] = useState({});
	const [displayTermsAndConditions, setDisplayTermsAndConditions] =
		useState(false);
	const [danger, setDanger] = useState(false);
	const [strength, setStrength] = useState(false);
	const [strongPasswordEntered, setStrongPasswordEntered] = useState(false);

	const isEmail = (email) => {
		if (/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(email)) {
			return true;
		}
		return false;
	};

	const checkErrors = () => {
		if (!formData.email || formData.email === "") {
			errors.email = "Email is required";
			setErrors({ ...errors, email: "Email is required" });
		} else if (!isEmail(formData.email)) {
			errors.email = "Enter a valid email.";
			setErrors({ ...errors, email: "Enter a valid email." });
		} else {
			delete errors.email;
			setErrors({ ...errors, email: "" });
		}
		if (!formData.password || formData.password === "") {
			errors.password = "Password is required";
			setErrors({ ...errors, password: "Password is required" });
		} else if (formData.password.length < 8) {
			errors.password = "Password should be of minimum 8 characters.";
			setErrors({
				...errors,
				password: "Password should be of minimum 8 characters.",
			});
		} else {
			delete errors.password;
			setErrors({ ...errors, password: "" });
		}
		if (!formData.termsChecked) {
			errors.terms = "Please accept the terms and conditions.";
			setErrors({
				...errors,
				terms: "Please accept the terms and conditions.",
			});
		} else {
			delete errors.terms;
			setErrors({ ...errors, terms: "" });
		}
	};

	async function handleSubmit() {
		checkErrors();

		if (
			sessionStorage.getItem("productId") &&
			sessionStorage.getItem("purposeId")
		) {
			if (!errors || Object.keys(errors).length === 0) {
				// delete formData.termsChecked
				localStorage.setItem("Consent", new Date().toUTCString());
				let registerResponse = await props.SignupUser(formData);

				const loggedUser = await getLoggedUser();
				const client_user_id = registerResponse
					? registerResponse.user.id
					: loggedUser.id;

				console.log("client_user_id", client_user_id);

				if (store.getState().users.success) {
					// Updating Product information
					let updatedInformation = {
						page: "product",
					};
					const result = await updateUserAppFlowRegister(
						store.getState().users.currentUser.user.id,
						updatedInformation
					);
					await updateAppProductRegister(
						{
							page: "product",
							productId: JSON.parse(localStorage.getItem("ProductSelection"))
								.id,
						},
						result.user.appId
					);

					//Trigger hotjar
					const userAppData = await getUserLatestApplication();
					if (
						typeof userAppData.application !== "undefined" &&
						userAppData.application
					) {
						hotjar.identify(loggedUser.email, {
							loanId: userAppData.application.loan_id,
							email: loggedUser.email,
						});
					}

					if (localStorage.getItem("productSelection_prev_page")) {
						let selectionData = JSON.parse(
							localStorage.getItem("productSelection_prev_page")
						);
						selectionData.appId = result.user.appId;
						await getAnalysisData(selectionData);
					}
					if (localStorage.getItem("productPurpose_prev_page")) {
						let purposeData = JSON.parse(
							localStorage.getItem("productPurpose_prev_page")
						);
						purposeData.appId = result.user.appId;
						await getAnalysisData(purposeData);
					}
					if (localStorage.getItem("loanAmount_prev_page")) {
						let amountData = JSON.parse(
							localStorage.getItem("loanAmount_prev_page")
						);
						amountData.appId = result.user.appId;
						await getAnalysisData(amountData);
					}

					// Save PR Details
					if (JSON.parse(localStorage.getItem("ProductSelection")).id === 3) {
						if (sessionStorage.getItem("prDetails")) {
							let pr_details = {
								page: STEP_PAGES.PR_DETAILS,
								appId: result.user.appId,
								PrDetails: sessionStorage.getItem("prDetails"),
							};
							await updateAppRegister(pr_details);
						}
					}

					//updatlaing purpose information
					let updatedPurposeInformation = {
						page: "purpose",
						appId: result.user.appId,
					};
					await updateAppRegister(updatedPurposeInformation);
					await updateAppProductRegister(
						{
							page: "purpose",
							purposeId: JSON.parse(localStorage.getItem("ProductPurpose")).id,
						},
						result.user.appId
					);

					//update loan amount information
					let updatedLoanInformation = {
						page: "loanAmount",
						appId: result.user.appId,
						loanAmount: localStorage.getItem("LoanAmount"),
						ucDetails: {
							countryDetails: sessionStorage.getItem("countryDetails"),
							uc_input: sessionStorage.getItem("uc_input"),
						},
					};
					await updateAppRegister(updatedLoanInformation);
					sessionStorage.setItem("appId", result.user.appId);
					sessionStorage.setItem(
						"productId",
						JSON.parse(localStorage.getItem("ProductSelection")).id
					);

					history.push("layout");
				}
				// history.push(`productSelection`);
			}
		} else {
			setDanger(true);
		}
	}

	const checkPasswordStrength = (passwordValue) => {
		const passwordStrengthScore = passwordStrength(passwordValue);
		console.log(passwordStrengthScore);
		setStrength(passwordStrengthScore);
		if (passwordStrengthScore.id >= 3 && passwordStrengthScore.length >= 10) {
			setStrongPasswordEntered(true);
		} else {
			setStrongPasswordEntered(false);
		}
	};

	// Render
	return (
		<section>
			{localStorage.getItem("ProductSelection") &&
			localStorage.getItem("ProductPurpose") &&
			localStorage.getItem("LoanAmount") ? (
				<div
					className="save-exit-button-container"
					onClick={() => {
						localStorage.clear();
						sessionStorage.clear();
						document.location.href = "/";
						setDanger(false);
					}}
					style={{ position: "absolute", top: 15, right: 15, width: "auto" }}>
					<a
						style={{
							display: "inline-block",
							padding: "5px 10px",
							cursor: "pointer",
						}}
						className="save-exit-button"
						href="#restart-application">
						{" "}
						Restart Application{" "}
					</a>
				</div>
			) : null}
			<div className="base-container sign-in-box">
				{localStorage.getItem("ProductSelection") &&
				localStorage.getItem("ProductPurpose") &&
				localStorage.getItem("LoanAmount") ? (
					<p className="signup-header-text">
						To save your application and protect your data, please create an
						account below.
					</p>
				) : null}
				<div className="header">
					<img src={logoIcon} alt="QuadFi Logo" />
				</div>
				<div className="content">
					<div className="form">
						<div className="form-group">
							<input
								type="email"
								name="email"
								placeholder="Email Address"
								className="user-input"
								value={formData.email}
								onChange={(e) =>
									setFormData({
										...formData,
										email: e.target.value,
									})
								}
							/>
							<p>{errors.email}</p>
						</div>
						<div className="form-group">
							<input
								type="password"
								name="password"
								placeholder="Password"
								className="user-input"
								value={formData.password}
								onChange={(e) => {
									setFormData({
										...formData,
										password: e.target.value,
									});
									setPasswordAdded(true);
									checkPasswordStrength(e.target.value);
								}}
							/>
							{passwordAdded ? (
								<div className="password-checker-container">
									<p className="password-checker-text">
										Password must contain the following:
									</p>
									<ul className="password-checker-list">
										<li
											class={
												strength.contains.includes("lowercase") ? "valid" : ""
											}>
											A lowercase letter{" "}
											<span className="fa fa-check-circle"></span>
										</li>
										<li
											class={
												strength.contains.includes("uppercase") ? "valid" : ""
											}>
											A capital (Uppercase) letter{" "}
											<span className="fa fa-check-circle"></span>
										</li>
										<li
											class={
												strength.contains.includes("number") ? "valid" : ""
											}>
											A number <span className="fa fa-check-circle"></span>
										</li>
										<li
											class={
												strength.contains.includes("symbol") ? "valid" : ""
											}>
											A Symbol <span className="fa fa-check-circle"></span>
										</li>
										<li class={strength.length >= 10 ? "valid" : ""}>
											Minimun 10 characters{" "}
											<span className="fa fa-check-circle"></span>
										</li>
									</ul>
								</div>
							) : null}
							<p>{errors.password}</p>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="checkbox"
								id="termsCheck"
								value={formData.termsChecked}
								onChange={(e) =>
									setFormData({
										...formData,
										termsChecked: e.target.checked,
									})
								}
							/>
							<label class="form-check-label" for="termsCheck">
								I agree to the{" "}
								<a
									//eslint-disable-next-line
									href="#terms-and-conditions"
									onClick={() => setDisplayTermsAndConditions(true)}>
									Terms and Conditions.
								</a>
								<p>{errors.terms}</p>
							</label>
						</div>
					</div>
				</div>
				<TermsAndConditions
					show={displayTermsAndConditions}
					closeHandler={() => setDisplayTermsAndConditions(false)}
				/>

				<div className="cta-button">
					{!strongPasswordEntered || !formData.termsChecked ? (
						<button type="button" className="btn custom-button" disabled>
							Get Started
						</button>
					) : (
						<button
							type="button"
							className="btn custom-button"
							onClick={() => handleSubmit()}>
							Get Started
						</button>
					)}
				</div>
				<div className="footer">
					{/* <a href="#">Forgot password?</a> */}
					<span>
						Already have an account? <Link to="/signin">Sign In Here.</Link>
					</span>
				</div>
			</div>

			<CModal
				className="custom-notification-sweetalert vertical-alert"
				show={danger}
				onClose={() => setDanger(false)}
				color="warning">
				<CModalHeader className="custom-notification-no-header" closeButton>
					<CModalTitle></CModalTitle>
				</CModalHeader>
				<CModalBody className="text-center">
					{/* <p className="custom-notification-title">Warning!</p> */}
					<p className="custom-notification-text warning-modal-text">
						To create an account with Quadfi, you need to start an application.
						To start an application, please click on{" "}
						<span>"Start Application"</span>
					</p>
				</CModalBody>
				<CModalFooter>
					<CButton
						className="custom-notification-btn-ok pad-btn"
						color="info"
						onClick={() => {
							localStorage.clear();
							sessionStorage.clear();
							document.location.href = "/";
							setDanger(false);
						}}>
						Start Application
					</CButton>
				</CModalFooter>
			</CModal>
		</section>
	);
};

const mapStateToProps = ({ users }) => ({
	data: users,
});

export default withRouter(connect(mapStateToProps, { SignupUser })(Register));
