import { CCol, CRow } from "@coreui/react";
import React from "react";
import logoIcon from "src/assets/icons/app-logo.png";
import { QUADFI_PRIVACY_POLICY_URL } from "src/Utils/consts";
const TermsOfUse = (props) => {
	return (
		<section className="product-wrappers">
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div className="product-sectionOuter no-scroll">
					<div className="content-container">
						<div className="text-container">
							<h1 className="product-title">
								<b>Terms of use</b>
							</h1>
						</div>
						<div className="purpose-row">
							<CRow class="w-87">
								<CCol lg="12">
									<p
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										Thank you for using quadfi.com (the “
										<strong>Website</strong>”), which is owned and operated by
										Quad-Fi Inc. or its affiliates (“
										<strong>Quad-Fi</strong>”, “<strong>we</strong>,” or “
										<strong>us</strong>”) in order to provide our services (“
										<strong>Services</strong>”) to you (“
										<strong>Borrower</strong>”, “<strong>you</strong>”, or “
										<strong>your</strong>”). By accessing the Website you
										acknowledge you have read, understand and agree to accept
										and adhere to the following terms of use (the “
										<strong>Terms of Use</strong>”). If you do not agree with
										the Terms of Use, you must not access or use the Website.
										Use of the Website outside of the Province of Ontario, by
										any person who had not attained the age of 18, or by any
										person, that is not a natural person is expressly
										prohibited. If you are located outside of Ontario or if you
										have not attained the age of majority in Ontario, you must
										not use the Website.
									</p>
								</CCol>
								<CCol className="pt-5" lg="12">
									<h4>CONSENT TO DOING BUSINESS ELECTRONICALLY</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										You agree that we may deliver to you all notices,
										disclosures and other documents by means of email, by
										posting on the Website or to your account with us, or by
										other means of electronic communication. By accessing the
										Website, you consent to transact business electronically and
										to receive electronically all notices, disclosures and other
										documents relating to you and Quad-Fi. You must inform us of
										any changes in your email so that you continue to receive
										all notices without interruption. We may choose to deliver
										notices, disclosures and other documents to you by regular
										mail, registered mail or courier. You must inform us of any
										changes in your mailing address so that you continue to
										receive all notices without interruption.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>CONSENT FOR CREDIT CHECKS</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										If you apply for a loan using the Website, you will be asked
										to provide consent to Quad-Fi obtaining a credit report
										and/or a credit score from a credit reporting agency. By
										providing such consent, you consent to Quad-Fi and any of
										its service providers, affiliates or agents obtaining and
										using your credit and other personal information (including
										credit reports) from any credit reporting agency, and
										exchanging information with any credit reporting agency, on
										an ongoing basis. You acknowledge that this consent is being
										obtained to determine your eligibility for a loan, and, if
										approved, to open and administer your loan, review and
										verify your ongoing creditworthiness, and manage and assess
										risk. You authorize any credit reporting agency to provide
										this information to Quad-Fi and any of its service
										providers, affiliates or agents.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>LIMITED LICENSE</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										All content on the Website, including but not limited to
										designs, text, graphics, pictures, video, information,
										software, music, sound and other files, and their selection
										and arrangement (the “<b>Website Content</b>”), are the
										proprietary property of Quad-Fi with all rights reserved. No
										Website Content may be modified, copied, distributed,
										framed, reproduced, republished, downloaded, displayed,
										posted, transmitted, or sold in any form or by any means, in
										whole or in part, without Quad-Fi's prior written
										permission, except as provided in the following sentence.
										Provided that you are eligible for use of the Website, you
										are granted a limited licence to access and use the Website
										and to download or print a copy of any portion of the
										Website Content solely for your own use in connection with
										your use of the Website or our Services, provided that you
										keep all copyright or other proprietary notices intact. You
										may not republish any Website Content on any Internet,
										Intranet or Extranet site or incorporate the information in
										any other database compilation, and any other use of the
										Website Content is strictly prohibited. Any use of the
										Website or any of the Website Content other than as
										specifically authorized herein, without the prior written
										permission of Quad-Fi, is strictly prohibited and will
										terminate the licence granted herein. Such unauthorized use
										may also violate applicable laws including without
										limitation copyright and trademark laws and applicable
										communications regulations and statutes. You agree that you
										will not use any device, software or routine to interfere or
										attempt to interfere with the proper working of the Website
										or any transaction being conducted on the Website. Unless
										explicitly stated herein, nothing in these Terms of Use
										shall be construed as conferring any licence to intellectual
										property rights, whether by estoppel, implication or
										otherwise. This licence is revocable by us at any time
										without notice and with or without cause.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>CONFIDENTIALITY</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										You will be required to create an account to access certain
										parts of the Website, including your loan. Upon
										registration, you will choose a username and password and
										may be asked to provide other distinctive information, such
										as personal information or account recovery questions
										(collectively, “<b>Access Credentials</b>”). You are
										responsible for maintaining the confidentiality of Access
										Credentials associated with any account you use to access
										the Website, as well as for all activities that occur under
										your account. You agree to immediately notify Quad-Fi of any
										unauthorized use of your login information or any other
										breach of security. Accessing (or attempting to access) the
										Website by any means other than through the means we provide
										is strictly prohibited and you agree not to access (or
										attempt to access) the Website through any automated or
										alternative means without our express prior written consent,
										which may be withheld in our absolute discretion. You are
										solely responsible for any consequences, losses, or damages
										that we may directly or indirectly incur or suffer due to
										any unauthorized activities conducted by you or any loss or
										disclosure of your Access Credentials.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>LOAN PRE-REGISTRATIONS AND APPLICATIONS</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										You may be presented with the opportunity to pre-register
										and/or apply for a loan through the Website. When
										pre-registering or applying for a loan on the Website, you
										agree to provide current, complete and accurate information
										about yourself. If any information you provide is untrue,
										inaccurate, not current, incomplete or otherwise misleading,
										Quad-Fi has the right to terminate your loan
										pre-registration and/or application and to decline to
										provide any and all future use of the Website. We reserve
										the right to decline your application for a loan.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>LINKS TO THIRD-PARTY SITES</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										The Website may contain links to websites controlled, owned,
										and operated by third parties (the “<b>third-party sites</b>
										”). Quad-Fi has no responsibility for the accuracy or
										availability of information provided on the third-party
										sites. The links which we may place on the Website do not
										imply that we sponsor, endorse or are affiliated or
										associated with, or have been legally authorized to use any
										trademark, trade name, servicemark, design, logo, symbol or
										other copyrighted materials displayed on or accessible
										through such sites. You acknowledge that use of any
										third-party sites is governed by the terms of use for those
										websites, and not by the Terms of Use. You acknowledge sole
										responsibility for, and assume all risk arising from, your
										use of any third-party websites or resources.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>PROMOTIONAL OFFERS</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										In connection with your use of the Website, we may send you
										marketing offers about third party financial products or
										services that may be of interest to you (“Third Party
										Offers”). Quad-Fi makes no representations or guarantees
										regarding your eligibility or qualification for such Third
										Party Offers. If you have any questions regarding any Third
										Party Offers, you must contact the third party directly. You
										understand and agree that Quad-Fi is merely providing you
										with information on Third Party Offers that may be of
										interest to you, and that Quad-Fi has no control over or
										responsibility for the Third Party Offers or any loss that
										you may suffer as a result of the use of, or your inability
										to avail yourself of, such Third Party Offers. You should
										exercise your own judgment and obtain any expert advice you
										consider necessary prior to entering into an arrangement
										with a third party.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>LIMITATION OF LIABILITY</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										Under no circumstances will Quad-Fi be liable for any
										damages including general, special, direct, indirect,
										incidental, consequential, punitive or any other damages
										(including, without limitation, lost profits, lost or
										damaged data, business interruption or debt servicing costs)
										of any kind whether in an action in contract, negligence or
										any other legal theory, arising or relating in any way to
										the use or inability to use by any party of the Website, our
										Services or any third-party Website to which the Website is
										linked, or in connection with any failure of performance,
										error, omission, interruption, defect, delay in operation or
										transmission, computer virus or line or system failure,
										computer hacking, even if Quad-Fi, or representatives
										thereof, are advised of the possibility of such damages,
										losses or expenses. Your sole and exclusive remedy is to
										stop using the Website and cease to use our Services. If
										your use of the Website results in the need for servicing,
										repair or correction of equipment or data, you accept such
										result and assume any costs thereof. If the foregoing
										limitation is found to be invalid, you agree that the total
										liability of Quad-Fi for all damages, losses, or causes of
										action of any kind or nature shall be limited to the
										greatest extent permitted by applicable law.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>LIMITATION OF WARRANTIES</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										The Website is provided on an “As-Is” or “As-Available”
										basis, and Quad-Fi expressly disclaims all warranties and
										conditions of any kind, whether express or implied,
										including, but not limited to, any implied warranties and
										conditions of merchantability, fitness for any particular
										purpose or non-infringement. Quad-Fi does not warrant: (i)
										that the Website will function on an uninterrupted or
										error-free basis, or be free of viruses or other harmful
										components; or (ii) the accuracy, completeness, reliability
										or currency of the Website Content.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>INDEMNIFICATION</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										You agree to indemnify and hold Quad-Fi, its subsidiaries
										and affiliates, and each of their directors, officers,
										agents, contractors, service providers and employees,
										harmless from and against any loss, liability, claim,
										demand, damages, costs and expenses, including reasonable
										legal fees, arising out of or in connection with your use of
										the Website or our Services, or any violation of the Terms
										of Use or of any law or the rights of any third-party. In
										the event that this indemnity is unavailable for any reason,
										you agree to contribute to such loss in full.
									</p>
								</CCol>
								<CCol className="pt-5" lg="12">
									<h4>NO ADVICE</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										Certain information on the Website is for informational
										purposes only. Nothing on the Website constitutes financial,
										legal, tax or other advice to you and should not be relied
										upon by you in that regard.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>PRIVACY</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										Quad-Fi's privacy policy (the “<b>Privacy Policy</b>”) is
										available at&nbsp;
										<a href={QUADFI_PRIVACY_POLICY_URL}>
											https://quadfi.com/privacy-policy/
										</a>
										. By visiting the Website and using our Services, you
										acknowledge and agree to accept and adhere to the Privacy
										Policy.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>MONITORING</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										Quad-Fi has no obligation to monitor access to or use of the
										Website, however, you acknowledge that Quad-Fi has the right
										to monitor the Website electronically at any time and from
										time to time, including without limitation, in order to: (i)
										to satisfy any applicable law, regulation or governmental
										request; (ii) to operate and improve the Website and our
										Services; or (iii) to protect itself or other users of the
										Website.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>TERMINATION</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										This agreement (the “<b>Agreement</b>”) made between you and
										Quad-Fi by your use of the Website in accordance with the
										Terms of Use is effective until terminated by Quad-Fi.
										Quad-Fi may terminate this Agreement at any time without
										notice, or suspend or terminate your access and use of the
										Website at any time, with or without cause, in Quad-Fi's
										absolute discretion and without notice.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>LAW AND MISCELLANEOUS</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										<p
											style={{
												fontSize: "18px",
												color: "black",
											}}
											className="mt-3">
											The Terms of Use and any and all other legal notices or
											statements posted on the Website constitute the entire
											agreement between you and Quad-Fi with respect to the use
											of the Website. In the event of a conflict between the
											Terms of Use and any other agreement you have with
											Quad-Fi, the terms of that agreement will prevail.
										</p>

										<p
											style={{
												fontSize: "18px",
												color: "black",
											}}
											className="mt-3">
											Failure by Quad-Fi to enforce any of its rights under the
											Terms of Use shall not be construed as a waiver of those
											rights or any other rights in any way whatsoever.
										</p>

										<p
											style={{
												fontSize: "18px",
												color: "black",
											}}
											className="mt-3">
											If any provision of the Terms of Use is ruled by an
											appropriate authority to be unlawful, void, or for any
											reason unenforceable, then such provision shall be
											severable from the Terms of Use and shall not affect the
											validity and enforceability of any remaining provisions of
											the Terms of Use.
										</p>

										<p
											style={{
												fontSize: "18px",
												color: "black",
											}}
											className="mt-3">
											The Terms of Use shall be governed by and construed in
											accordance with the laws of the Province of Ontario and of
											the laws of Canada applicable therein. You and we agree-
											that any dispute in connection with the Website or our
											Services is to be submitted exclusively to a court of
											competent jurisdiction in Toronto, Ontario, and no other
											court.
										</p>
									</p>
								</CCol>
								<CCol className="pt-5" lg="12">
									<h4>CHANGES TO THESE TERMS</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										Quad-Fi reserves the right to amend the Terms of Use at any
										time. All changes shall be effective upon posting to the
										Website. Your continued use of the Website or our Services
										after any change to the Terms of Use constitutes your
										agreement to be bound by any such changes. Quad-Fi may
										terminate, suspend, change, or restrict access to all or any
										part of the Website or our Services without notice or
										liability at any time.
									</p>
								</CCol>

								<CCol className="pt-5" lg="12">
									<h4>CONTACTING US</h4>
									<p
										className="mt-5"
										style={{
											fontSize: "18px",
											color: "black",
										}}>
										<p
											style={{
												fontSize: "18px",
												color: "black",
											}}>
											{" "}
											If you have questions regarding the Terms of Use or the
											practices of Quad-Fi, please contact us.
										</p>

										<p
											style={{
												fontSize: "18px",
												color: "black",
											}}>
											{" "}
											Quad-Fi Inc.
											<br /> 67 Yonge Street
											<br />
											Toronto, ON
											<br /> Canada M5E 1J8
										</p>
										<p
											className="mt-5"
											style={{
												fontSize: "18px",
												color: "black",
											}}>
											1-866-782-3340
										</p>
									</p>
								</CCol>
							</CRow>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default TermsOfUse;
