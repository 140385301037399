import axios from "axios";
import { headers } from "../reusable/headers";

export const getAnalysisData = (data) => {
	if (headers()) {
		return axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/analytics/updateAnalytics`,
			data: data,
			headers: headers(),
			withCredentials: true,
		})
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				console.log(err);
				return null;
			});
	}

	return null;
};

export const updateAnalytics = async (body) => {
	if (headers()) {
		let res = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/analytics/updateAnalytics`,
			data: body,
			headers: headers(),
			withCredentials: true,
		});
		if (res?.status === 200) {
			return res.data;
		}
	}
};

export const getDeviceAnalysisData = (data) => {
	if (headers()) {
		return axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/analytics/updateConfig `,
			data: data,
			headers: headers(),
			withCredentials: true,
		})
			.then((response) => {
				return response.data;
			})
			.catch((e) => {
				throw new ErrorEvent(e.message);
			});
	}
};
export const getUtmData = (data) => {
	if (headers()) {
		return axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/analytics/updateUtm`,
			data: data,
			headers: headers(),
			withCredentials: true,
		})
			.then((response) => {
				return response.data;
			})
			.catch((e) => {
				throw new ErrorEvent(e.message);
			});
	}
	return null;
};

export const getPrevPageTime = () => {
	const body = {
		appId: sessionStorage.getItem("appId"),
	};

	if (headers()) {
		return axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/analytics/getPrevPageTime`,
			data: body,
			headers: headers(),
			withCredentials: true,
		})
			.then((response) => {
				return response.data;
			})
			.catch((e) => {
				throw new ErrorEvent(e.message);
			});
	}
	return { result: 0 };
};
