import React, { useEffect, useState } from "react";
import { CProgress } from "@coreui/react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import logoIcon from "src/assets/icons/app-logo.png";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { getPrCategories } from "../../services/user";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import Select from "react-select";
import loadingIcon from "src/assets/icons/Spinner.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { COUNTRY_SUPPLY_CREDIT_REPORT } from "src/Utils/consts";

const PrQuestions = (props) => {
	const history = useHistory();

	let preFill = {
		prDate: null,
		prCategory: null,
		prSubCategoryId: "",
		prSubCategory: "",
		familyTie: null,
		numberOfDependents: "",
	};

	let prPreData = sessionStorage.getItem("prDetails");
	if (prPreData) {
		prPreData = JSON.parse(sessionStorage.getItem("prDetails"));
		preFill = {
			prDate: prPreData.prDate,
			prCategory: {
				id: prPreData.prCategory,
				name: prPreData.prCategory,
				value: prPreData.prCategory,
				label: prPreData.prCategory,
			},
			familyTie: {
				id: prPreData.familyTie,
				name: prPreData.familyTie,
				value: prPreData.familyTie,
				label: prPreData.familyTie,
			},
			prSubCategory: {
				prSubCategoryId: prPreData.prSubCategoryId,
				prSubCategory: prPreData.prSubCategory,
			},
			numberOfDependents: prPreData.numberOfDependents,
		};
	}

	const [loading, setLoading] = useState("");
	const [mouseClick, setMouseClick] = useState(1);
	const [keyboardClick, setKeyboardClick] = useState(1);
	const [prDate, setPrDate] = useState(
		preFill.prDate ? new Date(preFill.prDate) : null
	);
	const [pickerDate, setPickerDate] = useState(preFill.prDate);
	const [numberDependents, setNumberDependents] = useState(
		preFill.numberOfDependents
	);
	const [familyTie, setFamilyTie] = useState(preFill.familyTie);
	const [prCategory, setPrCategory] = useState(preFill.prCategory);
	const [prSubCategory, setPrSubCategory] = useState("");
	const [prSubCategories, setPrSubCategories] = useState([]);
	const [isFormValid, setisFormValid] = useState(false);

	const { register, handleSubmit, errors, formState } = useForm({
		reValidateMode: "onChange",
		mode: "all",
	});

	const { isValid } = formState;

	const styless = {
		option: (provided, state) => ({
			...provided,
			fontWeight: state.isSelected ? "bold" : "normal",
			color: state.data.color,
			backgroundColor: "white",
			paddingLeft: "20px",
			marginLeft: "20px",
			marginRight: "200px",
			fontSize: 15,
		}),
		menu: (provided) => ({ ...provided, minWidth: "100%", left: 0 }),
	};

	useEffect(() => {
		const localFn = async () => {
			if (sessionStorage.getItem("appId")) {
				await sendAnalytics(new Date(), STEP_PAGES.PR_DETAILS);
			}
			let prPreData = sessionStorage.getItem("prDetails");
			if (prPreData) {
				prPreData = JSON.parse(sessionStorage.getItem("prDetails"));
				if (prPreData.prCategory) {
					await getPrCategories(prPreData.prCategory)
						.then((res) => {
							let tmpArr = [];
							if (res.data.success && res.data.data.length > 0) {
								//eslint-disable-next-line
								res.data.data.map((item) => {
									const tmpObj = {
										id: item.id,
										name: item.name,
										value: item.name,
										label: item.name,
									};
									tmpArr.push(tmpObj);
								});
								setPrSubCategories(tmpArr);

								if (preFill.prSubCategory) {
									setPrSubCategory({
										id: preFill.prSubCategory.prSubCategoryId,
										name: preFill.prSubCategory.prSubCategory,
										value: preFill.prSubCategory.prSubCategory,
										label: preFill.prSubCategory.prSubCategory,
									});
									setisFormValid(true);
								}
							}
						})
						.catch((err) => {
							console.log("Error ", err);
						});
				}
			}
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	const prTypes = [
		{
			id: "Economic",
			name: "Economic",
			value: "Economic",
			label: "Economic",
		},
		{
			id: "Non-economic",
			name: "Non-economic",
			value: "Non-economic",
			label: "Non-economic",
		},
	];

	const familyTies = [
		{
			id: "Yes",
			name: "Yes",
			value: "Yes",
			label: "Yes",
		},
		{
			id: "No",
			name: "No",
			value: "No",
			label: "No",
		},
	];

	const hideLoader = () => {
		setLoading(false);
	};
	const showLoader = () => {
		setLoading(true);
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	const handleDateChange = (event) => {
		setPrDate(event);
		var today = new Date(event);
		var mm = String(today.getMonth() + 1).padStart(2, "0");
		var yyyy = today.getFullYear();
		setPickerDate(yyyy + "-" + mm);
	};

	const handlePrCategory = async (e) => {
		setPrCategory(e);
		setPrSubCategory(null);
		await getPrCategories(e.id)
			.then((res) => {
				let tmpArr = [];
				if (res.data.success && res.data.data.length > 0) {
					//eslint-disable-next-line
					res.data.data.map((item) => {
						const tmpObj = {
							id: item.id,
							name: item.name,
							value: item.name,
							label: item.name,
						};
						tmpArr.push(tmpObj);
					});
					setPrSubCategories(tmpArr);
				}
			})
			.catch((err) => {
				console.log("Error ", err);
			});
	};

	const handleSubCategory = (e) => {
		setPrSubCategory(e);
	};

	const handleFamilyTie = (e) => {
		setFamilyTie(e);
	};

	const handleNumberDependents = (e) => {
		setNumberDependents(e.target.value.trim());
		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);
	};

	const handleSave = async () => {
		showLoader();
		let details = {
			page: STEP_PAGES.PR_DETAILS,
			prDate: pickerDate,
			prCategory: prCategory ? prCategory.id : "",
			prSubCategoryId: prSubCategory ? prSubCategory.id : "",
			prSubCategory: prSubCategory ? prSubCategory.name : "",
			familyTie: familyTie ? familyTie.id : "",
			numberOfDependents: numberDependents ? numberDependents : "",
		};
		sessionStorage.setItem("prDetails", JSON.stringify(details));
		history.push("productPurpose");
		hideLoader();
	};
const handleBack = () => {
	const country = JSON.parse(sessionStorage.getItem("userCountry")).country
	if(COUNTRY_SUPPLY_CREDIT_REPORT.includes(country)){
		history.push("/CountryQuestion")
	}
	else{
		history.push("/Country");
	}
}
	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>Please provide the following details.</b>
								</h1>
							</div>
							<div className="input-container-pr">
								<div className="edubox">
									<label className="custom-label">
										<b>When did you land in Canada as a Permanent Resident?</b>
									</label>
									<br />
									<div class="form-control-plaintext custom-input third-datepicker">
										<DatePicker
											name="prDate"
											minDate={new Date("1 January, 1970")}
											maxDate={new Date()}
											selected={prDate}
											onClick={handleClick}
											onChange={(date) => handleDateChange(date)}
											dateFormat="yyyy-MM"
											showMonthYearPicker
											showFullMonthYearPicker
											onChangeRaw={(e) => e.preventDefault()}
										/>
									</div>
									<span class="validity"></span>
								</div>

								<div className="edubox">
									<label className="custom-label">
										<b>Under which category you become a Permanent Resident?</b>
									</label>
									<div className="form-control-plaintext custom-input custom-input-container">
										<Select
											placeholder="Please select an option"
											styles={styless}
											options={prTypes}
											name="prCategory"
											onChange={(event) => handlePrCategory(event)}
											defaultValue={prCategory}
											required
											isSearchable={false}
										/>
									</div>
								</div>
							</div>

							{prSubCategories && prSubCategories.length > 0 ? (
								<div className="input-container-pr">
									<div className="edubox" style={{ width: "100%", padding: 0 }}>
										<div className="edubox1">
											<label className="custom-label">
												<b>
													Under which {prCategory ? prCategory.id : null}{" "}
													category you become a Permanent Resident?
												</b>
											</label>
											<div className="form-control-plaintext custom-input custom-input-container">
												<Select
													placeholder="Please select an option"
													styles={styless}
													options={prSubCategories}
													name="prSubCategory"
													onChange={(event) => handleSubCategory(event)}
													defaultValue={prSubCategory}
													value={prSubCategory}
													required
													isSearchable={false}
													autosize={true}
												/>
											</div>
										</div>
									</div>
								</div>
							) : null}

							<div className="input-container-pr">
								<div className="edubox">
									<label className="custom-label">
										<b>
											Do you have family ties in Canada (parents, childeren,
											grand parents)?
										</b>
									</label>
									<div className="form-control-plaintext custom-input custom-input-container">
										<Select
											placeholder="Please select an option"
											styles={styless}
											options={familyTies}
											name="familyTie"
											onChange={(event) => handleFamilyTie(event)}
											onClick={handleClick}
											defaultValue={familyTie}
											required
											isSearchable={false}
										/>
									</div>
								</div>
								<div className="edubox">
									<label className="custom-label">
										<b>
											What is the number your dependants (individuals who
											financially depend on you)?{" "}
										</b>
									</label>
									<input
										name="numberDependents"
										defaultValue={numberDependents}
										onChange={handleNumberDependents}
										onClick={handleClick}
										className={
											"form-control-plaintext w-67 h-58 custom-input " +
											(errors.first_name && errors.first_name.message
												? "custom-input-error"
												: "")
										}
										ref={register({
											required: true,
											pattern: /^[0-9]*$/i,
										})}
									/>
								</div>
							</div>

							{Object.keys(errors).length > 0 && (
								<div className="error-container">
									<p className="error-message">
										{errors[Object.keys(errors)[0]].message}
									</p>
								</div>
							)}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{isFormValid ||
							(isValid &&
								pickerDate &&
								prCategory &&
								prSubCategory &&
								familyTie &&
								numberDependents) ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={false}>
									Next
								</button>
							) : (
								<button className="custom-button disabled" disabled={true}>
									Next
								</button>
							)}
							<button
								className="custom-back-button"
								onClick={async () => {
									handleBack();
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default PrQuestions;
