import { createBrowserHistory } from "history";
import { useEffect } from "react";
import ReactGA from "react-ga";
const history = createBrowserHistory();

const usePageTracking = (props) => {
	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID);
		ReactGA.pageview(window.location.pathname);
		window.analytics.page({
			path: window.location.pathname + window.location.hash,
		});
		//eslint-disable-next-line
	}, [history.location.pathname, props]);
};

export default usePageTracking;
