import { CButton, CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import empLogo from "src/assets/icons/App-Flow-Icons/employed-icon.png";
import futureLogo from "src/assets/icons/App-Flow-Icons/future-offer-letter.png";
import notEmpLogo from "src/assets/icons/App-Flow-Icons/not-employed.png";
import selfEmpLogo from "src/assets/icons/App-Flow-Icons/self-employed-icon.png";
import logoIcon from "src/assets/icons/app-logo.png";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import {
	getUserInProgressApp,
	updateUserAppFlow,
	userInfo,
} from "../../services/user";
import EmployedPage from "./Employment/Employed";
import FutureEmployedPage from "./Employment/FutureEmployed";
import SelfEmployedPage from "./Employment/SelfEmployed";

export default function EmploymentStatus(props) {
	const inProgressApp = getUserInProgressApp();
	let preFill = "";
	if (inProgressApp) {
		preFill = inProgressApp.appData.employment.status;
	}
	const [employmentStatus, setEmploymentStatus] = useState("");
	const { register, errors, handleSubmit, formState } = useForm({
		mode: "onChange",
	});
	const { isValid } = formState;
	const [user, setUser] = useState([]);
	const [mouseClick, setMouseClick] = useState(0);
	const arrivalTime = new Date();

	useEffect(() => {
		const localFn = async () => {
			let userId = sessionStorage.getItem("appId");
			await userInfo(userId, setUser);
			if (user.personal) {
				setEmploymentStatus(user.employment.status);
			}
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (preFill !== "" && mouseClick > 0) {
			// setEmploymentStatus(preFill);
		}

		if (employmentStatus !== "") {
			let details = {
				page: "employmentStatus",
				employmentStatus,
			};
			updateUserAppFlow(sessionStorage.getItem("appId"), details);
		}
		//eslint-disable-next-line
	}, [employmentStatus]);

	const handleEmploymentStatus = (event) => {
		setEmploymentStatus(event.target.value);
		sessionStorage.setItem("selectedEmploymentStatus", event.target.value);
		handleClick();
	};

	let details = {
		page: "employmentStatus",
		employmentStatus,
		companyName: "",
		currentTitle: "",
		annualIncome: "",
		startingDate: "",
		hasIncome: "",
		incomeAmount: "",
		typeOfWork: "",
		industry: "",
	};

	const handleSave = () => {
		handleClick();
		let details = {
			page: "employmentStatus",
			employmentStatus,
			companyName: "",
			currentTitle: "",
			annualIncome: "",
			startingDate: "",
			hasIncome: "",
			incomeAmount: "",
			typeOfWork: "",
			industry: "",
		};
		updateUserAppFlow(sessionStorage.getItem("appId"), details);
		if (preFill !== "") {
			setEmploymentStatus(preFill);
		}
	};

	const handleSaveAndExit = () => {
		localStorage.setItem("DropOff", "YES");
		let details = {
			page: "employmentStatus",
			employmentStatus,
		};
		updateUserAppFlow(sessionStorage.getItem("appId"), details);
		props.handleSaveExit();
		props.lastStep();
	};
	const handleSaveAndBack = async () => {
		localStorage.setItem("PreviousPage", "YES");
		let details = {
			page: "employmentStatus",
			employmentStatus,
		};
		props.previousStep();
		await sendAnalytics(arrivalTime, STEP_PAGES.EMPLOYMENT_STATUS);
		console.log(details);
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	if (employmentStatus === "employed" && mouseClick > 0) {
		// handleSave()
		return (
			<EmployedPage
				details={details}
				userId={props.user.id}
				steps={props}
				setEmploymentStatus={setEmploymentStatus}
			/>
		);
	} else if (employmentStatus === "self-employed" && mouseClick > 0) {
		// handleSave()
		return (
			<SelfEmployedPage
				details={details}
				userId={props.user.id}
				steps={props}
				setEmploymentStatus={setEmploymentStatus}
			/>
		);
	} else if (employmentStatus === "job-offer" && mouseClick > 0) {
		// handleSave()
		return (
			<FutureEmployedPage
				details={details}
				userId={props.user.id}
				steps={props}
				setEmploymentStatus={setEmploymentStatus}
			/>
		);
	} else if (employmentStatus === "unemployed" && mouseClick > 0) {
		props.nextStep();
		return <></>;
	} else {
		return (
			<section>
				<CProgress
					color="stack-overflow"
					value={(props.currentStep / props.totalSteps) * 100}
					className="bg-white"
				/>
				<div id="page-section" className="wrapper">
					<div className="save-exit-button-container">
						<img src={logoIcon} alt="" />
						<CButton className="save-exit-button" onClick={handleSaveAndExit}>
							{" "}
							Save and Exit{" "}
						</CButton>
					</div>
					<div className="no-scroll tablet vertical-middle-page">
						<div>
							<div class="content-container">
								<div className="text-container text-container-margin">
									<h1>
										<b>What is your employment status?</b>
									</h1>
								</div>

								<div className="cards-container">
									<div className="icon-card">
										<input
											type="radio"
											id="Emp"
											name="Emp-Status"
											value="employed"
											class="custom-radio"
											checked={
												employmentStatus === "employed" ||
												(preFill !== "" && preFill === "employed")
													? true
													: false
											}
											ref={register({
												required: "Please select one of the options",
											})}
											onChange={(event) => handleEmploymentStatus(event)}
										/>
										<label htmlFor="Emp" className="card-label">
											<img src={empLogo} alt="" />
											<b>Employed</b>
										</label>
									</div>

									<div className="icon-card">
										<input
											type="radio"
											id="SelfEmp"
											name="Emp-Status"
											value="self-employed"
											class="custom-radio"
											checked={
												employmentStatus === "self-employed" ||
												(preFill !== "" && preFill === "self-employed")
													? true
													: false
											}
											ref={register({
												required: "Please select one of the options",
											})}
											onChange={(event) => handleEmploymentStatus(event)}
										/>
										<label htmlFor="SelfEmp" className="card-label">
											<img src={selfEmpLogo} alt="" />
											<b>Self-Employed</b>
										</label>
									</div>

									<div className="icon-card">
										<input
											type="radio"
											id="Future"
											name="Emp-Status"
											value="job-offer"
											class="custom-radio"
											checked={
												employmentStatus === "job-offer" ||
												(preFill !== "" && preFill === "job-offer")
													? true
													: false
											}
											ref={register({
												required: "Please select one of the options",
											})}
											onChange={(event) => handleEmploymentStatus(event)}
										/>
										<label htmlFor="Future" className="card-label">
											<img src={futureLogo} alt="" />
											<b>Future (Offer Letter)</b>
										</label>
									</div>

									<div className="icon-card">
										<input
											type="radio"
											id="None"
											name="Emp-Status"
											value="unemployed"
											class="custom-radio"
											checked={
												employmentStatus === "unemployed" ||
												(preFill !== "" && preFill === "unemployed")
													? true
													: false
											}
											ref={register({
												required: "Please select one of the options",
											})}
											onChange={(event) => handleEmploymentStatus(event)}
										/>
										<label htmlFor="None" className="card-label">
											<img src={notEmpLogo} alt="" />
											<b>Not Employed</b>
										</label>
									</div>
								</div>
								{Object.keys(errors).length > 0 && (
									<div className="error-container">
										<p className="error-message">
											{errors[Object.keys(errors)[0]].message}
										</p>
									</div>
								)}
							</div>
							<div className="load-class"></div>
							<div className="button-container button-container-margin">
								{isValid || (preFill && preFill !== "") ? (
									<button
										type="submit"
										className="custom-button enabled"
										pressed="true"
										onClick={handleSubmit(handleSave)}>
										Next
									</button>
								) : (
									<button
										type="submit"
										className="custom-button disabled"
										pressed="false">
										Next
									</button>
								)}
								<button
									className="custom-back-button"
									onClick={handleSaveAndBack}>
									Back
								</button>
							</div>
						</div>
					</div>
				</div>
				<Tooltip />
			</section>
		);
	}
}
