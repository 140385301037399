export const GET_RESPONSE_ERROR = "GET_RESPONSE_ERROR";
export const GET_REQUEST_SUCCESS = "GET_REQUEST_SUCCESS";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_ROLES_DATA = "GET_ROLES_DATA";
export const GET_USERS_DATA = "GET_USERS_DATA";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const ALL_PURPOSE = "ALL_PURPOSE";
export const ALL_PRODUCTS = "ALL_PRODUCTS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const CUSTOM_ERROR = "CUSTOM_ERROR";
export const SET_EQUIFAX_ANSWER_1 = "SET_EQUIFAX_ANSWER_1";

export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const CREATE_PAYEE = "CREATE_PAYEE";

export const GET_DB_DATA = "GET_DB_DATA";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const REMEMBER_TOKEN_SUCCESS = "REMEMBER_TOKEN_SUCCESS";
export const GET_RM_REQUEST = "GET_RM_REQUEST";

export const STOP_LOADER = "STOP_LOADER";

export const SET_UNREAD_MESSAGE_COUNT = "SET_UNREAD_MESSAGE_COUNT";
