import { CCol, CContainer, CImg, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { getLoggedUser } from "src/actions/users";
import book from "../../assets/icons/App-Flow-Icons/bookclosed.png";
import globe from "../../assets/icons/App-Flow-Icons/globe.png";
import graph from "../../assets/icons/App-Flow-Icons/graph.png";
import piggyBank from "../../assets/icons/piggybank.png";
import TheSidebar from "../../containers/TheSidebar";
import TopNav from "../../containers/TopNav";
import {
	getLatestScheduledAmountData,
	getUserApplications,
	getuserInfo,
} from "../../services/user";
import "../../style/personalinformation.scss";

const Schedule = () => {
	const userInbox = useSelector((state) => {
		return state.userInbox;
	});
	const unRead = userInbox.filter((email) => !email.isRead);
	const [loading, setLoading] = React.useState("");

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	const setIcon = (product) => {
		switch (product) {
			case "Students":
				return book;
			case "Refinancing":
				return graph;
			case "International":
				return globe;
			default:
				return piggyBank;
		}
	};

	const [some, setSome] = useState([]);
	const [LoanDetail, setLoanDetail] = useState("");
	const [applications, setApplications] = useState([]);
	const [applicationsFound, setApplicationsFound] = useState(true);
	useEffect(() => {
		const localFn = async () => {
			showLoader();

			let user = await getLoggedUser();
			let userApps = await getUserApplications(user.id);
			//filter userApps by status_id
			userApps.applications =
				userApps &&
				userApps.applications &&
				userApps.applications.filter((app) => app.status_id > 15); // greater than 15 status , show funded applications
			if (
				userApps.success &&
				userApps.applications &&
				userApps.applications.length > 0
			) {
				let _apps = [];
				showLoader();

				for (let ap of userApps.applications) {
					ap.appData.plaidAllAccountsData = ap.appData.plaidPrimaryAccountData
						? JSON.parse(ap.appData.plaidPrimaryAccountData)
						: {};
					if (ap.status_id === 23 || ap.status_id === 24) {
						_apps.push(ap);
					} else if (
						ap.status_id !== 6 &&
						ap.appId === sessionStorage.getItem("CurrentSelect")
					) {
						await getLatestScheduledAmountData(ap.appId).then((res) => {
							if (res.success) {
								ap.EMIAmount = res.result;
							}
						});
						_apps.push(ap);
					} else {
						console.log("exception case");
					}
				}
				console.log("applicationsssssssss", _apps);
				await setApplications(_apps);

				const allappId = await _apps.map((d) => d.appId);
				// allappid of user
				allappId.forEach(async (d) => {
					await getuserInfo(d).then((data) => setSome([...some, data]));
				});

				// set state from calling api for every appId
				// tried through map forEach but i am unable to process further

				// below code working for one only left is to make it for multiple id's
				const detail = await getuserInfo(_apps[0].appId);

				setLoanDetail(detail.loan_detail);
				console.log(LoanDetail);
				sessionStorage.setItem("appId", _apps[0].appId);
				hideLoader();
			} else {
				hideLoader(); // hide loader if no application found
				setApplicationsFound(false);
			}
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	const formatDateIssued = (utcDate, onlyDate = false) => {
		try {
			if (!utcDate) return "Data Not Available";
			let localDate = new Date(utcDate);
			if (onlyDate) {
				return localDate.toLocaleDateString("en-CA");
			} else {
				return (
					localDate.toLocaleDateString("en-CA") +
					" " +
					localDate.toLocaleTimeString()
				);
			}
		} catch (e) {
			console.log("Formatting Error");
			return "";
		}
	};

	return (
		<div className="sche-bg">
			<TopNav unRead={unRead} />

			<CContainer fluid>
				<CRow>
					<CCol md={12} lg={2} sm={12} xs={12}>
						<TheSidebar />
					</CCol>

					<CCol md={12} lg={10} sm={12} xs={12}>
						<div className="my-2 tbl-space noappfound">
							<div class="title">Loan Summary</div>
							{applications.map((app, index) => (
								<div className="cMain" key={app.appId}>
									<div>
										<CRow>
											<CCol xs={2} md={2} sm={2}>
												<CImg height={50} src={setIcon(app.productName)} />
											</CCol>
											<CCol
												xs={10}
												md={10}
												sm={10}
												className="px-md-0 textAlig">
												<div className="persnlLoan">{app.productName} Loan</div>
												<span>
													XXXXXXXXXXXXXXX
													{app &&
														app.appData &&
														app.appData.plaidAllAccountsData &&
														app.appData.plaidAllAccountsData.accounts &&
														app.appData.plaidAllAccountsData.accounts[0] &&
														app.appData.plaidAllAccountsData.accounts[0].mask}
												</span>
												<small>
													Currency :{" "}
													{app &&
														app.appData &&
														app.appData.loan_detail.currency}{" "}
												</small>
												<small>
													{app &&
														app.appData &&
														app.appData.loan_detail.interest_rate}{" "}
													%{" "}
												</small>
											</CCol>
										</CRow>
									</div>
									<div className="table-responsive">
										<table className="mini-statement-table">
											<tr>
												{/* <th>Status</th> */}
												<th>Payment</th>
												<th>Installments</th>
												<th>Next payment due</th>
												<th>Interest Rate</th>
												<th>Amount</th>
												<th>Cosigner</th>
												<th>Issued Year</th>
												{/* <th>Loan ID</th> */}
												<th></th>
											</tr>

											<tr>
												<td>
													$
													{app.EMIAmount
														? Number(app.EMIAmount).toLocaleString("en-US", {
																maximumFractionDigits: 2,
																minimumFractionDigits: 2,
														  })
														: Number(
																app.appData.loan_detail.amount
														  ).toLocaleString("en-US", {
																maximumFractionDigits: 2,
																minimumFractionDigits: 2,
														  })}
												</td>
												<td>
													{app.paidInstallments}/{app.totalInstallments}
												</td>
												<td>
													{app.nextPaymentDate
														? app.nextPaymentDate.split("T")[0]
														: "Completed"}
												</td>
												<td>
													{app &&
														app.appData &&
														app.appData.loan_detail.interest_rate}{" "}
													%
												</td>
												<td>
													$
													{app.appData.loan_detail &&
													app.appData.loan_detail.amount
														? Number(
																app.appData.loan_detail.amount
														  ).toLocaleString("en-US", {
																maximumFractionDigits: 2,
																minimumFractionDigits: 2,
														  })
														: "0.00"}
												</td>
												<td>
													{app.cosigner
														? app.cosigner.name + app.cosigner.lastName
														: "No cosigner"}
												</td>
												<td>
													{app.appData &&
													app.appData.Origination_date &&
													app.appData.Origination_date._seconds
														? formatDateIssued(
																Number(app.appData.Origination_date._seconds) *
																	1000,
																true
														  )
														: ""}
												</td>
											</tr>
										</table>
									</div>
								</div>
							))}
							{loading ? (
								<div className="load-class load_change">
									<Loader
										type="ThreeDots"
										color="#ff9700"
										height={100}
										width={100}
										timeout={1000}
									/>
								</div>
							) : null}
							<div className="noapp-found">
								{applications.length === 0 && !applicationsFound ? (
									<p>No Applications found</p>
								) : null}
							</div>
						</div>
					</CCol>
				</CRow>
			</CContainer>
		</div>
	);
};

export default React.memo(Schedule);
