import { useState } from "react";
import { Autocomplete } from "./style";
//eslint-disable-next-line
export default function (props) {
	const show = useState(props.show || false);
	const data = props.data || [];
	const loading = props.loading || false;
	const onSelection = (item) => {
		props.selectionHandler(item);
	};
	console.log(show);
	return (
		<Autocomplete>
			{props.children}
			{props.show ? (
				<div id="myInputautocomplete-list" class="autocomplete-items">
					{loading ? (
						<div class="loading text-center">Loading...</div>
					) : data.length ? (
						data.map((option) => (
							<div onClick={() => onSelection(option)}>{option.name}</div>
						))
					) : (
						<div class="loading text-center">No Result found!</div>
					)}
				</div>
			) : null}
		</Autocomplete>
	);
}
