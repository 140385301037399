//TODO:Need Fixes
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import logoIcon from "src/assets/icons/app-logo.png";
import { COUNTRY_NAMES } from "src/Utils/consts";

import PlacesAutocomplete, {
	geocodeByAddress,
} from "react-places-autocomplete";

const PhillCountryQuestion = (props) => {
	let preFill = {
		currentAddress: "",
		city: "",
		country: "",
		province: "",
		postalCode: "",
	};
	const history = useHistory();
	const [idType, setIdType] = useState();
	const [address, setAddress] = React.useState("");
	const [gender, setGender] = React.useState("");
	const [fullAddress, setFullAddress] = React.useState(null);
	const [fullCheck, setFullCheck] = React.useState(true);
	const [user, setUser] = React.useState({ address: {} });
	const [streetname, setStreetName] = React.useState("");
	const [loading, setLoading] = React.useState("");
	const [editShow, setEditShow] = React.useState(false);
	const [postalCodeValue, setPostalCodeValue] = useState("");
	const [provinceCode, setProvinceCode] = React.useState("");
	const [submitIssue, setSubmitIssue] = React.useState(false);

	const {
		register,
		handleSubmit,
		errors,
		formState,
		watch,
		setValue,
		trigger,
		clearErrors,
		setError,
	} = useForm({
		reValidateMode: "onChange",
		mode: "all",
	});
	const [saveDisabled, setSaveDisabled] = useState(false);

	const componentForm = useMemo(
		() => [
			"street_number",
			"route",
			"sublocality",
			"neighborhood",
			"locality",
			"administrative_area_level_2",
			"administrative_area_level_1",
			"country",
			"postal_code",
		],
		[]
	);

	const identificationCode = watch("identificationCode");

	const compRestriction = JSON.parse(sessionStorage.getItem("userCountry"))
		.country.toLowerCase()
		.substring(0, 2);
	var searchOptions = {
		componentRestrictions: {
			country: compRestriction === "me" ? "mx" : compRestriction,
			// language: LANGUAGE_ENGLISH,
		},
	};
	const handleSave = async (data) => {
		try {
			let details = {
				address: address.trim(),
				identificationCode: identificationCode?.trim(),
				country: JSON.parse(sessionStorage.getItem("userCountry")).country,
				moveDate: JSON.parse(sessionStorage.getItem("userCountry")).moveDate,
				idType: idType,
				gender: gender,
			};
			sessionStorage.setItem("countryDetails", JSON.stringify(details));
			let ucForm = {
				orig_country: JSON.parse(sessionStorage.getItem("userCountry")).country,
				request_date: new Date().toISOString().split("T")[0],
				credit_report: null,
			};
			sessionStorage.setItem("uc_input", JSON.stringify(ucForm));
			history.push("/pr-details");
		} catch (e) {
			console.log(e.message, "e.mmmm");
		}
	};
	React.useEffect(() => {
		if (fullAddress) {
			let flag = 0,
				indexZeroFlag = 0;
			const address_vals = [];

			for (var i = 0; i < fullAddress[0].address_components.length; i++) {
				var addressType = fullAddress[0].address_components[i].types;
				for (let a of addressType) {
					for (let [index, value] of componentForm.entries()) {
						if (a === value) {
							if (index === 1) {
								flag = 1;
							}
							console.log(flag);
							console.log(index, value);
							if (index === 0) {
								indexZeroFlag = 1;
								index++;
								let streetAddressVal = "";
								if (i === 1) {
									streetAddressVal =
										fullAddress[0].address_components[i - 1].long_name +
										" " +
										fullAddress[0].address_components[i].long_name;
								} else {
									streetAddressVal =
										fullAddress[0].address_components[i].long_name;
								}
								address_vals[index] = streetAddressVal;
								continue;
							}
							if (index === 1 && indexZeroFlag === 1) {
								continue;
							}

							address_vals[index] =
								fullAddress[0].address_components[i].long_name;
						}
					}
				}
			}
			let provinceCode = {};
			//eslint-disable-next-line
			fullAddress[0].address_components.map((itemAddr) => {
				if (itemAddr.types.includes("administrative_area_level_1")) {
					provinceCode = itemAddr;
				}
			});
			if (
				typeof provinceCode.short_name !== "undefined" &&
				provinceCode.short_name !== ""
			) {
				setProvinceCode(provinceCode.short_name);
			}
			console.log(address_vals, "Address values");
			if (typeof address_vals[6] !== "undefined") {
				setPostalCodeValue(address_vals[6]);
			}
		}
		//eslint-disable-next-line
	}, [fullAddress]);
	useEffect(() => {
		(async () => {
			let countryDetails = JSON.parse(sessionStorage.getItem("countryDetails"));
			if (countryDetails) {
				setAddress(countryDetails?.address);
				setValue("identificationCode", countryDetails?.identificationCode);
				trigger("identificationCode");
				setIdType(countryDetails?.idType);
				setGender(countryDetails?.gender);
			}
		})();
	}, []);

	const handleidentificationCodeChange = (e) => {
		const { value } = e.target;
		//value must contain number only
		if (value.match(/^[0-9]*$/)) {
			setValue("identificationCode", value);
			trigger("identificationCode");
		} else {
			return setError("identificationCode", {
				type: "manual",
				message: "Identification code must contain number only",
			});
		}

		//TODO: Remove Comment and add validation
		if (idType === "TIN" && value.length > 12) {
			setError("identificationCode", {
				type: "manual",
				message: "Identification Code must be 9 to 12 digits",
			});
		} else if (idType === "SSS Card" && value.length > 10) {
			setError("identificationCode", {
				type: "manual",
				message: "Identification Code must be 10 digits",
			});
		} else if (idType === "GSIS" && value.length > 11) {
			setError("identificationCode", {
				type: "manual",
				message: "Identification Code must be 10 to 11 digits",
			});
		} else if (value.length < 9) {
			setError("identificationCode", {
				type: "manual",
				message: "Identification Code must be minimum 9 digits",
			});
		} else {
			clearErrors("identificationCode");
		}
	};

	const handleChange = (address) => {
		address = address.trim();
		if (address === "") {
			setFullAddress(null);
			setFullCheck(false);
			setAddress("");
		} else {
			setFullCheck(true);
			console.log(address, "Addfa");
			setAddress(address);
		}
		console.log(fullCheck);
	};

	const hideLoader = () => {
		setLoading(false);
	};

	useEffect(() => {
		if (fullAddress && fullAddress.length > 0) {
			setSaveDisabled(false);
		} else {
			setSaveDisabled(true);
		}
	}, [fullAddress]);

	useEffect(() => {
		if (
			!address ||
			!identificationCode ||
			identificationCode?.length <
				(idType === "TIN" ? 9 : idType === "SSS Card" ? 10 : 10)
		) {
			setSubmitIssue(true);
		} else {
			setSubmitIssue(false);
		}
	}, [address, identificationCode]);

	const handleMexSelect = async (G_address) => {
		const geocode = await geocodeByAddress(G_address);
		const { formatted_address } = geocode[0];
		setAddress(formatted_address);
		let streetAddressVal = "";
		if (geocode.length) {
			if (
				typeof geocode[0]["address_components"] !== "undefined" &&
				geocode[0]["address_components"].length
			) {
				//eslint-disable-next-line
				geocode[0]["address_components"].map((itm) => {
					if (itm.types.includes("street_number")) {
						streetAddressVal += itm.long_name + " ";
					}
					if (itm.types.includes("route")) {
						streetAddressVal += itm.long_name;
					}
				});

				if (streetAddressVal === "") {
					//eslint-disable-next-line
					geocode[0]["address_components"].map((itm) => {
						if (itm.types.includes("sublocality_level_1")) {
							streetAddressVal = itm.long_name;
						}
					});
				}
			}
		}
		// setAddress(streetAddressVal);

		console.log("user details handleselect--", user);

		if (sessionStorage.getItem("CurrentSelect") !== 0) {
			hideLoader();
		} else {
			setEditShow(true);
		}
	};
	return (
		<>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<section>
					<div id="page-section" className="wrapper">
						<div
							className="no-scroll vertical-middle-page"
							style={{ width: "100%" }}>
							<div>
								{/* Philippines */}
								{JSON.parse(sessionStorage.getItem("userCountry")).country ===
									COUNTRY_NAMES.PHILIPPINES && (
									<div className="content-container countryQuestion">
										<div className="text-container text-container-margin">
											<h1>
												<b>Please provide these details.</b>
											</h1>
										</div>

										<div className="input-container">
											<div className="edubox">
												<label className="custom-label">
													<b>What is your former Address?</b>
												</label>
												<PlacesAutocomplete
													value={address}
													onChange={handleChange}
													onSelect={handleMexSelect}
													searchOptions={searchOptions}>
													{({
														getInputProps,
														suggestions,
														getSuggestionItemProps,
														loading,
													}) => (
														<React.Fragment>
															<input
																{...getInputProps({
																	placeholder: streetname
																		? streetname
																		: user.address.currentAddress,
																	className:
																		"form-control-plaintext w-67 h-58 custom-input ",
																})}
																{...register("address")}
																// onClick={handleClick}
															/>
															{/* </div> */}
															<div
																className={
																	suggestions.length > 0
																		? "autocomplete-dropdown-container has-options"
																		: "autocomplete-dropdown-container"
																}>
																{loading && (
																	<div className="loading">Loading...</div>
																)}
																{suggestions.map((suggestion, i) => {
																	const className = suggestion.active
																		? "suggestion-item--active"
																		: "suggestion-item";
																	// inline style for demonstration purpose
																	const style = suggestion.active
																		? {
																				backgroundColor: "#ff9300",
																				cursor: "pointer",
																				padding: "0.5rem 1rem",
																		  }
																		: {
																				backgroundColor: "#ffffff",
																				cursor: "pointer",
																				padding: "0.5rem 1rem",
																		  };
																	return (
																		<div
																			key={i}
																			{...getSuggestionItemProps(suggestion, {
																				className,
																				style,
																			})}>
																			<span>{suggestion.description}</span>
																		</div>
																	);
																})}
															</div>
														</React.Fragment>
													)}
												</PlacesAutocomplete>
											</div>
										</div>
										<div
											className="input-container-pr "
											style={{ width: "100%" }}>
											<div className="edubox">
												<label className="custom-label">
													<b>Which ID would you like to share?</b>
												</label>
												<select
													defaultValue={
														JSON.parse(sessionStorage.getItem("countryDetails"))
															?.idType ?? "TIN"
													}
													onChange={(e) => {
														setIdType(e.target.value);
														setValue("identificationCode", "");
														trigger("identificationCode");
													}}
													className={
														"form-control-plaintext w-67 h-58 custom-input " +
														(errors.identificationCodeSelect &&
														errors.identificationCodeSelect.message
															? "custom-input-error"
															: "")
													}>
													<option value="TIN">TIN</option>
													<option value="SSS Card">SSS Card</option>
													<option value="GSIS">GSIS</option>
												</select>
											</div>
											<div className="edubox" style={{ width: "20rem" }}>
												<label className="custom-label">
													<b>What is your Gender?</b>
												</label>
												<select
													id="gender"
													name="gender"
													onChange={(e) => setGender(e.target.value)}
													defaultValue={
														JSON.parse(sessionStorage.getItem("countryDetails"))
															?.gender ?? "Male"
													}
													className={
														"form-control-plaintext w-67 h-58 custom-input " +
														(errors.gender && errors.gender.message
															? "custom-input-error"
															: "")
													}>
													<option value="Male">Male</option>
													<option value="Female">Female</option>
												</select>
											</div>
										</div>
										<div className="content-container">
											<div className="edubox">
												<label className="custom-label">
													{/* <b>What is your identification Code ?</b> */}
													<b>What is your {idType ?? "TIN"} Number ?</b>
												</label>
												<input
													id="identificationCode"
													name="identificationCode"
													defaultValue={identificationCode}
													value={identificationCode}
													onChange={handleidentificationCodeChange}
													minLength={
														idType === "TIN"
															? 9
															: idType === "SSS Card"
															? 10
															: 10
													}
													maxLength={
														idType === "TIN"
															? 12
															: idType === "SSS Card"
															? 10
															: 11
													}
													ref={
														(register("identificationCode"),
														{
															required: true,
															//follow rules of minlength and maxlength
															minLength:
																idType === "TIN"
																	? 9
																	: idType === "SSS Card"
																	? 10
																	: 10,
															maxLength:
																idType === "TIN"
																	? 12
																	: idType === "SSS Card"
																	? 10
																	: 11,
															//number only
															pattern: {
																value: /^[0-9]*$/,
																message:
																	"Identification Code can only contain numbers",
															},
														})
													}
													className={
														"form-control-plaintext w-67 h-58 custom-input " +
														(errors.identificationCode &&
														errors.identificationCode.message
															? "custom-input-error"
															: "")
													}
													placeholder="Identification Code"
												/>
												{identificationCode?.length <
												(!idType || idType === "TIN" ? 9 : 10) ? (
													<p className="text-danger">
														Identification Code must be at least{" "}
														{!idType || idType === "TIN" ? 9 : 10} characters
													</p>
												) : null}
											</div>
										</div>
										{console.log(errors, "errrrrrrr")}

										{Object.keys(errors).length > 0 && (
											<div className="error-container">
												<p className="error-message">
													{errors[Object.keys(errors)[0]].message}
												</p>
											</div>
										)}
									</div>
								)}
								<div className="button-container button-container-margin">
									{console.log(errors, submitIssue, "err Submit Issues")}
									{Object.values(errors).length > 0 || submitIssue ? (
										<button className="custom-button disabled">Next</button>
									) : (
										<button
											className="custom-button enabled"
											onClick={handleSubmit(handleSave)}>
											Next
										</button>
									)}
									<button
										className="custom-back-button"
										onClick={async () => {
											history.push("/Country");
										}}>
										Back
									</button>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default PhillCountryQuestion;
