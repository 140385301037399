/* eslint-disable */
import { CButton, CProgress } from "@coreui/react";

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import logoIcon from "src/assets/icons/app-logo.png";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import {
	getCompanyData,
	getUserInProgressApp,
	updateUserAppFlow,
} from "../../../services/user";
import AutoComplete from "../../Application_flow/components/autocomplete";

const FutureEmployed = (props) => {
	const inProgressApp = getUserInProgressApp();
	let preFill = {
		employer: "",
		starting_date: null,
		job_title: "",
		annual_income: "",
	};
	if (inProgressApp) {
		preFill = {
			employer: inProgressApp.appData.employment.type_of_work,
			starting_date: inProgressApp.appData.employment.starting_date,
			job_title: inProgressApp.appData.employment.current_title,
			annual_income: inProgressApp.appData.employment.annual_income,
		};
		if (
			inProgressApp.appData.employment.annual_income !== "" &&
			inProgressApp.appData.employment.annual_income
		) {
			preFill["annual_income"] = inProgressApp.appData.employment.annual_income
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	}

	const { register, errors, handleSubmit, formState, getValues, setValue } =
		useForm({
			mode: "onChange",
		});

	const { isValid } = formState;
	const [industry, setIndustry] = React.useState("");
	const [typingTime, setTypingTime] = useState(new Date());
	const [stopTyping, setStopTyping] = useState(new Date());
	const [keyboardClick, setKeyboardClick] = useState(1);
	const [mouseClick, setMouseClick] = useState(1);
	const [wpm, setWpm] = useState(0);
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const [jobStartDate, setJobStartDate] = React.useState(
		preFill.starting_date ? new Date(preFill.starting_date) : null
	);
	const [pickerDate, setPickerDate] = useState(
		preFill.starting_date ? preFill.starting_date : null
	);

	const jobCompanyName = preFill.employer;
	const jobTitle = preFill.job_title;
	const jobAnnualIncome = preFill.annual_income ? preFill.annual_income : "";

	const start_month = new Date().getMonth() + 1;
	const start_minStartDate = `${new Date().getFullYear()}-${
		start_month < 10 ? "0" + start_month : start_month
	}`;

	const arrivalTime = new Date();

	const handleBackbutton = () => {
		localStorage.setItem("PreviousPage", "YES");
		props.setEmploymentStatus("");
	};

	const handleSave = async (formData) => {
		console.log(start_minStartDate);
		setNextButtonDisabled(true);
		formData.annualIncome = formData.annualIncome.replace(/,/g, "");

		let details = {
			page: "employmentStatus",
			...formData,
			employmentStatus: "job-offer",
			industry: industry
				? industry
				: sessionStorage.getItem("industry_selected"),
			employmentExperience: "0",
			startingDate: pickerDate ? pickerDate : preFill.starting_date,
		};
		updateUserAppFlow(props.userId, details);
		props.steps.nextStep();
		await sendAnalytics(arrivalTime, STEP_PAGES.FUTURE_EMPLOYMENT_STATUS);
	};

	const handleSaveAndExit = () => {
		localStorage.setItem("DropOff", "YES");
		let details = {
			page: "employmentStatus",
			...getValues(),
		};
		updateUserAppFlow(props.userId, details);
		props.steps.lastStep();
	};
	const handleAnnulIncome = (value) => {
		const regex = /^[0-9.,]*$/i;
		if (value && regex.test(value)) {
			const newValue = value
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			console.log(newValue);
			return newValue;
		} else {
			return value.slice(0, -1);
		}
	};

	//Autocomplete
	const [suggestions, setSuggestions] = useState([]);
	const [suggestionsLoading, setSuggestionsLoading] = useState(false);
	const [showSuggestions, setShowSuggestions] = useState(false);

	// const [companyName,setCompanyName] = useState("");
	const handleCompanyName = (e) => {
		getSuggestions(e.target.value);

		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);

		var iTotal = 0;
		var iKeys = 0;
		var iWords = 0;

		setTypingTime(new Date());

		if (stopTyping !== 0) {
			iKeys++;

			// iTotal += typingTime - stopTyping;
			iTotal += Math.abs(Math.round(typingTime - stopTyping) / 1000 / 60);
			iWords = e.target.value.split(/\s/).length;
			let CPM = ((iKeys / iTotal) * 6000, 2);
			let WPM = ((iWords / iTotal) * 6000, 2);

			console.log("CPM" + CPM + "WPM" + WPM);
			setWpm(WPM);
			console.log("words " + iWords);
			localStorage.setItem("WPM", wpm);
		}

		setStopTyping(new Date());
	};

	const closeSuggestionBox = () => {
		setShowSuggestions(false);
		setSuggestions([]);
		setSuggestionsLoading(false);
	};

	let suggestionTimeout;

	const getSuggestions = (text = "") => {
		if (!text || text.length < 3) {
			return closeSuggestionBox();
		}
		clearTimeout(suggestionTimeout);
		//For handling concurrent on change
		setShowSuggestions(true);
		setSuggestionsLoading(true);
		suggestionTimeout = setTimeout(() => {
			fetchSuggestions(text);
		}, 300);
	};

	const fetchSuggestions = (text = "") => {
		fetchData(text);
	};

	const fetchData = async (text) => {
		try {
			const data = await getCompanyData(text);
			const newData = data.data.map((item) => {
				item.name = item.name.replace(/\b\w/g, (l) => l.toUpperCase());
				return item;
			});
			setSuggestionsLoading(false);
			setSuggestions(newData);
		} catch (err) {
			setSuggestionsLoading(false);
			setSuggestions([]);
		}
	};

	const companySelectionHandler = (item) => {
		closeSuggestionBox();
		setValue("typeOfWork", item.name);
		setIndustry(item.meta.industry);
		sessionStorage.setItem("industry_selected", item.meta.industry);
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};
	console.log("Mouse Click => " + mouseClick);

	const handleJobTitle = (e) => {
		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);

		var iTotal = 0;
		var iKeys = 0;
		var iWords = 0;

		setTypingTime(new Date());

		if (stopTyping !== 0) {
			iKeys++;

			// iTotal += typingTime - stopTyping;
			iTotal += Math.abs(Math.round(typingTime - stopTyping) / 1000 / 60);
			iWords = e.target.value.split(/\s/).length;
			let CPM = ((iKeys / iTotal) * 6000, 2);
			let WPM = ((iWords / iTotal) * 6000, 2);

			console.log("CPM" + CPM + "WPM" + WPM);
			setWpm(WPM);
			console.log("words " + iWords);
			localStorage.setItem("WPM", wpm);
		}

		setStopTyping(new Date());
	};

	function getFirstDayOfMonth(year, month) {
		return new Date(year, month, 1);
	}

	const minimunStartDate = getFirstDayOfMonth(
		new Date().getFullYear(),
		new Date().getMonth()
	);

	return (
		<section className="eduction-new">
			<CProgress
				color="stack-overflow"
				value={(props.steps.currentStep / props.steps.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>Tell us about your employment</b>
								</h1>
							</div>
							{/* <form onSubmit={handleSubmit(handleSave)}> */}
							<div className="address-input-container">
								{/* Here we are recording the type of employment 
                                    from the EmploymentStatus page
                                    in a hidden input
                                    */}
								<input
									type="hidden"
									name="Emp-Status"
									ref={register}
									value="job-offer"
								/>
								<div className="input-container">
									<div className="edubox">
										<label htmlFor="companyName" className="custom-label">
											<b>Name of Company</b>
										</label>

										{/* <div class="form-control-plaintext  custom-input custom-input-container"
                                        style = {{paddingLeft :'0px' , marginLeft:'0px' , paddingRight:'0px',marginRight:'0px'}}>
                                    <Select
                                        style={{marginLeft:'-20px'}}
                                         onKeyDown={(e)=>handleChange(e)}
                                         styles= {styless}
                                            name="typeOfWork"
                                            placeholder="Please Select an option"
                                            options={dropdowncompany}
                                            onChange={(event) =>
                                                handleTypeOfWork(event)
                                            }                          
                                        />
                                        </div> */}
										<AutoComplete
											loading={suggestionsLoading}
											data={suggestions}
											show={showSuggestions}
											selectionHandler={companySelectionHandler}>
											<input
												type="text"
												// id="companyName"
												name="typeOfWork"
												onBlur={() => setTimeout(closeSuggestionBox, 300)}
												autoComplete={"new-field-1"}
												className={
													"custom-input search-input" +
													(errors.companyName ? "custom-input-error col-8" : "")
												}
												onChange={(e) => handleCompanyName(e)}
												onClick={handleClick}
												// value={companyName}
												// onSelect={handleSelect}
												defaultValue={jobCompanyName}
												placeholder="XYZ Company"
												ref={register({
													required: true,
													pattern:
														/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/,
												})}
											/>
										</AutoComplete>
									</div>
									<div className="edubox">
										<label htmlFor="startingDate" className="custom-label">
											<b>When do you start</b>
										</label>

										<div
											className={
												"third-datepicker form-control-plaintext w-67 h-58 custom-input " +
												(errors.startingDate ? "custom-input-error" : "")
											}>
											<DatePicker
												name="startingDate"
												selected={jobStartDate}
												onChange={(date) => {
													setKeyboardClick(keyboardClick + 1);
													localStorage.setItem("KeyBoardClick", keyboardClick);
													setJobStartDate(date);

													var today = new Date(date);
													var mm = String(today.getMonth() + 1).padStart(
														2,
														"0"
													);
													var yyyy = today.getFullYear();
													setPickerDate(yyyy + "-" + mm);
													console.log(date);
												}}
												onClick={handleClick}
												minDate={minimunStartDate}
												maxDate={null}
												dateFormat="yyyy-MM"
												showMonthYearPicker
												showFullMonthYearPicker
												onChangeRaw={(e) => e.preventDefault()}
											/>
										</div>

										{/* <input
                                            type="month"
                                            id="startingDate"
                                            name="startingDate"
                                            className={
                                                "form-control-plaintext w-67 h-58 custom-input " +
                                                (errors.startingDate
                                                    ? "custom-input-error"
                                                    : "")
                                            }
                                            ref={register({ required: true })}
                                            placeholder={moment().format(
                                                "MM/YYYY"
                                            )}
                                            onChange={() => {
                                                setKeyboardClick(
                                                    keyboardClick + 1
                                                );
                                                localStorage.setItem(
                                                    "KeyBoardClick",
                                                    keyboardClick
                                                );
                                            }}
                                            defaultValue={jobStartDate}
                                            onClick={handleClick}
                                            min={start_minStartDate}
                                        /> */}
									</div>
								</div>
								<div className="input-container">
									<div className="edubox">
										<label htmlFor="currentTitle" className="custom-label">
											<b>Job title</b>
										</label>

										<input
											type="text"
											id="currentTitle"
											name="currentTitle"
											className={
												"form-control-plaintext w-67 h-58 custom-input " +
												(errors.currentTitle ? "custom-input-error" : "")
											}
											class="form-control-plaintext w-67 h-58 custom-input"
											placeholder="Data Analysis"
											maxLength={30}
											ref={register({
												required: true,
												pattern:
													/^[-a-z0-9,\/()&:. ]*[a-z][-a-z0-9,\/()&:. ]*$/i,
											})}
											defaultValue={jobTitle}
											onChange={(e) => handleJobTitle(e)}
											onClick={handleClick}
										/>
									</div>
									<div className="edubox">
										<label htmlFor="annualIncome" className="custom-label">
											<b>Annual Income</b>
										</label>
										<div className="amount-input">
											<input
												type="text"
												id="annualIncome"
												name="annualIncome"
												onChange={(event) => {
													const { value } = event.target;
													event.target.value = handleAnnulIncome(value);
													setKeyboardClick(keyboardClick + 1);
													localStorage.setItem("KeyBoardClick", keyboardClick);
												}}
												onClick={handleClick}
												className={
													"form-control-plaintext w-67 h-58 custom-input " +
													(errors.annualIncome ? "custom-input-error" : "")
												}
												defaultValue={jobAnnualIncome}
												placeholder="50,000"
												ref={register({
													required: "Please enter proper value",
												})}
											/>
										</div>
									</div>
								</div>
							</div>
							{Object.keys(errors).length > 0 && (
								<div className="error-container">
									<p className="error-message">Please enter a valid input</p>
								</div>
							)}
							{/* </form> */}
						</div>
						<div className="load-class"></div>
						<div className="button-container button-container-margin">
							{isValid && pickerDate != null ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							) : (
								<button
									className="custom-button disabled"
									disabled={true}
									//disabled
								>
									Next
								</button>
							)}
							<button
								className="custom-button"
								onClick={
									handleBackbutton
									// (handleSave, props.previousStep)
								}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default FutureEmployed;
