import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CProgress,
	CContainer,
	CRow,
	CCol,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useHistory } from "react-router-dom";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { QUADFI_PRIVACY_POLICY_URL } from "src/Utils/consts";
import {
	createPlaidLink,
	createReport,
	logPlaidError,
	saveCashFlow,
	savePlaidInfo,
	sendEmailToUser,
	updateApplicationStatus,
	updateAppStatus,
} from "../../services/user";
import infoIcon from "src/assets/icons/info.svg";

const PlaidConnect = (props) => {
	const [linkToken, setLinkToken] = useState(null);
	const [bankAccountView, setBankAccountView] = useState(true);
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [notificationPopup, setNotificationPopup] = useState(false);
	const [showQa, setShowQa] = useState(false);
	const [loading, setLoading] = React.useState("");
	const history = useHistory();
	const generateToken = async () => {
		const result = await createPlaidLink();
		if (result.error) {
			setLoading(false);
			setNotificationPopup(true);
			setTimeout(()=>{
				handleRedirection();
			},10000)
		} else {
			setLinkToken(result);
		}
	};

	const savePlaid = async (metadata) => {
		const result = await savePlaidInfo(props.user.id, metadata);
		return result;
	};

	const onSuccess = React.useCallback((public_token, metadata) => {
		savePlaid(JSON.stringify(metadata));
		createReport(metadata.public_token);
		props.nextStep();
		//eslint-disable-next-line
	}, []);

	const onExit = React.useCallback(async (error, metadata) => {
		if (error) {
			await logPlaidError(error);
			updateAppStatus();
			history.push("/customerPortal");
		}
		//eslint-disable-next-line
	}, []);

	const onEvent = React.useCallback(async (eventName, metadata) => {
		if (eventName === "EXIT") {
			setNotificationPopup(true);
			await logPlaidError("USER EXITED FROM PLAID");
			updateAppStatus();
		}
	}, []);

	useEffect(() => {
		if (sessionStorage.getItem("plaid_skipped")) {
			sessionStorage.removeItem("plaid_skipped");
			window.location.href = "/customerPortal";
		} else {
			generateToken();
			updateAppStatus();
		}
	}, []);

	const config = {
		token: linkToken,
		onSuccess,
		onExit,
		onEvent,
	};

	const { open, ready } = usePlaidLink(config);
	const openPlaidPopup = async () => {
		try {
			updateAppStatus();
		} catch (err) {}
		open();
		setBankAccountView(false);
		await logPlaidError("USER OPENED PLAID");
	};

	const handleRedirection = async () => {
		window.location.href = "/customerPortal";
	};

	const handleNoPlaid = async () => {
		setLoading(true);
		if (sessionStorage.getItem("appId")) {
			await updateApplicationStatus(
				sessionStorage.getItem("appId"),
				"pending_document_upload"
			);
			await saveCashFlow();
			await sendEmailToUser("Skip Plaid");
			await sendEmailToUser("Documents Request");
			sessionStorage.setItem("plaid_skipped", true);
		}
		setConfirmPopup(false);
		setNotificationPopup(true);
		setLoading(false);
	};

	useEffect(() => {
		const close = (e) => {
		  if(e.key === "Escape"){
			handleRedirection();
		  }
		}
		window.addEventListener('keydown', close)
	  return () => window.removeEventListener('keydown', close)
	},[notificationPopup])
	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper plaid-main-container">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton
						className="save-exit-button"
						onClick={() => {
							history.push("/customerPortal");
						}}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				{bankAccountView ? (
					<div className="no-scroll no-scrolls vertical-middle-page">
						<div className="content-container">
							<div className="text-container spaced">
								<h5 style={{ marginTop: 0 }}>
									<strong>Please note:-</strong>
								</h5>
								<CRow>
									<CCol sm="6" className="left-content">
										<h6 style={{ marginBottom: 5 }}>
											<strong>Customer with Plaid</strong> &#128578;
										</h6>
										<ol style={{ paddingLeft: 15, margin: 0 }}>
											<li>
												<strong>Processing time:</strong> Usually within 24
												hours
											</li>
											<li>
												<strong>Required documents:</strong> Only 2 documents
											</li>
										</ol>
									</CCol>
									<CCol sm="6" className="right-content" style={{ padding: 0 }}>
										<h6 style={{ marginBottom: 5 }}>
											<strong>Customer with NO Plaid</strong> &#128577;
										</h6>
										<ol style={{ paddingLeft: 15, margin: 0 }}>
											<li>
												<strong>Processing time:</strong> Up to 15 days
											</li>
											<li>
												<strong>Required documents:</strong> 7X more documents
												compared to customers with Plaid
											</li>
										</ol>
									</CCol>
								</CRow>
								<h3>
									<b className="highlight">Step 3 of 3</b>
								</h3>
								<h1>
									<b>Almost there!</b>
								</h1>
								<h4>
									Plaid works directly with Canada's leading banks and credit
									unions to create a secure connection to your account. We will
									never sell your personal info, and will only use it with your
									permission.
									<br />
									<br />
									When you use Plaid, your login information is protected from
									everyone else, including QuadFi. We do not access, record or
									store your credentials. For more information, see our privacy
									policy{" "}
									<a
										href={QUADFI_PRIVACY_POLICY_URL}
										target="_blank"
										rel="noreferrer">
										here
									</a>
									.
								</h4>
							</div>
							<div className="load-class"></div>
							<div className="button-container">
								<CButton
									onClick={openPlaidPopup}
									disabled={!ready}
									className="custom-button enabled">
									Connect My Account
								</CButton>
								<p style={{ margin: "10px 2px 0 0" }}>
									{/* eslint-disable-next-line */}
									<a href="javascript:void(0)" onClick={() => setShowQa(true)}>
										<strong>
											<img
												style={{ marginRight: 2 }}
												src={infoIcon}
												alt=""
												width="20"
											/>{" "}
											Plaid Q&A
										</strong>
									</a>
								</p>
							</div>
						</div>
					</div>
				) : null}
			</div>

			{/* Confirmation popup */}
			<CModal
				className="custom-notification-sweetalert vertical-alert"
				show={confirmPopup}
				onClose={() => {
					setConfirmPopup(false);
					openPlaidPopup();
				}}
				color="warning">
				<CModalHeader className="custom-notification-no-header" closeButton>
					<CModalTitle></CModalTitle>
				</CModalHeader>
				<CModalBody className="text-center">
					{loading ? (
						<div className="load-class load-class-absoulte-outter">
							<div className="load-class-inner load-class-absoulte">
								<img src={loadingIcon} alt="" />
							</div>
						</div>
					) : null}
					<p className="custom-notification-title">Some error occured!</p>
					<p className="custom-notification-text">Do you want to try again?</p>
				</CModalBody>
				<CModalFooter>
					<CButton
						className="custom-notification-btn-ok"
						color="info"
						onClick={() => {
							setConfirmPopup(false);
							openPlaidPopup();
						}}>
						Yes
					</CButton>{" "}
					<CButton
						className="custom-notification-btn-cancel"
						color="default"
						onClick={handleNoPlaid}>
						No
					</CButton>
				</CModalFooter>
			</CModal>

			{/* Notification popup */}
			<CModal
				className="custom-notification-sweetalert vertical-alert"
				show={notificationPopup}
				color="warning">
				<CModalHeader className="custom-notification-no-header">
					<CModalTitle></CModalTitle>
				</CModalHeader>
				<CModalBody className="text-center">
					<p className="custom-notification-title">Important Information</p>
					<p className="custom-notification-text">
						You will be redirected to the dashboard, so that you can connect to
						your bank accounts later.
					</p>
				</CModalBody>
				<CModalFooter>
					<CButton
						className="custom-notification-btn-ok"
						color="info"
						onClick={handleRedirection}>
						OK
					</CButton>
				</CModalFooter>
			</CModal>

			<CModal
				show={showQa}
				size="xl"
				onClose={() => setShowQa(false)}
				color="warning">
				<CModalHeader closeButton></CModalHeader>
				<CModalBody>
					<CContainer>
						<CRow>
							<CCol lg="12">
								<h4
									style={{
										textDecoration: "underline",
										fontWeight: "bold",
										textAlign: "center",
									}}>
									Plaid Q&A
								</h4>
							</CCol>
							<CCol lg="12">
								<p
									style={{
										fontSize: "14px",
										color: "black",
										textAlign: "justify",
									}}>
									<section className="autoHeight">
										<div class="auto-container">
											<div class="row clearfix">
												<div class="text-column col-md-12 col-sm-12 col-xs-12">
													<div class="inner">
														<p>
															<strong>What is Plaid?</strong>
														</p>
														<p>
															Plaid helps you securely connect your financial
															accounts to Quad-Fi.
														</p>
														<p>
															<strong> Why does Quad-Fi use Plaid?</strong>
														</p>
														<p>
															Quad-Fi uses Plaid to securely access your
															financial information directly from your financial
															institution in order to verify your identity as
															well as your income
														</p>
														<p>
															<strong>
																What data does Plaid access from my financial
																institution?
															</strong>
														</p>
														<p>
															With your consent Plaid shares the following
															information with Quad-Fi
														</p>
														<p>
															<strong>Account holder information:</strong> name,
															address, phone number, and email address, as held
															by your bank or other financial institution.
														</p>
														<p>
															<strong>Account transactions:</strong> amount,
															date, type, and a description of the transaction;
															and
														</p>
														<p>
															<strong>Account details:</strong> account name,
															account type, account and routing numbers, and
															balance.
														</p>
														<p>
															<strong>
																Does Plaid or Quad-Fi sell my information?
															</strong>
														</p>
														<p>
															No. Quad-Fi & Plaid do not sell or rent the
															personal information we collect. For more
															information on how we use your data, please refer
															to our
															<a
																href="https://quadfi.com/privacy-policy/"
																target="_blank"
																rel="noreferrer">
																End User Privacy Policy
															</a>
															.
														</p>
														<p>
															<strong>
																Does Quad-Fi have access to my banking
																credentials?
															</strong>
														</p>
														<p>
															Quad-Fi does not have access to your username and
															password
														</p>
													</div>
												</div>
											</div>
										</div>
									</section>
								</p>
							</CCol>
						</CRow>
					</CContainer>
				</CModalBody>
				<CModalFooter>
					<CButton color="info" onClick={() => setShowQa(false)}>
						Close
					</CButton>
				</CModalFooter>
			</CModal>
		</section>
	);
};

export default PlaidConnect;
