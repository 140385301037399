import { CButton } from "@coreui/react";
import HelloSign from "hellosign-embedded";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getLoggedUser } from "src/actions/users";
import fileUpload2 from "src/assets/icons/App-Flow-Icons/fileUpload.svg";
import fileUpload from "src/assets/icons/App-Flow-Icons/fileUpload2.svg";
import fileID from "src/assets/icons/fileID.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import {
	CheckHelloSignDoc,
	getEsignDoc,
	getUserBankDetails,
	getVouchedData,
	resendVerificationMail,
} from "../../services/user";
import { ENV } from "../../Utils/consts";

const LegalDoc = (props) => {
	const [docVouched, setDocVouched] = useState([]);
	const [signPopUp, setSignPopUp] = useState(false);
	const [isVerifiedUser, setIsVerifiedUser] = useState({ verification: 0 });
	const [userBankInfo, setUserBankInfo] = useState({ success: false });

	const resendEmailHandler = () => {
		const result = resendVerificationMail(isVerifiedUser.id);
		if (result) {
			toast.success("Check Your Email for verification");
		} else {
			toast.error("Something went wrong");
		}
	};

	const handleLegalOpen = async () => {
		setSignPopUp(true);
		const client = new HelloSign();
		await getEsignDoc(
			sessionStorage.getItem("CurrentSelect"),
			props.DocName.split(".")[0]
		).then((d) => {
			if (d.data) {
				client.open(d.data, {
					clientId: process.env.REACT_APP_ESIGN_DOC_CLIENT_ID,
					testMode: process.env.REACT_APP_ENVIRONMENT_DEPLOY_NAME !== ENV.PROD,
					debug: process.env.REACT_APP_ENVIRONMENT_DEPLOY_NAME !== ENV.PROD,
				});
			}
			setSignPopUp(false);
		});

		client.on("finish", () => {
			props.showLoader();
			let i = 0;
			let interval = setInterval(async () => {
				await CheckHelloSignDoc(
					sessionStorage.getItem("CurrentSelect"),
					props.fileType
				).then((d) => {
					if (d.length > 0 && d[0].approvedByAdmin) {
						props.setreload(!props.reload);
						clearInterval(interval);
					} else {
						i++;
						if (i === 10) {
							props.setreload(!props.reload);
							clearInterval(interval);
							// send message something went wrong
						}
					}
				});
			}, 3000);
		});
	};

	useEffect(() => {
		async function fecthVouchedData() {
			const legalDocData = await getVouchedData(
				sessionStorage.getItem("CurrentSelect"),
				props.fileType
			);
			if (legalDocData.success) {
				setDocVouched(legalDocData.data[0]);
			}
		}
		fecthVouchedData();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		const localFn = async () => {
			let logged_user = await getLoggedUser();
			const userBankDetails = await getUserBankDetails(logged_user.id);
			setUserBankInfo(userBankDetails.data);
			setIsVerifiedUser(logged_user);
		};
		localFn();
	}, []);

	const renderPadConsentEsignButton = () => {
		if (userBankInfo.success) {
			return (
				<CButton
					disabled={
						props.docStatus && isVerifiedUser.verification === 1 ? false : true
					}
					onClick={handleLegalOpen}>
					E-sign
				</CButton>
			);
		} else {
			return <CButton disabled={true}>E-sign</CButton>;
		}
	};
	return (
		<div className="file-card legalDoc_main">
			{signPopUp ? (
				<div className="full-screen-loader">
					<img src={loadingIcon} alt="" />
				</div>
			) : null}

			<div className="header">
				<div className="file-name">
					<h3 className="dcmnts-title">
						<img src={fileUpload} alt="" />
						<b> {props.fileType}</b>
					</h3>
				</div>
				{props.docStatus && props.fileName !== "pad_confirmation" ? (
					props.padConsentFlag === "true" ? (
						<div className="file-status">
							<b>Signed</b>
						</div>
					) : (
						<div className="file-status file-status-pending">
							<b>Processing</b>
						</div>
					)
				) : (
					""
				)}
			</div>
			<div className="main">
				<img
					src={props.fileType === "1 (E-signed)" ? fileID : fileUpload2}
					alt=""
					className={props.fileType === "1 (E-signed)" ? "large_img" : ""}
				/>
				<h4>{/* <b>{props.DocName}</b> */}</h4>

				{isVerifiedUser.verification === 0 ? (
					<p style={{ color: "red" }}>
						Please verify your email first.{" "}
						<span className="resendEmailLink" onClick={resendEmailHandler}>
							Resend Email
						</span>
					</p>
				) : userBankInfo.success ? null : (
					<p style={{ color: "red" }}>
						Your Bank Details are missing , Contact Quadfi regarding your bank
						Details{" "}
					</p>
				)}

				{props.padConsentFlag !== "true" ? (
					<>
						{props.fileType === "PAD_Consent" ||
						props.fileType === "Loan_Agreement" ? (
							renderPadConsentEsignButton()
						) : (
							<CButton
								disabled={
									props.docStatus && isVerifiedUser.verification === 1
										? false
										: true
								}
								onClick={handleLegalOpen}>
								E-sign
							</CButton>
						)}
					</>
				) : (
					""
				)}
				{props.docStatus ? (
					props.padConsentFlag === "true" ? (
						<a
							href={
								props.fileName === "pad_confirmation"
									? `${
											process.env.REACT_APP_API_HOST
									  }/V1/admin/documents/download/${sessionStorage.getItem(
											"CurrentSelect"
									  )}/${props.fileType}`
									: `${process.env.REACT_APP_API_HOST}/V1/admin/download_loan_document/${docVouched.id}/${props.fileType}`
							}>
							Download Doc
						</a>
					) : (
						""
					)
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default LegalDoc;
