import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import fileUpload2 from "src/assets/icons/App-Flow-Icons/fileUpload.svg";
import fileUpload from "src/assets/icons/App-Flow-Icons/fileUpload2.svg";
import fileID from "src/assets/icons/fileID.png";
import { checkFileBeforeUpload } from "src/Utils/fileCheck";
import {
	MULTIPLE_UPLOAD_DOCS,
	MAXIMUM_MULTIPLE_FILES_ALLOWED,
} from "src/Utils/consts";
import { headers } from "../../reusable/headers";

import axios from "axios";
import { getLoggedUser } from "src/actions/users";
import { getUserApplications, userInfoDoc } from "../../services/user";

const FileUpload = (props) => {
	const [user, setUser] = useState({});
	const [applications, setApplications] = useState([]);
	const [data, setData] = useState();
	const [warningMessageModal, setWarningMessageModal] = React.useState(false);
	const [warningMessage, setWarningMessage] = React.useState("");

	const hiddenFileInput = React.useRef(null);

	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};

	console.log(user);

	const handleChange = async (event) => {
		const fileUploaded = event.target.files[0];
		console.log("fileUploaded", fileUploaded);
		await handleAcceptedFiles(fileUploaded);
	};

	const handleMultipleAcceptedFiles = (acceptedFiles) => {
		console.log("Multiple acceptedFiles", acceptedFiles);
		const appId = sessionStorage.getItem("CurrentSelect");
		const data = new FormData();
		let fileNames = [];

		for (let i = 0; i < acceptedFiles.length; i++) {
			data.append("file", acceptedFiles[i]);
			fileNames.push(acceptedFiles[i].name);
		}

		data.append("userId", appId);
		data.append("documentId", props.fileType);

		const endpoint = process.env.REACT_APP_API_HOST + "/V1";
		props.showLoader();
		axios
			.post(`${endpoint}/user/uploadMultipleDocs`, data, {
				headers: headers(),
				withCredentials: true,
			})
			.then((res) => {
				props.hideLoader();
				console.log(res);
				props.handleUploadedValue(fileNames.join(", "));
			})
			.catch((err) => {
				props.hideLoader();
				console.log(err);
			});
	};

	const handleMultipleChange = async (event) => {
		const fileUploaded = event.target.files;
		if (fileUploaded.length > MAXIMUM_MULTIPLE_FILES_ALLOWED) {
			setWarningMessage(
				`Maximum ${MAXIMUM_MULTIPLE_FILES_ALLOWED} files are allowed`
			);
			setWarningMessageModal(true);
			return;
		} else {
			for (let i = 0; i < fileUploaded.length; i++) {
				if (!checkFileBeforeUpload(fileUploaded[i]).success) {
					setWarningMessage(
						"File Upload Failed! Only image (jpeg, jpg, png) and PDF files are allowed with maximum file size upto 5 MB"
					);
					setWarningMessageModal(true);
					return;
				}
			}
			await handleMultipleAcceptedFiles(fileUploaded);
		}
	};

	const handleAcceptedFiles = (acceptedFiles) => {
		if (!checkFileBeforeUpload(acceptedFiles).success) {
			console.log("File Upload Failed");
			return;
		}
		console.log("acceptedFiles", acceptedFiles);
		const appId = sessionStorage.getItem("CurrentSelect");
		const data = new FormData();
		data.append("file", acceptedFiles);
		data.append("userId", appId);
		data.append("documentId", props.fileType);
		const endpoint = process.env.REACT_APP_API_HOST + "/V1";
		props.showLoader();
		axios
			.post(`${endpoint}/user/uploadDoc`, data, {
				headers: headers(),
				withCredentials: true,
			})
			.then((res) => {
				// then print response status
				props.hideLoader();
				console.log(res);
				props.handleUploadedValue(acceptedFiles.name);
			})
			.catch((err) => {
				// then print response status
				props.hideLoader();
				console.log(err);
			});
	};
	useEffect(() => {
		const localFn = async () => {
			console.log(applications);
			console.log(data);
			console.log(props, "props");
			let user = await getLoggedUser();
			const appId = sessionStorage.getItem("appId");
			let userApps = await getUserApplications(user.id);

			if (
				userApps.success &&
				userApps.applications &&
				userApps.applications.length > 0
			) {
				setApplications(userApps.applications);
				const id = sessionStorage.getItem("CurrentSelect");

				const res = await userInfoDoc(id, setUser);
				setData(res);
			}
			console.log("testinggg", props);
			console.log("appId", appId);
		};
		localFn();
		//eslint-disable-next-line
	}, [sessionStorage.getItem("CurrentSelect")]);

	return (
		<div className="file-card">
			<div className="header">
				<div className="file-name">
					<h3 className="dcmnts-title">
						<img src={fileUpload} alt="" />
						<b>
							{props.fileType === "Income"
								? " Recent pay stubs"
								: props.fileType === "Certificate"
								? " Upload Certificate"
								: props.fileType === "Refinancing"
								? " Upload Refinancing"
								: props.fileType === "Student"
								? " Upload Degree"
								: props.fileType}
						</b>
					</h3>
				</div>

				{props.doc_data.approvedByAdmin === 1 ||
				props.doc_data.approvedByAdmin === 3 ? (
					<div className="file-status">
						<b>Verified</b>
					</div>
				) : props.doc_data.approvedByAdmin === 0 ? (
					<div className="file-status file-status-pending">
						<b>Pending Upload</b>
					</div>
				) : props.doc_data.approvedByAdmin === 2 ? (
					<div className="file-status file-status-rejected">
						<b>Pending Verification</b>
					</div>
				) : (
					<div className="file-status file-status-pending">
						<b>Pending Upload</b>
					</div>
				)}
			</div>
			{props.fileType === "Income" ? (
				<div className="info-document doc-info-text">
					<p>Most recent Pay Stub</p>
				</div>
			) : (
				""
			)}
			<div className="main">
				<img
					src={props.fileType === "ID" ? fileID : fileUpload2}
					alt=""
					className={props.fileType === "ID" ? "large_img" : ""}
				/>
				<h5>
					<CButton
						style={{
							padding: "10px 45px",
							border: "0 none",
							backgroundColor: "#ff9e00",
							color: "white",
							fontSize: "16px",
							borderRadius: "30px",
							marginTop: "30px",
						}}
						onClick={handleClick}
						disabled={
							props.doc_data.approvedByAdmin === 1 ||
							props.doc_data.approvedByAdmin === 3
								? true
								: props.doc_data.approvedByAdmin === 0
								? false
								: props.doc_data.approvedByAdmin === 2
								? true
								: props.iscustom
								? true
								: false
						}>
						Upload
					</CButton>
					{MULTIPLE_UPLOAD_DOCS.includes(props.doc_data.doc_name.trim()) ? (
						<input
							type="file"
							multiple={true}
							ref={hiddenFileInput}
							onChange={handleMultipleChange}
							style={{ display: "none" }}
						/>
					) : (
						<input
							type="file"
							multiple={false}
							ref={hiddenFileInput}
							onChange={handleChange}
							style={{ display: "none" }}
						/>
					)}
				</h5>
			</div>

			<CModal
				className="custom-notification-sweetalert vertical-alert"
				show={warningMessageModal}
				onClose={() => setWarningMessageModal(false)}>
				<CModalHeader closeButton>
					<CModalTitle>Warning!</CModalTitle>
				</CModalHeader>
				<CModalBody>{warningMessage}</CModalBody>
				<CModalFooter>
					<CButton
						color="secondary"
						onClick={() => setWarningMessageModal(false)}>
						OK
					</CButton>
				</CModalFooter>
			</CModal>
		</div>
	);
};

export default FileUpload;
