const SUPER_ADMIN = 18;

const STEP_PAGES = {
	PRODUCT_SELECTION: "ProductSelection",
	PRODUCT_PURPOSE: "ProductPurpose",
	REFINANCE_DETAILS: "RefinanceDetails",
	LOAN_AMOUNT: "loanAmount",
	INTRO: "Intro",
	NAME: "Name",
	BIRTH_DATE: "BirthDate",
	CITIZENSHIP: "Citizenship",
	MARITAL_STATUS: "MaritalStatus",
	PHONE_NUMBER: "PhoneNumber",
	ADDRESS: "Address",
	STUDENT_TYPE: "studentType",
	SCHOLAR_SHIP: "scholarship",
	STUDENT_EMPLOYED: "studentEmployed",
	STUDENT_EMPLOYED_STATUS: "StudentEmployedStatus",
	STUDENT_FUTURE_EMPLOYED_STATUS: "StudentFutureEmployedStatus",
	EMPLOYMENT_STATUS: "employmentStatus",
	UNEMPLOYED: "UnEmployed",
	SELF_EMPLOYMENT_STATUS: "SelfEmploymentStatus",
	FUTURE_EMPLOYMENT_STATUS: "FutureEmploymentStatus",
	LIVING_ARRANGMENT: "LivingArrangment",
	MONTHLY_PAYMENT: "MonthlyPayment",
	OTHER_INCOME: "OtherIncome",
	INVESTMENT: "Investment",
	HIGHEST_DEGREE: "HighestDegree",
	EDUCATION: "Education",
	PERSONAL_EDUCATION: "personalEducation",
	EQUIFAX_INTRO: "EquifaxIntro",
	EQUIFAXQ1: "EquifaxQ1",
	EQUIFAXQ2: "EquifaxQ2",
	SOFTCHECK_CONSENT: "softcheck",
	HARDCHECK: "hardcheck",
	OFFER_SUCCESS: "OfferSuccess",
	SUCCESS: "success",
	COSIGNER: "cosigner",
	COSIGNER_NAME: "cosignername",
	COSIGNER_PHONE_NUMBER: "CosignerPhoneNumber",
	COSIGNER_ADDRESS: "CosignerAddress",
	COSIGNER_ANNUAL_INCOME: "cosignerAnnualIncome",
	COSIGNER_LIVING_ARRANGMENT: "cosignerlivingArrangment",
	COSIGNER_CITIZENSHIP: "cosignercitizenship",
	COSIGNER_BIRTHDATE: "cosignerBirthDate",
	REJECTED: "Rejected",
	TRY_LATER: "TryAgainLater",
	PR_DETAILS: "PrDetails",
};
const _PRODUCTID = {
	NEWCOMER: 3,
	PERSONAL: 4,
};

const _STEP_PAGES = STEP_PAGES;
export { _PRODUCTID as PRODUCTID };
export { _STEP_PAGES as STEP_PAGES };

const _SUPER_ADMIN = SUPER_ADMIN;
export { _SUPER_ADMIN as SUPER_ADMIN };
