import React, { useState, useEffect } from "react";
import { updateUser, userInfo, getUserApplications } from "../../services/user";
import { TheSidebar } from "./../../containers/index";
import TopNav from "./../../containers/TopNav";
import menuIcon from "../../assets/icons/menuIcon.png";

import "../../style/personalinformation.scss";

import {
	CContainer,
	CRow,
	CCol,
	CCard,
	CCardBody,
	CCardFooter,
	CFormGroup,
	CLabel,
	CInput,
	CForm,
	CButton,
	CImg,
} from "@coreui/react";
import Loader from "react-loader-spinner";
import { getLoggedUser } from "src/actions/users";

//position, jobtype, field, status
const EmploymentInfo = () => {
	const [hidden, setHidden] = React.useState(true);
	const [position, setPosition] = React.useState("");
	const [applications, setApplications] = useState([]);
	const [user, setUser] = useState({});
	const [status, setStatus] = React.useState("");
	const [applicationId, setApplicationId] = React.useState("");
	const [editShow, setEditShow] = React.useState(false);
	const unRead = [];
	const [loading, setLoading] = React.useState("");
	const hideLoader = () => {
		setLoading(false);
	};
	const field = "";

	console.log(applications);
	console.log(applicationId);
	console.log(editShow);

	const showLoader = () => {
		setLoading(true);
	};
	const handlePosition = (event) => {
		setPosition(event.target.value);
	};

	const handleStatus = (event) => {
		setStatus(event.target.value);
	};

	const handleSave = () => {
		user.employment.position = position;
		user.employment.field = field;
		user.employment.status = status;

		let updatedInformation = {
			page: "employment",
			position: user.employment.position,
			field: user.employment.field,
			status: user.employment.status,
		};

		updateUser(sessionStorage.getItem("appId"), updatedInformation);
		setHidden(true);
	};

	useEffect(() => {
		const localFn = async () => {
			showLoader();

			// Todo: change the userId to the actual user with logs in

			let user = await getLoggedUser();
			let userApps = await getUserApplications(user.id);
			if (
				userApps.success &&
				userApps.applications &&
				userApps.applications.length > 0
			) {
				setApplications(userApps.applications);
			}

			if (
				sessionStorage.getItem("CurrentSelect") !== 0 &&
				sessionStorage.getItem("CurrentSelect") != null
			) {
				setEditShow(true);
				async function Call() {
					setApplicationId(sessionStorage.getItem("CurrentSelect"));
					showLoader();
					await userInfo(sessionStorage.getItem("CurrentSelect"), setUser);
					hideLoader();
				}
				Call();
			} else {
				const option = document.querySelectorAll(".options");

				sessionStorage.setItem("CurrentSelect", option[0].value);
				let userId = option[0].value;
				setApplicationId(option[0].value);
				showLoader();
				await userInfo(userId, setUser);
				hideLoader();
			}
		};
		localFn();
	}, []);

	const [activeCls, setActivecls] = useState(false);
	const menuHandler = () => {
		setActivecls(!activeCls);
	};

	return (
		<div className="PI_page">
			<div
				className={`c-app c-default-layout ${
					activeCls ? "sidBrexpand" : null
				}`}>
				<TheSidebar />
				<div className="c-wrapper">
					<div className="c-body">
						<CContainer fluid className="PageLayout">
							<CRow class="w-87">
								<CCol class="col-auto">
									<div className="user-name-container">
										<h1 className="p-0 pb-2">
											<b>Employment</b>
										</h1>
									</div>
									<CCard>
										<div className="contain_body">
											<CCardBody>
												{!user.employment ? null : (
													<CForm action="" method="post">
														<CFormGroup>
															<CLabel
																htmlFor="status"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Employment Status</b>
															</CLabel>
															{hidden ? (
																<CInput
																	type="status"
																	id="status"
																	name="status"
																	disabled
																	value={user.employment.status.replace(
																		"-",
																		" "
																	)}
																	class="form-control-plaintext w-67 h-58 text-capitalize"
																/>
															) : (
																<CInput
																	type="status"
																	id="status"
																	name="status"
																	onChange={handleStatus}
																	class="form-control w-67 h-58"
																/>
															)}
														</CFormGroup>
														<CFormGroup>
															<CLabel
																htmlFor="position"
																style={{
																	color: "#ff9e00",
																}}>
																{user.employment.status === "self-employed" ? (
																	<b>How are you self employed?</b>
																) : (
																	<b>Job Title</b>
																)}
															</CLabel>
															{hidden ? (
																<CInput
																	type="position"
																	id="position"
																	name="position"
																	disabled
																	value={user.employment.current_title}
																	class="form-control-plaintext w-67 h-58 text-capitalize"
																/>
															) : (
																<CInput
																	type="position"
																	id="position"
																	name="position"
																	onChange={handlePosition}
																	class="form-control w-67 h-58"
																/>
															)}
														</CFormGroup>

														<CFormGroup>
															<CLabel
																htmlFor="typeOfWork"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Company Name</b>
															</CLabel>
															<CInput
																type="typeOfWork"
																id="typeOfWork"
																name="typeOfWork"
																disabled
																value={user.employment.type_of_work}
																class="form-control-plaintext w-67 h-58 text-capitalize"
															/>
														</CFormGroup>

														<CFormGroup>
															<CLabel
																htmlFor="startingDate"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Start Date</b>
															</CLabel>
															<CInput
																type="startingDate"
																id="startingDate"
																name="startingDate"
																disabled
																value={user.employment.starting_date}
																class="form-control-plaintext w-67 h-58 text-capitalize"
															/>
														</CFormGroup>

														<CFormGroup>
															<CLabel
																htmlFor="industry"
																style={{
																	color: "#ff9e00",
																}}>
																<b>Industry</b>
															</CLabel>
															<CInput
																type="industry"
																id="industry"
																name="industry"
																disabled
																value={user.employment.industry}
																class="form-control-plaintext w-67 h-58 text-capitalize"
															/>
														</CFormGroup>
													</CForm>
												)}
												<div
													className={
														"load-class " +
														(!user.employment ? "load_change" : "")
													}>
													{loading ? (
														<Loader
															type="ThreeDots"
															color="#ff9700"
															height={100}
															width={100}
															timeout={10000}
														/>
													) : null}
												</div>
											</CCardBody>
										</div>
										<CCardFooter>
											<CRow>
												<CButton
													shape="pill"
													className="SaveButton"
													style={
														hidden
															? {
																	visibility: "hidden",
															  }
															: {
																	visibility: "visible",
															  }
													}
													onClick={() => handleSave()}>
													Save
												</CButton>
												<CButton
													shape="pill"
													className="CancelButton"
													style={
														hidden
															? {
																	visibility: "hidden",
															  }
															: {
																	visibility: "visible",
															  }
													}
													onClick={() => setHidden(true)}>
													Cancel
												</CButton>
											</CRow>
										</CCardFooter>
									</CCard>
								</CCol>
							</CRow>
						</CContainer>
						<div className="top_navOuter position-relative">
							<button className="bars" onClick={menuHandler}>
								<CImg src={menuIcon}></CImg>
							</button>
							<TopNav unRead={unRead} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(EmploymentInfo);
