import { CButton, CProgress } from "@coreui/react";
import logoIcon from "src/assets/icons/app-logo.png";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "../../Utils/enums";

const Name = (props) => {
	const arrivalTime = new Date();

	const handleSave = async () => {
		localStorage.setItem("PreviousPage", "YES");
		props.nextStep();
		await sendAnalytics(arrivalTime, STEP_PAGES.INTRO);
	};

	const handleSaveAndExit = async () => {
		await sendAnalytics(arrivalTime, STEP_PAGES.INTRO);
		localStorage.setItem("DropOff", "YES");
		window.location.href = "/customerPortal";
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="intro-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="content-container">
					<div className="text-container spaced">
						<h3>
							<b className="highlight">Step 1 of 3</b>
						</h3>
						<h1>
							<b>Designed for you!</b>
						</h1>
						<h4>
							Our process is quick, easy and secure. We collect your answers,
							and data from our partners, always and only with your express
							consent.
						</h4>
						<button className="custom-button enabled" onClick={handleSave}>
							Next
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Name;
