import { CButton, CLabel, CProgress } from "@coreui/react";
import { postcodeValidator } from "postcode-validator";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PlacesAutocomplete, {
	geocodeByAddress,
} from "react-places-autocomplete";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { COUNTRY_ADDRESS_ALLOWED, LANGUAGE_ENGLISH } from "src/Utils/consts";
import { STEP_PAGES } from "src/Utils/enums";
import { getUserInProgressApp, updateUserAppFlow } from "../../services/user";
const componentForm = [
	"route",
	"sublocality",
	"street_number",
	"locality",
	"administrative_area_level_1",
	"country",
	"postal_code",
];

const Address = (props) => {
	const inProgressApp = getUserInProgressApp();
	let preFill = {
		currentAddress: "",
		city: "",
		country: "",
		province: "",
		postalCode: "",
	};
	if (inProgressApp) {
		preFill = {
			currentAddress: inProgressApp.appData.address.currentAddress,
			city: inProgressApp.appData.address.City,
			country: inProgressApp.appData.address.Country,
			province: inProgressApp.appData.address.Province,
			postalCode: inProgressApp.appData.address.postalCode,
			civiNumber: inProgressApp.appData.address.civiNumber,
		};
	}

	const [address, setAddress] = React.useState(preFill.currentAddress);
	const [provinceCode, setProvinceCode] = React.useState("");
	const [fullAddress, setFullAddress] = React.useState(null);
	const [fullCheck, setFullCheck] = React.useState(true);
	const [keyboardClick, setKeyboardClick] = useState(0);
	const [mouseClick, setMouseClick] = useState(0);
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const [postalCodeValue, setPostalCodeValue] = useState("");
	const [isPostalCodeValid, setIsPostalCodeValid] = useState(false);
	const arrivalTime = new Date();
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		getValues,
		formState,
		trigger,
	} = useForm({ reValidateMode: "onChange", mode: "all" });
	const { isValid, errors } = formState;
	const streetAddress = watch("streetAddress");
	const streetAddressNumber = watch("streetAddressNumber");

	React.useEffect(() => {
		updateStreetAddress();
		//eslint-disable-next-line
	}, [streetAddress, streetAddressNumber]);

	const updateStreetAddress = () => {
		console.log("testing effect");
		if (streetAddress !== "" && streetAddressNumber !== "") {
			setAddress(streetAddress);
		} else if (streetAddress !== "") {
			setAddress(streetAddress);
		} else if (streetAddressNumber !== "") {
			setAddress(streetAddressNumber);
		} else if (streetAddress === "" && streetAddressNumber === "") {
			setAddress(streetAddress);
		} else if (streetAddress === "") {
			setAddress(streetAddress);
		} else if (streetAddressNumber === "") {
			setAddress(streetAddressNumber);
		}
	};

	const [loading, setLoading] = React.useState("");
	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	const handleSave = async (data, back) => {
		if (fullAddress == null) {
			setFullCheck(false);
			return;
		} else {
			setNextButtonDisabled(true);
			let details = {
				page: "address",
				currentAddress: address,
				provinceCode: provinceCode,
				...data,
			};
			showLoader();
			const response = await updateUserAppFlow(props.user.id, details);
			if (response.success) {
				setNextButtonDisabled(false);
				hideLoader();
				if (typeof back !== "boolean" || !back) {
					props.nextStep();
					await sendAnalytics(arrivalTime, STEP_PAGES.ADDRESS);
				} else {
					localStorage.setItem("PreviousPage", "YES");
					await sendAnalytics(arrivalTime, STEP_PAGES.ADDRESS);
				}
			}
			return response;
		}
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		if (fullAddress == null) {
			return;
		} else {
			let details = {
				page: "address",
				currentAddress: address,
				...getValues(),
			};
			showLoader();
			const response = await updateUserAppFlow(props.user.id, details);
			if (response.success) {
				hideLoader();
				props.handleSaveExit();
				props.lastStep();
			}
		}
	};

	const handleChange = (address, e) => {
		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);
		if (address === "") {
			setFullAddress(null);
			setFullCheck(false);
			setAddress("");
		} else {
			setFullCheck(true);
			setAddress(address);
		}
	};

	const handleState = (data = []) => {
		for (let i = 0; i < data.length; i++) {
			fillAddress(i, data[i] || "");
		}
	};

	const fillAddress = (index, value) => {
		//eslint-disable-next-line
		switch (index) {
			case 0:
				setValue("streetAddress", value);
				trigger("streetAddress");
				break;
			case 1:
				setValue("streetAddress", value);
				trigger("streetAddress");
				break;
			case 2:
				setValue("streetAddressNumber", value);
				trigger("streetAddressNumber");
				break;
			case 3:
				setValue("city", value);
				trigger("city");
				break;
			case 4:
				setValue("province", value);
				trigger("province");
				break;
			case 5:
				setValue("country", value);
				trigger("country");
				break;
			case 6:
				setValue("postalCode", value);
				trigger("postalCode");
				setPostalCodeValue(value);
				break;
		}
	};

	const handleSelect = async (address) => {
		const results = await geocodeByAddress(address);
		setFullAddress(results);
		updateStreetAddress();
	};

	var searchOptions = {
		componentRestrictions: {
			country: COUNTRY_ADDRESS_ALLOWED,
		},
	};

	React.useEffect(() => {
		if (preFill.currentAddress !== "") {
			handleSelect(preFill.currentAddress);
		}
		//eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		if (fullAddress) {
			let flag = 0,
				indexZeroFlag = 0;
			const address_vals = [];

			for (var i = 0; i < fullAddress[0].address_components.length; i++) {
				var addressType = fullAddress[0].address_components[i].types;
				for (let a of addressType) {
					for (let [index, value] of componentForm.entries()) {
						if (a === value) {
							if (index === 1) {
								flag = 1;
							}
							console.log(flag);
							console.log(index, value);
							if (index === 0) {
								indexZeroFlag = 1;
								index++;
								let streetAddressVal = "";
								if (i === 1) {
									streetAddressVal =
										fullAddress[0].address_components[i - 1].long_name +
										" " +
										fullAddress[0].address_components[i].long_name;
								} else {
									streetAddressVal =
										fullAddress[0].address_components[i].long_name;
								}
								address_vals[index] = streetAddressVal;
								continue;
							}
							if (index === 1 && indexZeroFlag === 1) {
								continue;
							}

							address_vals[index] =
								fullAddress[0].address_components[i].long_name;
						}
					}
				}
			}
			let provinceCode = {};
			//eslint-disable-next-line
			fullAddress[0].address_components.map((itemAddr) => {
				if (itemAddr.types.includes("administrative_area_level_1")) {
					provinceCode = itemAddr;
				}
			});
			if (
				typeof provinceCode.short_name !== "undefined" &&
				provinceCode.short_name !== ""
			) {
				setProvinceCode(provinceCode.short_name);
			}
			handleState(address_vals);
			if (typeof address_vals[6] !== "undefined") {
				setPostalCodeValue(address_vals[6]);
			}
		}
		//eslint-disable-next-line
	}, [fullAddress]);

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	const validatePostalCode = () => {
		const testPostCode = postcodeValidator(postalCodeValue, "CA");
		setIsPostalCodeValid(testPostCode);
	};

	useEffect(() => {
		validatePostalCode();
	});

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>What is your address?</b>
								</h1>
								<p
									className="title-page-note"
									style={{ margin: 0, fontWeight: 600 }}>
									Our product is only available in BC, ON and AB at the moment.
									<br /> We will announce when it becomes available in other
									regions.
								</p>
								<br />
								<p
									className="title-page-note"
									style={{ margin: 0, fontWeight: 600 }}>
									Address should be related to a credit card, mortgage, line of
									credit, etc.
								</p>
							</div>
							<div className="input-container multiple-mobile-inputs">
								<div className="location-search-container">
									<CLabel htmlFor="Location" className="custom-label">
										<b>Street</b>
									</CLabel>
									<input
										type="hidden"
										name="streetAddress"
										ref={register({ required: true })}
									/>
									<input
										type="hidden"
										name="streetAddressNumber"
										ref={register}
									/>

									<PlacesAutocomplete
										value={address}
										onChange={handleChange}
										onSelect={handleSelect}
										searchOptions={searchOptions}>
										{({
											getInputProps,
											suggestions,
											getSuggestionItemProps,
											loading,
										}) => (
											<React.Fragment>
												<div className="search-input">
													<input
														{...getInputProps({
															placeholder: "Start typing your address...",
															className: errors.streetAddress
																? "custom-input custom-input-error"
																: "custom-input ",
														})}
														onClick={handleClick}
													/>
												</div>
												<div
													className={
														suggestions.length > 0
															? "autocomplete-dropdown-container has-options"
															: "autocomplete-dropdown-container"
													}>
													{loading && <div className="loading">Loading...</div>}
													{suggestions.map((suggestion, i) => {
														const className = suggestion.active
															? "suggestion-item--active"
															: "suggestion-item";
														// inline style for demonstration purpose
														const style = suggestion.active
															? {
																	backgroundColor: "#ff9300",
																	cursor: "pointer",
																	padding: "0.5rem 1rem",
															  }
															: {
																	backgroundColor: "#ffffff",
																	cursor: "pointer",
																	padding: "0.5rem 1rem",
															  };
														return (
															<div
																key={i}
																{...getSuggestionItemProps(suggestion, {
																	className,
																	style,
																})}>
																<span>{suggestion.description}</span>
															</div>
														);
													})}
												</div>
											</React.Fragment>
										)}
									</PlacesAutocomplete>
								</div>
								{fullAddress != null && (
									<div>
										<CLabel
											htmlFor="unitNumber"
											className="custom-label"
											onClick={handleClick}>
											<b>Unit Number (optional)</b>
										</CLabel>
										<input
											type="unitNumber"
											id="unitNumber"
											name="unitNumber"
											class="form-control-plaintext w-67 h-58 custom-input"
											placeholder="Unit #"
											ref={register}
											defaultValue={preFill.civiNumber}
										/>
									</div>
								)}
							</div>
							{fullAddress != null && (
								<div className="input-container multiple-mobile-inputs">
									<div>
										<label htmlFor="city" className="custom-label">
											<b>City</b>
										</label>
										<input
											type="city"
											id="city"
											name="city"
											className={
												errors.city
													? "form-control-plaintext w-67 h-58 custom-input custom-input-error"
													: "form-control-plaintext w-67 h-58 custom-input"
											}
											placeholder="City"
											ref={register({
												required: "City is required",
											})}
											onChange={(e) => {
												if (e.target.value === "") {
													setFullCheck(false);
												} else if (fullAddress) {
													setFullCheck(true);
												}
											}}
											defaultValue={preFill.city}
										/>
									</div>
									<div>
										<label htmlFor="province" className="custom-label">
											<b>Province</b>
										</label>
										<input
											type="province"
											id="province"
											name="province"
											className={
												errors.province
													? "form-control-plaintext w-67 h-58 custom-input custom-input-error"
													: "form-control-plaintext w-67 h-58 custom-input"
											}
											placeholder="Province"
											ref={register({
												required: "Province is required",
											})}
											onChange={(e) => {
												if (e.target.value === "") {
													setFullCheck(false);
												} else if (fullAddress) {
													setFullCheck(true);
												}
											}}
											defaultValue={preFill.province}
											readOnly
										/>
									</div>

									<div>
										<label htmlFor="postalCode" className="custom-label">
											<b>Postal Code</b>
										</label>
										<input
											type="postalCode"
											id="postalCode"
											name="postalCode"
											className={
												errors.postalCode
													? "form-control-plaintext w-67 h-58 custom-input custom-input-error"
													: "form-control-plaintext w-67 h-58 custom-input"
											}
											placeholder="Postal Code"
											onChange={(e) => {
												if (e.target.value === "") {
													setFullCheck(false);
												} else if (fullAddress) {
													setFullCheck(true);
												}
												setPostalCodeValue(e.target.value);
												validatePostalCode();
											}}
											ref={register({
												required: "Postal Code is required",
											})}
											defaultValue={preFill.postalCode}
										/>
										{!isPostalCodeValid && postalCodeValue !== "" ? (
											<p style={{ color: "red", paddingLeft: 15 }}>
												Please enter a valid postcode
											</p>
										) : null}
									</div>
									<div>
										<label htmlFor="country" className="custom-label">
											<b>Country</b>
										</label>
										<input
											type="country"
											id="country"
											name="country"
											className={
												errors.country
													? "form-control-plaintext w-67 h-58 custom-input custom-input-error"
													: "form-control-plaintext w-67 h-58 custom-input"
											}
											placeholder="Country"
											ref={register({
												required: "Country is required",
											})}
											onChange={(e) => {
												if (e.target.value === "") {
													setFullCheck(false);
												} else if (fullAddress) {
													setFullCheck(true);
												}
											}}
											defaultValue={preFill.country}
										/>
									</div>
								</div>
							)}
							{Object.keys(errors).length > 0 || fullCheck === false ? (
								<div className="error-container">
									<p className="error-message">Please fill all the inputs</p>
								</div>
							) : null}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{isValid && fullCheck && isPostalCodeValid ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							) : (
								<button
									className="custom-button disabled"
									onClick={handleSubmit(handleSave)}
									disabled={true}>
									Next
								</button>
							)}
							<button
								className="custom-back-button"
								onClick={async () => {
									await handleSave({ currentAddress: address }, true);
									if (props?.previousStep) {
										props.previousStep();
									}
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default Address;
