import { useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import logoIcon from "src/assets/icons/app-logo.png";
import store from "src/store";
import { getLoggedUser, LoginUser } from "../../actions/users";
import { checkTwoFa } from "../../services/twoFaService";
import {
	getUserAppDataByAppId,
	redirectUser,
	getUserLatestApplication,
} from "../../services/user";
import { reloadApplication } from "../../utils";
import { hotjar } from "react-hotjar";
const Login = (props) => {
	// Add State / Rerouting to other offers
	const history = useHistory();

	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [isTwoFaEnabled, setTwoFa] = useState(false);
	const [signUpDisabled , setSignUpDisabled] = useState(false);

	const isEmail = (email) => {
		if (/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(email)) {
			return true;
		}
		return false;
	};

	const checkErrors = () => {
		if (!formData.email || formData.email === "") {
			errors.email = "Email is required";
			setErrors({ ...errors, email: "Email is required" });
		} else if (!isEmail(formData.email)) {
			errors.email = "Enter a valid email.";
			setErrors({ ...errors, email: "Enter a valid email." });
		} else {
			delete errors.email;
			setErrors({ ...errors, email: "" });
		}
		if (!formData.password || formData.password === "") {
			errors.password = "Password is required";
			setErrors({ ...errors, password: "Password is required" });
		} else if (formData.password.length < 8) {
			errors.password = "Password should be of minimum 8 characters.";
			setErrors({
				...errors,
				password: "Password should be of minimum 8 characters.",
			});
		} else {
			delete errors.password;
			setErrors({ ...errors, password: "" });
		}
	};

	const handleCompleteApplication = async (app) => {
		sessionStorage.setItem("appId", app.appId);
		sessionStorage.setItem("productId", app.productId);
		sessionStorage.setItem("refreshed", "1");
		await reloadApplication(history);
	};

	async function handleSubmit() {
		setSignUpDisabled(true)
		setTimeout(()=>{
			setSignUpDisabled(false)
		},1500)
		checkErrors();
		if (!errors || Object.keys(errors).length === 0) {
			console.log("no errors", errors);
			if (formData.tfa) {
				console.log("Validate Tfa");
			} else {
				const data = await checkTwoFa(formData);
				if (data.success) {
					setTwoFa(true);
					return;
				}
			}

			await props.LoginUser(formData);
			const loggedUser = await getLoggedUser();

			if (store.getState().users.currentUser.user) {
				//Trigger hotjar
				const userAppData = await getUserLatestApplication();
				if (
					typeof userAppData.application !== "undefined" &&
					userAppData.application
				) {
					hotjar.identify(loggedUser.email, {
						loanId: userAppData.application.loan_id,
						email: loggedUser.email,
					});
				}

				await redirectUser(store.getState().users.currentUser.user.id);
			}
			if (store.getState().users.currentUser.application) {
				const userAppDetails = await getUserAppDataByAppId(
					store.getState().users.currentUser.application[0].appId
				);
				sessionStorage.setItem(
					"inprogress_app",
					JSON.stringify(userAppDetails.data)
				);
				if (store.getState().users.currentUser.application[0].status_id > 15) {
					history.push(`customerPortal`); // if status id greater than 15 than redirect to customer portal
				} else {
					//when status id less than 16 than redirect to last page
					handleCompleteApplication(
						store.getState().users.currentUser.application[0]
					);
				}
			} else {
				history.push(`customerPortal`);
			}
		}
	}
	const handleStart = () => {
		localStorage.clear();
		sessionStorage.clear();
		document.location.href = "/";
	};

	return (
		<section>
			<div className="base-container sign-in-box">
				<div className="header">
					<img src={logoIcon} alt="QuadFi Logo" />
				</div>
				<div className="content">
					<div className="form">
						<div className="form-group">
							<input
								type="email"
								name="email"
								placeholder="Email Address"
								className="user-input"
								value={formData.email}
								onChange={(e) =>
									setFormData({
										...formData,
										email: e.target.value,
									})
								}
							/>
							<p>{errors.email}</p>
						</div>
						<div className="form-group">
							<input
								type="password"
								name="password"
								placeholder="Password"
								className="user-input"
								value={formData.password}
								onChange={(e) =>
									setFormData({
										...formData,
										password: e.target.value,
									})
								}
							/>
							{errors.password ? <p>{errors.password}</p> : ""}
						</div>
						{isTwoFaEnabled && (
							<div className="form-group">
								<input
									type="text"
									name="2facode"
									placeholder="Enter Code"
									className="user-input"
									onChange={(e) =>
										setFormData({
											...formData,
											tfa: e.target.value,
										})
									}
								/>
								<p style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>
									We protect your account with 2 factor authorization.
									<br /> We’ve just sent you a code by email. Please find the
									<br /> email and enter the code here.
								</p>
								<p>{errors.password}</p>
							</div>
						)}
					</div>
				</div>
				<div className="cta-button">
					<button
						type="button"
						className="btn custom-button"
						disabled={signUpDisabled}
						onClick={() => handleSubmit()}>
						Sign In
					</button>
				</div>
				<div className="footer">
					<a href="/forgetpassword">Forgot password?</a>
					<span>
						Don't have an account? <Link to="/signup">Create an account</Link>
					</span>
				</div>
			</div>
			<div
				className="save-exit-button-container"
				onClick={handleStart}
				style={{
					position: "absolute",
					top: 15,
					right: 15,
					width: "auto",
				}}>
				<a
					href="#start-application"
					style={{ display: "inline-block", padding: "5px 10px" }}
					className="save-exit-button">
					{" "}
					Start Application{" "}
				</a>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	data: state.users,
	success_message: state.users.success_message,
	success: state.users.success,
});

export default withRouter(connect(mapStateToProps, { LoginUser })(Login));
