import { CButton, CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import marriedIcon from "src/assets/icons/App-Flow-Icons/marital-married.png";
import singleIcon from "src/assets/icons/App-Flow-Icons/marital-single.png";
import skipIcon from "src/assets/icons/App-Flow-Icons/skip-icon.png";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { Tooltip } from "src/Components/Tooltip/Tooltip";
import { sendAnalytics } from "src/services/analyticsService";
import { debug } from "src/services/debugService";
import { STEP_PAGES } from "src/Utils/enums";
import { getUserInProgressApp, updateUserAppFlow } from "../../services/user";

export default function MartialStatus(props) {
	const inProgressApp = getUserInProgressApp();
	let preFill = "";
	if (inProgressApp) {
		preFill = inProgressApp.appData.personal.marital_status;
	}
	const [maritalStatus, setMaritalStatus] = React.useState(preFill);
	const { register, errors, handleSubmit } = useForm({
		mode: "onChange",
	});
	const [loading, setLoading] = React.useState("");
	const [mouseClick, setMouseClick] = useState(1);
	const arrivalTime = new Date();
	debug("MartialStatus props: ", props, arrivalTime);

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	useEffect(() => {
		if (maritalStatus !== "" && mouseClick > 1) {
			handleSave({ maritalStatus: maritalStatus });
		}
		//eslint-disable-next-line
	}, [maritalStatus, mouseClick]);

	const handleSave = async (data, back) => {
		const details = {
			page: "maritalStatus",
			maritalStatus: data.maritalStatus,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			hideLoader();
			if (typeof back !== "boolean" || !back) {
				props.nextStep();
				await sendAnalytics(arrivalTime, STEP_PAGES.MARITAL_STATUS);
			} else {
				localStorage.setItem("PreviousPage", "YES");
				await sendAnalytics(arrivalTime, STEP_PAGES.MARITAL_STATUS);
			}
		}
		return response;
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		const details = {
			page: "maritalStatus",
			maritalStatus,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			hideLoader();
			props.handleSaveExit();
			props.lastStep();
			await sendAnalytics(arrivalTime, STEP_PAGES.MARITAL_STATUS);
		}
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll tablet vertical-middle-page">
					<div>
						<div class="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>What is your marital status?</b>
								</h1>
							</div>
							<div className="cards-container">
								<div className="icon-card">
									<input
										type="radio"
										id="Single"
										name="maritalStatus"
										value="Single"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onChange={(event) => setMaritalStatus(event.target.value)}
										onClick={handleClick}
										checked={
											(preFill !== "" && preFill === "Single") ||
											maritalStatus === "Single"
												? true
												: false
										}
									/>
									<label htmlFor="Single" className="card-label">
										<img src={singleIcon} alt="" />
										<b>Single</b>
									</label>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="Married"
										name="maritalStatus"
										value="Married"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onChange={(event) => setMaritalStatus(event.target.value)}
										onClick={handleClick}
										checked={
											(preFill !== "" && preFill === "Married") ||
											maritalStatus === "Married"
												? true
												: false
										}
									/>
									<label htmlFor="Married" className="card-label">
										<img src={marriedIcon} alt="" />
										<b>Married / Common-Law</b>
									</label>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="Widowed"
										name="maritalStatus"
										value="Skip"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onChange={(event) => setMaritalStatus(event.target.value)}
										onClick={handleClick}
										checked={
											(preFill !== "" && preFill === "Skip") ||
											maritalStatus === "Skip"
												? true
												: false
										}
									/>
									<label htmlFor="Widowed" className="card-label">
										<img src={skipIcon} alt="" />
										<b>Skip</b>
									</label>
								</div>
							</div>
							{Object.keys(errors).length > 0 && (
								<div className="error-container">
									<p className="error-message">
										{errors[Object.keys(errors)[0]].message}
									</p>
								</div>
							)}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{preFill && preFill !== "" ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}>
									Next
								</button>
							) : null}
							<button
								className="custom-back-button"
								onClick={async () => {
									await handleSave({ maritalStatus: maritalStatus }, true);
									if (props?.previousStep) {
										props.previousStep();
									}
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
}
