import React, { useEffect, useState } from "react";
import { updateApp, updateAppProduct } from "../../services/user";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { GetAllPuposes } from "../../actions/purposes";
import store from "../../store";
import { toast } from "react-toastify";
import logoIcon from "src/assets/icons/app-logo.png";
import Spinner from "src/page/Application_flow/Loading";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { useForm } from "react-hook-form";
import infoIcon from "src/assets/icons/info.svg";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";

const ProductPurpose = (props) => {
	// Add State / Functionality
	const history = useHistory();

	const [allPurposes, setAllPurposes] = useState([]);
	const [purpose, setPurpose] = useState({});

	const [mouseClick, setMouseClick] = useState(0);

	const arrivalTime = new Date();
	const keyboardClick = 0;
	const wpm = 0;

	localStorage.setItem("KeyBoardClick", keyboardClick);
	localStorage.setItem("mouseCount", mouseClick);
	localStorage.setItem("WPM", wpm);

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};
	console.log("Mouse Click => " + mouseClick);

	const { register, errors } = useForm({
		mode: "onChange",
	});

	const [loading, setLoading] = React.useState("");
	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};
	useEffect(() => {
		const localFn = async () => {
			showLoader();
			const purposeid = JSON.parse(localStorage.getItem("ProductSelection"));
			await props
				.GetAllPuposes(purposeid.id)
				.then((d) => console.log(d, "purposedata"));
			hideLoader();
			if (
				localStorage.getItem("ProductPurpose") &&
				JSON.parse(localStorage.getItem("ProductPurpose")).value &&
				JSON.parse(localStorage.getItem("ProductPurpose")).id
			) {
				const data = JSON.parse(localStorage.getItem("ProductPurpose"));
				console.log(data);
				await setPurpose({ value: data.value, id: data.id });
				await handleSave();
			}
			setAllPurposes(store.getState().purposes.purposes);
		};
		localFn();
		//eslint-disable-next-line
	}, [purpose]);

	const handleRadioCheck = async (e, obj) => {
		setPurpose({ value: e.target.value, id: obj.id });
		localStorage.setItem(
			"ProductPurpose",
			JSON.stringify({ value: e.target.value, id: obj.id })
		);
		await sendAnalytics(arrivalTime, STEP_PAGES.PRODUCT_PURPOSE);
	};

	const handleSave = async () => {
		if (sessionStorage.getItem("appId")) {
			let appId = sessionStorage.getItem("appId");
			let updatedInformation = {
				page: "purpose",
				appId: appId,
			};
			showLoader();
			if (appId) {
				await updateApp(updatedInformation);
				await updateAppProduct({
					page: "purpose",
					purposeId: purpose.id,
				});
				hideLoader();
				history.push("loanAmount");
			} else {
				hideLoader();
				toast.error("Application is invalid.");
			}
		} else {
			sessionStorage.setItem("purposeId", purpose.id);
			history.push("loanAmount");
		}
	};

	let a = false;
	if (a) return <Spinner />;

	return (
		<section className="purposeWrappers">
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div className="purpose-outer no-scroll tiles-3 vertical-middle-page">
					<div className="content-container">
						<div className="text-container">
							<h1 className="product-title">
								<b>What would you like to finance?</b>
								<span className="info-container-product info-with-question">
									<p className="tool-tip-product">
										<b>
											Your choice won't affect your rate - we just want to be
											helpful!
										</b>
									</p>
									<img width="18px" height="18px" src={infoIcon} alt="" />
								</span>
							</h1>
						</div>
						<div className="purpose-row">
							{allPurposes &&
								allPurposes.map((p) => (
									<div className="icon-card">
										<input
											type="radio"
											id={`${p.title.toLowerCase()}-purpose`}
											name="product-purpose"
											value={`${p.title.toLowerCase()}-purpose`}
											className="custom-radio"
											ref={register({
												required: "Please select one of the options",
											})}
											onChange={(e) => handleRadioCheck(e, p)}
										/>
										<label
											htmlFor={`${p.title.toLowerCase()}-purpose`}
											className="card-label"
											onClick={handleClick}>
											<img src={`${p.path}`} alt="" />
											<b className="inboxtitle">{p.title}</b>
										</label>
									</div>
								))}
						</div>
						{errors["product-purpose"] && (
							<div className="error-container">
								<p className="error-message">
									Please select one of the options
								</p>
							</div>
						)}
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container">
							<button
								className="custom-back-button"
								onClick={() => {
									if (sessionStorage.getItem("productId") === "3") {
										history.push("/pr-details")
									} else {
										localStorage.removeItem("ProductSelection");
										sessionStorage.removeItem("productId");
										history.push("/");
									}
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	data: state.purposes,
});

export default withRouter(
	connect(mapStateToProps, { GetAllPuposes })(ProductPurpose)
);
