import Slider from "@material-ui/core/Slider";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
	browserName,
	isAndroid,
	isDesktop,
	isIOS,
	isMobileOnly,
	isTablet,
	isWinPhone,
} from "react-device-detect";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { sendAnalytics } from "src/services/analyticsService";
import { STEP_PAGES } from "src/Utils/enums";
import {
	checkGatewayLimit,
	getLoanAmountDetails,
	updateApp,
} from "../../services/user";
import { LOAN_AMOUNT } from "../../Utils/consts";
import NextButton from "./NextButton";

const LoanAmount = (props) => {
	// Add State and Funtionality
	const history = useHistory();

	// const [loanAmount, setLoanAmount] = useState(0);
	const [loading, setLoading] = React.useState("");
	const [isError, setError] = useState("no");
	const [selectSliderError, setSelectSliderError] = useState("no");

	const selectedLoan = JSON.parse(localStorage.getItem("ProductSelection")).id;

	const [keyboardClick, setKeyboardClick] = useState(0);
	const [mouseCount, setMouseCount] = useState(0);
	const [wpm, setWpm] = useState(0);
	const [stopTyping, setStopTyping] = useState(new Date());
	const [typingTime, setTypingTime] = useState(new Date());
	const [productDetails, setProductDetails] = useState([]);

	const arrivalTime = new Date();
	const doNotTrackMsg = "";
	const deviceCheckMsg = "";
	const deviceIP = "";
	const browserType = "";
	const operatngSystem = "";

	//max_amount , min_amount
	let min, max, step, marks, default_set_amount;
	console.log("product details::::::::");
	console.log(productDetails);
	switch (selectedLoan.toString()) {
		case "1":
			//student loan
			min =
				productDetails[0] && productDetails[0].min_amount
					? productDetails[0].min_amount
					: LOAN_AMOUNT.STUDENTS_MIN;
			max =
				productDetails[0] && productDetails[0].max_amount
					? productDetails[0].max_amount
					: LOAN_AMOUNT.STUDENTS_MAX;
			default_set_amount =
				productDetails[0] && productDetails[0].default_amount
					? productDetails[0].default_amount
					: LOAN_AMOUNT.STUDENTS_DEFAULT;
			step = 500;
			marks = [
				{
					value:
						productDetails[0] && productDetails[0].min_amount
							? productDetails[0].min_amount
							: min,
					label: `$${
						productDetails[0] && productDetails[0].min_amount
							? productDetails[0].min_amount
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							: min
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}`,
				},
				{
					value:
						productDetails[0] && productDetails[0].max_amount
							? productDetails[0].max_amount
							: max,
					label: `$${
						productDetails[0] && productDetails[0].max_amount
							? productDetails[0].max_amount
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							: max
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}`,
				},
			];
			break;
		case "2":
			//refinance loan
			min =
				productDetails[1] && productDetails[1].min_amount
					? productDetails[1].min_amount
					: LOAN_AMOUNT.REFINANCING_MIN;
			max =
				productDetails[1] && productDetails[1].max_amount
					? productDetails[1].max_amount
					: LOAN_AMOUNT.REFINANCING_MAX;
			default_set_amount =
				productDetails[1] && productDetails[1].default_amount
					? productDetails[1].default_amount
					: LOAN_AMOUNT.REFINANCING_DEFAULT;
			step = 500;
			marks = [
				{
					value:
						productDetails[1] && productDetails[1].min_amount
							? productDetails[1].min_amount
							: min,
					label: `$${
						productDetails[1] && productDetails[1].min_amount
							? productDetails[1].min_amount
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							: min
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}`,
				},
				{
					value:
						productDetails[1] && productDetails[1].max_amount
							? productDetails[1].max_amount
							: max,
					label: `$${
						productDetails[1] && productDetails[1].max_amount
							? productDetails[1].max_amount
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							: max
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}`,
				},
			];
			break;
		case "3":
			//international loan
			min =
				productDetails[2] && productDetails[2].min_amount
					? productDetails[2].min_amount
					: LOAN_AMOUNT.NEWCOMER_MIN;
			max =
				productDetails[2] && productDetails[2].max_amount
					? productDetails[2].max_amount
					: LOAN_AMOUNT.NEWCOMER_MAX;
			default_set_amount =
				productDetails[2] && productDetails[2].default_amount
					? productDetails[2].default_amount
					: min;
			step = 500;
			marks = [
				{
					value:
						productDetails[2] && productDetails[2].min_amount
							? productDetails[2].min_amount
							: min,
					label: `$${
						productDetails[2] && productDetails[2].min_amount
							? productDetails[2].min_amount
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							: min
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}`,
				},
				{
					value:
						productDetails[2] && productDetails[2].max_amount
							? productDetails[2].max_amount
							: max,
					label: `$${
						productDetails[2] && productDetails[2].max_amount
							? productDetails[2].max_amount
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							: max
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}`,
				},
			];
			break;
		case "4":
			//personal loan
			min =
				productDetails[3] && productDetails[3].min_amount
					? productDetails[3].min_amount
					: LOAN_AMOUNT.PERSONAL_MIN;
			max =
				productDetails[3] && productDetails[3].max_amount
					? productDetails[3].max_amount
					: LOAN_AMOUNT.PERSONAL_MAX;
			default_set_amount =
				productDetails[3] && productDetails[3].default_amount
					? productDetails[3].default_amount
					: LOAN_AMOUNT.PERSONAL_DEFAULT;
			step = 500;
			marks = [
				{
					value:
						productDetails[3] && productDetails[3].min_amount
							? productDetails[3].min_amount
							: min,
					label: `$${
						productDetails[3] && productDetails[3].min_amount
							? productDetails[3].min_amount
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							: min
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}`,
				},
				{
					value:
						productDetails[3] && productDetails[3].max_amount
							? productDetails[3].max_amount
							: max,
					label: `$${
						productDetails[3] && productDetails[3].max_amount
							? productDetails[3].max_amount
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							: max
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}`,
				},
			];
			break;
		case "5":
			//New grad
			min =
				productDetails[4] && productDetails[4].min_amount
					? productDetails[4].min_amount
					: LOAN_AMOUNT.GRADUATE_MIN;
			max =
				productDetails[4] && productDetails[4].max_amount
					? productDetails[4].max_amount
					: LOAN_AMOUNT.GRADUATE_MAX;
			default_set_amount =
				productDetails[4] && productDetails[4].default_amount
					? productDetails[4].default_amount
					: LOAN_AMOUNT.GRADUATE_DEFAULT;
			step = 500;
			marks = [
				{
					value:
						productDetails[4] && productDetails[4].min_amount
							? productDetails[4].min_amount
							: min,
					label: `$${
						productDetails[4] && productDetails[4].min_amount
							? productDetails[4].min_amount
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							: min
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}`,
				},
				{
					value:
						productDetails[4] && productDetails[4].max_amount
							? productDetails[4].max_amount
							: max,
					label: `$${
						productDetails[4] && productDetails[4].max_amount
							? productDetails[4].max_amount
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							: max
									.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}`,
				},
			];
			break;
		default:
			min = 10000;
			max = 100000;
			step = 500;
			default_set_amount = 1000;
			marks = [
				{
					value: 10000,
					label: "$10,000",
				},
				{
					value: 100000,
					label: "$100,000",
				},
			];
			break;
	}
	const [value, setValue] = React.useState(min);
	const [loanAmount, setLoanAmount] = useState(min);
	const [loanAmountValue, setLoanAmountValue] = React.useState(
		min
			.toString()
			.replace(/\D/g, "")
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	);

	console.log("default_set_amount : " + default_set_amount);

	useEffect(() => {
		const localFn = async () => {
			const getloanamountdetails = await getLoanAmountDetails();
			setProductDetails(getloanamountdetails);
		};
		localFn();
	}, []);

	const handleDoNotTrack = () => {
		let message = "";

		if (navigator.doNotTrack) {
			message = "ON";
		} else {
			message = "OFF";
		}
		// setDoNotTrackMsg(message);
		localStorage.setItem("DoNotTrack", message);
	};

	const handleDeviceCheck = () => {
		let message = "";

		if (isDesktop) {
			message = "Dekstop";
		} else if (isMobileOnly) {
			if (isWinPhone) {
				message = "Windows Mobile";
			}
			if (isAndroid) {
				message = "Android Mobile";
			}
			if (isIOS) {
				message = "iPhone";
			} else {
				message = "Unknown Mobile";
			}
		} else if (isTablet) {
			message = "Tablet";
		} else {
			message = "Unknown Device";
		}
		localStorage.setItem("deviceType", message);
	};

	const handleDeviceIP = async () => {
		const res = await axios.get("https://geolocation-db.com/json/");

		localStorage.setItem("ClientIP", res.data.IPv4);
	};

	const handleBrowser = () => {
		let message = "";

		if (browserName) {
			message = browserName;
		}
		localStorage.setItem("Browser", message);
	};

	const handleOperatingSystem = () => {
		let platform = window.navigator.platform;
		localStorage.setItem("OS", platform);
	};

	console.log(
		"Device => " +
			deviceCheckMsg +
			", Device IP => " +
			deviceIP +
			", Device Browser => " +
			browserType +
			", Do Not Track => " +
			doNotTrackMsg +
			", Operating System => " +
			operatngSystem
	);

	useEffect(() => {
		handleDoNotTrack();
		handleDeviceCheck();
		handleDeviceIP();
		handleBrowser();
		handleOperatingSystem();
	}, []);

	const mouseClickCount = () => {
		var count = mouseCount + 1;
		setMouseCount(count);
		localStorage.setItem("mouseCount", mouseCount);
		localStorage.setItem("PreviousPage", "NO");
	};

	const { handleSubmit } = useForm({
		mode: "onChange",
	});

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	useEffect(() => {
		const localFn = async () => {
			if (JSON.parse(localStorage.getItem("LoanAmount"))) {
				const data = JSON.parse(localStorage.getItem("LoanAmount"));
				console.log(data);
				await setLoanAmount(data);
				await handleSave();
			}
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	const handleSave = async () => {
		console.log("loannnnnnnnn", loanAmount);
		if (sessionStorage.getItem("appId") != null) {
			showLoader();
			let appId = sessionStorage.getItem("appId");
			let updatedInformation = {
				page: "loanAmount",
				appId: appId,
				loanAmount: loanAmount,
			};
			if (appId) {
				await updateApp(updatedInformation);
				hideLoader();
				history.push("layout");
				// window.location.reload();
			} else {
				hideLoader();
				toast.error("Application is invalid.");
			}
		} else {
			const { success, redirect } = await checkGatewayLimit({
				loanAmount: loanAmount,
			});
			if (!sessionStorage.getItem("loanAmount")) {
				sessionStorage.setItem("loanAmount", loanAmount);
			}
			if (!localStorage.getItem("LoanAmount")) {
				localStorage.setItem("LoanAmount", loanAmount);
			}
			if (localStorage.getItem("LoanAmount")) {
				if (success) {
					if (redirect) {
						window.location.href = "/be-first-to-know";
					} else {
						history.push("signin");
						// Don't do any change
					}
				}
			}

			if (success) {
				if (redirect) {
					window.location.href = "/be-first-to-know";
				} else {
					history.push("signup");

					// Don't do any change
				}
			}
		}
	};

	const handleSliderChange = (event, newValue) => {
		setError("no");
		setSelectSliderError("yes");
		const newVal = newValue
			.toString()
			.replace(/\D/g, "")
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		setLoanAmountValue(newVal);
		setValue(newValue);
		setLoanAmount(newValue);
		localStorage.setItem("LoanAmount", newValue);
		sessionStorage.setItem("loanAmount", newValue);
	};

	const handleInputChange = async (event) => {
		var iTotal = 0;
		var iKeys = 0;
		var iWords = 0;

		setTypingTime(new Date());

		if (stopTyping !== 0) {
			iKeys++;
			iTotal += Math.abs(Math.round(stopTyping - typingTime) / 1000);
			iWords = event.target.value.split(/\s/).length;
			let CPM = ((iKeys / iTotal) * 6000, 2);
			let WPM = ((iWords / iTotal) * 6000, 2);

			setWpm(WPM);
			localStorage.setItem("WPM", wpm);
			console.log(CPM);
		}

		setStopTyping(new Date());

		setKeyboardClick(keyboardClick + 1);
		localStorage.setItem("KeyBoardClick", keyboardClick);

		const val = event.target.value.replace(/,/g, "");
		if (val < min || val > max) {
			setError("yes");
		} else {
			setError("no");
			setSelectSliderError("yes");
		}
		setLoanAmountValue(
			val
				.toString()
				.replace(/\D/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		);
		const num = Number(val);
		if (Number.isNaN(num)) {
			setError("yes");
		}
		setLoanAmount(val);
		setValue(val);
		localStorage.setItem("LoanAmount", val);
		await sendAnalytics(arrivalTime, STEP_PAGES.LOAN_AMOUNT);
	};

	const handleBlur = () => {
		if (value < min || Number.isNaN(Number(value))) {
			setLoanAmountValue(
				min
					.toString()
					.replace(/\D/g, "")
					.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			);
			setValue(min);
			setLoanAmount(min);
			localStorage.setItem("LoanAmount", min);
		} else if (value > max) {
			setLoanAmountValue(
				max
					.toString()
					.replace(/\D/g, "")
					.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			);
			setValue(max);
			setLoanAmount(max);
			localStorage.setItem("LoanAmount", max);
		}
		setTimeout(() => {
			setError("no");
		}, 300);
	};
	const valueLabelFormat = (value) => {
		let areaLabel = value
			.toString()
			.replace(/\D/g, "")
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return `$${areaLabel}`;
	};
	// Render
	return (
		<section>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div className="no-scroll vertical-middle-page">
					<div className="content-container">
						<div className="text-container">
							<h1>
								<b>How much do you need?</b>
							</h1>
						</div>
						<div className="amount-input left-10">
							<input
								className={
									"custom-input amount-pointer" +
									(isError === "yes" ? "custom-input-error" : "")
								}
								value={loanAmountValue}
								margin="dense"
								onChange={handleInputChange}
								onClick={mouseClickCount}
								onBlur={handleBlur}
								inputProps={{
									step: { step },
									min: { min },
									max: { max },
									type: "number",
									"aria-labelledby": "loan-slider",
								}}
								name="loan-amount"
								placeholder="Enter your loan amount"
								id="loan-amount"
								readOnly={true}
							/>
						</div>
						<div className="loan-slider">
							<Slider
								value={value}
								onChange={handleSliderChange}
								onClick={mouseClickCount}
								aria-labelledby="loan-slider"
								step={step}
								marks={marks}
								min={min}
								max={max}
								valueLabelDisplay="auto"
								valueLabelFormat={valueLabelFormat}
							/>
						</div>

						{isError === "yes" ? (
							<div className="error-container">
								<p className="error-message">Please enter a valid amount</p>
							</div>
						) : null}
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container">
							{isError === "no" && selectSliderError === "yes" ? (
								<NextButton
									handleSave={handleSubmit(handleSave)}
									route="/layout"
									className="custom-button enabled"
								/>
							) : (
								<NextButton
									handleSave={handleSubmit(handleSave)}
									route="/layout"
									className="custom-button enabled"
								/>
								// <NextButton className="custom-button disabled"/>
							)}
							<button
								className="custom-back-button"
								onClick={async () => {
									await localStorage.removeItem("ProductPurpose");
									localStorage.setItem("PreviousPage", "YES");
									sessionStorage.removeItem("purposeId");
									history.push("productPurpose");
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default LoanAmount;
