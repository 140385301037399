import axios from "axios";
import "core-js";
import { createBrowserHistory } from "history";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import App from "./App";
import { icons } from "./assets/icons";
import "./polyfill";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID);

createBrowserHistory().listen((location) => {
	ReactGA.set({ page: location.pathname + location.hash });
	ReactGA.pageview(location.pathname + location.hash);
	window.analytics.page({
		path: location.pathname + location.hash,
	});
});
const UserContext = React.createContext({});

if (process.env.REACT_APP_LOGGING_OUTPUT !== "true") {
	console.log = () => {};
}

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		let appPaths = [
			"/",
			"/productPurpose",
			"/loanAmount",
			"/signup",
			"/signin",
			"/forgetpassword",
			"/verify",
			"/forgetpass",
			"/be-first-to-know",
			"/try-again-later",
			"/try-offer-later",
		];

		if (
			!window.location.pathname.split("/").includes("reset") &&
			!window.location.pathname.split("/").includes("password")
		) {
			if (!appPaths.includes(window.location.pathname)) {
				if (
					!error.response.data.success &&
					error.response.data.message === "Unauthorized Access"
				) {
					localStorage.clear();
					sessionStorage.clear();
					window.location.href = "/signin";
				}
			}
		}
	}
);

React.icons = icons;

ReactDOM.render(
	<Provider store={store}>
		<UserContext.Provider value={null}>
			<App />
		</UserContext.Provider>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
