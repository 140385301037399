import React, { Component } from "react";
import validator from "validator";
import { forgetPassword } from "../../services/user";
import dlogo from "src/containers/logo_dark.png";
import { toast } from "react-toastify";

export default class ForgetPass extends Component {
	constructor(props) {
		super(props);

		this.state = {
			warning: "",
			email: "",
			emailSent: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleChange = (e) => {
		this.setState({ email: e.target.value });
	};

	handleSubmit = async () => {
		if (validator.isEmail(this.state.email) && this.state.emailSent === false) {
			const { success, message } = await forgetPassword(this.state.email);
			if (success) {
				toast.success(message);
				this.setState({ warning: message, emailSent: true });
			} else {
				toast.error(message);
				this.setState({ warning: "Please Enter Correct Email" });
			}
		}
	};

	render() {
		return (
			<section>
				<div className="base-container">
					<div className="header">
						<img src={dlogo} alt="QuadFi Logo" />
					</div>
					<div className="content">
						<div className="form">
							<div className="form-group">
								<input
									value={this.state.email}
									type="email"
									name="email"
									onChange={this.handleChange}
									placeholder="Email"
									className="user-input"
								/>
							</div>
						</div>
						<span>{this.state.warning}</span>
					</div>
					<div className="cta-button">
						<button type="button" className="btn" onClick={this.handleSubmit}>
							Reset Password
						</button>
					</div>
					<div className="footer">
						<a href="/signin">Login</a>
						<span>
							Need an account? <a href="/signup">Sign Up Here.</a>
						</span>
					</div>
				</div>
			</section>
		);
	}
}
