import React, { useState, useEffect } from "react";
import { TheSidebar } from "../../containers/index";
import menuIcon from "../../assets/icons/menuIcon.png";
import TopNav from "../../containers/TopNav";
import { useSelector } from "react-redux";
import "../../style/documents.scss";
import {
	getuserInfo,
	getUserApplications,
	getDocumentsStatus,
	getVouchedData,
} from "../../services/user";

import LegalDoc from "./LegalDoc";

import { CContainer, CSelect, CCardHeader, CLabel, CImg } from "@coreui/react";
import Loader from "react-loader-spinner";
import { getLoggedUser } from "src/actions/users";

const LegalDocuments = () => {
	const userInbox = useSelector((state) => {
		return state.userInbox;
	});
	const unRead = userInbox.filter((email) => !email.isRead);
	const [activeCls, setActivecls] = useState(false);
	const menuHandler = () => {
		setActivecls(!activeCls);
	};
	const [downloadPadConsent, setPadConsent] = useState("false");
	const [downloadPadConfirmation, setPadConfirmation] = useState("false");
	const [reload, setreload] = useState(false);
	const [downloadLoanAgreement, setLoanAgreement] = useState("false");

	const [applications, setApplications] = useState([]);
	const [applicationId, setApplicationId] = React.useState("");
	const [loading, setLoading] = React.useState("");
	const [user, setUser] = useState({});
	const [editShow, setEditShow] = React.useState(false);
	const [docStatus, setDocStatus] = useState(false);
	const [showDocument, setShowDocument] = useState(false);

	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	const handleSelect = async (event) => {
		console.log(applicationId);
		if (event.target.value !== 0) {
			sessionStorage.setItem("CurrentSelect", event.target.value);
			setEditShow(true);
			console.log(editShow);
			let userId = event.target.value;
			setApplicationId(event.target.value);
			showLoader();
			let userdata = await getuserInfo(userId);
			setUser(userdata);

			let pad_consent = await getVouchedData(
				sessionStorage.getItem("CurrentSelect"),
				"PAD_Consent"
			);
			if (pad_consent.success === true) {
				if (pad_consent.data[0].approvedByAdmin === 1) {
					await setPadConsent("true");
				}
			}

			let loan_agreement = await getVouchedData(
				sessionStorage.getItem("CurrentSelect"),
				"Loan_Agreement"
			);

			if (loan_agreement.success === true) {
				if (loan_agreement.data[0].approvedByAdmin === 1) {
					await setLoanAgreement("true");
				}
			}

			await getVouchedData(
				sessionStorage.getItem("CurrentSelect"),
				"PAD_Confirmation"
			);

			await setreload(!reload);
			hideLoader();
		} else {
			setEditShow(false);
		}
	};
	const useeffectfunction = async () => {
		showLoader();
		let userSession = await getLoggedUser();
		let userApps = await getUserApplications(userSession.id);

		let currApps = userApps.applications.filter((item) => {
			if (
				item.status_id === 16 ||
				item.status_id === 6 ||
				item.status_id === 4 ||
				item.status_id === 9
			) {
				return false;
			} else {
				return item;
			}
		});
		console.log("userAPsssssssssssssssssss", currApps);

		console.log(userApps, "aklfj");
		if (currApps && currApps.length) {
			await setApplications(currApps);
			console.log("apppppppsssss", currApps);
		}

		if (
			sessionStorage.getItem("CurrentSelect") !== 0 &&
			sessionStorage.getItem("CurrentSelect") != null
		) {
			userApps.applications.map(async (item) => {
				if (item.appId === sessionStorage.getItem("CurrentSelect")) {
					if (item.status_id >= 11 && item.status_id !== 25) {
						await setShowDocument(true);
					} else {
						await setShowDocument(false);
					}
				}
			});
			console.log("truuuuuueee");
			await setEditShow(true);

			await setApplicationId(sessionStorage.getItem("CurrentSelect"));
			showLoader();

			let pad_consent = await getVouchedData(
				sessionStorage.getItem("CurrentSelect"),
				"PAD_Consent"
			);
			if (pad_consent.success === true) {
				if (pad_consent.data[0].approvedByAdmin === 1) {
					await setPadConsent("true");
				}
			}

			let loan_agreement = await getVouchedData(
				sessionStorage.getItem("CurrentSelect"),
				"Loan_Agreement"
			);

			if (loan_agreement.success === true) {
				if (loan_agreement.data[0].approvedByAdmin === 1) {
					await setLoanAgreement("true");
				}
			}

			await getVouchedData(
				sessionStorage.getItem("CurrentSelect"),
				"PAD_Confirmation"
			);

			let userdata = await getuserInfo(sessionStorage.getItem("CurrentSelect"));
			console.log("userData", userdata);
			await setUser(userdata);
			checkForPadConfirmation(userdata);
			hideLoader();
		} else {
			console.log("here");
			const option = document.querySelectorAll(".options");
			await sessionStorage.setItem("CurrentSelect", option[0].value);
			let userId = option[0].value;
			await setApplicationId(option[0].value);
			showLoader();

			userApps.applications.map(async (item) => {
				if (item.appId === option[0].value) {
					if (item.status_id >= 11 && item.status_id !== 25) {
						await setShowDocument(true);
					} else {
						await setShowDocument(false);
					}
				}
			});

			let userdata = await getuserInfo(userId);
			await setUser(userdata);
			checkForPadConfirmation(userdata);

			let pad_consent = await getVouchedData(
				sessionStorage.getItem("CurrentSelect"),
				"PAD_Consent"
			);
			if (pad_consent.success === true) {
				if (pad_consent.data[0].approvedByAdmin === 1) {
					await setPadConsent("true");
				}
			}

			let loan_agreement = await getVouchedData(
				sessionStorage.getItem("CurrentSelect"),
				"Loan_Agreement"
			);

			if (loan_agreement.success === true) {
				if (loan_agreement.data[0].approvedByAdmin === 1) {
					await setLoanAgreement("true");
				}
			}

			let pad_confirmation = await getVouchedData(
				sessionStorage.getItem("CurrentSelect"),
				"PAD_Confirmation"
			);
			if (pad_confirmation.success === true) {
				if (pad_confirmation.data[0].approvedByAdmin === 1) {
				}
			}
			hideLoader();
		}
		const appId =
			sessionStorage.getItem("CurrentSelect").length > 0
				? sessionStorage.getItem("CurrentSelect")
				: sessionStorage.getItem("appId");
		const documentStatus = await getDocumentsStatus(appId);
		console.log("documentStatus", documentStatus);
		await setDocStatus(documentStatus);
	};

	useEffect(() => {
		useeffectfunction();
		//eslint-disable-next-line
	}, [reload]);

	useEffect(() => {
		const localFn = async () => {
			const appId =
				sessionStorage.getItem("CurrentSelect").length > 0
					? sessionStorage.getItem("CurrentSelect")
					: sessionStorage.getItem("appId");
			let userdata = await getuserInfo(appId);
			await setUser(userdata);
			checkForPadConfirmation(userdata);
			const documentStatus = await getDocumentsStatus(appId);
			console.log("documentStatus", documentStatus);
			await setDocStatus(documentStatus);
		};
		localFn();
		//eslint-disable-next-line
	}, [sessionStorage.getItem("CurrentSelect")]);

	const checkForPadConfirmation = (data) => {
		if (data.pad_consent && data.pad_consent.PAD_Consent) {
			setPadConfirmation("true");
		} else {
			setPadConfirmation("false");
		}
	};

	useEffect(() => {
		const localFn = async () => {
			const interval = setInterval(async () => {
				let pad_consent = await getVouchedData(
					sessionStorage.getItem("CurrentSelect"),
					"PAD_Consent"
				);
				if (pad_consent.success === true) {
					if (pad_consent.data[0].approvedByAdmin === 1) {
						await setPadConsent("true");
					}
				}

				let loan_agreement = await getVouchedData(
					sessionStorage.getItem("CurrentSelect"),
					"Loan_Agreement"
				);
				if (loan_agreement.success === true) {
					if (loan_agreement.data[0].approvedByAdmin === 1) {
						await setLoanAgreement("true");
					}
				}

				let pad_confirmation = await getVouchedData(
					sessionStorage.getItem("CurrentSelect"),
					"PAD_Confirmation"
				);
				if (pad_confirmation.success === true) {
					if (pad_confirmation.data[0].approvedByAdmin === 1) {
					}
				}
			}, 10000);

			return () => clearInterval(interval);
		};
		localFn();
	}, []);

	return (
		<div className="legal_documents">
			<div className="top_navOuter position-relative">
				<button className="bars" onClick={menuHandler}>
					<CImg src={menuIcon}></CImg>
				</button>
				<TopNav unRead={unRead} />
			</div>
			<CContainer fluid className="px-0">
				<div className="d-flex main-outer-csp">
					<TheSidebar />
					<div className="csp-content-outer nw-costumer c-wrapper">
						<div>
							<CCardHeader style={{ display: "none" }}>
								<div className="d-flex mb-3">
									<CLabel
										style={{
											alignSelf: "center",
											marginRight: "10px",
										}}
										htmlFor="selectLg"
										className="mb-0">
										Select Application
									</CLabel>

									<CSelect
										style={{ width: "30%" }}
										custom
										name="ccmonth"
										id="ccmonth"
										onChange={handleSelect}>
										<option value="0">Please select</option>
										{applications.map((color, index) => (
											<option
												value={color.appId}
												className="options"
												selected={
													sessionStorage.getItem("CurrentSelect") ===
													color.appId
														? true
														: false
												}>
												{color.productName} -{" $"}
												{color.offerAmount == null
													? "0"
													: color.offerAmount
															.toString()
															.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
											</option>
										))}
									</CSelect>
								</div>
							</CCardHeader>
							{loading ? (
								<div
									className={
										"load-class " + (!user.address ? "load_change" : "")
									}>
									<Loader
										type="ThreeDots"
										color="#ff9700"
										height={100}
										width={100}
										timeout={10000}
									/>
								</div>
							) : (
								<div className="user-info-app">
									<h3>
										{docStatus
											? ""
											: showDocument && (
													<span
														style={{
															float: "right",
															backgroundColor: "#ff9e00",
															color: "white",
															padding: "5px",
														}}>
														Please verify the general documents first.
													</span>
											  )}
									</h3>
									{showDocument ? (
										<div className="legal-documents">
											<LegalDoc
												docLink={
													process.env.REACT_APP_LEGAL_DOCUMENT_LOAN_AGREEMENT
												}
												fileType="Loan_Agreement"
												DocName="Loan_Agreement.doc"
												fileName="loan_agreement"
												padConsentFlag={downloadLoanAgreement}
												docStatus={docStatus}
												showLoader={showLoader}
												hideLoader={hideLoader}
												setreload={setreload}
												reload={reload}></LegalDoc>
											<LegalDoc
												docLink={
													process.env.REACT_APP_LEGAL_DOCUMENT_PAD_CONSENT
												}
												fileType="PAD_Consent"
												completeItem="yes"
												DocName="PAD_Consent.docx"
												fileName="pad_consent"
												padConsentFlag={downloadPadConsent}
												docStatus={docStatus}
												showLoader={showLoader}
												hideLoader={hideLoader}
												setreload={setreload}
												reload={reload}></LegalDoc>
											{downloadPadConfirmation === "true" &&
											downloadPadConsent === "true" ? (
												<LegalDoc
													docLink={
														process.env
															.REACT_APP_LEGAL_DOCUMENT_PAD_CONFIRMATION
													}
													fileType="PAD_Confirmation"
													DocName="PAD_Confirmation.docx"
													fileName="pad_confirmation"
													padConsentFlag={downloadPadConfirmation}
													docStatus={downloadPadConfirmation === "true"}
													showLoader={showLoader}
													hideLoader={hideLoader}
													setreload={setreload}
													reload={reload}></LegalDoc>
											) : (
												""
											)}
										</div>
									) : (
										<p className="error-text-message">
											Please complete the application before accessing your loan
											documents
										</p>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</CContainer>
		</div>
	);
};

export default React.memo(LegalDocuments);
