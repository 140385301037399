import { createBrowserHistory } from "history";
import React from "react";
import ReactGA from "react-ga";

const Home = React.lazy(() => import("./page/Home/home"));
const UserInfo = React.lazy(() => import("./page/BasicInfo/userInfo"));
const AddressInfo = React.lazy(() => import("./page/BasicInfo/AddressInfo"));
const EducationInfo = React.lazy(() =>
	import("./page/BasicInfo/EducationInfo")
);
const EmployeeInfo = React.lazy(() =>
	import("./page/BasicInfo/EmploymentInfo")
);
const GeneralDocument = React.lazy(() =>
	import("./page/Documents/generalDocument")
);
const LegalDocument = React.lazy(() =>
	import("./page/Documents/legalDocument")
);

const LoanDetails = React.lazy(() => import("./page/LoanDetail/loanDetail"));
const Schedule = React.lazy(() => import("./page/Schedule/schedule"));
const Statement = React.lazy(() => import("./page/Statement/Statement"));
const Message = React.lazy(() => import("./page/Message/message"));
const ApplicationFlow = React.lazy(() =>
	import("./page/Application_flow/index")
);

createBrowserHistory().listen((location) => {
	ReactGA.set({ page: location.pathname + location.hash });
	ReactGA.pageview(location.pathname + location.hash);
});
const routes = [
	{ path: "/auth", name: "Auth", component: ApplicationFlow },
	{ path: "/", exact: true, name: "Home", component: Home },
	{
		path: "/basicInfo",
		name: "Personal Information",
		exact: true,
		component: UserInfo,
	},
	{ path: "/basicInfo/userInfo", name: "General", component: UserInfo },
	{ path: "/basicInfo/AddressInfo", name: "Address", component: AddressInfo },
	{
		path: "/basicInfo/EducationInfo",
		name: "Education",
		component: EducationInfo,
	},
	{
		path: "/basicInfo/EmploymentInfo",
		name: "Employment",
		component: EmployeeInfo,
	},
	{
		path: "/documents",
		name: "Documents",
		exact: true,
		component: GeneralDocument,
	},
	{
		path: "/documents/generalDocuments",
		name: "Customer Documents",
		exact: true,
		component: GeneralDocument,
	},
	{
		path: "/documents/legalDocuments",
		name: "Legal Documents",
		component: LegalDocument,
	},
	{
		path: "/loanDetails",
		exact: true,
		name: "Loan Details",
		component: LoanDetails,
	},
	{ path: "/schedule", exact: true, name: "Schedule", component: Schedule },
	{
		path: "/statement",
		exact: true,
		name: "Statement",
		component: Statement,
	},
	{ path: "/message", exact: true, name: "Message", component: Message },
];

export default routes;
