import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CProgress,
} from "@coreui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import errorIcon from "src/assets/icons/App-Flow-Icons/error-search-icon.png";
import logoIcon from "src/assets/icons/app-logo.png";
import { sendAnalytics } from "src/services/analyticsService";
import usePageTracking from "src/usePageTracking";
import { STEP_PAGES } from "src/Utils/enums";
import { headers } from "../../reusable/headers";

const ErrorPage = (props) => {
	const history = useHistory();
	const [rejectedApp, setRejectedApp] = useState(false);

	const handleClick = () => {
		history.push("customerPortal");
	};

	useEffect(() => {
		(async function () {
			if (!props?.location?.state?.isFraudCheckFailed) {
				await sendAnalytics(new Date(), STEP_PAGES.REJECTED);
			} else {
				await sendAnalytics(new Date(), STEP_PAGES.TRY_LATER);
			}
		})();
	});

	useEffect(() => {
		if (
			sessionStorage.getItem("loanLimitExceeded") &&
			sessionStorage.getItem("loanLimitExceeded") === 1
		) {
			setRejectedApp(true);
		}
	}, []);

	usePageTracking();

	const handleLogin = async () => {
		axios
			.get(`${process.env.REACT_APP_API_HOST}/V1/user/logout`, {
				headers: headers(),
				withCredentials: true,
			})
			.then((response) => {
				sessionStorage.clear();
				localStorage.clear();
				window.location.href = `/signin`;
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div className="no-scroll vertical-middle-page">
					<div class="content-container">
						<div className="text-container center error-page">
							<div></div>
							<div className="error-page-content-container">
								<ul className="list-inline error-page-head">
									<li className="list-inline-item">
										<img src={errorIcon} alt="" />
									</li>
									<li className="list-inline-item">
										<h3>
											<b>
												Unfortunately, we are unable to issue you a loan at this
												time.
											</b>
										</h3>
									</li>
								</ul>
								{!props.location.state?.isFraudCheckFailed ? (
									<h4 className="error-page-text-content">
										We appreciate you taking the time to apply for a loan with
										QuadFi.
										<br />
										<br />
										Our platform makes decisions automatically, without human
										bias, using alternative data. Sometimes there isn't enough
										information available to authorize a loan.
										<br />
										<br />
										We understand how disappointing this can be, and encourage
										you to apply again in a few months when there is change in
										your income or academic progress.
										<br />
										<br />
										Please contact us if you have any questions or concerns. We
										are always here to help.
										<br />
										<br />
										QuadFi
									</h4>
								) : (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											height: "5vh",
										}}>
										<h4>Please retry after 5 business days.</h4>
									</div>
								)}
							</div>
						</div>
						<div className="button-container">
							<CButton className="custom-button enabled" onClick={handleClick}>
								Go back to Portal
							</CButton>
						</div>
					</div>
				</div>
			</div>
			<CModal
				className="custom-notification-sweetalert vertical-alert"
				show={rejectedApp}
				onClose={() => setRejectedApp(false)}
				color="warning">
				<CModalHeader className="custom-notification-no-header">
					<CModalTitle></CModalTitle>
				</CModalHeader>
				<CModalBody className="text-center">
					<p className="custom-notification-title">
						Your application is rejected!
					</p>
					<p className="custom-notification-text">
						You already have an active application, so you cannot apply for a
						new application. Please log into your old existing account.
					</p>
				</CModalBody>
				<CModalFooter>
					<CButton
						className="custom-notification-btn-ok"
						color="info"
						onClick={() => handleLogin()}>
						Login
					</CButton>{" "}
					<CButton
						className="custom-notification-btn-cancel"
						color="default"
						onClick={() => setRejectedApp(false)}>
						Cancel
					</CButton>
				</CModalFooter>
			</CModal>
		</section>
	);
};

export default ErrorPage;
