import * as actionTypes from "../actions/actionTypes";

const initialState = {
	authenticated: false,
	loading: false,
	data: [],
	success_message: undefined,
	success: false,
	error: undefined,
	rm_loading: false,
};

const purposes = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_RESPONSE_ERROR:
			return {
				...state,
				rm_loading: false,
				loading: false,
				error: action.payload.response && action.payload.response.data.error,
			};
		case actionTypes.ALL_PURPOSE: {
			let newstate = Object.assign({}, state, {
				...state,
				loading: false,
				success: true,
				success_message: "All Purposes",
				purposes: action.payload.response,
			});
			console.log("newState", newstate);
			return newstate;
		}
		case actionTypes.CLEAR_ERROR:
			return {
				...state,
				loading: false,
				success: false,
				success_message: undefined,
				error: undefined,
			};
		case actionTypes.STOP_LOADER:
			return { ...state, rm_loading: false, loading: false };
		default:
			return { ...state, loading: false };
	}
};

export default purposes;
