import { useEffect } from "react";
import logoIcon from "src/assets/icons/app-logo.png";
import { sendAnalytics } from "src/services/analyticsService";
import usePageTracking from "src/usePageTracking";
import { STEP_PAGES } from "src/Utils/enums";

const TryLater = () => {
	useEffect(() => {
		(async function () {
			await sendAnalytics(new Date(), STEP_PAGES.TRY_LATER);
		})();
	});
	usePageTracking();

	return (
		<section className="purposeWrappers">
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
				</div>
				<div className="no-scroll vertical-middle-page">
					<div style={{ textAlign: "center" }}>
						<h2>
							<strong>
								Unfortunately, at this moment we cannot process your
								application.
							</strong>
						</h2>
						<h5>
							Please retry after{" "}
							{sessionStorage.getItem("try_after") ? "sometime" : "72 Hours"}.
						</h5>
					</div>
				</div>
			</div>
		</section>
	);
};

export default TryLater;
