import {
	CBadge,
	CButton,
	CCardBody,
	CCollapse,
	CContainer,
	CDataTable,
	CImg,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { getLoggedUser } from "src/actions/users";
import { formatDate } from "src/utils";
import menuIcon from "../../assets/icons/menuIcon.png";
import TheSidebar from "../../containers/TheSidebar";
import TopNav from "../../containers/TopNav";
import {
	getApplicationSchedule,
	getUserApplications,
	userInfo,
} from "../../services/user";
import "../../style/personalinformation.scss";

const Schedule = () => {
	const userInbox = useSelector((state) => {
		return state.userInbox;
	});
	const unRead = userInbox.filter((email) => !email.isRead);

	const [scheduleData, setScheduleData] = useState([]);

	const [editShow, setEditShow] = React.useState(false);
	const [applications, setApplications] = useState([]);
	const [applicationId, setApplicationId] = React.useState("");
	const [loading, setLoading] = React.useState("");
	const [user, setUser] = useState({});
	const details = [];
	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	useEffect(() => {
		const localFn = async () => {
			console.log(editShow);
			showLoader();
			let userdata = await getLoggedUser();
			let userApps = await getUserApplications(userdata.id);
			console.log(userApps, "aklfj");
			if (
				userApps.success &&
				userApps.applications &&
				userApps.applications.length > 0
			) {
				let _arr = [];
				for (let app of userApps.applications) {
					if (Number(app.status_id) >= 16 && Number(app.status_id) <= 21)
						_arr.push(app);
				}
				await setApplications(_arr);
			}
			console.log(applications);
			if (
				sessionStorage.getItem("CurrentSelect") !== 0 &&
				sessionStorage.getItem("CurrentSelect") != null
			) {
				setEditShow(true);
				async function Call() {
					setApplicationId(sessionStorage.getItem("CurrentSelect"));
					showLoader();
					await userInfo(sessionStorage.getItem("CurrentSelect"), setUser);
					hideLoader();
				}
				Call();
			} else {
				const option = document.querySelectorAll(".options");
				if (option.length > 0) {
					console.log("current sel", option);
					sessionStorage.setItem("CurrentSelect", option[0].value);
					let userId = option[0].value;
					console.log("here", option[0].value);
					setApplicationId(option[0].value);
					showLoader();
					await userInfo(userId, setUser);
					hideLoader();
				}
			}
		};
		localFn();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		const localFn = async () => {
			console.log("app di = ", applicationId);
			if (applicationId) {
				let paymentSchedule = await getApplicationSchedule(applicationId);
				if (paymentSchedule.success) {
					//eslint-disable-next-line
					paymentSchedule.payments.upcoming.map((d) => {
						paymentSchedule.payments.paid.push(d);
					});
					console.log(
						paymentSchedule.payments.paid,
						"paymentSchedule.payments.paid"
					);
					setScheduleData(paymentSchedule.payments.paid);
				}
			}
		};
		localFn();
	}, [applicationId]);

	const fields = [
		"No.",
		{ key: "payment_date", label: "Date" },
		{ key: "total" },
		"principal",
		{ key: "interest" },
		{ key: "outstanding_principal", label: "Remaining Principal" },
		{ key: "status" },
	];

	const getBadge = (status) => {
		switch (status) {
			case "paid on time":
				return "success";
			case "scheduled":
				return "secondary";
			case "paid late":
				return "warning";
			case "paid partial":
				return "danger";
			case "paid extra":
				return "success";
			case "missed":
				return "danger";
			default:
				return "primary";
		}
	};

	const [activeCls, setActivecls] = useState(false);
	const menuHandler = () => {
		setActivecls(!activeCls);
	};

	return (
		<div className="sche-bg">
			<div className="top_navOuter position-relative">
				<button className="bars" onClick={menuHandler}>
					<CImg src={menuIcon}></CImg>
				</button>
				<TopNav unRead={unRead} />
			</div>
			<CContainer fluid>
				<div className="d-flex main-outer-csp">
					<div className={`sideBar-outer ${activeCls ? "sidBrexpand" : null}`}>
						<TheSidebar />
					</div>
					<div className="csp-content-outer">
						<div className="tbl-space tblsNewcls">
							<div class="title">Loan Schedule</div>
							<div className="card">
								<div></div>
								{loading ? (
									<div
										className={
											"load-class " + (!user.address ? "load_change" : "ter")
										}>
										<Loader
											type="ThreeDots"
											color="#ff9700"
											height={100}
											width={100}
											timeout={10000}
										/>
									</div>
								) : (
									<div className="radius-table px-3 pt-3">
										<CDataTable
											items={scheduleData}
											fields={fields}
											itemsPerPage={10}
											hover
											pagination
											scopedSlots={{
												"No.": (item, index) => <td>{index + 1}</td>,
												payment_date: (item) => (
													<td>
														{item.due_date ? formatDate(item.due_date) : ""}
													</td>
												),
												"total": (item, index) => (
													<td>
														$
														{item.total
															? item.total.toLocaleString("en-US", {
																	maximumFractionDigits: 2,
																	minimumFractionDigits: 2,
															  })
															: item.principal
															? (
																	Number(item.interest) + Number(item.principal)
															  ).toLocaleString("en-US", {
																	maximumFractionDigits: 2,
																	minimumFractionDigits: 2,
															  })
															: "0.00"}
													</td>
												),
												principal: (item, index) => (
													<td>
														$
														{item.principal
															? item.principal.toLocaleString("en-US", {
																	maximumFractionDigits: 2,
																	minimumFractionDigits: 2,
															  })
															: "0.00"}
													</td>
												),
												close_date: (item) => (
													<td>
														{item.close_date == null ? "-" : item.close_date}
													</td>
												),
												interest: (item) => (
													<td>
														$
														{item.interest
															? item.interest.toLocaleString("en-US", {
																	maximumFractionDigits: 2,
																	minimumFractionDigits: 2,
															  })
															: "0%"}
													</td>
												),
												outstanding_principal: (item) => (
													<td>
														$
														{item.outstanding_principal
															? item.outstanding_principal.toLocaleString(
																	"en-US",
																	{
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																	}
															  )
															: "0.00"}
													</td>
												),
												status: (item) => (
													<td>
														<CBadge
															style={{
																textTransform: "capitalize",
															}}
															color={getBadge(
																item.schedule_status
																	? item.schedule_status
																	: item.payment_status
															)}>
															{item.schedule_status
																? item.schedule_status
																: item.payment_status
																? item.payment_status
																: ""}
														</CBadge>
													</td>
												),

												details: (item, index) => {
													return (
														<CCollapse show={details.includes(index)}>
															<CCardBody>
																<h4>{item.username}</h4>
																<p className="text-muted">
																	User since: {item.registered}
																</p>
																<CButton size="sm" color="info">
																	User Settings
																</CButton>
																<CButton
																	size="sm"
																	color="danger"
																	className="ml-1">
																	Delete
																</CButton>
															</CCardBody>
														</CCollapse>
													);
												},
											}}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</CContainer>
		</div>
	);
};

export default React.memo(Schedule);
