import * as actionTypes from "../actions/actionTypes";

const initialState = {
	authenticated: false,
	loading: false,
	data: [],
	success_message: undefined,
	unread_message_count: 0,
	success: false,
	error: undefined,
	rm_loading: false,
};

const users = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_RESPONSE_ERROR:
			return {
				...state,
				rm_loading: false,
				loading: false,
				error: action.payload.response && action.payload.response.data.error,
			};
		case actionTypes.LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
				success_message: "Successfully logged In",
				currentUser: action.payload.response,
			};
		case actionTypes.SIGNUP_SUCCESS: {
			return {
				...state,
				loading: false,
				success: true,
				success_message: "Successfully Registerd",
				currentUser: action.payload.response,
			};
		}
		case actionTypes.SET_EQUIFAX_ANSWER_1:
			return {
				...state,
				loading: false,
				success: true,
				success_message: "Answer 1 saved",
				answer: action.payload.answer,
				transactionKey: action.payload.transactionKey,
			};

		case actionTypes.CLEAR_ERROR:
			return {
				...state,
				loading: false,
				success: false,
				success_message: undefined,
				error: undefined,
			};
		case actionTypes.GET_RM_REQUEST:
			return { ...state, rm_loading: true };
		case actionTypes.STOP_LOADER:
			return { ...state, rm_loading: false, loading: false };
		case actionTypes.REMEMBER_TOKEN_SUCCESS:
			return {
				...state,
				rm_loading: false,
				loading: false,
				success: true,
				success_message: "Welcome back",
				currentUser: action.payload.response.user,
			};
		case actionTypes.SET_UNREAD_MESSAGE_COUNT:
			return {
				...state,
				unread_message_count: action.payload.count || 0,
			};
		default:
			return { ...state, loading: false };
	}
};

export default users;
