import { CButton, CProgress } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import degreeIcon from "src/assets/icons/App-Flow-Icons/degree-icon.png";
import diplomaIcon from "src/assets/icons/App-Flow-Icons/diploma-icon.png";
import graduateIcon from "src/assets/icons/App-Flow-Icons/graduate-icon.png";
import otherEduIcon from "src/assets/icons/App-Flow-Icons/other-education.png";
import prficon from "src/assets/icons/App-Flow-Icons/professional-degree-icon.png";
import logoIcon from "src/assets/icons/app-logo.png";
import infoIcon from "src/assets/icons/info.svg";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { sendAnalytics } from "src/services/analyticsService";
import { debug } from "src/services/debugService";
import { STEP_PAGES } from "src/Utils/enums";
import { getUserInProgressApp, updateUserAppFlow } from "../../services/user";
import { Tooltip } from "src/Components/Tooltip/Tooltip";

const HighestDegree = (props) => {
	const inProgressApp = getUserInProgressApp();
	let preFillDegree = "";
	if (inProgressApp) {
		preFillDegree = inProgressApp.appData.education.degree;
	}
	const [highestDegree, setHighestDegree] = React.useState(preFillDegree);

	const { register, errors, handleSubmit, formState, watch } = useForm({
		mode: "onChange",
	});
	const { isValid } = formState;

	const [mouseClick, setMouseClick] = useState(1);
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const arrivalTime = new Date();
	debug("HighestDegree props: ", props, arrivalTime);

	const handleHighestDegree = (event) => {
		setHighestDegree(event.target.value);
	};
	const [loading, setLoading] = React.useState("");
	const hideLoader = () => {
		setLoading(false);
	};

	const showLoader = () => {
		setLoading(true);
	};

	useEffect(() => {
		if (highestDegree !== "" && mouseClick > 1) {
			handleSave();
		}
		//eslint-disable-next-line
	}, [highestDegree, mouseClick]);

	const handleSave = async (data) => {
		setNextButtonDisabled(true);
		let details = {
			page: "highestDegree",
			highestDegree,
		};
		showLoader();
		sessionStorage.setItem("highestDegree", highestDegree);
		const response = await updateUserAppFlow(props.user.id, details);
		const currentProduct = sessionStorage.getItem("productId");
		if (response.success) {
			setNextButtonDisabled(false);
			hideLoader();
			if (highestDegree === 14) {
				let skipTo = "";
				if (currentProduct === "4" || currentProduct === "3") {
					skipTo = 15;
				}
				if (skipTo !== "") {
					props.goToStep(skipTo);
				}
			} else {
				props.nextStep();
			}
			await sendAnalytics(arrivalTime, STEP_PAGES.HIGHEST_DEGREE);
		}
	};

	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");

		let details = {
			page: "highestDegree",
			highestDegree,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			hideLoader();
			props.handleSaveExit();
			props.lastStep();
		}
	};

	const handleSaveAndBack = async () => {
		localStorage.setItem("PreviousPage", "YES");

		let details = {
			page: "highestDegree",
			highestDegree: watch("Highest-Degree-Status"),
		};
		updateUserAppFlow(props.user.id, details);
		props.previousStep();
		await sendAnalytics(arrivalTime, STEP_PAGES.HIGHEST_DEGREE);
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};
	console.log("Mouse Click => " + mouseClick);

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll tablet vertical-middle-page">
					<div>
						<div class="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>What is the highest degree you’ve completed?</b>
								</h1>
							</div>
							<div className="cards-container">
								<div className="icon-card">
									<input
										type="radio"
										id="Bachelors"
										name="Highest-Degree-Status"
										value="1"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onChange={handleHighestDegree}
										onClick={handleClick}
										checked={highestDegree === 1 ? true : false}
									/>
									<label htmlFor="Bachelors" className="card-label">
										<img src={graduateIcon} alt="" />
										<b>Bachelor's</b>
									</label>
									<div className="info-container icon-card-container">
										<p className="tool-tip">
											<b>
												A Bachelors is an undergraduate academic degree awarded
												by a Canadian college and universities upon completion
												of a course of study.
											</b>
										</p>
										<img src={infoIcon} alt="" />
									</div>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="Masters"
										name="Highest-Degree-Status"
										value="9"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onChange={handleHighestDegree}
										onClick={handleClick}
										checked={highestDegree === 9 ? true : false}
									/>
									<label htmlFor="Masters" className="card-label">
										<img src={diplomaIcon} alt="" />
										<b>Master's</b>
									</label>
									<div className="info-container icon-card-container">
										<p className="tool-tip">
											<b>
												A Masters is a graduate academic degree awarded by a
												Canadian university or college upon completion of a
												course of study. A Masters degree typically follows an
												undergraduate degree and precedes a doctorate or
												professional degree.
											</b>
										</p>
										<img src={infoIcon} alt="" />
									</div>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="Doctorate"
										name="Highest-Degree-Status"
										value="2"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onChange={handleHighestDegree}
										onClick={handleClick}
										checked={highestDegree === 2 ? true : false}
									/>
									<label htmlFor="Doctorate" className="card-label">
										<img src={degreeIcon} alt="" />
										<b>Doctorate</b>
									</label>
									<div className="info-container icon-card-container">
										<p className="tool-tip">
											<b>
												A Doctorate is generally the highest graduate academic
												degree awarded by universities. In most countries, this
												degree is a requirement for tenure-track positions at
												universities.
											</b>
										</p>
										<img src={infoIcon} alt="" />
									</div>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="ProfessionalDegree"
										name="Highest-Degree-Status"
										value="13"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onChange={handleHighestDegree}
										onClick={handleClick}
										checked={highestDegree === 13 ? true : false}
									/>
									<label htmlFor="ProfessionalDegree" className="card-label">
										<img src={prficon} alt="" />
										<b>Professional Degree</b>
									</label>
									<div className="info-container icon-card-container">
										<p className="tool-tip">
											<b>
												A professional degree is a degree that prepares someone
												to work in a particular profession , practice, or
												industry sector and often establishes one's academic
												qualifications for licensing, accreditation and
												practice.
											</b>
										</p>
										<img src={infoIcon} alt="" />
									</div>
								</div>
								<div className="icon-card">
									<input
										type="radio"
										id="OtherEducation"
										name="Highest-Degree-Status"
										value="14"
										class="custom-radio"
										ref={register({
											required: "Please select one of the options",
										})}
										onChange={handleHighestDegree}
										onClick={handleClick}
										checked={highestDegree === 14 ? true : false}
									/>
									<label htmlFor="OtherEducation" className="card-label">
										<img src={otherEduIcon} alt="" />
										<b>Other</b>
									</label>
									<div className="info-container icon-card-container">
										<p className="tool-tip">
											<b>
												Pick this option if you do not have an undergraduate or
												graduate degree from a Canadian university or college.
											</b>
										</p>
										<img src={infoIcon} alt="" />
									</div>
								</div>
							</div>
							{Object.keys(errors).length > 0 && (
								<div className="error-container">
									<p className="error-message">
										{errors[Object.keys(errors)[0]].message}
									</p>
								</div>
							)}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{isValid || highestDegree !== "" ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							) : (
								<button
									className="custom-button disabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							)}
							<button
								className="custom-back-button"
								onClick={handleSaveAndBack}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
};

export default HighestDegree;
