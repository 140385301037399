import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import users from "./users";
import purposes from "./purposes";
import products from "./products";
import userInbox from "./userInbox";
export default combineReducers({
	users: users,
	purposes: purposes,
	products: products,
	userInbox: userInbox,
	router: routerReducer,
});
