import { CButton, CProgress } from "@coreui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import logoIcon from "src/assets/icons/app-logo.png";
import loadingIcon from "src/assets/icons/Spinner.svg";
import { sendAnalytics } from "src/services/analyticsService";
import { debug } from "src/services/debugService";
import { PHONE_NUMBER_LENGTH } from "src/Utils/consts";
import { STEP_PAGES } from "src/Utils/enums";
import { getUserInProgressApp, updateUserAppFlow } from "../../services/user";
import { Tooltip } from "src/Components/Tooltip/Tooltip";

export default function PhoneNumber(props) {
	const inProgressApp = getUserInProgressApp();
	let preFill = "";
	if (inProgressApp) {
		preFill = inProgressApp.appData.personal.contact;
		let initialNumber1 = preFill.toString().replace(/[^0-9]/g, "");
		const numWithoutDashes1 = initialNumber1;
		const dashPlaces1 = [3, 6];
		preFill = numWithoutDashes1
			.split("")
			.reduce(
				(acc, curr, i) =>
					dashPlaces1.includes(i) ? [...acc, "-", curr] : [...acc, curr],
				[]
			)
			.join("");
	}
	const { register, errors, handleSubmit, watch } = useForm({
		mode: "onChange",
	});
	const watchAllFields = watch();
	const [contact, setContact] = useState(preFill);
	const [loading, setLoading] = React.useState("");
	const [mouseClick, setMouseClick] = useState(0);
	const [keyboardClick, setKeyboardClick] = useState(0);
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
	const arrivalTime = new Date();
	debug("PhoneNumber props: ", props, arrivalTime);

	const hideLoader = () => {
		setLoading(false);
	};
	const showLoader = () => {
		setLoading(true);
	};

	const handleSave = async (back) => {
		setNextButtonDisabled(true);
		const details = {
			page: "contact",
			contact,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);

		if (response.success) {
			setNextButtonDisabled(false);
			hideLoader();
			if (typeof back !== "boolean" || !back) {
				props.nextStep();
				await sendAnalytics(arrivalTime, STEP_PAGES.PHONE_NUMBER);
			} else {
				localStorage.setItem("PreviousPage", "YES");
				await sendAnalytics(arrivalTime, STEP_PAGES.PHONE_NUMBER);
			}
		}
		return response;
	};
	const handleSaveAndExit = async () => {
		localStorage.setItem("DropOff", "YES");
		const details = {
			page: "contact",
			contact: contact,
		};
		showLoader();
		const response = await updateUserAppFlow(props.user.id, details);
		if (response.success) {
			hideLoader();
			props.handleSaveExit();
			props.lastStep();
		}
	};
	const setPhoneNumber = (value) => {
		if (value) {
			let initialNumber = value.replace(/[^0-9]/g, "");
			const numWithoutDashes = initialNumber;
			const dashPlaces = [3, 6];
			setContact(numWithoutDashes);
			return numWithoutDashes
				.split("")
				.reduce(
					(acc, curr, i) =>
						dashPlaces.includes(i) ? [...acc, "-", curr] : [...acc, curr],
					[]
				)
				.join("");
		}
		return value;
	};

	const handleClick = () => {
		setMouseClick(mouseClick + 1);
		localStorage.setItem("mouseCount", mouseClick);
	};

	return (
		<section>
			<CProgress
				color="stack-overflow"
				value={(props.currentStep / props.totalSteps) * 100}
				className="bg-white"
			/>
			<div id="page-section" className="wrapper">
				<div className="save-exit-button-container">
					<img src={logoIcon} alt="" />
					<CButton className="save-exit-button" onClick={handleSaveAndExit}>
						{" "}
						Save and Exit{" "}
					</CButton>
				</div>
				<div className="no-scroll vertical-middle-page">
					<div>
						<div className="content-container">
							<div className="text-container text-container-margin">
								<h1>
									<b>What is your phone number?</b>
								</h1>
							</div>
							<div className="input-container medium-input">
								<input
									id="nf-contact"
									name="contact"
									placeholder="123-456-7890"
									onChange={(event) => {
										const { value } = event.target;
										event.target.value = setPhoneNumber(value);
										setKeyboardClick(keyboardClick + 1);
										localStorage.setItem("KeyBoardClick", keyboardClick);
									}}
									maxLength={PHONE_NUMBER_LENGTH} // dash is counted
									onClick={handleClick}
									class="form-control-plaintext w-67 h-58 custom-input"
									defaultValue={contact}
									ref={register({
										required: true,
										minLength: 10,
										pattern:
											/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
									})}
								/>
							</div>
							{errors.contact && (
								<div class="error-container">
									<p className="error-message">
										Please enter only numbers with length of 10
									</p>
								</div>
							)}
						</div>
						<div className="load-class">
							{loading ? (
								<div className="load-class-inner">
									<img src={loadingIcon} alt="" />
								</div>
							) : null}
						</div>
						<div className="button-container button-container-margin">
							{preFill ||
							(watchAllFields.contact &&
								typeof errors.contact === "undefined") ? (
								<button
									className="custom-button enabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							) : (
								<button
									className="custom-button disabled"
									onClick={handleSubmit(handleSave)}
									disabled={nextButtonDisabled}>
									Next
								</button>
							)}
							<button
								className="custom-back-button"
								onClick={async () => {
									await handleSave(true);
									if (props?.previousStep) {
										props.previousStep();
									}
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<Tooltip />
		</section>
	);
}
